import {ChangeDetectorRef, ViewChild} from '@angular/core';
import {debounceTime} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {HrMajorService} from '@app/api/hrMajor.service';
import {Subject, Subscription} from 'rxjs';
import {TreegridComponent} from '@app/views/shared/common_elements/treegrid/treegrid.component';
import {SelectSpecialtyService} from './select-specialty.service';

interface TreeNode {
  id: number;
  parent_id: number;
  children?: TreeNode[];
}

@Component({
  selector: 'meu-modal-select-speciality',
  templateUrl: './modal-select-speciality.component.html',
  styleUrls: ['./modal-select-speciality.component.scss'],
})
export class ModalSelectSpecialityComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public keyUp = new Subject<KeyboardEvent>();
  filter: any = '';
  treedata = null;
  selectedValue = [];

  @ViewChild('tree') private treeView: TreegridComponent;

  constructor(
    private hRmajorService: HrMajorService,
    public dialogRef: MatDialogRef<ModalSelectSpecialityComponent>,
    private cdr: ChangeDetectorRef,
    private selectSpecialtyService: SelectSpecialtyService
  ) {}

  ngOnInit(): void {
    this.getListOfSpecialties();
    this.onSearch();
  }

  getListOfSpecialties() {
    this.subscriptions.push(
      this.hRmajorService
        .apiHrMajorGetallGet('name|code@=' + this.filter)
        .subscribe((res) => {
          if (res.success) {
            this.treedata = this.unflatten(res.data.collection);
            this.selectSpecialtyService.dataSourceTable.next(this.treedata);
          }
        })
    );
  }
  unflatten(node) {
    const map = {};
    for (let i = 0; i < node.length; i++) {
      map[node[i].id] = node[i];
    }

    for (let i = 0; i < node.length; i++) {
      const parent = map[node[i].parent_id];
      if (parent) {
        if (parent.children == null) {
          parent.children = [node[i]];
        } else {
          parent.children.push(node[i]);
        }
      }
    }
    return node.filter((e) => e.parent_id == null);
  }
  selectedData(selected: any) {
    this.selectedValue = selected;
  }
  closeModal() {
    this.dialogRef.close();
  }
  onSearch() {
    this.keyUp.pipe(debounceTime(500)).subscribe((res) => {
      this.filter = res;
      this.getListOfSpecialties();
    });
  }
  onSubmit() {
    if (this.selectedValue.length > 0) {
      let array = [];
      this.selectedValue.forEach((element) => {
        array.push(element.name);
      });
      this.dialogRef.close(array.join(', '));
    }
  }
}
