import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RoleManagementComponent } from '../role-management.component';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PRoleService } from '@app/api/api';

@Component({
  selector: 'meu-modal-create-role',
  templateUrl: './modal-create-role.component.html',
  styleUrls: ['./modal-create-role.component.scss']
})
export class ModalCreateRoleComponent implements OnInit {

  hasFormErrors = false;
  isEdit;
  createRoleForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl()
  });

  constructor(
    public dialogRef: MatDialogRef<RoleManagementComponent>,
    @Inject(MAT_DIALOG_DATA) public dataInject,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private pRoleService: PRoleService,
  ) { }

  ngOnInit(): void {
    if (this.dataInject.roleId) {
      this.isEdit = true;
      this.getRoleByID();
    }
    else {
      this.isEdit = false;
    }
  }

  getRoleByID() {
    this.pRoleService.apiPRolesIdGet(this.dataInject.roleId).subscribe(res => {
      this.createRoleForm.setValue({
        name: res.data.name,
        description: res.data.description
      });
    })

  }

  save() {
    const controls = this.createRoleForm.controls;
    if (this.createRoleForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    }

    this.createRoleForm.markAllAsTouched();
    if (this.isEdit) {
      this.pRoleService.apiPRolesIdPut(this.dataInject.roleId, this.createRoleForm.value).subscribe(res => {
        if (res.success) {
          this.dialogRef.close({ event: true });
        }
        else {
          this.toastr.error('Cập nhật Role không thành công')
        }
      })
    }
    else {
      this.pRoleService.apiPRolesPost(this.createRoleForm.value).subscribe(res => {
        if (res.success) {
          this.dialogRef.close({ event: true });
        }
        else {
          this.toastr.error('Tạo mới Role không thành công')
        }
      })
    }

  }

  closeModal() {
    this.dialogRef.close({ event: false });
  }


}
