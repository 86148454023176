import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {HrMajorService} from '@app/api/hrMajor.service';
import {ListOfSpecialtiesComponent} from '@app/views/areas/portal/hr/setting/setting-permission/list-of-specialties/list-of-specialties.component';

@Component({
  selector: 'meu-treegrid-add-dialog',
  templateUrl: './treegrid-add-dialog.component.html',
  styleUrls: ['./treegrid-add-dialog.component.scss'],
})
export class TreegridAddDialogComponent implements OnInit {
  public addmore: FormGroup;
  private listOfSpecialtiesComp: ListOfSpecialtiesComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<TreegridAddDialogComponent>,
    private hRmajorService: HrMajorService
  ) {}
  private subscriptions: Subscription[] = [];
  ngOnInit(): void {
    if (this.data.type === 'addNew') {
      this.addmore = this._fb.group({
        code: '',
        name: '',
      });
    }
    if (this.data.type === 'add') {
      this.addmore = this._fb.group({
        code: '',
        name: '',
      });
    }
    if (this.data.type === 'edit') {
      this.addmore = this._fb.group({
        code: this.data.code,
        name: this.data.name,
      });
    }
    if (this.data.type === 'delete') {
      this.addmore = this._fb.group({
        code: this.data.code,
        name: this.data.name,
      });
    }
  }
  onClose() {
    console.log(1);
    this.dialogRef.close();
  }
  onSubmit() {
    console.log('2');

    if (this.data.type === 'add') {
      if (
        this.addmore.get('code').value.toString().length &&
        this.addmore.get('name').value.length
      ) {
        const s = {
          parent_id: this.data.id,
          code: this.addmore.get('code').value,
          name: this.addmore.get('name').value,
        };
        this.subscriptions.push(
          this.hRmajorService.apiHrMajorCreatePost(s).subscribe((res) => {
            if (res.success) {
              this.dialogRef.close(true);
            }
          })
        );
      }
    } else if (this.data.type === 'edit') {
      if (
        this.addmore.get('code').value.toString().length &&
        this.addmore.get('name').value.length
      ) {
        const s = {
          code: this.addmore.get('code').value,
          name: this.addmore.get('name').value,
        };
        this.subscriptions.push(
          this.hRmajorService
            .apiHrMajorIdPut(this.data.id, s)
            .subscribe((res) => {
              if (res.success) {
                this.dialogRef.close(true);
              }
            })
        );
      }
    } else if (this.data.type === 'delete') {
      if (this.data.id) {
        this.subscriptions.push(
          this.hRmajorService
            .apiHrMajorIdDelete(this.data.id)
            .subscribe((res) => {
              if (res.success) {
                this.dialogRef.close(true);
              }
            })
        );
      }
    } else if (this.data.type === 'addNew') {
      if (
        this.addmore.get('code').value.toString().length &&
        this.addmore.get('name').value.length
      ) {
        const s = {
          code: this.addmore.get('code').value,
          name: this.addmore.get('name').value,
        };
        this.subscriptions.push(
          this.hRmajorService.apiHrMajorCreatePost(s).subscribe((res) => {
            if (res.success) {
              this.dialogRef.close(true);
            }
          })
        );
      }
    }
  }
}
