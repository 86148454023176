import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
} from '@angular/forms';

import * as _ from 'lodash';

@Component({
  selector: 'meu-control-select-search',
  templateUrl: './control-select-search.component.html',
  styleUrls: ['./control-select-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ControlSelectSearchComponent),
      multi: true,
    },
  ],
})
export class ControlSelectSearchComponent implements ControlValueAccessor {
  @Input() placeholderLabel: string;
  @Input() noEntriesFoundLabel: string;
  @Input() arrayInput: any[];
  @Input() defaultID;
  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;
  @Input() allowUnSelect = true;
  @Output() change = new EventEmitter();

  /* @floatLabel:
   * true => show label in normally
   * false => hide label when input has value
   */
  @Input() floatLabel = true;

  ngOnChanges(changes: SimpleChanges) {
    if (this.arrayInput != undefined) {
      this.fiteredInput = this.arrayInput;
    }
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values
  }

  public fiteredInput: any[];
  public selectCtrl: FormControl = new FormControl();
  public searchControl: FormControl = new FormControl();

  private _value: any;
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
    this.propagateChange(this._value);
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    //throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    //throw new Error("Method not implemented.");
  }

  private propagateChange = (_: any) => {};

  constructor() {}

  ngOnInit() {
    //this.fiteredInput = [...this.arrayInput];

    this.selectCtrl.valueChanges.subscribe(() => {
      this.emitChangeValue(this.selectCtrl.value);
    });
    this.searchControl.valueChanges.subscribe(() => {
      this.filterData(this.searchControl.value);
    });
  }

  emitChangeValue(event) {
    this.value = event;
    this.change.emit(this.value);
    this.defaultID = event;
  }

  filterData(search) {
    if (!search) {
      this.fiteredInput = [...this.arrayInput];
    }
    search = search.toLowerCase();
    this.fiteredInput = _.filter(this.arrayInput, function (obj) {
      return obj.name.toLowerCase().indexOf(search) !== -1;
    });
  }

  unselect(): void {
    this.defaultID = null;
  }
}
