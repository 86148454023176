<div class="modal-header">
  <h4 class="modal-title text-16px pull-left">
    {{ input.isBudget ? 'Thiết lập ngân sách' : 'Thiết lập số lượng nhiệm vụ' }}
  </h4>
  <button
    type="button"
    class="close pull-right header-button"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="container-fluid">
    <form [formGroup]="companyFrm">
      <div class="row">
        <div class="col-lg-6 col-6">
          <div class="form-field">
            <span class="title">{{
              input.isBudget ? 'Ngân sách tối đa' : 'Số lượng nhiệm vụ tối đa'
            }}</span>
            <span class="color-red"> *</span>
            <input
              [ngClass]="
                isControlHasError('value', 'required')
                  ? 'modal-input-error'
                  : ''
              "
              class="modal-input"
              [placeholder]="
                input.isBudget ? 'Nhập ngân sách' : 'Nhập nhiệm vụ'
              "
              formControlName="value"
              autocomplete="off"
            />
            <span class="error" *ngIf="isControlHasError('value', 'required')"
              >{{
                input.isBudget ? 'Ngân sách tối đa' : 'Số lượng nhiệm vụ tối đa'
              }}
              <strong>không hợp lệ</strong>
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-6">
          <div class="form-field">
            <span class="title">Thời gian có hiệu lực</span>
            <span class="color-red"> *</span>
            <div class="input-group modal-input">
              <input
                type="text"
                name="name"
                class="pl-0 input-info"
                placeholder="Chọn thời gian"
                formControlName="date"
                matInput
                [matDatepicker]="dateTemplate"
              />
              <div
                class="input-group-append div-icon-append icon-datePicker-input"
              >
                <i
                  class="fa fa-calendar-o icon-open-datepicker"
                  aria-hidden="true"
                  (click)="openDatePicker(dateTemplate)"
                  autocomplete="off"
                ></i>
              </div>
            </div>
            <mat-datepicker #dateTemplate></mat-datepicker>
            <span class="error" *ngIf="isControlHasError('date', 'required')"
              >Thời gian hiệu lực
              <strong>bắt buộc</strong>
            </span>
          </div>
        </div>
      </div>
    </form>
    <span
      class="history-label"
      *ngIf="histories.length > 0"
      (click)="isShowHistory = !isShowHistory"
      >Đã chỉnh sửa vào ngày {{ momentDMY(histories[0].updated_at)
      }}<i
        class="fa ml-2"
        [ngClass]="{
          'fa-caret-down': !isShowHistory,
          'fa-caret-up': isShowHistory
        }"
      ></i
    ></span>
    <div class="modal-title text-16px title-section" *ngIf="isShowHistory">
      Lịch sử chỉnh sửa
    </div>
    <div
      class="example-container mat-elevation-z8 mat-table-user"
      *ngIf="isShowHistory"
    >
      <mat-table #table [dataSource]="histories">
        <ng-container matColumnDef="updated_at">
          <mat-header-cell *matHeaderCellDef>
            Thời gian chỉnh sửa
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="ellipsis"
            [title]="momentDMY(element.updated_at)"
          >
            <span class="ellipsis">
              {{ momentDMY(element.updated_at) }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef>
            {{ input.isBudget ? 'Ngân sách' : 'Số lượng' }}
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="ellipsis"
            [title]="formatCurrencyNumber(element.description)"
          >
            <span class="ellipsis">
              {{ formatCurrencyNumber(element.description) }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="effective_time">
          <mat-header-cell *matHeaderCellDef> Hiệu lực </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="ellipsis"
            [title]="momentDMY(element.effective_time)"
          >
            <span class="ellipsis">
              {{ momentDMY(element.effective_time) }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="extend_updator_name">
          <mat-header-cell *matHeaderCellDef> Người chỉnh sửa </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="ellipsis"
            [title]="element.extend_updator_name"
          >
            <span class="ellipsis">
              {{ element.extend_updator_name }}
            </span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <div *ngIf="histories.length == 0" class="no-records">
        Không có dữ liệu
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn-close" (click)="closeModal()">Đóng</button>
  <button class="btn-submit ml-2" (click)="onSubmit()">Lưu</button>
</div>
