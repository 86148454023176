<mat-card>
  <mat-card-content>
    <mat-table [dataSource]="allRole">
      <!-- Position Column -->

      <ng-container matColumnDef="index">
        <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
        <mat-cell *matCellDef="let element; index as i" class="cell-element">
          <span class="col-12 non-padding fw-600 show-on-mobile"> # </span>

          <span class="ellipsis">{{
            i + 1 + (pageIndex > 1 ? pagesize * (pageIndex - 1) : 0)
          }}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Tên Role </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="ellipsis"
          [title]="element.name"
          class="cell-element"
        >
          <span class="col-12 non-padding fw-600 show-on-mobile">
            Tên ROLE
          </span>
          <span class="ellipsis"> {{ element.name }} </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef> Mô tả </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="ellipsis"
          [title]="element.description"
          class="cell-element"
        >
          <span class="col-12 non-padding fw-600 show-on-mobile"> Mô tả </span>

          <span class="ellipsis"> {{ element.description }} </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef> Thao tác </mat-header-cell>
        <mat-cell *matCellDef="let element" class="cell-element">
          <span class="col-12 non-padding fw-600 show-on-mobile">
            Thao tác
          </span>

          <button
            mat-icon-button
            matTooltip="Chỉnh sửa"
            (click)="editRole(element.id)"
          >
            <mat-icon class="iconColor">edit</mat-icon>
          </button>

          <button
            mat-icon-button
            matTooltip="Chỉnh sửa quyền"
            (click)="settingRolePermission(element.id)"
          >
            <mat-icon class="iconColor">settings</mat-icon>
          </button>

          <button
            mat-icon-button
            matTooltip="Phân quyền người dùng"
            (click)="addUser(element.id)"
          >
            <mat-icon class="iconColor">person_add</mat-icon>
          </button>

          <button
            mat-icon-button
            matTooltip="Xóa"
            (click)="onRemove(element.id)"
          >
            <mat-icon class="iconColor">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
    <div *ngIf="allRole.length == 0" class="no-records">Không có dữ liệu</div>
    <mat-paginator
      (page)="paginatorChange($event)"
      [pageSize]="pagesize"
      [length]="total"
      [hidePageSize]="false"
      [pageSizeOptions]="[5, 10, 20, 100]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator>
  </mat-card-content>
</mat-card>
