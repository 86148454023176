import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import {MY_FORMATS} from '../../constants/constants';

@Component({
  selector: 'meu-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DatepickerComponent implements OnInit, AfterViewInit {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @Input() formControl: FormControl;
  @Input() title: string;
  @Input() placeholderText: string;
  @Input() invalidState: boolean;
  @Input() name: string;

  isRequiredField: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const validator = this.formControl?.validator({} as AbstractControl);
    this.isRequiredField = validator && validator?.required;
  }
}
