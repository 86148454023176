// Angular
import { NgModule } from '@angular/core';
import { MaterialModule } from 'mat-modules';
import { ControlSelectSearchComponent } from './control-select-search/control-select-search.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
//import {NgxMaterialTimepickerComponent} from './material-timepicker/ngx-material-timepicker.component'
// NgxMatSelectSearchModule
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ControlSelectSearchLoadMoreComponent } from './control-select-search-load-more/control-select-search-load-more.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from '@app/core/_base/crud';

import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { AppCommonElementComponent } from './app-common-element/app-common-element.component';
import { ControlDatepickerComponent } from './control-datepicker/control-datepicker.component';
import { ControlTimepickerComponent } from './control-timepicker/control-timepicker.component';
import { ControlUploadFileComponent } from './control-upload-file/control-upload-file.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ControlCommentComponent } from './control-comment/control-comment.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgxSummernoteModule } from 'ngx-summernote';
import { ControlUploadFileDropzoneComponent } from './control-upload-file-dropzone/control-upload-file-dropzone.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TimePipe } from '../shared/pipe/time';
//Pipe
// import { UdpCurrencyMaskPipe } from '../areas/portal/hr/recruitment/pipe';
import { ControlSearchComponent } from './control-search/control-search.component';
import { DynamicDashboardComponent } from './dynamic-dashboard/dynamic-dashboard.component';
import { ModalChooseLayoutComponent } from './dynamic-dashboard/modal-choose-layout/modal-choose-layout.component';
import { ModalAddWidgetComponent } from './dynamic-dashboard/modal-add-widget/modal-add-widget.component';
import { ModalCreateBoardComponent } from './dynamic-dashboard/modal-create-board/modal-create-board.component';
import { ModalEditWidgetNameComponent } from './dynamic-dashboard/modal-edit-widget-name/modal-edit-widget-name.component';
import { PortletModule } from '@app/views/partials/content/general/portlet/portlet.module';
import { SelectLoadMoreDynamicDashboardComponent } from './dynamic-dashboard/select-load-more-dynamic-dashboard/select-load-more-dynamic-dashboard.component';
import { AvatarCardComponent } from './avatar-card/avatar-card.component';

@NgModule({
  declarations: [
    // UdpCurrencyMaskPipe,
    TimePipe,
    ControlSelectSearchComponent,
    ControlSelectSearchLoadMoreComponent,
    AppCommonElementComponent,
    ControlDatepickerComponent,
    ControlTimepickerComponent,
    DateRangePickerComponent,
    ControlUploadFileComponent,
    ControlCommentComponent,
    ControlUploadFileDropzoneComponent,
    ControlSearchComponent,
    DynamicDashboardComponent,
    ModalChooseLayoutComponent,
    ModalAddWidgetComponent,
    ModalCreateBoardComponent,
    ModalEditWidgetNameComponent,
    SelectLoadMoreDynamicDashboardComponent,
    AvatarCardComponent,
  ],
  exports: [
    // UdpCurrencyMaskPipe,
    TimePipe,
    ControlSelectSearchComponent,
    ControlSelectSearchLoadMoreComponent,
    //NgxMaterialTimepickerComponent
    ControlDatepickerComponent,
    ControlUploadFileComponent,
    ControlCommentComponent,
    ControlUploadFileDropzoneComponent,
    ControlSearchComponent,
    DynamicDashboardComponent,
    AvatarCardComponent,
  ],
  providers: [
    // UdpCurrencyMaskPipe,
    TimePipe,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true },
  ],
  imports: [
    NgCircleProgressModule.forRoot(),
    // material modules
    MaterialFileInputModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    CommonModule,
    RouterModule.forChild([
      {
        path: 'appcommonelement',
        component: AppCommonElementComponent,
      },
    ]),
    NgxDaterangepickerMd.forRoot(),
    PickerModule,
    NgxSummernoteModule,
    NgxDropzoneModule,
    PortletModule,
  ],
})
export class CommonElementModule { }
