<mat-card>
    <mat-card-content>
        <h3 class="title">DANH SÁCH ROLE</h3>

        <form [formGroup]="groupRoleForm">
            <div class="row">
                <div class="col-lg-4 col-xs-8 col-sm-8 kt-margin-bottom-20-mobile">
                    <meu-control-select-search-load-more [placeholderLabel]="'Chọn Role'"
                        [noEntriesFoundLabel]="'Không có lựa chọn phù hợp'" [defaultID]="groupRoleForm.value.role_id"
                        formControlName="role_id" [columnName]="'extend_user_full_name'" [nameAPI]="'/api/users'">
                    </meu-control-select-search-load-more>
                </div>
                <div class="col-lg-4 col-xs-4 col-sm-4 kt-margin-bottom-20-mobile">
                    <a class="btn kt-subheader__btn-primary btn-label-primary btn-bold btn-icon-h" (click)="addRole()">
                        Thêm
                    </a>
                </div>
            </div>
        </form>

        <div class="example-container mat-elevation-z8 mat-table-user">
            <mat-table #table [dataSource]="listRole">
                <!-- index Column -->
                <ng-container matColumnDef="index">
                    <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index;"> {{i + 1}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Tên Role </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis" [title]="element.name">
                        <span class="ellipsis">
                            {{element.name}}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef> Mô tả </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis" [title]="element.description">
                        <span class="ellipsis">
                            {{element.description}}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef>
                        Thao tác
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button class="mat-icon-button-sm" mat-icon-button matTooltip="Xóa"
                            (click)="removeRole(element.id)" type="button">
                            <i class="fa fa-trash iconColor" aria-hidden="true"></i>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <div *ngIf="listRole.length == 0" class="no-records">
                Không có dữ liệu
            </div>

        </div>
    </mat-card-content>
</mat-card>