import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {stringify} from 'querystring';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TreegridAddDialogComponent} from './treegrid-add-dialog/treegrid-add-dialog.component';
import {E} from '@angular/cdk/keycodes';
import {LogServiceService} from '@app/views/areas/portal/hr/setting/setting-permission/list-of-specialties/log-service.service';
import {SelectSpecialtyService} from '@app/views/pages/auth/inventory/inventory-register/modal-select-speciality/select-specialty.service';
interface treeNode {
  code: string;
  name: string;
  id: string;
  parent_id: string;
  children?: treeNode[];
}
interface treeFlatNode {
  expandable: boolean;
  code: string;
  name: string;
  id: string;
  parent_id: string;
  level: number;
}
@Component({
  selector: 'meu-treegrid',
  templateUrl: './treegrid.component.html',
  styleUrls: ['./treegrid.component.scss'],
})
export class TreegridComponent implements OnInit {
  @Input() data: any;
  @Input() isService: Boolean;
  @Input() check: Boolean;
  @Input() isRegister: Boolean = false;
  @Output() selected = new EventEmitter<any>();
  @Output() refreshData = new EventEmitter<any>();
  expandedNodes: any;
  sData = [
    {
      expandable: true,
      code: '10501',
      name: 'khoa hoc vu tru',
      id: '542a3a54-4dfd-41fb-a338-ac939eb6275f',
      parent_id: null,
      level: 0,
    },
  ];
  displayedColumns: string[] = ['code', 'name', 'actions'];
  displayedRegisterColumns: string[] = ['code', 'name'];
  /** The selection for checklist */
  checklistSelection = new SelectionModel<treeFlatNode>(true /* multiple */);
  private transformer = (node: treeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      code: node.code,
      name: node.name,
      id: node.id,
      parent_id: node.parent_id,
      level: level,
    };
  };
  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: treeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: treeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  treeItemSelectionToggle(node: treeFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.checklistSelection.selected.forEach((item, index) => {
      if (item == null || item == undefined) {
        this.checklistSelection.selected.splice(index, 1);
      }
    });
    this.selectedData(
      this.checklistSelection.selected.filter((e) => e.expandable !== true)
    );
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  treeLeafItemSelectionToggle(node: treeFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }
  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: treeFlatNode): void {
    let parent: treeFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }
  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: treeFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }
  /* Get the parent node of a node */
  getParentNode(node: treeFlatNode): treeFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
  treeControl = new FlatTreeControl<treeFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  constructor(
    private magDialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private losService: LogServiceService,
    private cdr: ChangeDetectorRef,
    private selectSpecialtyService: SelectSpecialtyService
  ) {}
  hasChild = (_: number, node: treeFlatNode) => node.expandable;
  getLevel = (node: treeFlatNode) => node.level;
  ngOnInit(): void {
    if (this.isService) {
      this.selectSpecialtyService.dataSourceTable.subscribe((res) => {
        this.dataSource.data = res;
        this.cdr.detectChanges();
      });
    } else {
      this.dataSource.data = this.data;
      this.losService.dataSourceTable.subscribe((res) => {
        this.dataSource.data = res;
        this.changeDetectorRef.detectChanges();
        this.restoreExpandedNodes();
      });
    }
    this.sData.forEach((e) => {
      this.checklistSelection.select(this.data.find((i) => i.id === e.id));
    });
  }
  public clearItem(item) {
    // console.log(item);
    this.treeItemSelectionToggle(item);
  }
  selectedTreeNode(choosed, name) {
    let e = name;
    if (choosed.parent_id) {
      const s = this.getParentNode(choosed);
      e = s.name + '-' + e;
      if (s.parent_id) {
        const m = this.selectedTreeNode(s, e);
        e = m;
      }
    }
    return e;
  }
  selectedData(selected: any) {
    this.selected.emit(selected);
  }
  openAddDialog(data: any) {
    data.type = 'add';
    // console.log(data);
    let dialogRef: MatDialogRef<TreegridAddDialogComponent>;
    dialogRef = this.magDialog.open(TreegridAddDialogComponent, {
      data: data,
      width: '544px',
      height: '245px',
    });
    this.saveExpandedNodes();
    return dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.refreshData.emit(true);
      }
    });
  }
  openEditDialog(data: any) {
    data.type = 'edit';
    let dialogRef: MatDialogRef<TreegridAddDialogComponent>;
    dialogRef = this.magDialog.open(TreegridAddDialogComponent, {
      data: data,
      width: '544px',
      height: '245px',
    });
    this.saveExpandedNodes();
    return dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.refreshData.emit(true);
      }
    });
  }
  openDeleteDialog(data: any) {
    data.type = 'delete';
    let dialogRef: MatDialogRef<TreegridAddDialogComponent>;
    dialogRef = this.magDialog.open(TreegridAddDialogComponent, {
      data: data,
      width: '544px',
      height: '245px',
    });
    this.saveExpandedNodes();
    return dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.refreshData.emit(true);
      }
    });
  }
  tableClass(check: any) {
    if (check === true) {
      return 'mat-elevation-z8 table-container';
    } else {
      return 'mat-elevation-z8';
    }
  }
  saveExpandedNodes() {
    this.expandedNodes = new Array<treeFlatNode>();
    this.treeControl.dataNodes.forEach((node) => {
      if (node.expandable && this.treeControl.isExpanded(node)) {
        this.expandedNodes.push(node);
      }
    });
  }
  restoreExpandedNodes() {
    if (this.expandedNodes) {
      this.expandedNodes.forEach((node) => {
        this.treeControl.expand(
          this.treeControl.dataNodes.find((n) => n.id === node.id)
        );
      });
    }
  }
}
