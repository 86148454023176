// Angular
import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {Router} from '@angular/router';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
// RxJS
// Translate
// NGRX
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../core/reducers';
// Auth
import {Subject} from 'rxjs';
import {MatStepper} from '@angular/material/stepper';
import {ToastrService} from 'ngx-toastr';
import {UsersService} from '@app/api/users.service';
import {DropdownOption} from '@app/views/shared/common_elements/dropdown/dropdown.component';
import {HrDegreeCategoryService} from '@app/api/hrDegreeCategory.service';
import {UserDegreeService} from '@app/api/userDegree.service';
import {CompanyInfoService} from '@app/api/companyInfo.service';
import moment from 'moment';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {CustomValidators} from './custom-validators';
import {ModalSelectSpecialityComponent} from './modal-select-speciality/modal-select-speciality.component';
import {AppCreateCompanyModalComponent} from '@app/views/areas/portal/hr/setting/setting-company/app-create-company-modal/app-create-company-modal.component';
import {RootSpinnerService} from '@app/services/root-spinner.service';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'meu-inventory-register',
  templateUrl: './register.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  @Output() closeModal = new EventEmitter();
  registerForm: FormGroup;
  firstLoading = true;
  errors: any = [];
  disableNextBtn = false;
  createdUser: any;
  showPass = false;
  showPassConfirm = false;
  secretKey;
  lstGender = [];
  educationLevelList = [];
  filteredEducationLevelList = [];
  degreeList = [];
  filteredDegreeList = [];
  academicRankList = [];
  filteredAcademicRank = [];

  @Input('is_modal') is_modal: boolean;
  @Input('is_internal') is_internal: boolean;

  mobileQuery: MediaQueryList;
  tabletQuery: MediaQueryList;
  private screenQueryListener: () => void;

  private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  constructor(
    private router: Router,
    private userService: UsersService,
    private degreeCatService: HrDegreeCategoryService,
    private userDegreeService: UserDegreeService,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private _location: Location,
    private dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.unsubscribe = new Subject();

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.tabletQuery = media.matchMedia(
      '(max-width: 1024px) and (min-width: 600px)'
    );
    this.screenQueryListener = () => changeDetector.detectChanges();
    this.mobileQuery.addListener(this.screenQueryListener);
    this.tabletQuery.addListener(this.screenQueryListener);
  }

  @ViewChild('stepper') stepper: MatStepper;
  checkRepeatPassword = (control: AbstractControl): ValidationErrors => {
    if (
      this.fouthFormGroup?.value?.password &&
      this.fouthFormGroup.value.password !== control.value
    ) {
      return {notMatch: true};
    }
    return null;
  };
  firstFormGroup: FormGroup = new FormGroup({
    first_name: new FormControl(null, [Validators.required]),
    middle_name: new FormControl(null),
    last_name: new FormControl(null, [Validators.required]),
    gender: new FormControl(null, [Validators.required]),
    birthday: new FormControl(null, [Validators.maxLength(12)]),
    id_card_number: new FormControl(null, [Validators.maxLength(12)]),
    tax: new FormControl(null, [Validators.pattern(/^\d{10}-\d{3}$|^\d{10}$/)]),
    cell_phone: new FormControl(null, [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(12),
      Validators.pattern(/0(\d){9,10}/),
    ]),
    email: new FormControl(null, [Validators.required, Validators.email]),
    company_id: new FormControl(null),
    position: new FormControl(null),
  });

  fouthFormGroup: FormGroup = new FormGroup({
    password: new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
      CustomValidators.patternValidator(/\d/, {hasNumber: true}),
      CustomValidators.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
      CustomValidators.patternValidator(/[a-z]/, {hasSmallCase: true}),
      CustomValidators.patternValidator(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/, {
        hasSpecialCharacters: true,
      }),
    ]),
    repeat_password: new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
      this.checkRepeatPassword,
    ]),
  });
  arrDegree = [];
  arrListEducationLevelId = [''];
  arrListFinishDate = [''];
  arrListName = [''];
  arrListTrainingPlace = [''];
  arrListUserAcademicRankId = [''];
  arrListUserDegreeId = [''];
  lstDegree = new MatTableDataSource();
  displayedColumns: string[] = [
    'extend_user_education_level_name',
    'user_academic_rank_name',
    'extend_user_degree_name',
    'training_place',
    'name',
    'finish_date',
  ];

  ngOnInit() {
    if (this.is_modal) {
      this.firstFormGroup.controls['birthday'].setValidators([
        Validators.maxLength(12),
      ]);
      this.firstFormGroup.controls['id_card_number'].setValidators([
        Validators.maxLength(12),
      ]);
    } else {
      this.firstFormGroup.controls['birthday'].setValidators([
        Validators.maxLength(12),
        Validators.required,
      ]);
      this.firstFormGroup.controls['id_card_number'].setValidators([
        Validators.maxLength(12),
        Validators.required,
      ]);
      this.firstFormGroup.controls['company_id'].setValidators([
        Validators.required,
      ]);
      this.firstFormGroup.controls['position'].setValidators([
        Validators.required,
      ]);
    }
    this.lstGender = [
      {id: 1, name: 'Nam'},
      {id: 0, name: 'Nữ'},
    ];
    this.getHrDegreeCategories('EDUCATION_LEVEL');
    this.getHrDegreeCategories('ACADEMIC_RANK');
    this.getHrDegreeCategories('DEGREE');
    this.initTable();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.registerForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  initTable() {
    let obj = {
      education_level_id: '',
      academic_rank_id: '',
      degree_category_id: '',
      name: '',
      training_place: '',
      finish_date: '',
    };
    this.arrDegree.push(obj);
    this.lstDegree.data = [...this.arrDegree];
    this.changeDetector.detectChanges();
  }

  goBack() {
    this._location.back();
  }

  registerStep1() {
    const controls = this.firstFormGroup.controls;
    this.firstLoading = false;

    if (this.is_modal) {
      if (this.firstFormGroup.controls.birthday.value)
        this.firstFormGroup.controls.birthday.setValue(
          moment(this.firstFormGroup.controls.birthday.value, 'DD/MM/YYYY')
        );
    } else {
      this.firstFormGroup.controls.birthday.setValue(
        moment(this.firstFormGroup.controls.birthday.value, 'DD/MM/YYYY')
      );
    }
    if (this.firstFormGroup.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      this.toastr.warning('Vui lòng nhập các thông tin bắt buộc');
      return;
    }
    this.submitUser();
  }
  submitUser() {
    const value = {
      ...this.firstFormGroup.value,
      disabled: true,
      username: this.firstFormGroup.value.email,
      is_internal: this.is_internal ? this.is_internal : false,
    };
    if (this.createdUser?.id) {
      this.userService
        .apiUsersIdPut(this.createdUser.id, value)
        .subscribe((res) => {
          if (res.success) {
            this.createdUser = res.data;
            this.stepper.next();
          } else
            this.toastr.error(res.message, 'Chỉnh sửa TK không thành công');
        });
    } else {
      this.userService.apiUsersPost(value).subscribe((res) => {
        if (res.success) {
          this.createdUser = res.data;
          this.secretKey = res.data.secretKey;
          this.stepper.next();
        } else this.toastr.error(res.message, 'Tạo TK không thành công');
      });
    }
  }
  registerStep3() {
    let regEx = new RegExp('^\\d{4}$');
    if (this.arrListEducationLevelId.length == 0) {
      this.toastr.warning(
        'Xin hãy tạo ít nhất một quá trình đào tạo',
        'Quá trình đào tạo'
      );
      return;
    }
    for (let i = 0; i < this.arrListEducationLevelId.length; i++) {
      if (
        this.arrListEducationLevelId[i] == '' ||
        this.arrListEducationLevelId[i] == undefined
      ) {
        this.toastr.warning('Xin hãy nhập bậc đào tạo', 'Quá trình đào tạo');
        return;
      }
      if (
        this.arrListUserDegreeId[i] == '' ||
        this.arrListUserDegreeId[i] == undefined
      ) {
        this.toastr.warning('Xin hãy nhập học vị', 'Quá trình đào tạo');
        return;
      }
      if (this.arrListName[i] == '' || this.arrListName[i] == undefined) {
        this.toastr.warning('Xin hãy nhập chuyên môn', 'Quá trình đào tạo');
        return;
      }
      if (
        !regEx.test(this.arrListFinishDate[i]) &&
        this.arrListFinishDate[i] != null &&
        this.arrListFinishDate[i] != ''
      ) {
        this.toastr.warning('Ngày kết thúc không hợp lệ', 'Quá trình đào tạo');
        return;
      }
    }
    let arrDegrees = [];
    for (let i = 0; i < this.arrDegree.length; i++) {
      arrDegrees[i] = {};
      arrDegrees[i].user_id = this.createdUser.id;
      arrDegrees[i].education_level_id = this.arrListEducationLevelId[i];
      arrDegrees[i].academic_rank_id = this.arrListUserAcademicRankId[i];
      arrDegrees[i].degree_category_id = this.arrListUserDegreeId[i];
      arrDegrees[i].name = this.arrListName[i];
      arrDegrees[i].training_place = this.arrListTrainingPlace[i];
      arrDegrees[i].finish_date = this.arrListFinishDate[i]
        ? this.formatDate(this.arrListFinishDate[i])
        : null;
    }

    if (this.is_modal) {
      this.userService
        .apiUsersUserIdDegreesPut(this.createdUser.id, arrDegrees)
        .subscribe((res) => {
          if (res.success) {
            setTimeout(() => {
              RootSpinnerService.show();
              this.sendForgetPassword();
            }, 500);
          } else this.toastr.error(res.message, 'Cập nhật không thành công');
        });
    } else {
      this.userService
        .apiUsersUserIdDegreesPut(this.createdUser.id, arrDegrees)
        .subscribe((res) => {
          if (res.success) {
            this.stepper.next();
          } else this.toastr.error(res.message, 'Cập nhật không thành công');
        });
    }
  }

  sendForgetPassword() {
    this.userService
      .apiUsersSetPasswordPut({
        newPassword: null,
        secretkey: this.secretKey,
        sendEmail: true,
      })
      .subscribe((res) => {
        if (res.success) {
          this.toastr.success('Tạo tài khoản thành công!', 'Thông báo');
          this.closeModal.emit(true);
        } else {
          this.toastr.error(
            'Tạo tài khoản thất bại, vui lòng thử lại sau!',
            'Thông báo'
          );
        }
      });
  }
  registerStep4() {
    const controls = this.fouthFormGroup.controls;
    if (this.fouthFormGroup.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      this.toastr.warning('Vui lòng nhập các thông tin bắt buộc');
      return;
    }
    this.userService
      .apiUsersSetPasswordPut({
        newPassword: this.fouthFormGroup.value.password,
        secretkey: this.secretKey,
        sendEmail: true,
      })
      .subscribe((res) => {
        if (res.success) {
          this.stepper.next();
          this.toastr.success(res.message, 'Đăng kí thành công');
          setTimeout(
            () =>
              this.router.navigateByUrl(
                '/auth/confirm-account?notificatePage=true'
              ),
            6000
          );
        } else this.toastr.error(res.message, 'Cập nhật không thành công');
      });
  }

  getHrDegreeCategories(type) {
    if (type === 'EDUCATION_LEVEL') {
      this.degreeCatService
        .apiHrDegreeCategoriesGet('type==EDUCATION_LEVEL')
        .subscribe((res) => {
          if (res.success) {
            this.educationLevelList = res.data.collection;
            this.filteredEducationLevelList = this.educationLevelList;
          }
        });
    } else if (type === 'ACADEMIC_RANK') {
      this.degreeCatService
        .apiHrDegreeCategoriesGet('type==ACADEMIC_RANK')
        .subscribe((res) => {
          if (res.success) {
            this.academicRankList = res.data.collection;
            this.filteredAcademicRank[0] = this.academicRankList;
          }
        });
    } else {
      this.degreeCatService
        .apiHrDegreeCategoriesGet('type==DEGREE')
        .subscribe((res) => {
          if (res.success) {
            this.degreeList = res.data.collection;
            this.filteredDegreeList[0] = this.degreeList;
          }
        });
    }
  }

  formatDate(date) {
    if (date == null) {
      return '';
    }
    try {
      let utcDate = moment.utc(date).toDate();
      let fmDate = moment(utcDate).format('DD-MM-YYYY');
      return fmDate;
    } catch (error) {
      return '';
    }
  }

  createDegree() {
    let obj = {
      education_level_id: '',
      academic_rank_id: '',
      degree_category_id: '',
      name: '',
      training_place: '',
      finish_date: '',
    };
    this.arrDegree.push(obj);
    this.lstDegree.data = [...this.arrDegree];
    this.changeDetector.detectChanges();
  }

  deleteDegree(index) {
    this.arrDegree.splice(index, 1);
    this.arrListEducationLevelId.splice(index, 1);
    this.arrListUserAcademicRankId.splice(index, 1);
    this.arrListUserDegreeId.splice(index, 1);
    this.arrListFinishDate.splice(index, 1);
    this.arrListName.splice(index, 1);
    this.arrListTrainingPlace.splice(index, 1);
    this.lstDegree.data = [...this.arrDegree];
    this.changeDetector.detectChanges();
  }

  onChangeEducationLevel(data, i) {
    let el = this.educationLevelList.find((el) => el.id === data);
    if (!el) {
      return;
    }
    this.arrListEducationLevelId[i] = el.id;

    if (el.name === 'Đại học' || el.name === 'Cao đẳng') {
      this.filteredAcademicRank[i] = [];

      let filteredDegreeClone = [];
      this.degreeList.forEach((item) => {
        if (item.name === 'Cử nhân') {
          filteredDegreeClone.push(item);
        }
      });
      this.filteredDegreeList[i] = [...filteredDegreeClone];
    } else if (el.name === 'Cao học') {
      this.filteredAcademicRank[i] = [...this.academicRankList];
      let filteredDegreeClone = [];

      this.degreeList.forEach((item) => {
        if (item.name !== 'Cử nhân') {
          filteredDegreeClone.push(item);
        }
      });
      this.filteredDegreeList[i] = [...filteredDegreeClone];
    }
  }

  onChangeDegree(data, i) {
    let el = this.degreeList.find((el) => el.id === data);
    if (!el) {
      return;
    }
    this.arrListUserDegreeId[i] = el.id;
  }

  onChangeAcademicRank(data, i) {
    let el = this.academicRankList.find((el) => el.id === data);
    if (!el) {
      return;
    }
    this.arrListUserAcademicRankId[i] = el.id;
  }

  onSelectSpeciality(index) {
    const dialogRef = this.dialog.open(ModalSelectSpecialityComponent, {
      data: {},
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.arrListName[index] = result;
        this.changeDetector.detectChanges();
      }
    });
  }

  onOpenAddNewRowInDropdownModal(e) {
    if (e) {
      const dialogRef = this.dialog.open(AppCreateCompanyModalComponent, {
        width: '754px',
        data: {isCreate: true, isRegister: true},
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result?.event) {
            this.toastr.success(
              'Thêm đơn vị thành công',
              'Thao tác thành công'
            );
          }
        }
      });
    }
  }
}
