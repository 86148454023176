import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'meu-user-guide-register',
  templateUrl: './user-guide-register.component.html',
  styleUrls: ['./user-guide-register.component.scss'],
})
export class UserGuideRegisterComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
