import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CompanyInfoService} from '@app/api/companyInfo.service';
import moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {CompanyImportModalComponent} from '../../company-import-modal/company-import-modal.component';

@Component({
  selector: 'meu-company-budget-mission-modal',
  templateUrl: './company-budget-mission-modal.component.html',
  styleUrls: ['./company-budget-mission-modal.component.scss'],
})
export class CompanyBudgetMissionModalComponent implements OnInit {
  companyFrm: FormGroup;
  histories: any[] = [];
  isShowHistory = false;
  displayedColumns = [
    'updated_at',
    'description',
    'effective_time',
    'extend_updator_name',
  ];

  constructor(
    public dialogRef: MatDialogRef<CompanyImportModalComponent>,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private companyInfoService: CompanyInfoService,
    @Inject(MAT_DIALOG_DATA) public input,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initFrm();
    this.getCompanyHistory();
  }

  getCompanyHistory() {
    this.companyInfoService
      .apiCompanyIdHistoriesGet(this.input.id)
      .subscribe((res) => {
        if (res.success) {
          this.histories = res.data.collection
            .filter(
              (item) =>
                item.field ===
                (this.input.isBudget ? 'budget' : 'mission_amount')
            )
            .sort((a, b) =>
              moment(a.updated_at).isBefore(moment(b.updated_at)) ? 1 : -1
            );
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  initFrm() {
    this.companyFrm = new FormGroup({
      value: new FormControl(this.input.value || null, [
        Validators.required,
        Validators.min(1),
      ]),
      date: new FormControl(this.input.date || null, [Validators.required]),
    });
  }

  onSubmit() {
    this.companyFrm.markAllAsTouched();
    if (this.companyFrm.invalid) {
      return;
    }
    this.dialogRef.close({
      event: true,
      value: this.companyFrm.get('value').value as number,
      date: this.companyFrm.get('date').value,
    });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.companyFrm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  openDatePicker(template) {
    template.open();
  }

  momentDMY(date) {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  formatCurrencyNumber(num: string) {
    try {
      return num.trim().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } catch (e) {
      return null;
    }
  }

  closeModal() {
    this.dialogRef.close({event: false});
  }
}
