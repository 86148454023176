<div class="kt-portlet">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title title-color bold" *ngIf="!isEdit">
                Thêm mới Role
            </h3>
            <h3 class="kt-portlet__head-title title-color bold" *ngIf="isEdit">
                Cập nhật Role
            </h3>
        </div>
        <div mat-dialog-close class="close_modal_btn" [matTooltip]="'Đóng'">
            <mat-icon>close</mat-icon>
        </div>
    </div>

    <div class="kt-portlet__body">
        <div class="row">
            <div class="col-xl-12">
                <div class="kt-section">
                    <form [formGroup]="createRoleForm">
                        <div class="example-container">
                            <div class="row">
                                <div class="col-12">
                                    <mat-form-field class="full-width-field">
                                        <mat-label>Tên Role</mat-label>
                                        <input formControlName="name" matInput autocomplete="off" required>
                                        <mat-error>
                                            Vui lòng điền tên Role
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <mat-form-field class="full-width-field">
                                        <mat-label>Mô tả</mat-label>
                                        <textarea matInput formControlName="description" autocomplete="off"
                                            matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="5">
                                        </textarea>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="kt-portlet__foot">
        <div class="row">
            <div class="col-12 footer-padding text-right">
                <button type="button" class="btn button-cancel" (click)="closeModal()">
                    Hủy
                </button>
                <button type="button" class="btn button-save" (click)="save()" *ngIf="!isEdit">
                    Thêm
                </button>
                <button type="button" class="btn button-save" (click)="save()" *ngIf="isEdit">
                    Cập nhật
                </button>
            </div>
        </div>
    </div>
</div>