export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [
        {
          title: 'Người dùng',
          root: true,
          alignment: 'left',
          toggle: 'click',
          permissions: 'HR_EMPLOYEE',
          submenu: [
            {
              title: 'Hồ sơ nhân sự',
              bullet: 'dot',
              permissions: 'HR_EMPLOYEE',
              page: '/portal/hr/user-management',
              icon: 'flaticon-users',
            },
          ],
        },
        {
          title: 'Đơn vị',
          root: true,
          alignment: 'left',
          toggle: 'click',
          page: '/portal/hr/setting/permission/company-management',
          permissions: 'HR_EMPLOYEE',
        },
        {
          title: 'Cài đặt',
          root: true,
          alignment: 'left',
          toggle: 'click',
          permissions: 'PERMISSION_MANGEMENT',
          hideMobile: true,

          submenu: [
            {
              title: 'Quản lý phân quyền',
              bullet: 'dot',
              icon: 'flaticon-interface-7',
              permissions: 'PERMISSION_MANGEMENT',
              page: '/portal/hr/setting/permission/role-management',
              // submenu: [
              //   {
              //     title: 'Quản lý phân quyền',
              //     page: '/portal/hr/setting/permission/role-management',
              //     icon: 'flaticon-security',
              //   },
              // ],
            },
            {
              title: 'Danh sách chuyên môn',
              bullet: 'dot',
              icon: 'flaticon-interface-7',
              permissions: 'PERMISSION_MANGEMENT',
              page: '/portal/hr/setting/permission/list-of-specialties',
              // submenu: [
              //   {
              //     title: 'Quản lý phân quyền',
              //     page: '/portal/hr/setting/permission/role-management',
              //     icon: 'flaticon-security',
              //   },
              // ],
            },
          ],
        },
      ],
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
