/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { WorkflowItemFieldData } from '../model/workflowItemFieldData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WorkflowItemFieldDataService {

    protected basePath = 'https://gateway.dev.meu-solutions.com/sciencetechnologysso';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param workflowItemId 
     * @param activityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIdFormsGet(workflowItemId: string, activityId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIdFormsGet(workflowItemId: string, activityId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIdFormsGet(workflowItemId: string, activityId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdActivitiesActivityIdFormsGet(workflowItemId: string, activityId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdActivitiesActivityIdFormsGet.');
        }

        if (activityId === null || activityId === undefined) {
            throw new Error('Required parameter activityId was null or undefined when calling apiWorkflowItemsWorkflowItemIdActivitiesActivityIdFormsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/activities/${encodeURIComponent(String(activityId))}/forms`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param body 
     * @param subformId 
     * @param completeForm 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdFieldsPost(workflowItemId: string, body?: Array<WorkflowItemFieldData>, subformId?: string, completeForm?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdFieldsPost(workflowItemId: string, body?: Array<WorkflowItemFieldData>, subformId?: string, completeForm?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdFieldsPost(workflowItemId: string, body?: Array<WorkflowItemFieldData>, subformId?: string, completeForm?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdFieldsPost(workflowItemId: string, body?: Array<WorkflowItemFieldData>, subformId?: string, completeForm?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdFieldsPost.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (subformId !== undefined && subformId !== null) {
            queryParameters = queryParameters.set('subformId', <any>subformId);
        }
        if (completeForm !== undefined && completeForm !== null) {
            queryParameters = queryParameters.set('completeForm', <any>completeForm);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/fields`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param formId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdFormsFormIdGet(workflowItemId: string, formId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdFormsFormIdGet(workflowItemId: string, formId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdFormsFormIdGet(workflowItemId: string, formId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdFormsFormIdGet(workflowItemId: string, formId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdFormsFormIdGet.');
        }

        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling apiWorkflowItemsWorkflowItemIdFormsFormIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/forms/${encodeURIComponent(String(formId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param formId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdFormsFormIdPrintTemplateGet(workflowItemId: string, formId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemsWorkflowItemIdFormsFormIdPrintTemplateGet(workflowItemId: string, formId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemsWorkflowItemIdFormsFormIdPrintTemplateGet(workflowItemId: string, formId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemsWorkflowItemIdFormsFormIdPrintTemplateGet(workflowItemId: string, formId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdFormsFormIdPrintTemplateGet.');
        }

        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling apiWorkflowItemsWorkflowItemIdFormsFormIdPrintTemplateGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/forms/${encodeURIComponent(String(formId))}/printTemplate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param subformId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdSubformsSubformIdFieldsGet(workflowItemId: string, subformId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdSubformsSubformIdFieldsGet(workflowItemId: string, subformId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdSubformsSubformIdFieldsGet(workflowItemId: string, subformId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdSubformsSubformIdFieldsGet(workflowItemId: string, subformId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdSubformsSubformIdFieldsGet.');
        }

        if (subformId === null || subformId === undefined) {
            throw new Error('Required parameter subformId was null or undefined when calling apiWorkflowItemsWorkflowItemIdSubformsSubformIdFieldsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/subforms/${encodeURIComponent(String(subformId))}/fields`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
