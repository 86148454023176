// Angular
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// RxJS
import {Observable, Subject} from 'rxjs';
import {finalize, takeUntil, tap} from 'rxjs/operators';
// Store
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../core/reducers';

// Auth
import {
  AuthNoticeService,
  AuthService,
  Login,
  Logout,
} from '../../../../../core/auth';
import {AuthenticateService} from '@app/api/authenticate.service';

import {CookieService} from 'ngx-cookie-service';
import {FormErrorService} from '@app/views/shared/services/form-error.service';
import * as CryptoJS from 'crypto-js';
import {environment} from 'environments/environment';
import {HtmlClassService} from '@app/views/theme/html-class.service';
import * as asymmetricCrypto from 'asymmetric-crypto';
//const QuickEncrypt = require('quick-encrypt')

/**
 * ! Just example => Should be removed in development
 */
const DEMO_PARAMS = {
  EMAIL: '',
  PASSWORD: '',
};

@Component({
  selector: 'meu-inventory-login',
  templateUrl: './inventory-login.component.html',
  styleUrls: ['./inventory-login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InventoryLoginComponent implements OnInit {
  // Public params

  loginForm: FormGroup;
  isLoggedIn$: Observable<boolean>;
  errors: any = [];
  authenticateError: boolean = false;
  showPass: boolean = false;
  emailPattern = '^[A-z0-9._%+-]+@[a-z0-9.-]+[.]+[a-z]{2,4}$';
  emailEncrypt: string;
  encryptPassword = '@123RAPTOR!@#&^';
  private unsubscribe: Subject<any>;

  private returnUrl: any;
  private channel: any;
  private publicKey: any;
  hideForm: boolean;
  formErrorServie: any;

  // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param router: Router
   * @param auth: AuthService
   * @param authNoticeService: AuthNoticeService
   * @param translate: TranslateService
   * @param store: Store<AppState>
   * @param fb: FormBuilder
   * @param cdr
   * @param route
   */
  constructor(
    private router: Router,
    private auth: AuthService,
    private authNoticeService: AuthNoticeService,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private authenticateService: AuthenticateService,
    public htmlClassService: HtmlClassService
  ) {
    this.unsubscribe = new Subject();
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.hideForm = true;
    setTimeout(() => {
      this.htmlClassService.loader$.next(true);
    }, 10);
    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params.returnUrl || '/';
      this.channel = params.channel;
      this.publicKey = atob(params.publicKey || '');
    });

    if (localStorage.getItem(environment.authTokenKey)) {
      this.redirectLoggedInUser(localStorage.getItem(environment.authTokenKey));
    } else {
      this.hideForm = false;
      setTimeout(() => {
        this.htmlClassService.loader$.next(false);
      }, 1000);
      this.authNoticeService.setNotice(null);
      this.initLoginForm();
      // redirect back to the returnUrl before login

      this.formErrorServie = new FormErrorService();
      document.addEventListener('keyup', (e) => {
        if (e.key == 'Enter') {
          this.submit();
        }
      });
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.authNoticeService.setNotice(null);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    $('input').attr('autocomplete', 'off');
  }

  /**
   * Form initialization
   * Default params, validators
   */
  initLoginForm() {
    // demo message to show
    if (!this.authNoticeService.onNoticeChanged$.getValue()) {
      const initialNotice = `Use account
			<strong>${DEMO_PARAMS.EMAIL}</strong> and password
			<strong>${DEMO_PARAMS.PASSWORD}</strong> to continue.`;
      this.authNoticeService.setNotice(initialNotice, 'info');
    }

    this.loginForm = this.fb.group({
      email: [
        DEMO_PARAMS.EMAIL,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
          Validators.minLength(3),
          Validators.maxLength(320),
          // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
      password: [
        DEMO_PARAMS.PASSWORD,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ],
    });
  }
  logout() {
    this.store.dispatch(new Logout());
  }

  redirectLoggedInUser(token) {
    if (this.channel) {
      const encToken = this.getEncToken(token);
      window.location.href =
        this.returnUrl.split('#')[0] +
        '?token=' +
        encToken +
        (this.returnUrl.split('#').length > 1
          ? '#' + this.returnUrl.split('#')[1]
          : '');
    } else {
      const data = new Login({authToken: token});
      this.store.dispatch(data);
      this.auth.getMyPermissions(true).subscribe((result) => {
        if (result) {
          if (!this.returnUrl || this.returnUrl === '/') {
            window.location.href = environment.feReferenceUrl;
            //this.router.navigate(['/portal/hr/user-management']);
          } else {
            this.router.navigate([this.returnUrl]);
          }
        }
      });
    }
  }

  getEncToken(token) {
    const myKeyPair = asymmetricCrypto.keyPair();
    const keyCode = asymmetricCrypto.encrypt(
      token,
      this.publicKey,
      myKeyPair.secretKey
    );
    keyCode.SSOServerPublicKey = myKeyPair.publicKey;
    const encToken = btoa(JSON.stringify(keyCode));
    return encToken;
  }

  /**
   * Form Submit
   */
  submit() {
    const controls = this.loginForm.controls;
    /** check form */
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    const authData = {
      email: controls.email.value,
      password: controls.password.value,
    };
    this.htmlClassService.loader$.next(true);
    this.authenticateService
      .apiAuthenticateAuthenticatePost({
        username: authData.email,
        password: authData.password,
      })
      .pipe(
        tap((res) => {
          if (res.token) {
            const data = new Login({authToken: res.token});
            this.store.dispatch(data);
            this.emailEncrypt = CryptoJS.AES.encrypt(
              JSON.stringify(authData.email),
              this.encryptPassword
            ).toString();
            localStorage.setItem(
              'logged-in',
              JSON.stringify(this.emailEncrypt)
            );
            this.redirectLoggedInUser(res.token);
          } else {
            this.authNoticeService.setNotice(res.message, 'danger');
          }
        }),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.cdr.markForCheck();
          this.htmlClassService.loader$.next(false);
        })
      )
      .subscribe();
  }
  resetForm() {
    this.loginForm.reset();
    this.router.navigate(['/auth/forgot-password']);
  }
}
