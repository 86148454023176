<div class="modal-header">
  <div>
    <h2 class="headder">
      {{ titleName }}
    </h2>
  </div>
  <div>
    <button type="button" class="close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="modal-body">
  <div class="example-container">
    <div class="row">
      <div class="col-12 justify-content-center d-flex">
        <span>Bạn có muốn xuất file?</span>
        <!-- <meu-control-select-search-load-more
          #meuLoadmoreComponent
          [placeholderLabel]="'Biểu mẫu'"
          [noEntriesFoundLabel]="'Không có lựa chọn'"
          [nameAPI]="'/api/hrTemplates'"
          [multiple]="false"
          [columnName]="'name'"
          [required]="true"
          (change)="onChangeModal($event)"
        >
        </meu-control-select-search-load-more> -->
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-submit" (click)="exportExcel()">Xuất file</button>
  <button class="btn btn-close" (click)="closeModal()">Hủy</button>
</div>
