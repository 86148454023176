// Angular
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule, Injectable} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {OverlayModule} from '@angular/cdk/overlay';
import {BASE_PATH} from './variables';
// Angular in memory
// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// Perfect Scroll bar
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
// SVG inline
import {InlineSVGModule} from 'ng-inline-svg';
// Env
import {environment} from '../environments/environment';
// NGX Permissions
import {NgxPermissionsModule} from 'ngx-permissions';
// NGRX
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {
  StoreRouterConnectingModule,
  DefaultRouterStateSerializer,
} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
// State
import {metaReducers, reducers} from './core/reducers';
// Copmponents
import {AppComponent} from './app.component';
// Modules
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
import {ThemeModule} from './views/theme/theme.module';

// Partials
import {PartialsModule} from './views/partials/partials.module';
import {ApiModule} from '@app/api.module';
// Toastr
import {ToastrModule} from 'ngx-toastr';
// Spinner
import {NgxSpinnerModule} from 'ngx-spinner';

// LY_Theme
/** Alyle UI */

import {LyThemeModule, LY_THEME, LY_THEME_GLOBAL_VARIABLES} from '@alyle/ui';
import {LyImageCropperModule} from '@alyle/ui/image-cropper';
import {MinimaLight, MinimaDark} from '@alyle/ui/themes/minima';

@Injectable()
export class GlobalVariables {
  testVal = '#00bcd4';
  Quepal = {
    default: `linear-gradient(135deg,#11998e 0%,#38ef7d 100%)`,
    contrast: '#fff',
    shadow: '#11998e',
  };
  SublimeLight = {
    default: `linear-gradient(135deg,#FC5C7D 0%,#6A82FB 100%)`,
    contrast: '#fff',
    shadow: '#B36FBC',
  };
  Amber = {
    default: '#ffc107',
    contrast: 'rgba(0, 0, 0, 0.87)',
  };
}

// Layout Services
import {
  DataTableService,
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
  SubheaderService,
} from './core/_base/layout';
// Auth
import {AuthModule} from './views/pages/auth/auth.module';
import {AuthService} from './core/auth';
// CRUD
import {
  HttpUtilsService,
  LayoutUtilsService,
  TypesUtilsService,
} from './core/_base/crud';
// Config
import {LayoutConfig} from './core/_config/layout.config';
// Highlight JS
//import {HIGHLIGHT_OPTIONS, HighlightLanguage} from 'ngx-highlightjs';
import {HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';
import * as typescript from 'highlight.js/lib/languages/typescript';
import * as scss from 'highlight.js/lib/languages/scss';
import * as xml from 'highlight.js/lib/languages/xml';
import * as json from 'highlight.js/lib/languages/json';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatSelectCountryModule} from '@angular-material-extensions/select-country';
//Summernote
import {NgxSummernoteModule} from 'ngx-summernote';
//Permissions
import {CanActivateRouteGuard} from './core/auth/_guards/permissions-route.guard';
import {ModalModule} from 'ngx-bootstrap/modal';
// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}

// export function hljsLanguages(): HighlightLanguage[] {
//   return [
//     {name: 'typescript', func: typescript},
//     {name: 'scss', func: scss},
//     {name: 'xml', func: xml},
//     {name: 'json', func: json},
//   ];
// }

import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {SharedModule} from './views/shared/shared.module';
import {HashRouteGuard} from './core/auth/_guards/hashUrl.guard';
import {CheckHashRouteGuard} from './core/auth/_guards/checkHash.guard';
import {PluginsConfigProvider} from './services/plugins-config.provider';
import {PluginLoaderService} from './services/plugin-loader/plugin-loader.service';
import {ClientPluginLoaderService} from './services/plugin-loader/client-plugin-loader.service';
import {RouteReuseStrategy} from '@angular/router';
import {RouteReuseService} from './services/route-reuse.service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {TreeGridModule} from '@syncfusion/ej2-angular-treegrid';

@NgModule({
  declarations: [AppComponent],
  imports: [
    ApiModule,
    BrowserAnimationsModule,
    BrowserModule,
    TreeGridModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    PartialsModule,
    CoreModule,
    OverlayModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictActionImmutability: true, // disable it to avoid the error
        strictStateImmutability: false,
      },
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      serializer: DefaultRouterStateSerializer,
      stateKey: 'router',
    }),
    StoreDevtoolsModule.instrument(),
    AuthModule.forRoot(),
    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    InlineSVGModule.forRoot(),
    ThemeModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
    }),
    NgxSpinnerModule,
    LyThemeModule.setTheme('minima-light'),
    MatSelectCountryModule,
    NgxSummernoteModule,
    LyImageCropperModule,
    ModalModule.forRoot(),
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    //BrowserTransferStateModule
  ],
  exports: [NgxMaterialTimepickerModule],
  providers: [
    AuthService,
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    DataTableService,
    SplashScreenService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },
    // {
    //   provide: HIGHLIGHT_OPTIONS,
    //   useValue: {languages: hljsLanguages},
    // },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
    {
      provide: LY_THEME,
      useClass: MinimaLight,
      multi: true,
    },
    {
      provide: LY_THEME,
      useClass: MinimaDark,
      multi: true,
    },
    {
      provide: LY_THEME_GLOBAL_VARIABLES,
      useClass: GlobalVariables,
    }, // global variables
    {provide: BASE_PATH, useValue: environment.backendhost},
    //permission
    CanActivateRouteGuard,
    HashRouteGuard,
    CheckHashRouteGuard,
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {touchGestures: 'off'}},
    {provide: PluginLoaderService, useClass: ClientPluginLoaderService},
    PluginsConfigProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: (provider: PluginsConfigProvider) => () =>
        provider
          .loadConfig()
          .toPromise()
          .then((config) => (provider.config = config)),
      multi: true,
      deps: [PluginsConfigProvider],
    },
    //Route reuse
    {
      provide: RouteReuseStrategy,
      useClass: RouteReuseService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
