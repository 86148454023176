import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CompanyInfoService } from '@app/api/companyInfo.service';
import { AuthService, currentUser, User } from '@app/core/auth';
import { AppState } from '@app/core/reducers';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { SubheaderService } from '@app/core/_base/layout';
import { SubHeaderButtonService } from '@app/services/sub-header-button.service';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ExportModalComponent } from '../../../user-management/export-modal/export-modal.component';
import { CompanyImportModalComponent } from '../company-import-modal/company-import-modal.component';
import { AppCreateCompanyModalComponent } from '../app-create-company-modal/app-create-company-modal.component';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'meu-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class CompanyManagementComponent implements OnInit {
  selection = new SelectionModel<Element>(true, []);
  searchText: string = '';
  subject: Subject<any> = new Subject();
  allCompanies = [];
  selectedCompanies = [];
  totalCompanies = 0;
  pageIndex = 1;
  pagesize = 10;
  isHasPermission;
  user$: Observable<User>;
  curUser: any = {};

  displayedColumns: string[] = [
    'select',
    'index',
    'name',
    'address',
    'phone',
    'tax_code',
    'is_approve',
    'actions',
  ];
  //Mobile expand/collapse
  displayedColumnsMobile: string[] = [
    'name',
    'phone',
    'expand'
  ];
  expandedElement: any = null;

  constructor(
    private companyInfoService: CompanyInfoService,
    private dialog: MatDialog,
    private subheaderService: SubheaderService,
    public subHeaderButtonService: SubHeaderButtonService,
    private layoutUtilsService: LayoutUtilsService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private route: Router,
    private store: Store<AppState>,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.checkPermissions();
    this.subHeaderButtonService.setButtonCollection([]);
    this.subheaderServiceInit();
    this.searchContract();
    this.getData();
  }

  checkPermissions() {
    this.user$ = this.store.pipe(select(currentUser));
    this.user$.subscribe((user) => {
      this.curUser = Object.assign(this.curUser, user);
    });
    this.authService.getMyPermissions().subscribe((lstPermission) => {
      this.isHasPermission = lstPermission.includes('HR_EMPLOYEE');
      if (!this.isHasPermission) {
        this.route.navigate([
          '/portal/hr/setting/permission/company-management/' +
          this.curUser.company_id,
        ]);
      }
    });
  }

  subheaderServiceInit() {
    this.subheaderService.title$.next({
      title: 'Đơn vị',
      desc: 'Quản lý danh sách đơn vị',
    });
  }

  getData() {
    let filterString = 'name@=' + this.searchText;
    this.companyInfoService
      .apiCompaniesGet(
        filterString,
        'is_approve',
        this.pageIndex,
        this.pagesize
      )
      .subscribe((res) => {
        this.allCompanies = [...res.data.collection];
        this.allCompanies.forEach((value, index) => {
          this.allCompanies[index].index =
            this.pagesize * (this.pageIndex - 1) + (index + 1);
        });
        this.totalCompanies = res.data.total;
        this.pageIndex = res.data.pageIndex;
        for (let i = 0; i < this.allCompanies.length; i++) {
          for (let j = 0; j < this.selection.selected.length; j++) {
            if (this.allCompanies[i].id == this.selection.selected[j].id) {
              this.selection.deselect(this.selection.selected[j]);
              this.selection.select(this.allCompanies[i]);
            }
          }
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  onSearch() {
    this.subject.next();
  }
  searchContract() {
    this.subject.pipe(debounceTime(300)).subscribe(() => {
      this.getData();
    });
  }

  onClickPrint() {
    this.selectedCompanies = this.selection.selected;
    const dialogRef = this.dialog.open(ExportModalComponent, {
      data: { data: this.selectedCompanies, isUser: false },
      width: '500px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.event) {
        this.getData();
      }
    });
  }

  onClickUpload() {
    const dialogRef = this.dialog.open(CompanyImportModalComponent, {
      data: {},
      width: '500px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.event) {
        this.getData();
      }
    });
  }

  approveCompany(companyId: string) {
    const payload = this.allCompanies.find(({ id }) => id == companyId);
    payload.is_approve = true;
    this.companyInfoService
      .apiCompanyIdPut(companyId, { item1: payload, item2: [] })
      .subscribe((res) => {
        if (res.success) {
          this.toastr.success('Duyệt thành công', '');
        }
      });
  }

  rejectCompany(companyId: string) {
    const payload = this.allCompanies.find(({ id }) => id == companyId);
    payload.is_approve = false;
    this.companyInfoService
      .apiCompanyIdPut(companyId, { item1: payload, item2: [] })
      .subscribe((res) => {
        if (res.success) {
          this.toastr.success('Từ chối thành công', '');
        }
      });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.allCompanies.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.allCompanies.forEach((row) => this.selection.select(row));
  }

  paginatorChange(event) {
    this.pagesize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getData();
  }

  openModalCreate(id) {
    if (id == null) {
      let companyInfo = {
        name: '',
        tax_code: '',
        cellphone: '',
        address: '',
        employee_code_prefix: '',
        is_draft: true,
      };
      let arrayCompany = {};
      arrayCompany[0] = {};
      arrayCompany[0].item1 = companyInfo;
      arrayCompany[0].item2 = [];
      this.companyInfoService
        .apiCompanyPost(arrayCompany[0])
        .subscribe((res) => {
          if (res.success) {
            const dialogRef = this.dialog.open(AppCreateCompanyModalComponent, {
              width: '754px',
              data: { companyId: res.data.id, isCreate: true },
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result?.event) {
                this.getData();
              }
              this.changeDetectorRef.detectChanges();
            });
          }
        });
      return;
    }
    const dialogRef = this.dialog.open(AppCreateCompanyModalComponent, {
      width: '754px',
      data: { companyId: id, isCreate: false },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.event) {
        this.getData();
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  deleteUnit(id) {
    const title = 'Xóa đơn vị';
    const description = 'Bạn có chắc chắn muốn xóa đơn vị này?';
    const waitDesciption = 'Đang xử lý...';

    const dialogRef = this.layoutUtilsService.deleteElement(
      title,
      description,
      waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.companyInfoService.apiCompanyIdDelete(id).subscribe(
        (result) => {
          if (result.success) {
            this.getData();
            this.toastr.success(
              'Xóa thông tin khách hàng thành công',
              'Thao tác thành công'
            );
          } else {
            this.toastr.error(
              'Hợp đồng không thể xóa do chứa dữ liệu phụ thuộc',
              'Thao tác không thành công',
              {
                timeOut: 3000,
              }
            );
          }
        },
        (error) => {
          this.toastr.error(
            'Xóa thông tin khách hàng không thành công',
            'Thao tác không thành công',
            {
              timeOut: 3000,
            }
          );
        }
      );
    });
  }
}
