// Angular
import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ViewChild,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
// RxJS
// Translate
// NGRX
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../core/reducers';
// Auth
import {Subject} from 'rxjs';
import {MatStepper} from '@angular/material/stepper';
import {ToastrService} from 'ngx-toastr';
import {UsersService} from '@app/api/users.service';
import {DropdownOption} from '@app/views/shared/common_elements/dropdown/dropdown.component';
import {HrDegreeCategoryService} from '@app/api/hrDegreeCategory.service';
import {UserDegreeService} from '@app/api/userDegree.service';

@Component({
  selector: 'inventory-confirm-account',
  templateUrl: './inventory-confirm-account.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./inventory-confirm-account.component.scss'],
})
export class InventoryConfirmAccountComponent implements OnInit, OnDestroy {
  loading = true;
  key = '';
  success = false;
  errors?: string = '';
  notificatePage? = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UsersService,
    private degreeCatService: HrDegreeCategoryService,
    private userDegreeService: UserDegreeService,
    private store: Store<AppState>,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    console.log(this.route.snapshot);
    if (this.route.snapshot.queryParams.notificatePage == 'true') {
      this.notificatePage = true;
    } else {
      this.key = this.route.snapshot.queryParams.key;
      this.userService
        .apiUsersActivateAccountPut(this.route.snapshot.queryParams.key)
        .subscribe(
          (res) => {
            if (res.success && res.data) {
              this.router.navigateByUrl(
                '/auth/set-password?secretkey=' + res.data
              );
              return;
            }
            this.success = res.success;
            if (!res.success) {
              this.errors = res.message;
            }
          },
          null,
          () => {
            this.loading = false;
          }
        );
    }
  }

  ngOnDestroy(): void {
    this.loading = false;
  }
}
