<mat-card>
    <mat-card-content>
        <h3 class="title">DANH SÁCH CÁC QUYỀN</h3>
        <mat-table [dataSource]="allPermission">
            <ng-container matColumnDef="index">
                <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Tên </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef> Mô tả </mat-header-cell>
            </ng-container>
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef style="justify-content:center;">
                    <!-- <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox> -->
                </mat-header-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        </mat-table>

        <div *ngFor="let item of allPermission">
            <div class="row title-group-permission">{{item.groupName}}</div>
            <mat-table [dataSource]="item.listPermission">
                <ng-container matColumnDef="index">
                    <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.index}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Tên </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis" [title]="element.name">
                        <span class="ellipsis">
                            {{element.name}}
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef> Mô tả </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis" [title]="element.description">
                        <span class="ellipsis">
                            {{element.description}}
                        </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef style="justify-content:center;">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" style="justify-content:center;">
                        <mat-checkbox #eventCheck (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null; checkDependencyPermission(row, eventCheck)"
                            [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>
                <!-- <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row> -->
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
        </div>

        <div class="text-right">

            <a class="btn kt-subheader__btn-primary btn-label-primary btn-bold btn-icon-h kt-margin-t-20"
                (click)="save()">
                Lưu thay đổi
            </a>
        </div>


    </mat-card-content>
</mat-card>