<div class="los-header">
  <div class="wrapper-search mb-2">
    <img src="assets/icons/svg/icon_search.svg" alt="" />
    <input
      class="input-search"
      type="text"
      placeholder="Tìm kiếm mã ngành, tên ngành..."
      (keyup)="keyUp.next($event.target.value)"
    />
  </div>
  <button mat-button (click)="openAddDialog('addNew')">
    <mat-icon>add</mat-icon>
    Thêm
  </button>
</div>
<meu-treegrid
  *ngIf="treedata"
  [data]="treedata"
  [check]="false"
  (selected)="selectedData($event)"
  (refreshData)="getListOfSpecialties()"
></meu-treegrid>
<!-- <div class="los-paginator">
  <mat-paginator
    (page)="paginatorChange($event)"
    [pageSize]="pagesize"
    [length]="total"
    [hidePageSize]="false"
    [pageSizeOptions]="[5, 10, 20, 100]"
    [showFirstLastButtons]="true"
  >
  </mat-paginator>
</div> -->
