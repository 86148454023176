<label for="{{ name }}" attr.data-name="{{ name }}" class="input-label"
  >{{ title }}
  <span class="required-input-label" *ngIf="isRequiredField">*</span></label
>
<div
  class="w-100 meu-dropdown"
  clickOutside
  (clickOutside)="openDropdown = false"
>
  <div
    #drdbtn
    (click)="openDropdown = !openDropdown"
    (focusout)="markAsTouched()"
    [ngClass]="{'bg-lightest-grey': isDisabled}"
    class="meu-dropdown-trigger"
  >
    <div class="meu-dropdown-arrow">
      <svg
        fill="#4d4d50"
        viewBox="0 0 20 20"
        class="meu-dropdown-icon"
        [ngClass]="{
          rotate: openDropdown
        }"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </div>
    <div class="d-flex">
      <img
        *ngIf="selectedItem?.icon && !isActionMenu"
        [src]="selectedItem?.icon"
        alt=""
        width="16px"
        height="16px"
        class="mr-5px meu-dropdown-text-icon"
      />
      <input
        *ngIf="canInput; else showText"
        id="{{ name }}"
        [value]="value"
        (change)="onSelectItem($event.target.value)"
        [placeholder]="placeholder"
        [formControl]="formControl"
        [type]="inputType"
        class="w-100 input-box form-control {{
          classInput
        }} meu-dropdown-text pr-2"
      />
      <ng-template #showText>
        <input
          class="w-100 input-box form-control {{
            classInput
          }} meu-dropdown-text pr-2"
          [title]="
            selectedItem && selectedItem.tooltip ? selectedItem.tooltip : ''
          "
          [formControl]="formControl"
          [ngClass]="{
            placeholder: !isActionMenu && !selectedItem?.name,
            'input-text': isActionMenu || selectedItem?.name
          }"
          [placeholder]="
            selectedItem && selectedItem.name && !isActionMenu
              ? selectedItem.name
              : placeholder
          "
        />
      </ng-template>
    </div>
  </div>
  <div
    class="meu-dropdown-options-wrap"
    [ngClass]="{hidden: !openDropdown}"
    *ngIf="!isActionMenu; else actionMenu"
  >
    <div class="meu-dropdown-options">
      <li
        *ngFor="let item of options"
        class="meu-dropdown-item"
        [title]="item.tooltip ? item.tooltip : ''"
        (click)="onSelectItem(item)"
        [value]="item.id"
      >
        <img
          *ngIf="item.icon"
          [src]="item.icon"
          alt=""
          width="20px"
          height="20px"
          class="mr-5px"
        />
        <span class="{{ classFont }} truncate">
          {{ item.name }}
        </span>
      </li>
    </div>
  </div>

  <ng-template #actionMenu>
    <ul
      id="dropdown-options"
      class="{{ openDropdown ? '' : 'hidden' }} {{ align + '-0' }} {{
        'meu-dropdown-options'
      }}"
    >
      <li
        *ngFor="let item of options"
        class="meu-dropdown-item"
        [value]="item.id"
        (click)="onSelectItem(item)"
      >
        <img
          *ngIf="item.icon"
          class="w-20px h-20px mr-10px"
          alt="u_edit-alt"
          [src]="item.icon"
        />
        <div class="text-gray text-14px font-roboto-regular">
          {{ item.name }}
        </div>
      </li>
    </ul>
  </ng-template>
</div>

<div *ngIf="invalidState" class="input-error-label mt-[4px]">
  <ng-content select="[errorMessage]"></ng-content>
</div>
