import {Injectable} from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
} from '@angular/router';

import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {AuthService} from '../_services/auth.service';
import {Location} from '@angular/common';
import {HashService} from '../_services/hash.service';
import {AuthenticateService} from '@app/api/authenticate.service';

@Injectable()
export class CanActivateRouteGuard implements CanActivate {
  destinationRoute;
  currRoute;
  constructor(
    private router: Router,
    private authService: AuthService,
    private AuthService: AuthenticateService,
    private location: Location,
    private hashService: HashService,
    private route: ActivatedRoute
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const permissions = route.data['permissions'] as Array<string>;
    if (permissions) {
      this.authService.getMyPermissions().subscribe((lstPermission) => {
        const isHasPermission = lstPermission.includes(permissions);
        if (!isHasPermission) {
          this.AuthService.apiAuthenticateGetMyInfoGet().subscribe((res) => {
            if (res.success) {
              this.router.navigate([
                '/portal/hr/user-management/edit/',
                res.user.id,
              ]);
            } else {
              this.router.navigate(['/error/error-v6']);
            }
          });
        }
      });
    }

    return true;
  }
}
