import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SubHeaderButtonService } from "@app/services/sub-header-button.service";
import { MatDialog } from '@angular/material/dialog';
import { SubheaderService } from '@app/core/_base/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';


@Component({
  selector: 'meu-group-role',
  templateUrl: './group-role.component.html',
  styleUrls: ['./group-role.component.scss']
})
export class GroupRoleComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['index', 'name', 'description', 'action'];
  groupId;
  private subscriptions: Subscription[] = [];

  listRole = [];
  groupRoleForm = new FormGroup({
    group_id: new FormControl(),
    role_id: new FormControl()
  });

  constructor(
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private toastr: ToastrService,
    private subHeaderButtonService: SubHeaderButtonService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private subheaderService: SubheaderService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.subheaderService.title$.next({
      title: '',
      desc: 'Quản lý phân quyền Role'
    });
    this.subHeaderButtonService.setButtonCollection([]);

    if (this.activatedRoute.snapshot.params.groupId !== undefined && this.activatedRoute.snapshot.params.groupId !== null) {
      this.groupId = this.activatedRoute.snapshot.params.groupId;
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }


  getData() {
    // let filter = "ot_category_id=="+ this.currentOTCategoryDataID;
    // this.hrOtCategoryWorkingShiftService.apiHrOtCategoryWorkingShiftGet(filter,"",this.pageIndex, this.pagesize).subscribe(async res => {
    //   this.lstWorkingShift = res.data.collection;
    //   this.total = res.data.total;
    //   this.pageIndex = res.data.pageIndex;

    //   for (let i = 0; i < this.lstWorkingShift.length; i++) {
    //     this.lstWorkingShift[i]['index'] = (this.pageIndex - 1) * this.pagesize + i + 1;
    //     await this.workingShiftService.apiWorkingShiftsWorkingshiftIdGet(this.lstWorkingShift[i].working_shift_id).subscribe(res=>{
    //       if(res.success){
    //         this.lstWorkingShift[i]['name'] = res.data.name;
    //       }  
    //     })
    //     const timeRangeItem = {
    //       timeRanges: [],
    //       numberTimeRange: 0,
    //       totalTime: 0
    //     };
    //     this.lstWorkingShift[i].listTimeRange = timeRangeItem;


    //   }
    //   this.changeDetectorRef.detectChanges();
    // });
  }

  addRole() {
    if (this.groupRoleForm.value.staff_id == null) {
      this.toastr.info("Vui lòng lựa chọn nhân viên", "Thông báo");
      return;
    }

    let indexOfItem = this.listRole.map(function (x) { return x.staff_id; }).indexOf(this.groupRoleForm.value.staff_id);

    if (indexOfItem != -1) {
      this.toastr.warning("Nhân viên này đã được thêm", "Cảnh báo");
      return;
    }
    this.groupRoleForm.value.group_id = this.groupId;
    // this.hrOtCategoryWorkingShiftService.apiHrOtCategoryWorkingShiftPost(this.workingShiftForm.value).subscribe(res => {
    //   if (res.success) {
    //     this.getData();
    //     this.Toast.success('Thêm ca làm việc thành công', "Thao tác thành công");
    //   }
    //   else {
    //     this.Toast.error("Thao tác thêm mới thất bại", "");
    //   }
    // })
  }

  removeRole(id) {
    const title = "Xóa Role";
    const description = "Bạn có chắc chắn muốn xóa?";
    const waitDesciption = "Đang xử lý...";

    const dialogRef = this.layoutUtilsService.deleteElement(
      title,
      description,
      waitDesciption
    );
    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      // this.hrOtCategoryWorkingShiftService.apiHrOtCategoryWorkingShiftIdDelete(id).subscribe(res => {
      //   if (res.success) {
      //     this.getData()
      //     this.Toast.success("Thao tác xóa ca làm việc thành công", "Thao tác thành công");
      //   }
      //   else {
      //     this.Toast.error("Thao tác xóa ca làm việc không thành công", "Thao tác không thành công", {
      //       timeOut: 3000
      //     }
      //     );
      //   }
      // })
    })
  }

}
