import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {LyTheme2, StyleRenderer} from '@alyle/ui';
//Image resize frame
import {Platform} from '@angular/cdk/platform';
import {
  ImgCropperConfig,
  ImgCropperEvent,
  LyImageCropper,
} from '@alyle/ui/image-cropper';
import {ToastrService} from 'ngx-toastr';
import {environment} from '@app/../environments/environment';

const styles = {
  actions: {
    display: 'flex',
  },
  cropping: {
    textAlign: 'center',
    maxWidth: '400px',
    height: '260px',
  },
  flex: {
    flex: 1,
  },
  range: {
    textAlign: 'center',
    maxWidth: '400px',
    margin: '14px',
  },
  slider: {
    display: 'block',
    padding: '16px',
  },
};

export interface DialogData {
  image: any;
  cover: boolean;
  logo: boolean;
}

@Component({
  selector: 'kt-user-image-modal',
  templateUrl: './user-image-modal.component.html',
  styleUrls: ['./user-image-modal.component.scss'],
})
export class UserImageModalComponent implements OnInit, AfterViewInit {
  @ViewChild(LyImageCropper) cropper: LyImageCropper;
  urlImage: string = environment.url_svg_local;
  //image: any;
  Title_Modal = 'Chọn ảnh hồ sơ nhân sự';
  currentAvatar = null;
  classes: any;
  result: string;
  imgURL = 'assets/media/users/default.jpg';
  croppedImage?: string;
  scale: number;
  ready: boolean;

  myConfig: ImgCropperConfig = {
    autoCrop: true, // Default `false` - pls set true
    width: 200, // Default `250`
    height: 200, // Default `200`
    fill: '#ffffff', // Default transparent if type = png else #000
    type: 'image/png', // Or you can also use `image/jpeg`
    extraZoomOut: false,
    maxFileSize: 2000000,
  };

  constructor(
    public dialogRef: MatDialogRef<UserImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private theme: LyTheme2,
    readonly sRenderer: StyleRenderer,
    private _platform: Platform,
    private changeDetector: ChangeDetectorRef,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    if (this.data.cover) {
      this.setAttributeCover();
    } else if (this.data.logo) {
      this.setAttributeLogo();
    }
    this.classes = this.theme.addStyleSheet(styles);
    this.currentAvatar = this.data.image;
  }
  ngAfterViewInit() {
    if (this._platform.isBrowser) {
      const config = {
        scale: 1,
        position: {
          xOrigin: 0,
          yOrigin: 0,
        },
      };
      this.getCurrentImg(config);
    }
    //.center();
    //this.cropper.fit();
  }

  getCurrentImg(config) {
    this.cropper.loadImage(
      !!!this.currentAvatar ? this.imgURL : this.currentAvatar,
      () => {
        this.cropper.setScale(config.scale, true);
        this.cropper.updatePosition(
          config.position.xOrigin,
          config.position.yOrigin
        );
        this.cropper.fit();
        this.cropper.center();
      }
    );
  }

  setAttributeLogo() {
    this.Title_Modal = 'Chọn ảnh logo';
  }

  setAttributeCover() {
    this.Title_Modal = 'Chọn ảnh bìa';
    styles.cropping.maxWidth = '900px';
    styles.range.maxWidth = '900px';
    this.myConfig.width = 900;
    this.myConfig.height = 140;
  }

  onCropped(e) {
    this.data.image = e;
    this.croppedImage = e.dataURL;
  }
  onloaded(e: ImgCropperEvent) {
    this.cropper.center();
    this.changeDetector.detectChanges();
    this.cropper.fitToScreen();
  }
  onerror(e) {
    if (e.size > 1000000) {
      this.toastr.info('Vui lòng chọn hình ảnh có dung lượng dưới 2MB', '', {
        timeOut: 3000,
      });
    }
  }

  onButtonClick() {
    this.dialogRef.close({data: this.data.image});
  }

  onInputSliderChange(e) {
    this.scale = e.value;
  }
}
