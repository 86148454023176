<mat-form-field
  floatLabel="{{ floatLabel ? 'auto' : 'never' }}"
  class="full-width-field"
  [ngClass]="invalid ? 'ng-invalid mat-form-field-invalid' : ''"
>
  <mat-label>{{ placeholderLabel }}</mat-label>

  <mat-select
    placeholder="Select"
    [disabled]="disabled"
    [formControl]="selectCtrl"
    [ngModel]="defaultID"
  >
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="placeholderLabel"
        [noEntriesFoundLabel]="noEntriesFoundLabel"
        [formControl]="searchControl"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option (click)="unselect()"  *ngIf="allowUnSelect == true">-- Bỏ chọn --</mat-option>
    <mat-option *ngFor="let item of fiteredInput" [value]="item.id">{{
      item.name
    }}</mat-option>
  </mat-select>
  <!-- <button mat-button *ngIf="selectCtrl.value != undefined" matSuffix mat-icon-button aria-label="Clear"
	(click)="unselect()">
		<mat-icon class="icon-close">close</mat-icon>
	</button> -->
</mat-form-field>
