import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {SubheaderService} from '@app/core/_base/layout';
import {SubHeaderButtonService} from '@app/services/sub-header-button.service';
import {Subject, Subscription} from 'rxjs';
import {HrMajorService} from '@app/api/hrMajor.service';
import {LogServiceService} from './log-service.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TreegridAddDialogComponent} from '@app/views/shared/common_elements/treegrid/treegrid-add-dialog/treegrid-add-dialog.component';
import {debounceTime} from 'rxjs/operators';
interface TreeNode {
  id: number;
  parent_id: number;
  children?: TreeNode[];
}
@Component({
  selector: 'meu-list-of-specialties',
  templateUrl: './list-of-specialties.component.html',
  styleUrls: ['./list-of-specialties.component.scss'],
})
export class ListOfSpecialtiesComponent implements OnInit, OnDestroy {
  constructor(
    private subheaderService: SubheaderService,
    private subHeaderButtonService: SubHeaderButtonService,
    private hRmajorService: HrMajorService,
    private changeDetectorRef: ChangeDetectorRef,
    private losService: LogServiceService,
    private magDialog: MatDialog
  ) {}

  private subscriptions: Subscription[] = [];
  public keyUp = new Subject<KeyboardEvent>();
  filter: any = '';
  total = 0;
  pageIndex = 1;
  pagesize = 10;
  treedata = null;

  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
    this.subheaderService.title$.next({title: null, desc: null});
  }

  ngOnInit(): void {
    this.subheaderService.title$.next({
      title: 'Cài đặt',
      desc: 'Danh sách chuyên môn',
    });

    // this.subHeaderButtonService.setButtonCollection([
    //   {
    //     name: 'New',
    //     type: 'Text',
    //     icon: 'flaticon-download-1',
    //     label: 'Thêm mới',
    //     Code: 'openModalCreateListOfSpecialties',
    //   },
    // ]);
    this.getListOfSpecialties();
    this.onSearch();
  }

  public getListOfSpecialties() {
    this.subscriptions.push(
      this.hRmajorService
        .apiHrMajorGetallGet('name|code@=' + this.filter, '', this.pageIndex)
        .subscribe((res) => {
          if (res.success) {
            const unflattened = this.unflatten(res.data.collection);
            this.treedata = unflattened;
            this.losService.dataSourceTable.next(this.treedata);
          }
        })
    );
  }
  unflatten = (node: TreeNode[]) => {
    const map = {};
    for (let i = 0; i < node.length; i++) {
      map[node[i].id] = node[i];
    }

    for (let i = 0; i < node.length; i++) {
      const parent = map[node[i].parent_id];
      if (parent) {
        if (parent.children == null) {
          parent.children = [node[i]];
        } else {
          parent.children.push(node[i]);
        }
      }
    }
    return node.filter((e) => e.parent_id == null);
  };
  selectedData(selected: any) {
    console.log(selected);
  }
  onSearch() {
    this.keyUp.pipe(debounceTime(500)).subscribe((res) => {
      this.filter = res;
      this.getListOfSpecialties();
    });
  }
  openAddDialog(data: String) {
    const sdata = {
      type: data,
    };
    let dialogRef: MatDialogRef<TreegridAddDialogComponent>;
    dialogRef = this.magDialog.open(TreegridAddDialogComponent, {
      data: sdata,
      width: '544px',
      height: '245px',
    });
    return dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getListOfSpecialties();
      }
    });
  }
  paginatorChange(event) {
    this.pagesize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getListOfSpecialties();
  }
}
