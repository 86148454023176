<div class="row m-0">
  <!--Header section-->
  <div class="col-12 card card-info d-flex flex-row align-items-center mb-3">
    <img class="customer-img" [src]="companyInfo?.logo" />
    <div class="customer-info d-flex flex-column">
      <strong class="text-darker text-16px mb-2">{{
        companyInfo?.name
      }}</strong>
    </div>
  </div>
  <!--End header section-->
</div>
<div class="row m-0 mb-3">
  <div class="col-12 col-md-4 col-lg-3 p-0 mb-3 d-flex flex-column">
    <div class="card card-basic box-shadow mb-3">
      <div class="card-header pt-0 pb-0 d-flex justify-content-between">
        <label class="m-0 lable-Basic">Thông tin chung</label>
      </div>
      <div class="card-body">
        <div class="row m-0 row-basic">
          <div class="col-6 p-0 mb-3 d-flex pr-1">
            <div class="rectangle-icon div-id-card mr-2">
              <i class="fa fa-id-card-o icon-info-basic" aria-hidden="true"></i>
            </div>
            <div class="d-flex flex-column">
              <label class="title-info-basic mb-0 label-input-profile">
                Mã đơn vị
              </label>
              <span class="form-control pl-0 input-info">{{
                companyInfo.employee_code_prefix
              }}</span>
            </div>
          </div>
          <div class="col-6 p-0 mb-4 d-flex pl-1">
            <div class="rectangle-icon div-id-status-user mr-2">
              <i class="fa fa-sign-in icon-info-basic" aria-hidden="true"></i>
            </div>
            <div class="d-flex flex-column">
              <label class="title-info-basic mb-0 label-input-profile"
                >Ngày tạo</label
              >
              <span class="form-control pl-0 input-info">{{
                companyInfo.created_at | dateCustom : 'DD/MM/YYYY'
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-basic box-shadow mb-3 h-full">
      <div class="card-header pt-0 pb-0 d-flex justify-content-between">
        <label class="m-0 lable-Basic">Thiết lập khác</label>
      </div>
      <div
        class="p-3 border-bottom"
        role="button"
        (click)="onOpenConfig('budget')"
      >
        <div>
          {{
            companyInfo.budget
              ? 'Ngân sách: ' + formatCurrencyNumber(companyInfo.budget)
              : 'Ngân sách'
          }}
        </div>
      </div>
      <div
        class="p-3 border-bottom"
        role="button"
        (click)="onOpenConfig('mission')"
      >
        <div>
          {{
            companyInfo.mission_amount
              ? 'Số nhiệm vụ: ' + companyInfo.mission_amount
              : 'Số lượng nhiệm vụ'
          }}
        </div>
      </div>
      <div
        *ngIf="!companyInfo.is_approve"
        class="p-3 border-bottom"
        role="button"
        (click)="onOpenApprove()"
      >
        <div>Duyệt đơn vị</div>
      </div>
    </div>
  </div>
  <div
    class="col-12 col-md-8 col-lg-9 p-0 pl-md-3 mb-3 max-h"
    [formGroup]="companyPersonalForm"
  >
    <div class="card box-shadow mb-3">
      <div class="title-header pt-0 pb-0 d-flex justify-content-between">
        <label class="m-0 lable-Basic">Thông tin đơn vị </label>
        <div>
          <button
            mat-icon-button
            *ngIf="!openEditUnitInfo && isHasPermission"
            (click)="openEditUnitInfo = !openEditUnitInfo"
            matTooltip="Chỉnh sửa"
          >
            <i
              class="fa fa-pencil-square-o i-pencil-basic"
              aria-hidden="true"
            ></i>
          </button>
          <div class="card-header__button-a">
            <button
              mat-icon-button
              *ngIf="openEditUnitInfo"
              matTooltip="Lưu"
              (click)="saveEdit()"
            >
              <mat-icon>save</mat-icon>
            </button>
            <button
              mat-icon-button
              *ngIf="openEditUnitInfo"
              (click)="cancelEdit()"
              matTooltip="Hủy"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row m-0">
          <div class="col-6 col-md-6 pl-0 mb-2 pt-1">
            <div class="d-flex">
              <div class="flex-column div-input">
                <label class="label-input-profile">Tên đơn vị</label>
                <!-- <input
                  type="text"
                  name="name"
                  class="form-control d-none d-xl-block"
                  placeholder="{{
                    !companyPersonalForm.value.name && !openEditUnitInfo
                      ? '---'
                      : 'Nhập mã số thuế'
                  }}"
                  [readonly]="!openEditUnitInfo"
                  [ngClass]="{'input-info': !openEditUnitInfo}"
                  formControlName="name"
                /> -->
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  placeholder="{{
                    !companyPersonalForm.value.name && !openEditUnitInfo
                      ? '---'
                      : 'Nhập tên đơn vị'
                  }}"
                  [readonly]="!openEditUnitInfo"
                  [ngClass]="{'input-info': !openEditUnitInfo}"
                  formControlName="name"
                />
                <mat-error
                  *ngIf="
                    formErrorPersonal.isControlHasError(
                      companyPersonalForm,
                      'name',
                      'required'
                    ) && openEditUnitInfo
                  "
                >
                  Tên đơn vị là
                  <strong>bắt buộc</strong>
                </mat-error>
                <!-- <label class="input-info ellipsis form-control d-xl-none">{{
                  companyPersonalForm.value.name
                }}</label> -->
              </div>
            </div>
          </div>
          <div class="col-6 col-md-6 pl-0 mb-2 pt-1">
            <div class="d-flex">
              <div class="flex-column div-input">
                <label class="label-input-profile">Mã số thuế</label>
                <input
                  type="text"
                  name="tax_code"
                  class="form-control"
                  placeholder="{{
                    !companyPersonalForm.value.tax_code && !openEditUnitInfo
                      ? '---'
                      : 'Nhập mã số thuế'
                  }}"
                  [readonly]="!openEditUnitInfo"
                  formControlName="tax_code"
                  [ngClass]="{'input-info': !openEditUnitInfo}"
                />
                <mat-error
                  *ngIf="
                    formErrorPersonal.isControlHasError(
                      companyPersonalForm,
                      'tax_code',
                      'required'
                    ) && openEditUnitInfo
                  "
                >
                  Mã số thuế là
                  <strong>bắt buộc</strong>
                </mat-error>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-12 p-0">
            <hr />
          </div>
          <div class="col-6 col-md-6 pl-0 mb-2 pt-1">
            <div class="">
              <div class="">
                <div class="flex-column div-input">
                  <label class="label-input-profile">Điện thoại đơn vị</label>
                  <input
                    type="text"
                    name="cellphone"
                    class="form-control w-100"
                    placeholder="{{
                      companyPersonalForm.value.cellphone == null &&
                      !openEditUnitInfo
                        ? '---'
                        : 'Nhập số điện thoại đơn vị'
                    }}"
                    [readonly]="!openEditUnitInfo"
                    formControlName="cellphone"
                    [ngClass]="{'input-info': !openEditUnitInfo}"
                  />
                  <mat-error
                    *ngIf="
                      formErrorPersonal.isControlHasError(
                        companyPersonalForm,
                        'cellphone',
                        'maxlength'
                      ) && openEditUnitInfo
                    "
                  >
                    Số điện thoại không quá
                    <strong>20 ký tự </strong>
                  </mat-error>
                  <mat-error
                    *ngIf="
                      formErrorPersonal.isControlHasError(
                        companyPersonalForm,
                        'cellphone',
                        'pattern'
                      ) && openEditUnitInfo
                    "
                  >
                    Số điện thoại
                    <strong>không hợp lệ</strong>
                  </mat-error>
                  <!-- <label class="input-info ellipsis form-control d-xl-none">{{
                    companyPersonalForm.value.cellphone
                  }}</label> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-6 pl-0 mb-2 pt-1 px-0">
            <div class="d-flex">
              <div class="flex-column div-input">
                <label class="label-input-profile">Địa chỉ đơn vị</label>
                <!-- <input
                  type="text"
                  name="name"
                  class="form-control ellipsis d-none d-xl-block"
                  placeholder="---"
                  [readonly]="!openEditUnitInfo"
                  formControlName="address"
                  [ngClass]="{'input-info': !openEditUnitInfo}"
                /> -->
                <input
                  type="text"
                  name="name"
                  class="form-control ellipsis"
                  placeholder="{{
                    companyPersonalForm.value.address == null &&
                    !openEditUnitInfo
                      ? '---'
                      : 'Nhập địa chỉ đơn vị'
                  }}"
                  [readonly]="!openEditUnitInfo"
                  formControlName="address"
                  [ngClass]="{'input-info': !openEditUnitInfo}"
                />
                <mat-error
                  *ngIf="
                    formErrorPersonal.isControlHasError(
                      companyPersonalForm,
                      'address',
                      'required'
                    ) && openEditUnitInfo
                  "
                >
                  Địa chị là
                  <strong>bắt buộc</strong>
                </mat-error>
                <!-- <label class="input-info ellipsis form-control d-xl-none">{{
                  companyPersonalForm.value.address
                }}</label> -->
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-12 p-0">
            <hr />
          </div>
          <div class="col-6 col-md-6 pl-0 mb-2 pt-1">
            <div class="d-flex">
              <div class="flex-column div-input">
                <label class="label-input-profile">Website</label>
                <!-- <input
                  type="text"
                  name="name"
                  class="form-control ellipsis d-none d-xl-block"
                  placeholder="{{
                    companyPersonalForm.value.website == null &&
                    !openEditUnitInfo
                      ? '---'
                      : 'Nhập website'
                  }}"
                  [readonly]="!openEditUnitInfo"
                  formControlName="website"
                  [ngClass]="{'input-info': !openEditUnitInfo}"
                /> -->
                <input
                  type="text"
                  name="name"
                  class="form-control ellipsis"
                  placeholder="{{
                    companyPersonalForm.value.website == null &&
                    !openEditUnitInfo
                      ? '---'
                      : 'Nhập website'
                  }}"
                  [readonly]="!openEditUnitInfo"
                  formControlName="website"
                  [ngClass]="{'input-info': !openEditUnitInfo}"
                />
                <mat-error
                  *ngIf="
                    formErrorPersonal.isControlHasError(
                      companyPersonalForm,
                      'website',
                      'pattern'
                    ) && openEditUnitInfo
                  "
                >
                  Website
                  <strong>không hợp lệ</strong>
                </mat-error>
                <!-- <label class="input-info ellipsis form-control d-xl-none">{{
                  companyPersonalForm.value.website
                }}</label> -->
              </div>
            </div>
          </div>
          <div class="col-6 col-md-6 mb-2 pt-1 px-0">
            <div class="d-flex">
              <div class="flex-column div-input">
                <label class="label-input-profile">Email</label>
                <!-- <input
                  type="email"
                  name="name"
                  class="form-control ellipsis d-none d-xl-block"
                  placeholder="{{
                    companyPersonalForm.value.email == null && !openEditUnitInfo
                      ? '---'
                      : 'Nhập email'
                  }}"
                  [readonly]="!openEditUnitInfo"
                  formControlName="email"
                  [ngClass]="{'input-info': !openEditUnitInfo}"
                /> -->
                <input
                  type="email"
                  name="name"
                  class="form-control ellipsis"
                  placeholder="{{
                    companyPersonalForm.value.email == null && !openEditUnitInfo
                      ? '---'
                      : 'Nhập email'
                  }}"
                  [readonly]="!openEditUnitInfo"
                  formControlName="email"
                  [ngClass]="{'input-info': !openEditUnitInfo}"
                />
                <mat-error
                  *ngIf="
                    formErrorPersonal.isControlHasError(
                      companyPersonalForm,
                      'email',
                      'pattern'
                    ) && openEditUnitInfo
                  "
                >
                  Email
                  <strong>không hợp lệ</strong>
                </mat-error>
                <!-- <label class="input-info ellipsis form-control d-xl-none">{{
                  companyPersonalForm.value.email
                }}</label> -->
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-12 p-0">
            <hr />
          </div>
          <div class="col-6 col-md-6 pl-0 mb-2 pt-1">
            <div class="d-flex">
              <div class="flex-column div-input">
                <label class="label-input-profile">Fax</label>
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  placeholder="{{
                    companyPersonalForm.value.fax == null && !openEditUnitInfo
                      ? '---'
                      : 'Nhập fax'
                  }}"
                  [readonly]="!openEditUnitInfo"
                  formControlName="fax"
                  [ngClass]="{'input-info': !openEditUnitInfo}"
                />
                <mat-error
                  *ngIf="
                    formErrorPersonal.isControlHasError(
                      companyPersonalForm,
                      'fax',
                      'pattern'
                    ) && openEditUnitInfo
                  "
                >
                  Số Fax
                  <strong>không hợp lệ</strong>
                </mat-error>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-6 mb-2 pt-1 px-0">
            <div class="d-flex">
              <div class="flex-column div-input">
                <label class="label-input-profile">Tên thủ trưởng</label>
                <!-- <input
                  type="text"
                  name="chief_full_name"
                  class="form-control ellipsis d-none d-xl-block"
                  placeholder="---"
                  [readonly]="!openEditUnitInfo"
                  formControlName="chief_full_name"
                  [ngClass]="{'input-info': !openEditUnitInfo}"
                /> -->
                <input
                  type="text"
                  name="chief_full_name"
                  class="form-control ellipsis"
                  placeholder="{{
                    companyPersonalForm.value.chief_full_name == null &&
                    !openEditUnitInfo
                      ? '---'
                      : 'Nhập tên thủ trưởng'
                  }}"
                  [readonly]="!openEditUnitInfo"
                  formControlName="chief_full_name"
                  [ngClass]="{'input-info': !openEditUnitInfo}"
                />
                <mat-error
                  *ngIf="
                    formErrorPersonal.isControlHasError(
                      companyPersonalForm,
                      'chief_full_name',
                      'required'
                    ) && openEditUnitInfo
                  "
                >
                  Tên thủ trưởng là
                  <strong>bắt buộc</strong>
                </mat-error>
                <!-- <label class="input-info ellipsis form-control d-xl-none">{{
                  companyPersonalForm.value.chief_full_name
                }}</label> -->
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-12 p-0">
            <hr />
          </div>
          <div class="col-6 col-md-6 pl-0 mb-2 pt-1">
            <div class="d-flex">
              <div class="flex-column div-input">
                <label class="label-input-profile">Giới tính thủ trưởng</label>
                <div *ngIf="openEditUnitInfo" class="d-flex align-items-center">
                  <label
                    class="d-flex align-items-center mr-4 label-input-profile"
                  >
                    <input
                      class="mr-2"
                      type="radio"
                      [value]="true"
                      name="chief_gender"
                      formControlName="chief_gender"
                      [checked]="
                        companyPersonalForm.get('chief_gender').value === true
                      "
                    />
                    Nam
                  </label>
                  <label class="d-flex align-items-center label-input-profile">
                    <input
                      class="mr-2"
                      type="radio"
                      [value]="false"
                      name="chief_gender"
                      formControlName="chief_gender"
                      [checked]="
                        companyPersonalForm.get('chief_gender').value === false
                      "
                    />
                    Nữ
                  </label>
                </div>
                <label
                  *ngIf="!openEditUnitInfo"
                  class="input-info ellipsis form-control"
                  >{{
                    companyPersonalForm.get('chief_gender').value == null
                      ? '---'
                      : companyPersonalForm.get('chief_gender').value
                      ? 'Nam'
                      : 'Nữ'
                  }}</label
                >
                <mat-error
                  *ngIf="
                    formErrorPersonal.isControlHasError(
                      companyPersonalForm,
                      'chief_gender',
                      'required'
                    ) && openEditUnitInfo
                  "
                >
                  Giới tính thủ trưởng là
                  <strong>bắt buộc</strong>
                </mat-error>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card box-shadow mb-3">
      <div class="title-header pt-0 pb-0 d-flex justify-content-between">
        <label class="m-0 lable-Basic">Danh sách quản trị viên</label>
      </div>
      <div class="row mt-2 ml-3 d-none d-sm-block" *ngIf="isHasPermission">
        <div class="col-lg-4 col-xs-8 col-sm-8 kt-margin-bottom-20-mobile">
          <!-- <meu-control-select-search-load-more
            [stringFilter]="'company_id==' + companyId"
            [placeholderLabel]="'Chọn nhân viên'"
            [noEntriesFoundLabel]="'Không có lựa chọn phù hợp'"
            [columnName]="'extend_user_full_name'"
            [nameAPI]="'/api/users'"
            (change)="onChangeManager($event)"
          >
          </meu-control-select-search-load-more> -->
          <meu-control-select-search-load-more
            [stringFilter]="'is_disabled==false,is_internal==false'"
            [placeholderLabel]="'Chọn nhân viên'"
            [noEntriesFoundLabel]="'Không có lựa chọn phù hợp'"
            [columnName]="'extend_user_full_name'"
            [nameAPI]="'/api/users'"
            (change)="onChangeManager($event)"
          >
          </meu-control-select-search-load-more>
        </div>
        <div class="col-lg-4 col-xs-4 col-sm-4 kt-margin-bottom-20-mobile">
          <a
            class="btn kt-subheader__btn-primary btn-label-primary btn-bold btn-icon-h"
            (click)="addUser()"
          >
            Thêm
          </a>
        </div>
      </div>
      <div class="card-body">
        <div class="row m-0 mat-table-user table-user-head">
          <mat-table class="w-100" #table [dataSource]="lstManager">
            <!-- Index -->
            <ng-container matColumnDef="index">
              <mat-header-cell *matHeaderCellDef class="d-none d-lg-flex">
                #
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = index"
                class="justify-content-center d-none d-lg-flex"
              >
                {{ i + 1 }}
              </mat-cell>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="extend_user_full_name">
              <mat-header-cell *matHeaderCellDef>
                Tên quản trị viên
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="cell-element">
                <span
                  [matTooltip]="element.extend_user_full_name"
                  class="ellipsis input-info"
                >
                  {{ element.extend_user_full_name }}
                </span>
              </mat-cell>
            </ng-container>
            <!-- employee code Column -->
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = index"
                class="cell-element"
              >
                <span [matTooltip]="element.email" class="ellipsis input-info">
                  {{ element.email || '--' }}
                </span>
              </mat-cell>
            </ng-container>
            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <mat-header-cell
                *matHeaderCellDef
                class="justify-content-center d-none d-sm-flex"
                [ngClass]="!isHasPermission ? 'hide' : ''"
              >
                Thao tác
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = index"
                class="cell-element justify-content-center d-none d-sm-flex"
                [ngClass]="!isHasPermission ? 'hide' : ''"
              >
                <span class="col-12 non-padding fw-600 show-on-mobile">
                  Thao tác
                </span>
                <button
                  mat-icon-button
                  color="warn"
                  matTooltip="Xóa"
                  class="btn-height"
                  (click)="removeUser(element.id)"
                >
                  <mat-icon class="iconColor">delete</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedManagerColumns; sticky: true"
              class="table-header"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedManagerColumns"
              class="table-row"
            ></mat-row>
          </mat-table>
        </div>
      </div>
    </div>
    <div class="card box-shadow mb-3" *ngIf="isHasPermission">
      <div class="title-header pt-0 pb-0 d-flex justify-content-between">
        <label class="m-0 lable-Basic">Danh sách chủ nhiệm</label>
      </div>
      <div class="card-body">
        <div class="row m-0 mat-table-user">
          <mat-table
            class="w-100 d-none d-sm-block"
            #table
            [dataSource]="lstUser"
          >
            <!-- Index -->
            <ng-container matColumnDef="index">
              <mat-header-cell *matHeaderCellDef class="d-none d-lg-flex">
                #
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = index"
                class="d-none d-lg-flex"
              >
                {{ i + 1 }}
              </mat-cell>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="extend_user_full_name">
              <mat-header-cell *matHeaderCellDef>
                Tên chủ nhiệm
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="cell-element">
                <span
                  class="col-12 non-padding fw-600 show-on-mobile label-input-profile"
                  >Tên chủ nhiệm</span
                >
                <span
                  [matTooltip]="element.extend_user_full_name"
                  class="ellipsis input-info"
                  *ngIf="!element.isNew"
                >
                  {{ element.extend_user_full_name }}
                </span>
                <div
                  class="custom-select-control table-select"
                  *ngIf="element.isNew"
                >
                  <meu-control-select-search-load-more
                    [placeholderLabel]="'Chọn nhân viên'"
                    [noEntriesFoundLabel]="'Không có lựa chọn phù hợp'"
                    [stringFilter]="'disabled==false'"
                    [columnName]="'extend_user_full_name'"
                    [nameAPI]="'/api/users'"
                    [floatLabel]="false"
                    [allowUnSelect]="false"
                    [multiple]="false"
                    [defaultID]="element.id"
                    (changeItem)="onChangeUser($event)"
                  >
                  </meu-control-select-search-load-more>
                </div>
              </mat-cell>
            </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="position">
              <mat-header-cell *matHeaderCellDef>
                Học vị - Chức danh
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = index"
                class="cell-element"
              >
                <span
                  class="col-12 non-padding fw-600 show-on-mobile label-input-profile"
                >
                  Học vị - Chức danh
                </span>
                <span
                  [matTooltip]="
                    element.academic_rank
                      ? element.degree
                        ? element.academic_rank + ' - ' + element.degree
                        : element.academic_rank
                      : element.degree
                      ? element.degree
                      : 'Không'
                  "
                  class="ellipsis input-info"
                >
                  {{
                    element.academic_rank
                      ? element.degree
                        ? element.academic_rank + ' - ' + element.degree
                        : element.academic_rank
                      : element.degree
                      ? element.degree
                      : 'Không'
                  }}
                </span>
              </mat-cell>
            </ng-container>
            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = index"
                class="cell-element"
              >
                <span
                  class="col-12 non-padding fw-600 show-on-mobile label-input-profile"
                >
                  Email
                </span>
                <span [matTooltip]="element.email" class="ellipsis input-info">
                  {{ element.email }}
                </span>
              </mat-cell>
            </ng-container>
            <!-- Status Column -->
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef class="justify-content-center"
                >Trạng thái</mat-header-cell
              >
              <mat-cell
                *matCellDef="let element; let i = index"
                class="cell-element"
              >
                <span
                  class="col-12 non-padding fw-600 show-on-mobile label-input-profile"
                >
                  Trạng thái
                </span>
                <span
                  class="ellipsis status-badge"
                  [ngClass]="element.disabled ? 'unAuth' : 'auth'"
                  [matTooltip]="
                    element.disabled ? 'Chưa kích hoạt' : 'Đã kích hoạt'
                  "
                >
                  {{ element.disabled ? 'Chưa kích hoạt' : 'Đã kích hoạt' }}
                </span>
              </mat-cell>
            </ng-container>
            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef class="justify-content-center">
                <button
                  mat-flat-button
                  (click)="addNewUser()"
                  class="btn-text-blue p-0"
                  matTooltip="Thêm đơn vị"
                >
                  <mat-icon>add</mat-icon>Thêm
                </button>
              </mat-header-cell>
              <mat-cell
                *matCellDef="let element; let i = index"
                class="cell-element"
              >
                <span class="col-12 non-padding fw-600 show-on-mobile">
                  <button
                    mat-flat-button
                    (click)="addNewUser()"
                    class="btn-text-blue p-0"
                    matTooltip="Thêm đơn vị"
                  >
                    <mat-icon>add</mat-icon>Thêm
                  </button>
                </span>
                <button
                  mat-icon-button
                  color="primary"
                  (click)="onSaveAssignUser()"
                  *ngIf="element.isNew"
                  class="btn-edit btn-height"
                >
                  <mat-icon class="iconColor">save</mat-icon>
                </button>
                <button
                  mat-icon-button
                  color="primary"
                  matTooltip="Chi tiết"
                  [routerLink]="['/portal/hr/user-management/edit', element.id]"
                  class="btn-edit btn-height"
                  *ngIf="!element.isNew"
                >
                  <mat-icon class="iconColor">info</mat-icon>
                </button>
                <button
                  mat-icon-button
                  color="warn"
                  [matTooltip]="'Xóa người dùng khỏi đơn vị'"
                  class="btn-height"
                  (click)="unassignCompany(element.id)"
                >
                  <mat-icon class="iconColor" [inline]="false">
                    delete
                  </mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
              class="table-header"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              class="table-row"
            ></mat-row>
          </mat-table>
          <!-- Mobile -->
          <mat-table
            class="d-sm-none tablet-user-mobile"
            [dataSource]="lstUser"
            multiTemplateDataRows
          >
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef>
                Tên chủ nhiệm
              </mat-header-cell>
              <mat-cell *matCellDef="let element" class="ellipsis">
                <span
                  class="ellipsis"
                  [matTooltip]="element.extend_user_full_name"
                >
                  {{ element.extend_user_full_name || '--' }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
              <mat-cell *matCellDef="let element" class="ellipsis">
                <span class="ellipsis" [matTooltip]="element.email">
                  {{ element.email || '--' }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="expand">
              <mat-header-cell *matHeaderCellDef aria-label="row actions">
                &nbsp;
              </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <button
                  mat-icon-button
                  aria-label="expand row"
                  (click)="
                    expandedElement =
                      expandedElement === element ? null : element;
                    $event.stopPropagation()
                  "
                >
                  <mat-icon *ngIf="expandedElement !== element"
                    >keyboard_arrow_down</mat-icon
                  >
                  <mat-icon *ngIf="expandedElement === element"
                    >keyboard_arrow_up</mat-icon
                  >
                </button>
              </mat-cell>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <mat-cell
                *matCellDef="let element; let i = index"
                [attr.colspan]="displayedColumnsMobile.length"
              >
                <div
                  class="example-element-detail"
                  [@detailExpand]="
                    element == expandedElement ? 'expanded' : 'collapsed'
                  "
                >
                  <div class="expand-container">
                    <div class="row">
                      <div class="col-6">
                        <p class="expand-label-text">Học vị - Chức danh</p>
                      </div>
                      <div class="col-6">
                        <div class="ellipsis">
                          <p
                            [matTooltip]="element.address"
                            class="expand-detail-main-text ellipsis"
                          >
                            {{
                              element.academic_rank
                                ? element.degree
                                  ? element.academic_rank +
                                    ' - ' +
                                    element.degree
                                  : element.academic_rank
                                : element.degree
                                ? element.degree
                                : 'Không'
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="col-6">
                        <p class="expand-label-text">Trạng thái</p>
                      </div>
                      <div class="col-6">
                        <span
                          class="ellipsis status-badge ml-0"
                          [ngClass]="element.disabled ? 'unAuth' : 'auth'"
                          [matTooltip]="
                            element.disabled ? 'Chưa kích hoạt' : 'Đã kích hoạt'
                          "
                        >
                          {{
                            element.disabled ? 'Chưa kích hoạt' : 'Đã kích hoạt'
                          }}
                        </span>
                      </div>
                      <div class="col-6">
                        <p></p>
                      </div>
                      <div class="col-6">
                        <div class="ellipsis">
                          <button
                            class="fw-400"
                            mat-icon-button
                            [routerLink]="[
                              '/portal/hr/user-management/edit',
                              element.id
                            ]"
                          >
                            <mat-icon class="icon-btn">info</mat-icon
                            ><span>Xem</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsMobile"
            ></mat-header-row>
            <mat-row
              *matRowDef="let element; columns: displayedColumnsMobile"
              class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"
              (click)="expandedElement = element"
            ></mat-row>
            <mat-row
              *matRowDef="let row; columns: ['expandedDetail']"
              class="example-detail-row"
              [hidden]="expandedElement !== row"
            ></mat-row>
          </mat-table>
        </div>
      </div>
    </div>
  </div>
</div>
