import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ReplaySubject } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import * as CryptoJS from 'crypto-js';
@Injectable()
export class HashService {
  private _configs: any[] = [];
  constructor() { }

  encryptData(data: any) {
    const md5 = new Md5();
    return md5
      .appendStr(
        JSON.stringify(data).toLocaleLowerCase() + environment.encryptPassword
      )
      .end()
      .toString();
  }

  encryptDataSignalR(data: any) {
    return CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(data),
      CryptoJS.enc.Utf8.parse(environment.aes_key),
      {
        keySize: 128 / 8,
        iv: CryptoJS.enc.Utf8.parse(environment.aes_iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString();
  }

  get configs() {
    return this._configs;
  }

  set configs(configs: any) {
    if (configs) {
      this._configs = configs;
    } else {
      this._configs = [];
    }
  }
}
