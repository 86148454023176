import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LogServiceService {
  dataSourceTable = new BehaviorSubject<any>([]);
  constructor() {}
}
