<div [ngClass]="isSubmitted ? 'hide' : ''">
  <div class="modal-header">
    <div>
      <h2 class="headder">Tải lên file đơn vị</h2>
    </div>
    <div>
      <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="example-container">
      <div class="row">
        <div class="col-12">
          <form [formGroup]="form" novalidate>
            <mat-form-field class="w-100">
              <ngx-mat-file-input
                placeholder="Chọn tệp"
                accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                required
                (change)="uploadFile($event)"
                formControlName="file"
              ></ngx-mat-file-input>
              <mat-icon matSuffix class="uploadfile-icon">folder</mat-icon>
            </mat-form-field>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a
      class=""
      download="import-company.xlsx"
      target="_blank"
      href="./assets/templates/import-company-template.xlsx"
      >Tải về file mẫu</a
    >
    <div>
      <button class="btn btn-submit mr-2" (click)="onSubmit()">Tải lên</button>
      <button class="btn btn-close" (click)="closeModal()">Hủy</button>
    </div>
  </div>
</div>
<div [ngClass]="isSubmitted ? '' : 'hide'">
  <div class="example-container mat-elevation-z8 mat-table-user">
    <mat-table #table [dataSource]="allResult">
      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef class="justify-content-center"
          >Tên đơn vị</mat-header-cell
        >
        <mat-cell *matCellDef="let element" class="ellipsis">
          <span [matTooltip]="element.title" class="ellipsis">
            {{ element.title }}
          </span>
        </mat-cell>
      </ng-container>

      <!-- Message Column -->
      <ng-container matColumnDef="message">
        <mat-header-cell *matHeaderCellDef> Trạng thái </mat-header-cell>
        <mat-cell *matCellDef="let element" class="cell-element">
          <span [matTooltip]="element.message" class="ellipsis">
            {{ element.message }}
          </span>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="table-header"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="table-row"
      ></mat-row>
    </mat-table>
  </div>
</div>
