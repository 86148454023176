import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';

@Component({
  selector: 'meu-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() name: string;
  @Input() title: string;
  @Input() placeholderText: string;
  @Input() formControl: FormControl;
  @Input() invalidState: boolean;
  @Input() iconClass: string;
  @Input() maximumLength: number;
  @Input() minimumLength: number;
  @Input() numberKeypadOn: boolean;
  @Input() classInput: string;
  @Input() disabled: boolean;
  @Input() type: string = 'text';
  @Input() currency: boolean = false;
  @Input() readonly: boolean = false;
  @Input() isRequiredField: boolean = false;
  @Output() iconClick = new EventEmitter();
  pattern: string;
  inputType: string;

  ngOnInit(): void {
    if (this.numberKeypadOn) {
      this.pattern = '[0-9]*';
      this.inputType = 'numeric';
    } else {
      this.pattern = '';
      this.inputType = 'text';
    }
  }
}
