import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {HrTemplateService} from '@app/api/hrTemplate.service';
import {MailMergeService} from '@app/api/mailMerge.service';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {saveAs} from 'file-saver';
import {UsersService} from '@app/api/users.service';
import {CompanyInfoService} from '@app/api/companyInfo.service';

@Component({
  selector: 'meu-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss'],
})
export class ExportModalComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  @ViewChild('meuLoadmoreComponent') meuLoadmoreComponent;
  constructor(
    public dialogRef: MatDialogRef<ExportModalComponent>,
    private toastr: ToastrService,
    private hrTemplateService: HrTemplateService,
    private mailMergeService: MailMergeService,
    private userService: UsersService,
    private companyInfoService: CompanyInfoService,
    @Inject(MAT_DIALOG_DATA) public selectedUser
  ) {}
  template_id;
  selectedUser_id = [];
  isUser;
  titleName;
  ngOnDestroy(): void {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  ngOnInit(): void {
    if (this.selectedUser.isUser) {
      this.isUser = true;
      this.titleName = 'Xuất thông tin nhân viên';
    } else {
      this.isUser = false;
      this.titleName = 'Xuất thông tin đơn vị';
    }
    for (let i = 0; i < this.selectedUser.data.length; i++) {
      this.selectedUser_id[i] = this.selectedUser.data[i].id;
    }
  }

  onChangeModal($event) {
    if ($event) {
      this.hrTemplateService
        .apiHrTemplatesGet('id ==' + $event)
        .subscribe((res) => {
          if (res.success) {
            this.template_id = res.data.collection[0].id;
          }
        });
    } else {
      this.template_id = undefined;
    }
  }

  exportExcel() {
    if (this.isUser) {
      this.userService
        .apiUsersExportUserByIdsGet(
          this.selectedUser_id.toString(),
          this.selectedUser.disabled
        )
        .subscribe(
          (res) => {
            var blob = new Blob([res]);
            saveAs(blob, 'UserInfo.xlsx');
            this.dialogRef.close({event: true});
          },
          (err) => {
            this.toastr.warning(err, 'Thao tác không thành công', {
              timeOut: 3000,
            });
          }
        );
    } else {
      this.companyInfoService
        .apiCompanyExportGet(this.selectedUser_id.toString())
        .subscribe(
          (res) => {
            var blob = new Blob([res]);
            saveAs(blob, 'CompanyInfo.xlsx');
            this.dialogRef.close({event: true});
          },
          (err) => {
            this.toastr.warning(err, 'Thao tác không thành công', {
              timeOut: 3000,
            });
          }
        );
    }
  }

  exportTemplate() {
    this.meuLoadmoreComponent.triggerCheckError();
    if (this.template_id == undefined) {
      this.toastr.warning('Vui lòng chọn biểu mẫu cần xuất!', '');
    } else {
      this.mailMergeService
        .apiMailMergeExportTemplateIdCategoryCodePost(
          this.template_id,
          'USER',
          this.selectedUser_id
        )
        .subscribe(
          (res) => {
            var blob = new Blob([res]);
            saveAs(blob, 'attachment.zip');
          },
          (err) => {
            this.toastr.warning(err, 'Thao tác không thành công', {
              timeOut: 3000,
            });
          }
        );
    }
  }

  closeModal() {
    this.dialogRef.close({event: false});
  }
}
