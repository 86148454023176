import {
  Component,
  OnInit,
  Inject,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { GroupManagementComponent } from '../group-management.component';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'meu-modal-create-group',
  templateUrl: './modal-create-group.component.html',
  styleUrls: ['./modal-create-group.component.scss']
})
export class ModalCreateGroupComponent implements OnInit {

  hasFormErrors = false;
  isEdit;

  createGroupForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl()
  });

  constructor(
    public dialogRef: MatDialogRef<GroupManagementComponent>,
    @Inject(MAT_DIALOG_DATA) public dataInject,
    private toastr: ToastrService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    if (this.dataInject.groupId) {
      this.isEdit = true;
      // this.getSectionByID();
    }
    else {
      this.isEdit = false;
    }
  }

  getGroupByID() {
    // this.saPayslipSectionService.apiSalariesSaSheetsSheetIdSaPayslipSectionsIdGet(this.dataInject.sheetID, this.dataInject.sectionID)
    //   .subscribe(res => {
    //     this.createGroupForm.setValue({
    //       name: res.data.name,
    //       row_count: res.data.description
    //     });
    //   })

  }

  save() {
    const controls = this.createGroupForm.controls;
    if (this.createGroupForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    }

    this.createGroupForm.markAllAsTouched();
    // if (this.isEdit) {
    //   this.saPayslipSectionService.apiSalariesSaSheetsSheetIdSaPayslipSectionsIdPut(this.dataInject.roleId, this.createGroupForm.value)
    //     .subscribe(res => {
    //       if (res.success) {
    //         this.dialogRef.close({ event: true });
    //       }
    //       else {
    //         this.toastr.error('Cập nhật Role không thành công')
    //       }
    //     })
    // }
    // else {
    //   this.saPayslipSectionService.apiSalariesSaSheetsSheetIdSaPayslipSectionsPost(this.createGroupForm.value)
    //     .subscribe(res => {
    //       if (res.success) {
    //         this.dialogRef.close({ event: true });
    //       }
    //       else {
    //         this.toastr.error('Tạo mới Role không thành công')
    //       }
    //     })
    // }

  }

  closeModal() {
    this.dialogRef.close({ event: false });
  }


}
