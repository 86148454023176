<div class="kt-portlet m-0">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title title-color bold" *ngIf="!isEdit">
                Thêm thẻ mới
            </h3>
            <h3 class="kt-portlet__head-title title-color bold" *ngIf="isEdit">
                Chỉnh sửa thẻ
            </h3>
        </div>
        <div (click)="closeModalClick(null)" class="close_modal_btn pointer" [matTooltip]="'Đóng'">
            <mat-icon>close</mat-icon>
        </div>
    </div>

    <div class="kt-portlet__body max-height-body">
        <div class="row">
            <div class="col-xl-12">
                <div class="row">
                    <div class="col-12 kt-padding-l-5 kt-padding-r-5">
                        <form [formGroup]="createBoardForm">
                            <div class="kt-margin-b-15">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Tên thẻ</mat-label>
                                            <input matInput formControlName="name" autocomplete="off" required>
                                            <mat-error *ngIf="createBoardForm.controls.name?.errors?.required">
                                                Vui lòng nhập tên thẻ
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field">
                                            <mat-label>Mô tả</mat-label>
                                            <textarea matInput formControlName="description" autocomplete="off"
                                                matTextareaAutosize matAutosizeMinRows="2"
                                                matAutosizeMaxRows="4"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div> -->
                            </div>
                        </form>

                    </div>
                </div>


            </div>
        </div>
    </div>

    <div class="kt-portlet__foot">
        <div class="row">
            <div class="col-12 footer-padding text-right">
                <button type="button" class="btn button-cancel" (click)="closeModalClick(null)">
                    Hủy
                </button>
                <button type="button" class="btn button-save" (click)="save()">
                    Lưu
                </button>
            </div>
        </div>
    </div>
</div>