<div>
    <div class="header-modal">
        <div class="font-roboto-medium">
            Chọn chuyên ngành
        </div>
        <div>
            <button type="button" class="close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="p-4 custom-tree tree-height">
        <div class="wrapper-search mb-2">
            <img src="assets/icons/svg/icon_search.svg" alt="">
            <input 
                class="input-search" 
                type="text" 
                placeholder="Tìm kiếm mã ngành, tên ngành..." 
                (keyup)="keyUp.next($event.target.value)">
        </div>
        <meu-treegrid
            #tree
            *ngIf="treedata"
            [data]="treedata"
            [isService]="true"
            [check]="true"
            [isRegister]="true"
            (selected)="selectedData($event)"
        ></meu-treegrid>
    </div>
    <div class="kt-footer px-4 pb-4">
        <button 
            mat-flat-button 
            class="mr-3" 
            style="color: #1878f9;" 
            mat-dialog-close>
            Đóng
        </button>
        <button 
            mat-flat-button
            (click)="onSubmit()"
            [disabled]="selectedValue.length === 0"
            style="background-color: #1878f9; color: #ffffff;">
            Xác nhận
        </button>
    </div>
</div>
