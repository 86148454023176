<div class="example-container">
  <!-- <mat-form-field class="full-width mat-form-field-fluid" >
    <input type="text" matInput   [(ngModel)]="focus"
    [attr.autofocus] ="true" >
  </mat-form-field>  -->
  <mat-table #table [dataSource]="arrRequired">
    <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
    <!-- Position Column -->
    <ng-container matColumnDef="index">
      <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        {{ i + 1 }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="fileDescription">
      <mat-header-cell *matHeaderCellDef> Mô tả tệp đính kèm </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <mat-form-field class="full-width mat-form-field-fluid">
          <input
            [id]="'name' + i"
            type="text"
            matInput
            [(ngModel)]="element.name"
            [disabled]="
              checkExistRequired(element.sub_code) || element.editFile == false
            "
            (ngModelChange)="element.isChange = true"
            [placeholder]="
              checkExistRequired(element.sub_code) || element.editFile == false
                ? ''
                : 'Mô tả'
            "
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="fileName">
      <mat-header-cell *matHeaderCellDef>Tên tệp đính kèm </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index">
        <ng-container *ngIf="element.editFile == false">
          <a
            [matTooltip]="'Đọc tệp ' + element.file_name"
            href="javascript:void(0)"
            class="link-page ellipsis width-cell"
            (click)="viewFile(element.physical_path, element.file_name)"
          >
            {{ element.file_name }}</a
          >
        </ng-container>
        <div *ngIf="edit" class="full-width">
          <mat-form-field
            [ngClass]="
              element.invalid == true ? 'ng-invalid mat-form-field-invalid' : ''
            "
            class="full-width"
            *ngIf="element.editFile != false"
          >
            <ngx-mat-file-input
              matTooltip="Chọn tệp"
              placeholder=""
              id="{{ 'file' + i }}"
              class="ellipsis"
              [valuePlaceholder]="element.file_name"
              accept="file_extension|audio/*|video/*|image/*|media_type"
              (change)="chooseFile($event, element)"
              [disabled]="element.progress > 0 || !edit"
              (empty)="(true)"
            >
            </ngx-mat-file-input>

            <mat-progress-spinner
              matSuffix
              class="example-margin"
              color="accent"
              mode="determinate"
              [value]="element.progress"
              diameter="17"
              *ngIf="element.progress > 0"
            >
            </mat-progress-spinner>
            <mat-icon
              matSuffix
              class="icon"
              *ngIf="element.progress == 0 || element.progress == null"
              matTooltip="Chọn tệp"
            >
              attach_file
            </mat-icon>
          </mat-form-field>
        </div>
        <div *ngIf="!element.file_name && !edit">
          <label>---</label>
        </div>
      </mat-cell>
    </ng-container>
    <!-- Weight Column -->
    <ng-container matColumnDef="fileSize" style="text-align: center;">
      <mat-header-cell *matHeaderCellDef> Dung lượng </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div *ngIf="!!element.file_name">
          {{ element.file_size }}
        </div>

        <div *ngIf="!element.file_name && !edit">
          <label>---</label>
        </div>
      </mat-cell>
    </ng-container>
    <!-- Symbol Column -->
    <ng-container matColumnDef="action">
      <ng-container *ngIf="permissionStr; else actionTemplate">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell
          *matCellDef="let element; let i = index"
          style="display: flex; justify-content: flex-end; padding-right: 0;"
        >
          <button
            [attr.permission]="permissionStr"
            type="button"
            mat-icon-button
            color="primary"
            matTooltip="Chỉnh sửa tệp"
            *ngIf="element.editFile == false && edit == true"
            (click)="element.editFile = true; oldName = element.name"
          >
            <mat-icon class="icon iconGray">create</mat-icon>
          </button>
          <button
            [attr.permission]="permissionStr"
            type="button"
            mat-icon-button
            color="primary"
            matTooltip="Xóa tệp"
            (click)="deleteFile(element, i)"
            *ngIf="element.editFile == false && edit == true"
          >
            <mat-icon class="icon iconGray">delete</mat-icon>
          </button>
          <button
            [attr.permission]="permissionStr"
            type="button"
            mat-icon-button
            color="primary"
            matTooltip="Lưu tệp"
            *ngIf="element.editFile != false && edit"
            (click)="uploadFile(element.event, element, i)"
          >
            <mat-icon class="icon iconGray">save</mat-icon>
          </button>
          <button
            [attr.permission]="permissionStr"
            type="button"
            mat-icon-button
            color="primary"
            matTooltip="Hủy"
            *ngIf="element.editFile != false && edit"
            (click)="cancelEdit(i, element)"
          >
            <mat-icon class="icon iconGray">close</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <ng-template #actionTemplate>
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell
          *matCellDef="let element; let i = index"
          style="display: flex; justify-content: flex-end; padding-right: 0;"
        >
          <button
            type="button"
            mat-icon-button
            color="primary"
            matTooltip="Chỉnh sửa tệp"
            *ngIf="element.editFile == false && edit == true"
            (click)="element.editFile = true; oldName = element.name"
          >
            <mat-icon class="icon iconGray">create</mat-icon>
          </button>
          <button
            type="button"
            mat-icon-button
            color="primary"
            matTooltip="Xóa tệp"
            (click)="deleteFile(element, i)"
            *ngIf="element.editFile == false && edit == true"
          >
            <mat-icon class="icon iconGray">delete</mat-icon>
          </button>
          <button
            type="button"
            mat-icon-button
            color="primary"
            matTooltip="Lưu tệp"
            *ngIf="element.editFile != false && edit"
            (click)="uploadFile(element.event, element, i)"
          >
            <mat-icon class="icon iconGray">save</mat-icon>
          </button>
          <button
            type="button"
            mat-icon-button
            color="primary"
            matTooltip="Hủy"
            *ngIf="element.editFile != false && edit"
            (click)="cancelEdit(i, element)"
          >
            <mat-icon class="icon iconGray">close</mat-icon>
          </button>
        </mat-cell>
      </ng-template>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <div class="row" *ngIf="edit == true && addMore == true">
    <div
      class="col-12"
      style="display: flex; justify-content: center; background: white;"
      (click)="addRowFile()"
    >
      <button
        type="button"
        mat-button
        color="acc"
        style="
          width: 100%;
          background-color: #f5f5f5;
          border-radius: unset;
          color: #5867dd;
        "
      >
        Thêm dòng
      </button>
    </div>
  </div>
</div>
