<div class="bg-gradient">
  <div class="bg-image">
    <div class="grid-contain content for-iphone">
      <div class="logo">
        <img class="w-100" src="/assets/media/Image/logo.png" />
      </div>
    </div>

    <div
      class="grid-contain content"
      [ngClass]="curUrl == '/auth/register' ? 'scrollable' : ''"
    >
      <div class="left">
        <router-outlet></router-outlet>
      </div>
      <div class="right">
        <img src="/assets/media/Image/business-guy.png" />
      </div>
    </div>
  </div>
  <div class="footer">
    &nbsp;© &nbsp;{{ today | date : 'yyyy' }}
    {{ env.titlePage }}
  </div>
</div>
