import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {CompanyInfoService} from '@app/api/companyInfo.service';
import {UsersService} from '@app/api/users.service';
import {AuthService, currentUser, User} from '@app/core/auth';
import {AppState} from '@app/core/reducers';
import {SubheaderService} from '@app/core/_base/layout';
import {FormErrorService} from '@app/views/shared/services/form-error.service';
import {Store, select} from '@ngrx/store';
import {environment} from 'environments/environment';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs';
import {CompanyBudgetMissionModalComponent} from '../company-budget-mission-modal/company-budget-mission-modal/company-budget-mission-modal.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CompanyInfocompanyManagerListTuple} from '@app/model/companyInfocompanyManagerListTuple';

const phonePattern =
  /(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*/;

const websitePattern =
  /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

@Component({
  selector: 'meu-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class CompanyDetailComponent implements OnInit {
  companyId: string;
  companyInfo: any = {};
  curUserId;
  lstUser = new MatTableDataSource();
  lstManager = new MatTableDataSource();
  companyManagers = [];
  lstManagersInfo = [];
  companyUsers = [];
  displayedColumns: string[] = [
    'index',
    'extend_user_full_name',
    'position',
    'email',
    'status',
    'action',
  ];
  displayedManagerColumns: string[] = [
    'index',
    'extend_user_full_name',
    'email',
    'action',
  ];

  curUser: any = {};
  openEditUnitInfo: boolean = false;
  formErrorPersonal;
  user$: Observable<User>;
  isHasPermission;
  companyPersonalForm = new FormGroup({
    address: new FormControl(null, [Validators.required]),
    tax_code: new FormControl(null, [Validators.required]),
    name: new FormControl(null, [Validators.required]),
    cellphone: new FormControl(null, [
      Validators.maxLength(20),
      Validators.pattern(phonePattern),
    ]),
    website: new FormControl('', [Validators.pattern(websitePattern)]),
    email: new FormControl('', [
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
    fax: new FormControl('', [Validators.pattern(phonePattern)]),
    chief_full_name: new FormControl('', [Validators.required]),
    chief_gender: new FormControl(null, [Validators.required]),
  });
  //Mobile expand/collapse
  displayedColumnsMobile: string[] = ['name', 'email', 'expand'];
  expandedElement: any = null;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private subheaderService: SubheaderService,
    private userService: UsersService,
    private store: Store<AppState>,
    private authService: AuthService,
    private companyInfoService: CompanyInfoService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.checkPermissions();
    if (this.route.snapshot.params.id) {
      this.companyId = this.route.snapshot.params.id;
      this.getCompanyData();
      this.getCompanyManagerData();
      this.formErrorPersonal = new FormErrorService();
    }
  }

  checkPermissions() {
    this.user$ = this.store.pipe(select(currentUser));
    this.user$.subscribe((user) => {
      this.curUser = Object.assign(this.curUser, user);
    });
    this.authService.getMyPermissions().subscribe((lstPermission) => {
      this.isHasPermission =
        lstPermission.includes('HR_EMPLOYEE') ||
        lstPermission.includes('HR_MY_COMPANY');
    });
  }

  initSubheaderService(name) {
    this.subheaderService.title$.next({
      title: null,
      desc: 'Thông tin đơn vị - ' + name,
    });
  }

  getCompanyData() {
    this.companyInfoService.apiCompanyIdGet(this.companyId).subscribe((res) => {
      if (res.success) {
        this.companyInfo = res.data;
        this.companyInfo.logo = environment.backendhost + this.companyInfo.logo;
        this.companyPersonalForm.patchValue(this.companyInfo);
        this.initSubheaderService(res.data.name);
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  unassignCompany(userId: string) {
    try {
      this.companyInfoService
        .apiUsersUserIdCompaniesCompanyIdDelete(userId, this.companyId)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success('Rời khỏi đơn vị thành công', '');
            this.getCompanyManagerData();
          }
        });
    } catch (e) {
      console.log(e);
    }
  }

  addNewUser() {
    this.lstUser.data = [
      ...this.lstUser.data,
      {
        id: '',
        isNew: true,
      },
    ];
  }

  onChangeUser(item: any) {
    (this.lstUser.data[this.lstUser.data.length - 1] as any) = {
      ...item,
      isNew: true,
    };
  }

  onSaveAssignUser() {
    const addedUser: any = this.lstUser.data.find((item: any) => item.isNew);

    if (
      this.lstUser.data.find(
        (item: any) => item.id == addedUser.id && !item.isNew
      )
    ) {
      this.toastr.warning('Nhân viên đã được thêm', '');
      return;
    }
    if (!addedUser || !addedUser.id) {
      this.toastr.warning('Chọn nhân viên trước khi lưu', '');
      return;
    }
    this.companyInfoService
      .apiUsersUserIdCompaniesCompanyIdassignCompanyPatch(
        addedUser.id,
        this.companyId
      )
      .subscribe((res) => {
        if (res.success) {
          this.toastr.success('Thêm vào đơn vị thành công!', '');
          this.getCompanyManagerData();
        }
      });
  }

  getCompanyManagerData() {
    this.lstManagersInfo = [];
    this.companyInfoService.apiCompanyIdGet(this.companyId).subscribe((res) => {
      if (res.success) {
        this.companyManagers = res.data.extend_company_managers;
        this.userService.apiUsersGet(null, '', 1, 9999).subscribe((res) => {
          if (res.success) {
            this.lstManager.data = this.companyManagers
              .filter((item) => !item.is_not_manager)
              .map((item) =>
                res.data.collection.find((x) => x.id == item.user_id)
              );

            this.lstUser.data = this.companyManagers
              .filter((item) => item.is_not_manager)
              .map((item) =>
                res.data.collection.find((x) => x.id == item.user_id)
              );
          }
        });
      }
    });
  }

  addUser() {
    if (this.curUserId == null) {
      this.toastr.warning('Vui lòng lựa chọn nhân viên', 'Thông báo');
      return;
    }

    let indexOfItem = this.companyManagers
      .map(function (x) {
        return x.user_id;
      })
      .indexOf(this.curUserId);

    if (indexOfItem != -1) {
      this.toastr.warning('Nhân viên này đã được thêm', 'Cảnh báo');
      return;
    }
    const curManagerData = {
      user_id: this.curUserId,
      company_id: this.companyId,
    };
    const payload = [
      ...this.companyManagers.filter((item) => !item.is_not_manager),
      curManagerData,
    ];

    this.companyManagers.push(curManagerData);
    this.companyInfoService
      .apiCompanyIdManagersPut(this.companyId, payload)
      .subscribe((res) => {
        if (res.success) {
          this.getCompanyManagerData();
        }
      });
  }

  removeUser(userId: string) {
    const newManagers = this.companyManagers.filter(
      (item) => item.user_id != userId && !item.is_not_manager
    );
    this.companyInfoService
      .apiCompanyIdManagersPut(this.companyId, newManagers)
      .subscribe((res) => {
        if (res.success) {
          this.getCompanyManagerData();
        }
      });
  }

  onChangeManager(e) {
    this.curUserId = e;
  }

  cancelEdit() {
    this.openEditUnitInfo = false;
    this.companyPersonalForm.patchValue(this.companyInfo);
  }

  saveEdit() {
    const controls = this.companyPersonalForm.controls;
    /** check form */
    if (this.companyPersonalForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    let companyInfo = {...this.companyInfo};
    delete companyInfo.extend_company_managers;
    this.companyInfoService
      .apiCompanyIdPut(this.companyId, {
        item1: {...companyInfo, ...this.companyPersonalForm.value},
        item2: [],
      })
      .subscribe((res) => {
        if (res.success) {
          this.toastr.success(
            'Chỉnh sửa thông tin đơn vị thành công',
            'Thao tác thành công',
            {}
          );
          this.openEditUnitInfo = false;
          this.getCompanyData();
        } else {
          this.toastr.error(res.message, 'Thao tác thất bại');
        }
      });
  }

  onOpenConfig(type: 'budget' | 'mission') {
    const dialogRef = this.dialog.open(CompanyBudgetMissionModalComponent, {
      width: '754px',
      data: {
        id: this.companyId,
        isBudget: type === 'budget',
        ...(type === 'budget'
          ? {
              value: this.companyInfo.budget,
              date: this.companyInfo.budget_duration,
            }
          : {
              value: this.companyInfo.mission_amount,
              date: this.companyInfo.mission_duration,
            }),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.event) {
        this.companyInfoService
          .apiCompanyIdPut(this.companyId, {
            item1: {
              ...this.companyInfo,
              ...(type === 'budget' && {
                budget: result.value as number,
                budget_duration: result.date,
              }),
              ...(type === 'mission' && {
                mission_amount: result.value as number,
                mission_duration: result.date,
              }),
            },
            item2: [],
          })
          .subscribe((res) => {
            if (res.success) {
              this.toastr.success(
                'Cập nhật thông tin đơn vị thành công',
                'Thao tác thành công',
                {}
              );
              this.getCompanyData();
            } else {
              this.toastr.error(res.message, 'Thao tác thất bại', {
                timeOut: 3000,
              });
            }
          });
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  onOpenApprove() {
    const payload: CompanyInfocompanyManagerListTuple = {
      item1: {...this.companyInfo, is_approve: true, logo: null},
      item2: [],
    };
    this.companyInfoService
      .apiCompanyIdPut(this.companyId, payload)
      .subscribe((res) => {
        if (res.success) {
          this.toastr.success('Duyệt thành công', '');
          this.getCompanyData();
        }
      });
  }

  formatCurrencyNumber(num: string) {
    try {
      return num
        .toString()
        .trim()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } catch (e) {
      return null;
    }
  }
}
