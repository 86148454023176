import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {SubheaderService} from '@app/core/_base/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {SelectionModel} from '@angular/cdk/collections';
import {PRoleService} from '@app/api/api';
import {LayoutUtilsService} from '@app/core/_base/crud';

@Component({
  selector: 'meu-role-permission',
  templateUrl: './role-permission.component.html',
  styleUrls: ['./role-permission.component.scss'],
})
export class RolePermissionComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['index', 'name', 'description', 'action'];
  private subscriptions: Subscription[] = [];
  roleId;
  selection = new SelectionModel<Element>(true, []);
  allPermission = [];
  listCurrentPermissionOfUser = [];

  constructor(
    private toastr: ToastrService,
    private changeDetectorRef: ChangeDetectorRef,
    private subheaderService: SubheaderService,
    private activatedRoute: ActivatedRoute,
    private pRoleService: PRoleService,
    private layoutUtilsService: LayoutUtilsService
  ) {}

  ngOnInit() {
    this.subheaderService.url$.next({
      url: '/portal/hr/setting/permission/role-management',
    });
    if (
      this.activatedRoute.snapshot.params.roleId !== undefined &&
      this.activatedRoute.snapshot.params.roleId !== null
    ) {
      this.roleId = this.activatedRoute.snapshot.params.roleId;
      this.getListPermission();
      this.pRoleService.apiPRolesIdGet(this.roleId).subscribe((res) => {
        this.subheaderService.title$.next({
          title: '',
          desc: 'Quản lý phân quyền - ' + res.data.name,
        });
      });
    }
  }

  getListPermission() {
    this.pRoleService
      .apiPRolesRoleIdPermissionsGet(this.roleId, '', '', 1, 100)
      .subscribe((res) => {
        if (res.success) {
          this.listCurrentPermissionOfUser.splice(
            0,
            this.listCurrentPermissionOfUser.length
          );
          for (let i = 0; i < res.data.collection.length; i++) {
            // this.listCurrentPermissionOfUser.push(res.data.collection[i].permission_id)
            this.listCurrentPermissionOfUser.push({
              id: res.data.collection[i].id,
              permission_id: res.data.collection[i].permission_id,
            });
          }
          this.pRoleService.apiAllPermissionsGet().subscribe((result) => {
            if (result.success) {
              this.allPermission.splice(0, this.allPermission.length);
              for (let j = 0; j < result.data.length; j++) {
                let objPermission: any = {
                  id: result.data[j].id,
                  index: j + 1,
                  name: result.data[j].name,
                  description: result.data[j].description,
                  groupName: result.data[j].groupName,
                  dependency_permission: result.data[j].dependency_permission
                    ? result.data[j].dependency_permission
                    : [],
                };
                const check = this.allPermission.some(
                  (d) => d.groupName == result.data[j].groupName
                );
                if (check) {
                  this.allPermission.forEach((value, index) => {
                    if (value.groupName == result.data[j].groupName) {
                      this.allPermission[index].listPermission.push(
                        objPermission
                      );
                    }
                  });
                } else {
                  this.allPermission.push({
                    groupName: result.data[j].groupName,
                    listPermission: [objPermission],
                  });
                }

                const isExists = this.listCurrentPermissionOfUser.some(
                  (d) => d.permission_id == result.data[j].id
                );
                if (isExists) {
                  this.selection.select(objPermission);
                }
              }
            }

            this.changeDetectorRef.detectChanges();
          });
        }
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
    this.subheaderService.url$.next({url: null});
    this.subheaderService.title$.next({title: null, desc: null});
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    let numRows = 0;
    for (let i = 0; i < this.allPermission.length; i++) {
      numRows += this.allPermission[i].listPermission.length;
    }
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      for (let i = 0; i < this.allPermission.length; i++) {
        this.allPermission[i].listPermission.forEach((row: any) =>
          this.selection.select(row)
        );
      }
    }
  }

  checkDependencyPermission(row, event) {
    if (event.checked) {
      for (let i = 0; i < row.dependency_permission.length; i++) {
        let isBreak = false;
        for (let k = 0; k < this.allPermission.length; k++) {
          for (
            let j = 0;
            j < this.allPermission[k].listPermission.length;
            j++
          ) {
            if (
              this.allPermission[k].listPermission[j].id ==
              row.dependency_permission[i]
            ) {
              this.selection.select(this.allPermission[k].listPermission[j]);
              isBreak = true;
              break;
            }
          }
          if (isBreak) break;
        }
      }
    } else {
      let isBreak = false;
      for (let i = 0; i < this.allPermission.length; i++) {
        for (let j = 0; j < this.allPermission[i].listPermission.length; j++) {
          const isExists = this.allPermission[i].listPermission[
            j
          ].dependency_permission.some((d) => d == row.id);
          const isExistsInSelection = this.selection.selected.some(
            (d) => d.id == this.allPermission[i].listPermission[j].id
          );
          if (isExists && isExistsInSelection) {
            this.toastr.warning(
              'Bạn không thể bỏ chọn quyền này vì có liên quan đến quyền phụ thuộc',
              'Cảnh báo'
            );
            this.selection.select(row);
            event.checked = true;
            isBreak = true;
            break;
          }
        }
        if (isBreak) break;
      }
    }
  }

  save() {
    const action = 'Lưu';
    const color = '#000000';
    const title = 'Xác nhận lưu phân quyền';
    const description = 'Bạn có chắc chắn muốn lưu phân quyền cho role này?';
    const waitDesciption = 'Đang xử lý...';
    const dialogRef = this.layoutUtilsService.updateElement(
      action,
      color,
      title,
      description,
      waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        // this.selection.clear()
        // this.getListPermission()
        return;
      }
      for (let i = 0; i < this.listCurrentPermissionOfUser.length; i++) {
        this.pRoleService
          .apiPRolesRoleIdPermissionsIdDelete(
            this.roleId,
            this.listCurrentPermissionOfUser[i].id
          )
          .subscribe((result) => {
            if (
              result.success &&
              i == this.listCurrentPermissionOfUser.length - 1 &&
              this.selection.selected.length == 0
            ) {
              // this.selection.clear()
              this.toastr.success('Lưu thành công');
              // this.getListPermission()
            }
          });
      }
      for (let j = 0; j < this.selection.selected.length; j++) {
        let obj: any = {
          role_id: this.roleId,
          permission_id: this.selection.selected[j].id,
        };
        this.pRoleService
          .apiPRolesRoleIdPermissionsPost(this.roleId, obj)
          .subscribe((res) => {
            if (res.success) {
              if (j == this.selection.selected.length - 1) {
                // this.selection.clear()
                this.toastr.success('Lưu thành công');
                // this.getListPermission()
              }
            }
          });
      }
    });
  }
}
