<!--begin::Body-->
<div class="confirm-account-body">
  <!--begin::Signin-->
  <div class="" *ngIf="!notificatePage">
    <div *ngIf="success" class="confirm-account-container">
      <mat-icon class="icon">check_circle</mat-icon>
      <div class="confirm-account-title">Xác nhận thành công</div>
      <div class="confirm-account-subtitle">
        Chúc mừng bạn đã đăng kí thành công tài khoản tại SKHCN!
      </div>
      <button class="login-button" mat-flat-button routerLink="/auth/login">
        Đăng nhập ngay
      </button>
    </div>
    <div *ngIf="!success" class="confirm-account-container">
      <mat-icon class="icon error">highlight_off</mat-icon>
      <div class="confirm-account-title">Xác nhận không thành công</div>
      <div class="confirm-account-subtitle">{{ errors }}</div>
      <button
        mat-stroked-button
        class="register-button"
        routerLink="/auth/register"
      >
        Đến trang đăng kí
      </button>
    </div>
  </div>
  <div class="" *ngIf="notificatePage">
    <div class="confirm-account-container">
      <mat-icon class="icon notificate">info</mat-icon>
      <div class="confirm-account-title">Một bước nữa</div>
      <div class="confirm-account-subtitle">
        Vui lòng kiểm tra e-mail đã đăng kí để hoàn tất đăng kí!
      </div>
      <div class="text-center"><a routerLink="/auth/login">Đăng nhập</a></div>
    </div>
  </div>
</div>
