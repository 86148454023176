<div class="kt-footer kt-footer--extended kt-grid__item" id="kt_footer">
  <div class="kt-footer__bottom">
    <div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
      <div class="kt-footer__wrapper">
        <div class="kt-footer__logo">
          <a href="/portal/hr/user-management">
            <img
              class="logo-footer"
              alt="Logo"
              [src]="htmlClassService.logoPathSubject$ | async"
            />
          </a>
          <div class="kt-footer__copyright">
            {{ today | date: 'yyyy' }}&nbsp;©&nbsp;
            <a
              href="https://meu-solutions.com/"
              target="_blank"
              rel="noreferrer"
              >MeU Solutions, Inc</a
            >
          </div>
        </div>
        <div class="float-right" [title]="version.detail">
          Version: {{ version.number }}
        </div>
        <div class="kt-footer__menu">
          <!-- <a href="http://keenthemes.com/metronic" target="_blank"
            >Purchase Lisence</a
          >
          <a href="http://keenthemes.com/metronic" target="_blank">Team</a>
          <a href="https://meu-solutions.com/" target="_blank">Contact</a> -->
        </div>
      </div>
    </div>
  </div>
</div>
