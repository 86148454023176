/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { SaSheetLeaveData } from '../model/saSheetLeaveData';
import { SaSheetOtData } from '../model/saSheetOtData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SaSheetProceedDataService {

    protected basePath = 'https://gateway.dev.meu-solutions.com/sciencetechnologysso';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param sheetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetIdApprovePost(sheetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetIdApprovePost(sheetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetIdApprovePost(sheetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetIdApprovePost(sheetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSaSheetsSheetIdApprovePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/saSheets/${encodeURIComponent(String(sheetId))}/approve`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetIdRejectPost(sheetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetIdRejectPost(sheetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetIdRejectPost(sheetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetIdRejectPost(sheetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSaSheetsSheetIdRejectPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/saSheets/${encodeURIComponent(String(sheetId))}/reject`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetIdRequestApproveSalaryDataByListStaffPost(sheetId: string, body?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetIdRequestApproveSalaryDataByListStaffPost(sheetId: string, body?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetIdRequestApproveSalaryDataByListStaffPost(sheetId: string, body?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetIdRequestApproveSalaryDataByListStaffPost(sheetId: string, body?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSaSheetsSheetIdRequestApproveSalaryDataByListStaffPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/saSheets/${encodeURIComponent(String(sheetId))}/requestApproveSalaryDataByListStaff`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetIdRequestApproveSalaryDataForAllPost(sheetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetIdRequestApproveSalaryDataForAllPost(sheetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetIdRequestApproveSalaryDataForAllPost(sheetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetIdRequestApproveSalaryDataForAllPost(sheetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSaSheetsSheetIdRequestApproveSalaryDataForAllPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/saSheets/${encodeURIComponent(String(sheetId))}/requestApproveSalaryDataForAll`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetIdStaffLeaveDataGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetIdStaffLeaveDataGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetIdStaffLeaveDataGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetIdStaffLeaveDataGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSaSheetsSheetIdStaffLeaveDataGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/saSheets/${encodeURIComponent(String(sheetId))}/staffLeaveData`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetIdStaffOTDataGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetIdStaffOTDataGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetIdStaffOTDataGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetIdStaffOTDataGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSaSheetsSheetIdStaffOTDataGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/saSheets/${encodeURIComponent(String(sheetId))}/staffOTData`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetIdStaffSalaryDataGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetIdStaffSalaryDataGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetIdStaffSalaryDataGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetIdStaffSalaryDataGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSaSheetsSheetIdStaffSalaryDataGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/saSheets/${encodeURIComponent(String(sheetId))}/staffSalaryData`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param staffId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetIdStaffStaffIdWorkingDataForSalaryGet(sheetId: string, staffId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetIdStaffStaffIdWorkingDataForSalaryGet(sheetId: string, staffId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetIdStaffStaffIdWorkingDataForSalaryGet(sheetId: string, staffId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetIdStaffStaffIdWorkingDataForSalaryGet(sheetId: string, staffId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSaSheetsSheetIdStaffStaffIdWorkingDataForSalaryGet.');
        }

        if (staffId === null || staffId === undefined) {
            throw new Error('Required parameter staffId was null or undefined when calling apiSaSheetsSheetIdStaffStaffIdWorkingDataForSalaryGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/saSheets/${encodeURIComponent(String(sheetId))}/staff/${encodeURIComponent(String(staffId))}/workingDataForSalary`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetIdSummaryDataGet(sheetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetIdSummaryDataGet(sheetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetIdSummaryDataGet(sheetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetIdSummaryDataGet(sheetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSaSheetsSheetIdSummaryDataGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/saSheets/${encodeURIComponent(String(sheetId))}/summaryData`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetStaffsProceedLeaveByListPost(body?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetStaffsProceedLeaveByListPost(body?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetStaffsProceedLeaveByListPost(body?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetStaffsProceedLeaveByListPost(body?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/saSheets/sheetStaffs/proceedLeaveByList`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetStaffsProceedOTByListPost(body?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetStaffsProceedOTByListPost(body?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetStaffsProceedOTByListPost(body?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetStaffsProceedOTByListPost(body?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/saSheets/sheetStaffs/proceedOTByList`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetStaffId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveDataGet(sheetStaffId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveDataGet(sheetStaffId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveDataGet(sheetStaffId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveDataGet(sheetStaffId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetStaffId === null || sheetStaffId === undefined) {
            throw new Error('Required parameter sheetStaffId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdLeaveDataGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/saSheets/sheetStaffs/${encodeURIComponent(String(sheetStaffId))}/leaveData`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetStaffId 
     * @param leaveDateID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveDataLeaveDateIDProceedPost(sheetStaffId: string, leaveDateID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveDataLeaveDateIDProceedPost(sheetStaffId: string, leaveDateID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveDataLeaveDateIDProceedPost(sheetStaffId: string, leaveDateID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveDataLeaveDateIDProceedPost(sheetStaffId: string, leaveDateID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetStaffId === null || sheetStaffId === undefined) {
            throw new Error('Required parameter sheetStaffId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdLeaveDataLeaveDateIDProceedPost.');
        }

        if (leaveDateID === null || leaveDateID === undefined) {
            throw new Error('Required parameter leaveDateID was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdLeaveDataLeaveDateIDProceedPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/saSheets/sheetStaffs/${encodeURIComponent(String(sheetStaffId))}/leaveData/${encodeURIComponent(String(leaveDateID))}/proceed`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetStaffId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataPost(sheetStaffId: string, body?: SaSheetLeaveData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataPost(sheetStaffId: string, body?: SaSheetLeaveData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataPost(sheetStaffId: string, body?: SaSheetLeaveData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataPost(sheetStaffId: string, body?: SaSheetLeaveData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetStaffId === null || sheetStaffId === undefined) {
            throw new Error('Required parameter sheetStaffId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/saSheets/sheetStaffs/${encodeURIComponent(String(sheetStaffId))}/leaveProceedData`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetStaffId 
     * @param proceedDataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdDelete(sheetStaffId: string, proceedDataId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdDelete(sheetStaffId: string, proceedDataId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdDelete(sheetStaffId: string, proceedDataId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdDelete(sheetStaffId: string, proceedDataId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetStaffId === null || sheetStaffId === undefined) {
            throw new Error('Required parameter sheetStaffId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdDelete.');
        }

        if (proceedDataId === null || proceedDataId === undefined) {
            throw new Error('Required parameter proceedDataId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/saSheets/sheetStaffs/${encodeURIComponent(String(sheetStaffId))}/leaveProceedData/${encodeURIComponent(String(proceedDataId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetStaffId 
     * @param proceedDataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdGet(sheetStaffId: string, proceedDataId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdGet(sheetStaffId: string, proceedDataId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdGet(sheetStaffId: string, proceedDataId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdGet(sheetStaffId: string, proceedDataId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetStaffId === null || sheetStaffId === undefined) {
            throw new Error('Required parameter sheetStaffId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdGet.');
        }

        if (proceedDataId === null || proceedDataId === undefined) {
            throw new Error('Required parameter proceedDataId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/saSheets/sheetStaffs/${encodeURIComponent(String(sheetStaffId))}/leaveProceedData/${encodeURIComponent(String(proceedDataId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetStaffId 
     * @param proceedDataId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdPut(sheetStaffId: string, proceedDataId: string, body?: SaSheetLeaveData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdPut(sheetStaffId: string, proceedDataId: string, body?: SaSheetLeaveData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdPut(sheetStaffId: string, proceedDataId: string, body?: SaSheetLeaveData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdPut(sheetStaffId: string, proceedDataId: string, body?: SaSheetLeaveData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetStaffId === null || sheetStaffId === undefined) {
            throw new Error('Required parameter sheetStaffId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdPut.');
        }

        if (proceedDataId === null || proceedDataId === undefined) {
            throw new Error('Required parameter proceedDataId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdLeaveProceedDataProceedDataIdPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/saSheets/sheetStaffs/${encodeURIComponent(String(sheetStaffId))}/leaveProceedData/${encodeURIComponent(String(proceedDataId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetStaffId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetStaffsSheetStaffIdOtDataGet(sheetStaffId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtDataGet(sheetStaffId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtDataGet(sheetStaffId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtDataGet(sheetStaffId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetStaffId === null || sheetStaffId === undefined) {
            throw new Error('Required parameter sheetStaffId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdOtDataGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/saSheets/sheetStaffs/${encodeURIComponent(String(sheetStaffId))}/otData`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetStaffId 
     * @param otRequestID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetStaffsSheetStaffIdOtDataOtRequestIDProceedPost(sheetStaffId: string, otRequestID: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtDataOtRequestIDProceedPost(sheetStaffId: string, otRequestID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtDataOtRequestIDProceedPost(sheetStaffId: string, otRequestID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtDataOtRequestIDProceedPost(sheetStaffId: string, otRequestID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetStaffId === null || sheetStaffId === undefined) {
            throw new Error('Required parameter sheetStaffId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdOtDataOtRequestIDProceedPost.');
        }

        if (otRequestID === null || otRequestID === undefined) {
            throw new Error('Required parameter otRequestID was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdOtDataOtRequestIDProceedPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/saSheets/sheetStaffs/${encodeURIComponent(String(sheetStaffId))}/otData/${encodeURIComponent(String(otRequestID))}/proceed`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetStaffId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataPost(sheetStaffId: string, body?: SaSheetOtData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataPost(sheetStaffId: string, body?: SaSheetOtData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataPost(sheetStaffId: string, body?: SaSheetOtData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataPost(sheetStaffId: string, body?: SaSheetOtData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetStaffId === null || sheetStaffId === undefined) {
            throw new Error('Required parameter sheetStaffId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/saSheets/sheetStaffs/${encodeURIComponent(String(sheetStaffId))}/otProceedData`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetStaffId 
     * @param proceedDataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdDelete(sheetStaffId: string, proceedDataId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdDelete(sheetStaffId: string, proceedDataId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdDelete(sheetStaffId: string, proceedDataId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdDelete(sheetStaffId: string, proceedDataId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetStaffId === null || sheetStaffId === undefined) {
            throw new Error('Required parameter sheetStaffId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdDelete.');
        }

        if (proceedDataId === null || proceedDataId === undefined) {
            throw new Error('Required parameter proceedDataId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/saSheets/sheetStaffs/${encodeURIComponent(String(sheetStaffId))}/otProceedData/${encodeURIComponent(String(proceedDataId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetStaffId 
     * @param proceedDataId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdGet(sheetStaffId: string, proceedDataId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdGet(sheetStaffId: string, proceedDataId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdGet(sheetStaffId: string, proceedDataId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdGet(sheetStaffId: string, proceedDataId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetStaffId === null || sheetStaffId === undefined) {
            throw new Error('Required parameter sheetStaffId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdGet.');
        }

        if (proceedDataId === null || proceedDataId === undefined) {
            throw new Error('Required parameter proceedDataId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/saSheets/sheetStaffs/${encodeURIComponent(String(sheetStaffId))}/otProceedData/${encodeURIComponent(String(proceedDataId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetStaffId 
     * @param proceedDataId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdPut(sheetStaffId: string, proceedDataId: string, body?: SaSheetOtData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdPut(sheetStaffId: string, proceedDataId: string, body?: SaSheetOtData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdPut(sheetStaffId: string, proceedDataId: string, body?: SaSheetOtData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdPut(sheetStaffId: string, proceedDataId: string, body?: SaSheetOtData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetStaffId === null || sheetStaffId === undefined) {
            throw new Error('Required parameter sheetStaffId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdPut.');
        }

        if (proceedDataId === null || proceedDataId === undefined) {
            throw new Error('Required parameter proceedDataId was null or undefined when calling apiSaSheetsSheetStaffsSheetStaffIdOtProceedDataProceedDataIdPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/saSheets/sheetStaffs/${encodeURIComponent(String(sheetStaffId))}/otProceedData/${encodeURIComponent(String(proceedDataId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
