<div
  style="
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgb(213, 246, 247);
  "
>
  <div
    style="
      padding: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: large;
      font-weight: bold;
    "
  >
    <div style="font-size: larger; margin-bottom: 100px">Đăng xuất</div>
    Đang đăng xuất ...
  </div>
</div>
