<div class="comment_component" (click)="handleEmojiModal($event)">
  <mat-card-title>{{ title }}</mat-card-title>
  <mat-card-content>
    <mat-card>
      <!--post comment section-->
      <div class="row new_comment_section">
        <div>
          <img
            class="comment_user_avatar"
            alt="current_user_avatar"
            [src]="curUser.avatar"
          />
        </div>
        <div class="col">
          <div>
            <form class="new_comment_form" [formGroup]="newCmtForm">
              <mat-form-field
                *ngIf="!showSummerNoteNewCmt"
                class="example-full-width"
              >
                <mat-label>Nhập bình luận ở đây</mat-label>
                <textarea
                  (focus)="toggleemotionNewCmt = false"
                  type="text"
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="5"
                  matInput
                  formControlName="content"
                  placeholder="Viết bình luận"
                  (keydown)="postNewComment($event)"
                  #textareaInput="matInput"
                ></textarea>
                <mat-icon
                  svgIcon="send"
                  matSuffix
                  (click)="postNewComment(null)"
                  aria-hidden="false"
                  matTooltip="Gửi bình luận"
                  [style.color]="
                    changeFocusedColor(true, textareaInput.focused)
                  "
                ></mat-icon>
              </mat-form-field>
              <div *ngIf="showSummerNoteNewCmt" class="row summernote-field">
                <div
                  class="col-11"
                  id="ngxSummernote"
                  [ngxSummernote]="config"
                  formControlName="content"
                ></div>
                <div>
                  <mat-icon
                    svgIcon="send"
                    matSuffix
                    (click)="postNewComment(null)"
                    aria-hidden="false"
                    matTooltip="Gửi bình luận"
                  ></mat-icon>
                </div>
              </div>
            </form>
            <div class="new_comment_actions">
              <mat-icon
                matSuffix
                svgIcon="text-editor"
                aria-hidden="false"
                (click)="
                  showSummerNoteNewCmt = !showSummerNoteNewCmt;
                  bindKeyupToSummernote('newField', showSummerNoteNewCmt, '')
                "
              >
              </mat-icon>
              <mat-icon
                matSuffix
                svgIcon="paperclip"
                matTooltip="File đính kèm"
                (click)="fileInput.click()"
                aria-hidden="false"
              >
              </mat-icon>
              <mat-icon
                class="emoji-icon"
                matSuffix
                svgIcon="emotion"
                matTooltip="Emoji"
                aria-hidden="false"
                (click)="toggleemotionNewCmt = !toggleemotionNewCmt"
              ></mat-icon>
              <emoji-mart
                *ngIf="toggleemotionNewCmt"
                (emojiClick)="handleEmojiSelection($event, newCmtForm)"
                [showPreview]="false"
                size="14"
                set="apple"
                totalFrequentLines="4"
                [style]="{
                  position: 'absolute',
                  marginTop: '5px',
                  width: '26.5rem'
                }"
              >
              </emoji-mart>
              <input
                type="file"
                name="fileupload"
                class="form-control"
                style="display: none"
                #fileInput
                (change)="onFileChange($event, fileList)"
                multiple="multiple"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="comment_attachments_section">
        <div class="row">
          <div
            *ngFor="let file of fileList; let i = index"
            class="attachment col-md-3 col-sm-12"
          >
            <div class="row">
              <mat-icon
                class="col-1"
                svgIcon="file"
                aria-hidden="false"
              ></mat-icon>
              <div class="col-10">
                <div class="row">
                  <div class="ellipsis" [matTooltip]="file.name">
                    {{ file.name }}
                  </div>
                  <div>({{ file.size }} KB)</div>
                </div>
              </div>
              <div class="remove_file_mark col-1" (click)="removeTempFile(i)">
                x
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
    <hr />
    <!--comment tree-->
    <div>
      <div class="comment_block">
        <div class="parent_comment" *ngFor="let comment of lstParentComments">
          <div class="col">
            <!--parent comment-->
            <div class="row">
              <div>
                <img
                  class="comment_user_avatar"
                  alt="user_avatar"
                  [src]="comment.extend_staff_avatar"
                />
              </div>
              <div class="col">
                <div class="comment_staff_fullname">
                  {{ comment.extend_staff_full_name }}
                </div>
                <div class="comment_created_time">
                  {{ momentDMY(comment.created_at) }}
                </div>
              </div>
            </div>
            <div class="row">
              <pre
                class="comment_content"
                *ngIf="!comment.isEditing"
                [innerHTML]="comment.content"
              ></pre>
              <div *ngIf="comment.isEditing" class="col">
                <div class="col">
                  <mat-form-field
                    class="edit_comment_field col-12"
                    *ngIf="!comment.showSummernoteField"
                  >
                    <textarea
                      (focus)="comment.toggleemotion = false"
                      type="text"
                      matInput
                      [(ngModel)]="comment.content"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5"
                      (keydown)="editComment(comment, $event)"
                      #textareaInput="matInput"
                    ></textarea>
                    <mat-icon
                      matSuffix
                      svgIcon="cancel"
                      aria-hidden="false"
                      matTooltip="Huỷ chỉnh sửa"
                      (click)="stopEditingComment(comment)"
                      [style.color]="
                        changeFocusedColor(false, textareaInput.focused)
                      "
                    >
                    </mat-icon>
                    <mat-icon
                      (click)="editComment(comment, null)"
                      matSuffix
                      svgIcon="send"
                      matTooltip="Xác nhận"
                      aria-hidden="false"
                      [style.color]="
                        changeFocusedColor(true, textareaInput.focused)
                      "
                    ></mat-icon>
                  </mat-form-field>
                  <div
                    *ngIf="comment.showSummernoteField"
                    class="row summernote-field"
                  >
                    <div
                      class="col-11"
                      [id]="'parentCommentSummernote' + comment.id"
                      [ngxSummernote]="config"
                      [(ngModel)]="comment.content"
                    ></div>
                    <div>
                      <mat-icon
                        (click)="editComment(comment, null)"
                        matSuffix
                        svgIcon="send"
                        matTooltip="Xác nhận"
                        aria-hidden="false"
                      ></mat-icon>
                    </div>
                  </div>
                  <div class="new_comment_actions">
                    <mat-icon
                      matSuffix
                      svgIcon="text-editor"
                      aria-hidden="false"
                      (click)="
                        comment.showSummernoteField = !comment.showSummernoteField;
                        bindKeyupToSummernote(
                          'parentComment',
                          comment.showSummernoteField,
                          comment.id
                        )
                      "
                    >
                    </mat-icon>
                    <mat-icon
                      matSuffix
                      svgIcon="paperclip"
                      matTooltip="File đính kèm"
                      (click)="editedFileInput.click()"
                      aria-hidden="false"
                    >
                    </mat-icon>
                    <mat-icon
                      class="emoji-icon"
                      (click)="comment.toggleemotion = !!!comment.toggleemotion"
                      matSuffix
                      svgIcon="emotion"
                      matTooltip="Emoji"
                      aria-hidden="false"
                    >
                    </mat-icon>
                    <emoji-mart
                      *ngIf="!!comment.toggleemotion"
                      (emojiClick)="
                        handleEmojiSelectionInComment($event, comment)
                      "
                      [showPreview]="false"
                      size="14"
                      set="apple"
                      totalFrequentLines="4"
                      [style]="{
                        position: 'absolute',
                        marginTop: '5px',
                        width: '26.5rem'
                      }"
                    >
                    </emoji-mart>
                    <input
                      type="file"
                      name="fileupload"
                      class="form-control"
                      style="display: none"
                      #editedFileInput
                      (change)="onFileChange($event, comment.tmpAttachmentsLst)"
                      multiple="multiple"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row"
              *ngIf="
                comment.extend_attachments.length > 0 && !comment.isEditing
              "
            >
              <div class="attachment_block col">
                <div class="row">
                  <div>
                    Tài liệu đính kèm ({{ comment.extend_attachments.length }})
                  </div>
                  <div
                    (click)="
                      downloadAllAttachments(
                        comment.id,
                        comment.extend_attachments
                      )
                    "
                  >
                    Tải tất cả tệp
                  </div>
                </div>
                <div class="row">
                  <div
                    *ngFor="let file of comment.extend_attachments"
                    class="attachment col-md-4 col-12"
                  >
                    <div class="row">
                      <mat-icon
                        class="col-1"
                        svgIcon="file"
                        aria-hidden="false"
                      ></mat-icon>
                      <div class="col-11">
                        <div class="row">
                          <div class="ellipsis" [matTooltip]="file.name">
                            {{ file.name }}
                          </div>
                          <div>({{ file.size }} KB)</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="comment_attachments_section" *ngIf="comment.isEditing">
              <div class="row">
                <div
                  *ngFor="let file of comment.tmpAttachmentsLst; let i = index"
                  class="attachment col-md-3 col-sm-12"
                >
                  <div class="row">
                    <mat-icon
                      class="col-1"
                      svgIcon="file"
                      aria-hidden="false"
                    ></mat-icon>
                    <div class="col-10">
                      <div class="row">
                        <div class="ellipsis" [matTooltip]="file.name">
                          {{ file.name }}
                        </div>
                        <div>({{ file.size }} KB)</div>
                      </div>
                    </div>
                    <div
                      class="remove_file_mark col-1"
                      (click)="
                        removeEditingTempFile(i, comment.tmpAttachmentsLst)
                      "
                    >
                      x
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row comment_actions">
              <div
                (click)="likeComment(comment)"
                class="unliked_comment row"
                *ngIf="!comment.extend_liked"
              >
                <span
                  ><span *ngIf="comment.number_of_like > 0">{{
                    comment.number_of_like
                  }}</span
                  >&nbsp;</span
                >
                <mat-icon svgIcon="thumbs-up" aria-hidden="false"> </mat-icon>
              </div>
              <div
                (click)="unlikeComment(comment)"
                class="liked_comment row"
                *ngIf="comment.extend_liked"
              >
                {{ comment.number_of_like }}&nbsp;
                <mat-icon svgIcon="thumbs-up-solid" aria-hidden="false">
                </mat-icon>
              </div>
              <mat-icon
                (click)="
                  comment.showReplyInputField = !comment.showReplyInputField
                "
                svgIcon="reply"
                aria-hidden="false"
                matTooltip="Phản hồi"
              ></mat-icon>
              <mat-icon
                *ngIf="comment.created_by === curUser.id"
                (click)="comment.isEditing = !comment.isEditing"
                svgIcon="edit"
                aria-hidden="false"
                matTooltip="Chỉnh sửa"
              >
              </mat-icon>
              <mat-icon
                *ngIf="comment.created_by === curUser.id"
                matTooltip="Xoá bình luận"
                (click)="deleteComment(comment.id)"
                svgIcon="times"
                aria-hidden="false"
              ></mat-icon>
              <div
                class="show_reply_btn"
                (click)="comment.showReplies = !comment.showReplies"
                *ngIf="
                  !comment.showReplies &&
                  comment.extend_repply_comments.length > 0
                "
                style="color: rgb(32, 120, 244)"
              >
                Hiện {{ comment.extend_repply_comments.length }} phản hồi
              </div>
              <div
                (click)="comment.showReplies = !comment.showReplies"
                *ngIf="comment.showReplies"
                style="color: rgb(32, 120, 244)"
              >
                Ẩn {{ comment.extend_repply_comments.length }} phản hồi
              </div>
            </div>
          </div>
          <div>
            <!--child comment section-->
            <div class="child_comments_section" *ngIf="comment.showReplies">
              <div
                *ngFor="let child_comment of comment.extend_repply_comments"
                class="child_comment_content"
              >
                <div class="col">
                  <div class="row">
                    <div>
                      <img
                        class="comment_user_avatar"
                        alt="user_avatar"
                        [src]="child_comment.extend_staff_avatar"
                      />
                    </div>
                    <div class="col">
                      <div class="comment_staff_fullname">
                        {{ child_comment.extend_staff_full_name }}
                      </div>
                      <div class="comment_created_time">
                        {{ momentDMY(child_comment.created_at) }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <pre
                      class="comment_content"
                      *ngIf="!child_comment.isEditing"
                      [innerHTML]="child_comment.content"
                    ></pre>
                    <div *ngIf="child_comment.isEditing" class="col">
                      <div>
                        <mat-form-field
                          class="edit_comment_field col-12"
                          *ngIf="!child_comment.showSummernoteField"
                        >
                          <textarea
                            (focus)="child_comment.toggleemotion = false"
                            type="text"
                            matInput
                            (keydown)="editComment(child_comment, $event)"
                            [(ngModel)]="child_comment.content"
                            cdkTextareaAutosize
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="5"
                            #textareaInput="matInput"
                          ></textarea>
                          <mat-icon
                            matSuffix
                            svgIcon="cancel"
                            aria-hidden="false"
                            matTooltip="Huỷ chỉnh sửa"
                            (click)="stopEditingComment(child_comment)"
                            [style.color]="
                              changeFocusedColor(false, textareaInput.focused)
                            "
                          >
                          </mat-icon>
                          <mat-icon
                            (click)="editComment(child_comment, null)"
                            matSuffix
                            svgIcon="send"
                            aria-hidden="false"
                            matTooltip="Xác nhận"
                            [style.color]="
                              changeFocusedColor(true, textareaInput.focused)
                            "
                          >
                          </mat-icon>
                        </mat-form-field>
                        <div
                          *ngIf="child_comment.showSummernoteField"
                          class="row summernote-field"
                        >
                          <div
                            class="col-11"
                            [id]="'childCommentSummernote' + child_comment.id"
                            [ngxSummernote]="config"
                            [(ngModel)]="child_comment.content"
                          ></div>
                          <div>
                            <mat-icon
                              (click)="editComment(child_comment, null)"
                              matSuffix
                              svgIcon="send"
                              matTooltip="Xác nhận"
                              aria-hidden="false"
                            >
                            </mat-icon>
                          </div>
                        </div>
                        <div class="new_comment_actions">
                          <mat-icon
                            matSuffix
                            svgIcon="text-editor"
                            aria-hidden="false"
                            (click)="
                              child_comment.showSummernoteField = !child_comment.showSummernoteField;
                              bindKeyupToSummernote(
                                'childComment',
                                child_comment.showSummernoteField,
                                child_comment.id
                              )
                            "
                          >
                          </mat-icon>
                          <mat-icon
                            class="emoji-icon"
                            (click)="
                              child_comment.toggleemotion = !!!child_comment.toggleemotion
                            "
                            matSuffix
                            svgIcon="emotion"
                            matTooltip="Emoji"
                            aria-hidden="false"
                          >
                          </mat-icon>
                          <mat-icon
                            matSuffix
                            svgIcon="paperclip"
                            matTooltip="File đính kèm"
                            (click)="editedChildFileInput.click()"
                            aria-hidden="false"
                          >
                          </mat-icon>
                          <emoji-mart
                            *ngIf="!!child_comment.toggleemotion"
                            (emojiClick)="
                              handleEmojiSelectionInComment(
                                $event,
                                child_comment
                              )
                            "
                            [showPreview]="false"
                            size="14"
                            set="apple"
                            totalFrequentLines="4"
                            [style]="{
                              position: 'absolute',
                              marginTop: '5px',
                              width: '26.5rem'
                            }"
                          >
                          </emoji-mart>
                          <input
                            type="file"
                            name="fileupload"
                            class="form-control"
                            style="display: none"
                            #editedChildFileInput
                            (change)="
                              onFileChange(
                                $event,
                                child_comment.tmpAttachmentsLst
                              )
                            "
                            multiple="multiple"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      child_comment.extend_attachments.length > 0 &&
                      !child_comment.isEditing
                    "
                  >
                    <div class="attachment_block col">
                      <div class="row">
                        <div class="pr-2">
                          Tài liệu đính kèm ({{
                            child_comment.extend_attachments.length
                          }})
                        </div>
                        <div
                          (click)="
                            downloadAllAttachments(
                              child_comment.id,
                              child_comment.extend_attachments
                            )
                          "
                        >
                          Tải tất cả tệp
                        </div>
                      </div>
                      <div class="row">
                        <div
                          *ngFor="let file of child_comment.extend_attachments"
                          class="attachment col-md-4 col-sm-12"
                        >
                          <div class="row">
                            <mat-icon
                              class="col-1"
                              svgIcon="file"
                              aria-hidden="false"
                            ></mat-icon>
                            <div class="col-11">
                              <div class="row">
                                <div class="ellipsis" [matTooltip]="file.name">
                                  {{ file.name }}
                                </div>
                                <div>({{ file.size }} KB)</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="comment_attachments_section"
                    *ngIf="child_comment.isEditing"
                  >
                    <div class="row">
                      <div
                        *ngFor="
                          let file of child_comment.tmpAttachmentsLst;
                          let i = index
                        "
                        class="attachment col-md-3 col-sm-12"
                      >
                        <div class="row">
                          <mat-icon
                            class="col-1"
                            svgIcon="file"
                            aria-hidden="false"
                          ></mat-icon>
                          <div class="col-10">
                            <div class="row">
                              <div class="ellipsis" [matTooltip]="file.name">
                                {{ file.name }}
                              </div>
                              <div>({{ file.size }} KB)</div>
                            </div>
                          </div>
                          <div
                            class="remove_file_mark col-1"
                            (click)="
                              removeEditingTempFile(
                                i,
                                child_comment.tmpAttachmentsLst
                              )
                            "
                          >
                            x
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row comment_actions">
                    <div
                      (click)="likeComment(child_comment)"
                      class="unliked_comment row"
                      *ngIf="!child_comment.extend_liked"
                    >
                      <span
                        ><span *ngIf="child_comment.number_of_like > 0">{{
                          child_comment.number_of_like
                        }}</span
                        >&nbsp;</span
                      >
                      <mat-icon svgIcon="thumbs-up" aria-hidden="false">
                      </mat-icon>
                    </div>
                    <div
                      (click)="unlikeComment(child_comment)"
                      class="liked_comment row"
                      *ngIf="child_comment.extend_liked"
                    >
                      {{ child_comment.number_of_like }}&nbsp;
                      <mat-icon svgIcon="thumbs-up-solid" aria-hidden="false">
                      </mat-icon>
                    </div>
                    <mat-icon
                      (click)="
                        comment.showReplyInputField = !comment.showReplyInputField
                      "
                      svgIcon="reply"
                      aria-hidden="false"
                      matTooltip="Phản hồi"
                    ></mat-icon>
                    <mat-icon
                      *ngIf="child_comment.created_by === curUser.id"
                      (click)="
                        child_comment.isEditing = !child_comment.isEditing
                      "
                      svgIcon="edit"
                      aria-hidden="false"
                      matTooltip="Chỉnh sửa"
                    ></mat-icon>
                    <mat-icon
                      *ngIf="child_comment.created_by === curUser.id"
                      (click)="deleteComment(child_comment.id)"
                      svgIcon="times"
                      aria-hidden="false"
                      matTooltip="Xoá bình luận"
                    ></mat-icon>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="reply_comment_section col"
              *ngIf="comment.showReplyInputField"
            >
              <div class="row">
                <div>
                  <img
                    class="comment_user_avatar"
                    alt="current_user_avatar"
                    [src]="curUser.avatar"
                  />
                </div>
                <div class="col-11">
                  <div>
                    <form class="reply_comment_form" [formGroup]="replyCmtForm">
                      <mat-form-field
                        class="example-full-width"
                        *ngIf="!comment.showSummernoteReplyInputField"
                      >
                        <mat-label>Nhập bình luận ở đây</mat-label>
                        <textarea
                          (focus)="comment.toggleEmotionAtReplyfield = false"
                          type="text"
                          matInput
                          formControlName="content"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="5"
                          placeholder="Viết bình luận"
                          #textareaInput="matInput"
                          (keydown)="replyComment(comment, $event)"
                        ></textarea>
                        <mat-icon
                          (click)="replyComment(comment, null)"
                          matSuffix
                          svgIcon="send"
                          aria-hidden="false"
                          matTooltip="Gửi phản hồi"
                          [style.color]="
                            changeFocusedColor(true, textareaInput.focused)
                          "
                        >
                        </mat-icon>
                      </mat-form-field>
                      <div
                        *ngIf="comment.showSummernoteReplyInputField"
                        class="row summernote-field"
                      >
                        <div
                          class="col-11"
                          [id]="'replySummernote' + comment.id"
                          [ngxSummernote]="config"
                          formControlName="content"
                        ></div>
                        <div>
                          <mat-icon
                            (click)="replyComment(comment, null)"
                            matSuffix
                            svgIcon="send"
                            aria-hidden="false"
                            matTooltip="Gửi phản hồi"
                          ></mat-icon>
                        </div>
                      </div>
                    </form>
                    <div class="new_comment_actions">
                      <mat-icon
                        matSuffix
                        svgIcon="text-editor"
                        aria-hidden="false"
                        (click)="
                          comment.showSummernoteReplyInputField = !comment.showSummernoteReplyInputField;
                          bindKeyupToSummernote(
                            'replyField',
                            comment.showSummernoteReplyInputField,
                            comment.id
                          )
                        "
                      >
                      </mat-icon>
                      <mat-icon
                        (click)="replyFileInput.click()"
                        matSuffix
                        svgIcon="paperclip"
                        aria-hidden="false"
                        matTooltip="File đính kèm"
                      >
                      </mat-icon>
                      <input
                        type="file"
                        name="fileupload"
                        class="form-control"
                        style="display: none"
                        #replyFileInput
                        (change)="onFileChange($event, replyFileList)"
                        multiple="multiple"
                      />
                      <mat-icon
                        class="emoji-icon"
                        (click)="
                          comment.toggleEmotionAtReplyfield = !!!comment.toggleEmotionAtReplyfield
                        "
                        matSuffix
                        svgIcon="emotion"
                        matTooltip="Emoji"
                        aria-hidden="false"
                      >
                      </mat-icon>
                      <emoji-mart
                        *ngIf="!!comment.toggleEmotionAtReplyfield"
                        (emojiClick)="
                          handleEmojiSelection($event, replyCmtForm)
                        "
                        [showPreview]="false"
                        size="14"
                        set="apple"
                        totalFrequentLines="4"
                        [style]="{
                          position: 'absolute',
                          marginTop: '5px',
                          width: '26.5rem'
                        }"
                      >
                      </emoji-mart>
                    </div>
                  </div>
                </div>
              </div>
              <div class="comment_attachments_section">
                <div class="row">
                  <div
                    *ngFor="let file of replyFileList; let i = index"
                    class="attachment col-md-3 col-sm-12"
                  >
                    <div class="row">
                      <mat-icon
                        class="col-1"
                        svgIcon="file"
                        aria-hidden="false"
                      ></mat-icon>
                      <div class="col-10">
                        <div class="row">
                          <div class="ellipsis" [matTooltip]="file.name">
                            {{ file.name }}
                          </div>
                          <div>({{ file.size }} KB)</div>
                        </div>
                      </div>
                      <div
                        class="remove_file_mark col-1"
                        (click)="removeReplyTempFile(i)"
                      >
                        x
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>

    <!--to load emoji once after initiation then we hide it-->
    <emoji-mart id="hidenTarget"></emoji-mart>

    <mat-paginator
      (page)="paginatorChange($event)"
      [pageSize]="pagesize"
      [length]="total"
      [hidePageSize]="false"
      [pageSizeOptions]="[5, 10, 20, 100]"
      [showFirstLastButtons]="true"
    >
    </mat-paginator>
  </mat-card-content>
</div>
