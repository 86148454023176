<mat-card class="mh-70">
  <mat-card-content>
    <div class="row mb-3">
      <div class="col-12 col-lg-3 col-sm-5">
        <div class="input-group border-search">
          <div class="input-group-prepend">
            <div class="icon-search none-border">
              <mat-icon class="d-flex justify-content-center flex-column">
                search</mat-icon>
            </div>
          </div>
          <input class="input-search none-border" (keyup)="onSearch()" [(ngModel)]="searchText" id="txtListSearch"
            placeholder="Tìm kiếm tên, đơn vị..." autocomplete="off" />
        </div>
      </div>
      <div class="col-12 col-lg-9 col-sm-7 text-right mt-8">
        <button class="button-print mr-sm-2" (click)="onClickPrint()">
          <mat-icon class="icon-align"> print </mat-icon>
          Xuất file
        </button>
        <button class="button-upload d-none d-sm-inline" (click)="onClickUpload()">
          <mat-icon class="icon-align"> file_upload </mat-icon>
          Tải file lên
        </button>
      </div>
    </div>
    <div class="example-container mat-elevation-z8 mat-table-user">
      <mat-table #table [dataSource]="allCompanies" class="d-none d-sm-block">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <!-- Index Column -->
        <ng-container matColumnDef="index">
          <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let element" class="ellipsis">
            <span class="ellipsis">
              {{ element.index }}
            </span>
          </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Tên đơn vị </mat-header-cell>
          <mat-cell *matCellDef="let element" class="cell-element">
            <span class="col-12 non-padding fw-600 show-on-mobile">Tên đơn vị</span>
            <span [matTooltip]="element.name" class="ellipsis">
              {{ element.name }}
            </span>
          </mat-cell>
        </ng-container>

        <!-- address Column -->
        <ng-container matColumnDef="address">
          <mat-header-cell *matHeaderCellDef>Địa chỉ liên lạc</mat-header-cell>
          <mat-cell *matCellDef="let element" class="cell-element">
            <span class="col-12 non-padding fw-600 show-on-mobile">
              Địa chỉ liên lạc
            </span>
            <span class="ellipsis" [matTooltip]="element.address">
              {{ element.address ? element.address : '---' }}
            </span>
          </mat-cell>
        </ng-container>

        <!-- phoneNumber Column -->
        <ng-container matColumnDef="phone">
          <mat-header-cell *matHeaderCellDef> Số điện thoại </mat-header-cell>
          <mat-cell *matCellDef="let element" class="cell-element">
            <span class="col-12 non-padding fw-600 show-on-mobile">
              Số điện thoại
            </span>
            <span class="ellipsis" [matTooltip]="element.cellphone">
              {{ element.cellphone ? element.cellphone : '---' }}
            </span>
          </mat-cell>
        </ng-container>

        <!-- Tax code Column -->
        <ng-container matColumnDef="tax_code">
          <mat-header-cell *matHeaderCellDef> Mã số thuế </mat-header-cell>
          <mat-cell *matCellDef="let element" [matTooltip]="element.tax_code" class="cell-element">
            <span class="col-12 non-padding fw-600 show-on-mobile">
              Mã số thuế
            </span>
            <span class="ellipsis">
              {{ element.tax_code ? element.tax_code : '---' }}
            </span>
          </mat-cell>
        </ng-container>

        <!-- Status code Column -->
        <ng-container matColumnDef="is_approve">
          <mat-header-cell *matHeaderCellDef> Trạng thái </mat-header-cell>
          <mat-cell *matCellDef="let element" class="cell-element">
            <span class="col-12 non-padding fw-600 show-on-mobile">
              Trạng thái
            </span>
            <span class="ellipsis status-badge" [ngClass]="
                element.is_approve === true
                  ? 'approve'
                  : element.is_approve === null
                  ? 'pending'
                  : 'reject'
              ">
              {{
              element.is_approve === true
              ? 'Đã duyệt'
              : element.is_approve === null
              ? 'Chưa duyệt'
              : 'Từ chối'
              }}
            </span>
          </mat-cell>
        </ng-container>

        <!-- actions Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>
            <button mat-flat-button (click)="openModalCreate(null)" class="btn-text-blue" matTooltip="Thêm đơn vị">
              <mat-icon>add</mat-icon>Thêm
            </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index" class="cell-element">
            <div ngbDropdown>
              <button mat-icon-button ngbDropdownToggle>
                <mat-icon> more_vert </mat-icon>
              </button>
              <div ngbDropdownMenu [ngClass]="
                  i < 8 ? 'dropdown-menu-action' : 'dropdown-menu-action-bottom'
                " [attr.aria-labelledby]="'dropdownMenuButton'">
                <div ngbDropdownItem class="dropdown-item" *ngIf="element.is_approve == null">
                  <button mat-icon-button (click)="approveCompany(element.id)">
                    <mat-icon class="icon-btn">done</mat-icon>
                    <label class="m-0 button-text-pointer">Duyệt đơn vị</label>
                  </button>
                </div>
                <div ngbDropdownItem class="dropdown-item" *ngIf="element.is_approve == null">
                  <button mat-icon-button (click)="rejectCompany(element.id)">
                    <mat-icon class="icon-btn">close</mat-icon>
                    <label class="m-0 button-text-pointer">Từ chối đơn vị</label>
                  </button>
                </div>
                <div ngbDropdownItem class="dropdown-item">
                  <button mat-icon-button [routerLink]="[
                      '/portal/hr/setting/permission/company-management',
                      element.id
                    ]">
                    <mat-icon class="icon-btn">info</mat-icon>
                    <label class="m-0 button-text-pointer">Xem chi tiết</label>
                  </button>
                </div>
                <div ngbDropdownItem class="dropdown-item">
                  <button mat-icon-button (click)="openModalCreate(element.id)">
                    <mat-icon class="icon-btn">edit</mat-icon>
                    <label class="m-0 button-text-pointer">Chỉnh sửa</label>
                  </button>
                </div>
                <div ngbDropdownItem class="dropdown-item">
                  <button mat-icon-button (click)="deleteUnit(element.id)">
                    <mat-icon class="icon-btn">delete</mat-icon>
                    <label class="m-0 button-text-pointer">Xóa</label>
                  </button>
                </div>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" class="table-row"></mat-row>
      </mat-table>
      <!-- Mobile -->
      <mat-table class="d-sm-none" [dataSource]="allCompanies" multiTemplateDataRows>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Tên đơn vị </mat-header-cell>
          <mat-cell *matCellDef="let element" class="ellipsis">
            <span class="ellipsis" [matTooltip]="element.name">
              {{ element.name || '--' }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="phone">
          <mat-header-cell *matHeaderCellDef> Số điện thoại </mat-header-cell>
          <mat-cell *matCellDef="let element" class="ellipsis">
            <span class="ellipsis" [matTooltip]="element.cellphone">
              {{ element.cellphone || '--' }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expand">
          <mat-header-cell *matHeaderCellDef aria-label="row actions">
            &nbsp;
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="expand row" (click)="
          expandedElement = expandedElement === element ? null : element;
          $event.stopPropagation()
        ">
              <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
              <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <mat-cell *matCellDef="let element; let i = index" [attr.colspan]="displayedColumnsMobile.length">
            <div class="example-element-detail" [@detailExpand]="
          element == expandedElement ? 'expanded' : 'collapsed'
        ">
              <div class="expand-container">
                <div class="row">
                  <div class="col-5">
                    <p class="expand-label-text">Địa chỉ liên lạc</p>
                  </div>
                  <div class="col-7">
                    <div class="ellipsis">
                      <p [matTooltip]="element.address" class="expand-detail-main-text ellipsis">
                        {{ element.address || '--' }}
                      </p>
                    </div>
                  </div>
                  <div class="col-5">
                    <p class="expand-label-text">Mã số thuế</p>
                  </div>
                  <div class="col-7">
                    <div class="ellipsis">
                      <p [matTooltip]="
                    element.tax_code
                  " class="expand-detail-main-text ellipsis">
                        {{
                        element.tax_code || '--'
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="col-5">
                    <p class="expand-label-text">Trạng thái</p>
                  </div>
                  <div class="col-7">
                    <div class="ellipsis">
                      <span class="ellipsis status-badge ml-0" [ngClass]="
              element.is_approve === true
                ? 'approve'
                : element.is_approve === null
                ? 'pending'
                : 'reject'
            ">
                        {{
                        element.is_approve === true
                        ? 'Đã duyệt'
                        : element.is_approve === null
                        ? 'Chưa duyệt'
                        : 'Từ chối'
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="col-5">
                    <p></p>
                  </div>
                  <div class="col-7">
                    <div class="ellipsis ">
                      <button class="fw-400" mat-icon-button [routerLink]="['/portal/hr/setting/permission/company-management',
                        element.id]">
                        <mat-icon class="icon-btn">info</mat-icon><span>Xem</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsMobile"></mat-header-row>
        <mat-row *matRowDef="let element; columns: displayedColumnsMobile" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = element"></mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"
          [hidden]="expandedElement !== row"></mat-row>
      </mat-table>
      <div class="col-12 d-flex justify-content-center p-3" *ngIf="allCompanies.length == 0">
        <span class="no-record">Chưa có dữ liệu</span>
      </div>
      <div class="mat-table__bottom pt-mobile row m-0">
        <div class="col-12 col-lg-8 col-md-8 p-0">
          <mat-paginator #paginator (page)="paginatorChange($event)" [pageSize]="pagesize" [length]="totalCompanies"
            [hidePageSize]="false" [pageSizeOptions]="[5, 10, 20, 100]" [showFirstLastButtons]="true">
          </mat-paginator>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>