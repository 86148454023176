import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {CompanyInfoService} from '@app/api/companyInfo.service';
import {UsersService} from '@app/api/users.service';
import {environment} from 'environments/environment';
import {ToastrService} from 'ngx-toastr';
import {UserImageModalComponent} from '../../../user-management/subs/user-image-modal/user-image-modal.component';

@Component({
  selector: 'meu-app-create-company-modal',
  templateUrl: './app-create-company-modal.component.html',
  styleUrls: ['./app-create-company-modal.component.scss'],
})
export class AppCreateCompanyModalComponent implements OnInit {
  modalTitle = '';
  modalButton = '';
  isCreate = false;
  companyFrm: FormGroup;
  numberPattern = '^[0-9 ]*$';
  noNumberPattern = '^[^0-9]+$';
  fileNameShow;
  listAllUser = [];
  listUser = [];
  listUserShow = [];
  curUser = {user_id: ''};
  // curUser = {user_id: '', company_id: ''};
  leaderId;
  displayedColumns: string[] = ['index', 'name', 'code', 'action'];
  logoUrl = './assets/media/users/default.jpg';
  oCompany: any = {};
  newCoverImg;
  companyArray = {};
  isRegister: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AppCreateCompanyModalComponent>,
    private companyInfoService: CompanyInfoService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private usersService: UsersService,
    @Inject(MAT_DIALOG_DATA) public companyId
  ) {}

  ngOnInit(): void {
    this.initFrm();
    this.getAllUser();
    if (this.companyId.isCreate == true) {
      this.isCreate = true;
      this.modalTitle = 'Thêm mới đơn vị';
      this.modalButton = 'Thêm';
    } else {
      this.modalTitle = 'Chỉnh sửa đơn vị';
      this.modalButton = 'Lưu';
      this.isCreate = false;
      this.getCompanyById(this.companyId.companyId);
    }

    if (this.companyId.isRegister) {
      this.isRegister = true;
    }
  }

  initFrm() {
    this.companyFrm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      tax_code: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^\d{10}-\d{3}$|^\d{10}$/),
      ]),
      cellphone: new FormControl(null, [
        Validators.pattern(this.numberPattern),
      ]),
      address: new FormControl(null, [Validators.required]),
      employee_code_prefix: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.noNumberPattern),
      ]),
      is_draft: new FormControl(false),
    });
  }

  getCompanyById(id) {
    this.companyInfoService.apiCompanyIdGet(id).subscribe((res) => {
      if (res.success) {
        this.oCompany = res.data;
        this.oCompany.logo = environment.backendhost + this.oCompany.logo;
        this.fileNameShow = this.oCompany.logo;
        this.listUser = this.oCompany.extend_company_managers;
        this.companyFrm.patchValue(this.oCompany);
      }
    });
  }

  getAllUser() {
    if (!this.companyId.isRegister) {
      this.usersService.apiUsersGet(null, '', 1, 500).subscribe((res) => {
        if (res.success) {
          this.listAllUser = res.data.collection;
          for (const item of this.listUser) {
            let curItem = this.listAllUser.find((x) => x.id == item.user_id);
            this.listUserShow.push(curItem);
            this.listUserShow = [...this.listUserShow];
          }
        }
      });
    }
  }

  onSubmit() {
    this.companyArray[0] = {};
    this.companyFrm.markAllAsTouched();
    if (this.companyFrm.invalid) {
      return;
    }
    this.companyArray[0].item1 = this.companyFrm.value;
    if (this.listUserShow.length > 0) {
      this.listUser.forEach((value, index) => {
        this.listUser[index].company_id = this.companyId.companyId;
      });
    }
    this.companyArray[0].item2 = this.listUser;
    this.companyInfoService
      .apiCompanyIdPut(this.companyId.companyId, this.companyArray[0])
      .subscribe((res) => {
        this.dialogRef.close({event: true});
      });
    if (this.newCoverImg != null && this.newCoverImg != undefined) {
      this.companyInfoService
        .apiCompanyIdLogoPut(this.companyId.companyId, this.newCoverImg)
        .subscribe((res) => {});
    }
  }

  onSubmitRegister() {
    this.companyArray[0] = {};
    this.companyFrm.markAllAsTouched();
    if (this.companyFrm.invalid) {
      return;
    }
    this.companyArray[0].item1 = this.companyFrm.value;
    this.companyArray[0].item2 = [];
    this.companyInfoService
      .apiNewCompanyPost(this.companyArray[0])
      .subscribe((res) => {
        this.dialogRef.close({event: true});
      });
  }

  openModalImageCovert() {
    if (!this.oCompany.logo) {
      this.oCompany.logo = this.logoUrl;
    }
    const dialogRef = this.dialog.open(UserImageModalComponent, {
      minWidth: '85vw',
      data: {image: this.oCompany.logo, logo: true},
    });
    const dialogSubmitSubscription = dialogRef
      .afterClosed()
      .subscribe((result) => {
        if (!!!result) {
          return;
        }
        if (result.data != null) {
          this.oCompany.logo = result.data.dataURL;
          this.newCoverImg = this.convertImageFile(this.oCompany.logo);
          this.fileNameShow = this.newCoverImg.name;
        }
        dialogSubmitSubscription.unsubscribe();
      });
  }

  convertImageFile(base64) {
    const iRandom = Math.floor(Math.random() * (10000 - 10 + 1)) + 10;
    const date = new Date().valueOf();
    const imageName =
      '[logo]' + '[' + date + ']' + '[' + iRandom + ']' + '.jpeg';
    // call method that creates a blob from dataUri
    const imageBlob = this.dataURItoBlob(base64);
    const imageFile = new File([imageBlob], imageName, {type: 'image/jpeg'});
    return imageFile;
  }

  dataURItoBlob(Base64) {
    let dataURI = Base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], {type: 'image/jpeg'});
    return blob;
  }

  deleteFile() {
    this.newCoverImg = null;
    this.fileNameShow = '';
  }

  onChangeLeader(event) {
    this.leaderId = event;
  }

  addUser() {
    if (this.leaderId == null) {
      this.toastr.warning('Vui lòng lựa chọn nhân viên', 'Thông báo');
      return;
    }

    let indexOfItem = this.listUser
      .map(function (x) {
        return x.user_id;
      })
      .indexOf(this.leaderId);

    if (indexOfItem != -1) {
      this.toastr.warning('Nhân viên này đã được thêm', 'Cảnh báo');
      return;
    }
    this.curUser.user_id = this.leaderId;
    this.listUser.push({...this.curUser});
    this.listUserShow.push(
      this.listAllUser.find((x) => x.id === this.leaderId)
    );
    this.listUserShow = [...this.listUserShow];
  }

  removeUser(index) {
    this.listUser.splice(index, 1);
    this.listUserShow.splice(index, 1);
    this.listUserShow = [...this.listUserShow];
  }

  goBack() {
    this.dialogRef.close({event: false});
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.companyFrm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
