import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {LayoutUtilsService} from '@app/core/_base/crud';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {SubHeaderButtonService} from '@app/services/sub-header-button.service';
import {ModalCreateRoleComponent} from './modal-create-role/modal-create-role.component';
import {MatDialog} from '@angular/material/dialog';
import {SubheaderService} from '@app/core/_base/layout';
import {PRoleService} from '@app/api/api';

@Component({
  selector: 'meu-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss'],
})
export class RoleManagementComponent implements OnInit, OnDestroy {
  constructor(
    private pRoleService: PRoleService,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private toastr: ToastrService,
    private subHeaderButtonService: SubHeaderButtonService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private subheaderService: SubheaderService
  ) {}

  displayedColumns: string[] = ['index', 'name', 'description', 'action'];
  allRole = [];
  total = 0;
  pageIndex = 1;
  pagesize = 10;
  private subscriptions: Subscription[] = [];
  ngOnInit() {
    this.subheaderService.title$.next({
      title: 'Cài đặt',
      desc: 'Quản lý Role',
    });

    this.subHeaderButtonService.setButtonCollection([
      {
        name: 'New',
        type: 'Text',
        icon: 'flaticon-download-1',
        label: 'Thêm mới',
        Code: 'openModalCreateRole',
      },
    ]);
    this.initServiceSubheader();
    this.getRoleData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
    this.subHeaderButtonService.setButtonCollection([]);
    this.subheaderService.title$.next({title: null, desc: null});
  }

  getRoleData() {
    this.subscriptions.push(
      this.pRoleService
        .apiPRolesGet('', '', this.pageIndex, this.pagesize)
        .subscribe((res) => {
          if (res.success) {
            this.allRole = res.data.collection;
            this.total = res.data.total;
            this.pageIndex = res.data.pageIndex;
            this.changeDetectorRef.detectChanges();
          }
        })
    );
  }

  initServiceSubheader() {
    this.subscriptions.push(
      this.subHeaderButtonService.onClickButton.subscribe((code) => {
        if (code === 'openModalCreateRole') {
          const dialogRef = this.dialog.open(ModalCreateRoleComponent, {
            data: {},
            width: '700px',
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result?.event) {
              this.getRoleData();
            }
          });
        }
      })
    );
  }

  editRole(id) {
    const dialogRef = this.dialog.open(ModalCreateRoleComponent, {
      data: {roleId: id},
      width: '700px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.event) {
        this.getRoleData();
      }
    });
  }

  settingRolePermission(id) {
    this.router.navigate([
      '/portal/hr/setting/permission/role-management/role-permission/' + id,
    ]);
  }

  addUser(id) {
    this.router.navigate([
      '/portal/hr/setting/permission/role-management/role-user/' + id,
    ]);
  }

  onRemove(id) {
    const title = 'Xóa Role';
    const description = 'Bạn có chắc chắn muốn xóa Role này?';
    const waitDesciption = 'Đang xử lý...';

    const dialogRef = this.layoutUtilsService.deleteElement(
      title,
      description,
      waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.pRoleService.apiPRolesIdDelete(id).subscribe(
        (result) => {
          if (result.success) {
            this.toastr.success('Đã xóa thành công', '');
            this.getRoleData();
          } else {
            this.toastr.error('Xóa thất bại', '', {
              timeOut: 3000,
            });
          }
        },
        (error) => {
          this.toastr.error('Xóa thất bại', '', {
            timeOut: 3000,
          });
        }
      );
    });
  }

  paginatorChange(event) {
    this.pagesize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getRoleData();
  }
}
