// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';

import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';
// Auth
import {MeuBaseComponent} from './views/theme/meu-base/meu-base.component';
import {UserGuideRegisterComponent} from './views/pages/apps/user-guide-register/user-guide-register.component';
import {UserGuideWorkflowComponent} from './views/pages/apps/user-guide-workflow/user-guide-workflow.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('app/views/pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'portal/hr',
    component: MeuBaseComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            'app/views/areas/portal/hr/user-management/user-management.module'
          ).then((m) => m.UserManagementModule),
      },

      {
        path: '',
        loadChildren: () =>
          import('app/views/areas/portal/hr/setting/setting.module').then(
            (m) => m.SettingModule
          ),
      },
      {
        path: 'error/403',
        component: ErrorPageComponent,
        data: {
          type: 'error-v6',
          code: 403,
          title: '403... Access forbidden',
          desc: "Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
        },
      },
    ],
  },
  {
    path: 'error/404',
    component: ErrorPageComponent,
    data: {
      type: 'error-v6',
      code: 404,
      title: '404... Not found',
    },
  },

  {
    path: 'error',
    component: BaseComponent,
    children: [
      {
        path: 'error-v6',
        component: ErrorPageComponent,
        data: {
          type: 'error-v6',
          code: 403,
          title: '403... Access forbidden',
          desc: "Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
        },
      },
      {path: ':type', component: ErrorPageComponent},
      // { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  {path: '', redirectTo: '/portal/hr/user-management', pathMatch: 'full'},
  //{path: '**', redirectTo: '/portal/hr/user-management', pathMatch: 'full'},
  {path: 'user-guide-register', component: UserGuideRegisterComponent},
  {path: 'user-guide-workflow', component: UserGuideWorkflowComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
