<div class="container-fluid">
  <div class="form-header">
    <h1 *ngIf="this.data.type === 'addNew'">Thêm mới ngành</h1>
    <h1 *ngIf="this.data.type === 'add' && !this.data.parent_id">
      Thêm mới ngành
    </h1>
    <h1 *ngIf="this.data.type === 'add' && this.data.parent_id">
      Thêm mới chuyên ngành
    </h1>
    <h1 *ngIf="this.data.type === 'edit' && !this.data.parent_id">
      Chỉnh sửa ngành {{ this.data.name }}
    </h1>
    <h1 *ngIf="this.data.type === 'edit' && this.data.parent_id">
      Chỉnh sửa chuyên ngành {{ this.data.name }}
    </h1>
    <h1 *ngIf="this.data.type === 'delete' && !this.data.parent_id">
      Xóa ngành {{ this.data.name }}
    </h1>
    <h1 *ngIf="this.data.type === 'delete' && this.data.parent_id">
      Xóa chuyên ngành {{ this.data.name }}
    </h1>
    <mat-icon class="h-icon" (click)="onClose()">close</mat-icon>
  </div>
  <form [formGroup]="addmore" (ngSubmit)="onSubmit()">
    <div *ngIf="this.data.type !== 'delete'" class="form-container">
      <div class="left-code">
        <label>Mã ngành</label>
        <input formControlName="code" type="number" />
      </div>
      <div class="right-name">
        <label>Tên ngành</label>
        <input formControlName="name" />
      </div>
    </div>
    <div
      class="form-delete"
      *ngIf="this.data.type === 'delete' && !this.data.parent_id"
    >
      <p>Bạn có muốn xóa ngành {{ this.data.name }} này không ?</p>
    </div>
    <div
      class="form-delete"
      *ngIf="this.data.type === 'delete' && this.data.parent_id"
    >
      <p>Bạn có muốn xóa chuyên ngành {{ this.data.name }} này không ?</p>
    </div>
    <div class="form-footer">
      <button class="close-btn" type="button" (click)="onClose()">Đóng</button>
      <button
        *ngIf="this.data.type !== 'delete'"
        type="submit"
        class="save-btn"
      >
        Lưu
      </button>
      <button
        *ngIf="this.data.type === 'delete'"
        type="submit"
        class="save-btn"
      >
        Xóa
      </button>
    </div>
  </form>
</div>
