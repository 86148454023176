<div class="grid-contain">
  <div class="login-loading-text" *ngIf="hideForm">Đang đăng nhập...</div>
  <div class="login-form" *ngIf="!hideForm">
    <div class="login-title">Đăng nhập</div>
    <kt-auth-notice class="error-mess"></kt-auth-notice>
    <form class="kt-form" [formGroup]="loginForm">
      <div class="input-group">
        <span class="label"> Email hoặc tên đăng nhập </span>
        <input
          type="text"
          placeholder="Nhập email"
          class="input-style mb-1"
          formControlName="email"
        />
        <mat-error
          *ngIf="
            formErrorServie.isControlHasError(loginForm, 'email', 'required')
          "
        >
          <strong>
            <!-- {{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }} -->
            Email là bắt buộc
          </strong>
        </mat-error>
        <mat-error
          *ngIf="
            formErrorServie.isControlHasError(loginForm, 'email', 'pattern')
          "
        >
          <strong> Email không hợp lệ </strong>
        </mat-error>
        <!-- <mat-error
          *ngIf="
            formErrorServie.isControlHasError(loginForm, 'email', 'minlength')
          "
        >
          <strong> Email phải có độ dài hơn 3 kí tự </strong>
        </mat-error> -->
        <mat-error
          class="mb-2"
          *ngIf="
            formErrorServie.isControlHasError(loginForm, 'email', 'maxlength')
          "
        >
          <strong> Email có thể dài tối đa 320 kí tự </strong>
        </mat-error>
      </div>
      <div class="input-group" [ngStyle]="{'margin-bottom': '24px'}">
        <span class="label"> Mật khẩu </span>
        <input
          [type]="showPass ? 'text' : 'password'"
          placeholder="Nhập mật khẩu"
          class="input-style mb-1"
          formControlName="password"
        />
        <mat-error
          *ngIf="
            formErrorServie.isControlHasError(loginForm, 'password', 'required')
          "
        >
          <strong> Mật khẩu là bắt buộc </strong>
        </mat-error>
        <!-- <mat-error
          *ngIf="
            formErrorServie.isControlHasError(loginForm, 'password', 'minlength')
          "
        >
          <strong> Mật khẩu phải có độ dài hơn 3 kí tự </strong>
        </mat-error> -->
        <mat-error
          *ngIf="
            formErrorServie.isControlHasError(
              loginForm,
              'password',
              'maxlength'
            )
          "
        >
          <strong> Mật khẩu có thể dài tối đa 100 kí tự </strong>
        </mat-error>
        <mat-icon
          matSuffix
          (click)="showPass = !showPass"
          *ngIf="!showPass"
          class="icon-input"
          matTooltip="Hiển thị mật khẩu"
        >
          visibility
        </mat-icon>
        <mat-icon
          matSuffix
          (click)="showPass = !showPass"
          *ngIf="showPass"
          class="icon-input"
          matTooltip="Ẩn mật khẩu"
        >
          visibility_off</mat-icon
        >
      </div>
      <button
        id="login-btn"
        class="btn btn-login"
        type="button"
        (click)="submit()"
      >
        Đăng nhập
      </button>
      <button
        id="login-btn"
        class="btn btn-login register-label-mobile"
        type="button"
        routerLink="/auth/register"
      >
        Đăng ký
      </button>
      <div class="forgot-pw" href="javascript:;" (click)="resetForm()">
        <a class="">Quên mật khẩu?</a>
      </div>

      <div
        class="text-center fs-14px register-label-desktop"
        (click)="logout()"
      >
        Chưa có tài khoản?
        <a routerLink="/auth/register" class="forgot-pw">Đăng kí</a>
      </div>
      <div class="text-center fs-14px register-label-desktop">
        <a routerLink="/user-guide-register" target="_blank" class="forgot-pw"
          >Hướng dẫn đăng ký tài khoản</a
        >
      </div>
      <div class="text-center fs-14px register-label-desktop">
        <a routerLink="/user-guide-workflow" target="_blank" class="forgot-pw"
          >Hướng dẫn nộp hồ sơ</a
        >
      </div>
    </form>
  </div>
</div>
