<div class="content-custom-dashboard">
  <div class="row kt-margin-b-20">
    <div class="col-12 d-flex justify-content-between">
      <div class="row m-0 kt-margin-t-5">
        <div
          *ngFor="let item of listMyBoard; index as i"
          class="ng-auto color-el pointer"
        >
          <span
            class="kt-padding-15"
            (click)="getCurrentBoard(item, i)"
            [ngStyle]="{
              color:
                boardSelected != null && indexSelect === i
                  ? '#5d78ff'
                  : '#959595',
              'font-weight':
                boardSelected != null && indexSelect === i ? '600' : '500'
            }"
          >
            {{ item.name }}
          </span>
          <hr
            class="line-select"
            [ngStyle]="{
              visibility:
                boardSelected != null && indexSelect === i
                  ? 'inherit'
                  : 'hidden'
            }"
          />
        </div>
      </div>
      <div *ngIf="!isEdit">
        <button class="btn btn-action d-flex" [matMenuTriggerFor]="menuSetting">
          Thao tác
          <mat-icon class="d-flex justify-content-center flex-column text-end"
            >keyboard_arrow_down</mat-icon
          >
        </button>
        <mat-menu #menuSetting="matMenu" [class]="'customize-menu-action'">
          <button
            mat-menu-item
            class="item-menu-context color-text-menu"
            (click)="openModalCreateBoard(createBoard, false)"
          >
            <img [src]="imgPath + 'leading-icon.svg'" class="mr-2 mt--3" />
            Thêm thẻ mới
          </button>
          <button
            mat-menu-item
            class="item-menu-context color-text-menu"
            (click)="openModalCreateBoard(createBoard, true)"
          >
            <img [src]="imgPath + 'leading-icon-2.svg'" class="mr-2 mt--3" />
            Đổi tên thẻ
          </button>
          <button
            mat-menu-item
            class="item-menu-context color-text-menu"
            (click)="deleteBoard()"
          >
            <img [src]="imgPath + 'delete.svg'" class="mr-2 mt--3" />
            Xóa thẻ đang mở
          </button>
          <button
            mat-menu-item
            class="item-menu-context color-text-menu"
            (click)="isEdit = !isEdit"
          >
            <img [src]="imgPath + 'layout.svg'" class="mr-2 mt--3" />
            Chỉnh sửa bố cục
          </button>
        </mat-menu>
      </div>

      <div *ngIf="isEdit">
        <button
          type="button"
          class="btn button-cancel"
          (click)="isEdit = !isEdit"
        >
          Hoàn thành chỉnh sửa
        </button>
      </div>
    </div>
  </div>

  <div
    class="row m-0 justify-content-center color-text-menu"
    *ngIf="!isEdit && checkBoardEmpty()"
  >
    Không có widget
  </div>

  <div class="row m-0">
    <div
      *ngFor="let item of boardContent; let indexSection = index"
      class="col-12 p-0"
      [ngClass]="
        isEdit
          ? 'border-row-widget kt-margin-b-15'
          : 'border-none kt-margin-b-20'
      "
    >
      <div class="row m-0 header-row-widget" [ngClass]="isEdit ? '' : 'd-none'">
        <div class="text-right full-width-field action-section">
          <mat-icon
            class="color-icon pointer mr-2 d-flex justify-content-center flex-column"
            [matTooltip]="'Xóa'"
            (click)="deleteSection(item.board_section_id)"
            >delete
          </mat-icon>
          <mat-icon
            class="color-icon pointer mr-2 d-flex justify-content-center flex-column"
            [matTooltip]="indexSection != 0 ? 'Chuyển lên' : ''"
            [ngClass]="{'disable-icon-move': indexSection == 0}"
            (click)="moveUpSection(item, indexSection)"
          >
            arrow_upward
          </mat-icon>
          <mat-icon
            class="color-icon pointer mr-2 d-flex justify-content-center flex-column"
            [matTooltip]="
              indexSection + 1 != boardContent.length ? 'Chuyển xuống' : ''
            "
            [ngClass]="{
              'disable-icon-move': indexSection + 1 == boardContent.length
            }"
            (click)="moveDownSection(item, indexSection)"
            >arrow_downward
          </mat-icon>
        </div>
      </div>
      <div
        class="row m-0"
        [ngClass]="isEdit ? 'kt-padding-5' : 'kt-padding-0 mg--10'"
      >
        <div
          *ngFor="let subItem of item.array_section_layout; index as i"
          [ngClass]="'col-lg-' + subItem"
          class="p-0 test-screen"
        >
          <div
            class="mg-item-widget br-4 d-flex flex-column text-center justify-content-center pointer item-choose-widget"
            *ngIf="checkObjEmpty(item.board_widget[i]) && isEdit"
            (click)="
              openModalChooseWidget(chooseWidget, item.board_section_id, i)
            "
          >
            <div>
              <mat-icon class="color-grey size-icon-add">add</mat-icon>
            </div>
            <span class="color-grey">Chọn widget</span>
          </div>

          <kt-portlet
            class="custom-card"
            [class]="'mg-item-widget br-4 card-widget'"
            *ngIf="!checkObjEmpty(item.board_widget[i])"
          >
            <kt-portlet-body [class]="'p-0'">
              <div class="header-card-widget">
                <div class="name-widget ellipsis">
                  {{ item.board_widget[i].name }}
                </div>
                <div>
                  <mat-icon
                    [matTooltip]="'Xem thêm'"
                    class="icon-view-more pointer"
                    mat-icon-button
                    mat-button
                    [matMenuTriggerFor]="menuSetting"
                    >more_horiz</mat-icon
                  >
                  <mat-menu #menuSetting="matMenu">
                    <button
                      mat-menu-item
                      class="item-menu-context color-text-menu"
                      (click)="
                        openModalEditWidgetName(
                          editWidgetName,
                          item.board_widget[i]
                        )
                      "
                    >
                      <img
                        [src]="imgPath + 'rename_widget.svg'"
                        class="mr-2 mt--3"
                      />
                      Đổi tên widget
                    </button>
                    <button
                      mat-menu-item
                      class="item-menu-context color-text-menu"
                      (click)="
                        configWidget(item.board_widget[i].id, indexSection, i)
                      "
                    >
                      <img [src]="imgPath + 'setting.svg'" class="mr-2 mt--3" />
                      Cài đặt
                    </button>
                    <button
                      mat-menu-item
                      class="item-menu-context color-text-menu"
                      (click)="deleteBoardWidget(item.board_widget[i].id)"
                    >
                      <img [src]="imgPath + 'delete.svg'" class="mr-2 mt--3" />
                      Xóa widget
                    </button>
                  </mat-menu>
                </div>
              </div>
              <div
                class="body-card-widget"
                [ngClass]="
                  !item.board_widget[i].is_edit_config ? 'h-35' : 'min-h-35'
                "
                [id]="item.board_widget[i].id"
              >
                <div #pluginReference [id]="item.board_widget[i].id">
                  <!--Nội dung widget-->
                </div>
                <div *ngIf="item.board_widget[i].is_edit_config">
                  <div class="row content-config">
                    <div
                      *ngFor="
                        let itemConfig of item.board_widget[i]
                          .array_configuration
                      "
                      class="full-width-field"
                    >
                      <div class="col-12 kt-margin-b-15">
                        <div class="kt-margin-b-5">
                          <span class="color-black">{{ itemConfig.Name }}</span>
                        </div>
                        <div class="col-12 p-0 col-md-8 col-lg-8 input-value">
                          <div *ngIf="itemConfig.Control.Name == 'TEXTBOX'">
                            <input
                              type="text"
                              placeholder="{{ itemConfig.Control.PlaceHolder }}"
                              class="form-control"
                              autocomplete="off"
                              [(ngModel)]="itemConfig.values"
                            />
                          </div>

                          <div *ngIf="itemConfig.Control.Name == 'DATE_PICKER'">
                            <input
                              class="form-control"
                              (click)="datePicker.open()"
                              [matDatepicker]="datePicker"
                              placeholder="{{ itemConfig.Control.PlaceHolder }}"
                              autocomplete="off"
                              readonly
                              [(ngModel)]="itemConfig.values"
                              [ngModel]="
                                itemConfig.values != ''
                                  ? (itemConfig.values | date: 'yyyy-MM-dd')
                                  : ''
                              "
                            />
                            <mat-datepicker-toggle
                              [for]="datePicker"
                              class="custom-icon-date"
                            >
                            </mat-datepicker-toggle>
                            <mat-datepicker #datePicker></mat-datepicker>
                          </div>

                          <div *ngIf="itemConfig.Control.Name == 'NUMBER'">
                            <input
                              min="0"
                              type="number"
                              placeholder="{{ itemConfig.Control.PlaceHolder }}"
                              class="form-control"
                              autocomplete="off"
                              [(ngModel)]="itemConfig.values"
                            />
                          </div>

                          <div
                            class="cls-selectbox"
                            *ngIf="
                              itemConfig.Control.Name == 'SINGLE_SELECTBOX'
                            "
                          >
                            <meu-select-load-more-dynamic-dashboard
                              [placeholderLabel]="
                                itemConfig.Control.PlaceHolder
                              "
                              [noEntriesFoundLabel]="
                                'Không có lựa chọn phù hợp'
                              "
                              [columnName]="itemConfig.Control.ColumnName"
                              [nameAPI]="itemConfig.Control.APIURL"
                              [defaultID]="getIdDefault(itemConfig.values)"
                              [multiple]="false"
                              [(ngModel)]="itemConfig.values"
                            >
                            </meu-select-load-more-dynamic-dashboard>
                          </div>

                          <div
                            *ngIf="itemConfig.Control.Name == 'MULTIPLE_SELECT'"
                          >
                            <meu-select-load-more-dynamic-dashboard
                              [placeholderLabel]="
                                itemConfig.Control.PlaceHolder
                              "
                              [noEntriesFoundLabel]="
                                'Không có lựa chọn phù hợp'
                              "
                              [columnName]="itemConfig.Control.ColumnName"
                              [nameAPI]="itemConfig.Control.APIURL"
                              [defaultID]="itemConfig.values"
                              [multiple]="true"
                              [(ngModel)]="itemConfig.values"
                            >
                            </meu-select-load-more-dynamic-dashboard>
                          </div>

                          <div *ngIf="itemConfig.Control.Name == 'CHECKBOX'">
                            <mat-checkbox
                              class="color-black"
                              [(ngModel)]="itemConfig.values"
                              >{{
                                itemConfig.Control.PlaceHolder
                              }}</mat-checkbox
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 footer-padding text-right">
                      <button
                        type="button"
                        class="btn button-cancel-config"
                        (click)="
                          cancelConfig(item.board_widget[i].id, indexSection, i)
                        "
                      >
                        Hủy
                      </button>
                      <button
                        type="button"
                        class="btn button-save-config"
                        (click)="
                          saveConfig(item.board_widget[i].id, indexSection, i)
                        "
                      >
                        Lưu
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </kt-portlet-body>
          </kt-portlet>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row d-flex flex-column text-center kt-padding-t-25 kt-padding-b-20 row-add pointer"
    *ngIf="isEdit"
    (click)="openModalChooseLayout(chooseLayout)"
  >
    <div>
      <mat-icon class="color-grey size-icon-add">add</mat-icon>
    </div>
    <span class="color-grey">Thêm mới bố cục</span>
  </div>
</div>

<!-- modal -->
<ng-template #createBoard centered>
  <meu-modal-create-board
    id="modal-create-board"
    [boardId]="idBoard"
    [code]="categoryDashboard.code"
    [sort_order]="listMyBoard.length"
    [is_public]="false"
    (closeModal)="closeModalCreateBoard($event)"
  ></meu-modal-create-board>
</ng-template>

<ng-template #chooseLayout centered>
  <meu-modal-choose-layout
    (closeModal)="closeModalChooseLayout($event)"
  ></meu-modal-choose-layout>
</ng-template>

<ng-template #chooseWidget centered>
  <meu-modal-add-widget
    [boardId]="idBoard"
    [sort_order]="sort_order_add_widget"
    [board_section_id]="board_section_id"
    [category]="category_add_widget"
    (closeModal)="closeModalChooseWidget($event)"
  ></meu-modal-add-widget>
</ng-template>

<ng-template #editWidgetName centered>
  <meu-modal-edit-widget-name
    [board_widget]="board_widget"
    (closeModal)="closeModalEditWidgetName($event)"
  ></meu-modal-edit-widget-name>
</ng-template>
