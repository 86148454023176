import {Component, OnInit} from '@angular/core';
import {environment} from 'environments/environment';
import {Router} from '@angular/router';

@Component({
  selector: 'meu-inventory-login-layout',
  templateUrl: './inventory-login-layout.component.html',
  styleUrls: ['./inventory-login-layout.component.scss'],
})
export class InventoryLoginLayoutComponent implements OnInit {
  today: number = Date.now();
  env = environment;
  curUrl;
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((ev) => {
      this.curUrl = this.router.url;
    });
  }
}
