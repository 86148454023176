<label for="{{ name }}" attr.data-name="{{ name }}" class="input-label"
  >{{ title }}
  <span class="required-input-label" *ngIf="isRequiredField">*</span></label
>
<span class="meu-datepicker-input">
  <mat-icon class="icon-calendar" (click)="founding_datepicker.open()"
    >date_range</mat-icon
  >
  <input
    #input
    [matDatepicker]="founding_datepicker"
    class="input-box"
    placeholder="{{ placeholderText }}"
    [formControl]="formControl"
  />
</span>

<mat-datepicker #founding_datepicker></mat-datepicker>
<div *ngIf="invalidState" class="input-error-label">
  <ng-content select="[errorMessage]"></ng-content>
</div>
