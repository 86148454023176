<mat-form-field
  class="full-width-field"
  floatLabel="{{ floatLabel ? 'auto' : 'never' }}"
  [ngClass]="invalid == true ? 'ng-invalid mat-form-field-invalid' : ''"
>
  <mat-label *ngIf="showLabel">{{ placeholderLabel }}</mat-label>
  <mat-select
    [disabled]="disabled"
    [formControl]="selectCtrl"
    [(ngModel)]="defaultID"
    [multiple]="multiple"
    [required]="required"
    (change)="emitChangeValue($event)"
    #myInputRef
    id="matSelectLoadmore"
  >
    <mat-option>
      <ngx-mat-select-search
        [placeholderLabel]="placeholderLabel"
        [noEntriesFoundLabel]="noEntriesFoundLabel"
        [formControl]="searchControl"
        (ngModelChange)="search($event)"
        disableScrollToActiveOnOptionsChanged="false"
      >
      </ngx-mat-select-search>
    </mat-option>
    <mat-option (click)="unselect()" *ngIf="allowUnSelect && multiple != true"
      >-- Bỏ chọn --</mat-option
    >
    <mat-option
      [id]="item.id"
      *ngFor="let item of filteredInput"
      [value]="item.id"
      >{{ item[columnName] }}</mat-option
    >
    <mat-option *ngIf="isAdd" class="addNewRow" (click)="emitOpenAddNewModal()"
      ><img [src]="imgPath + 'icon_add.svg'" width="25" height="25" /><span
        >Thêm mới</span
      ></mat-option
    >
    <div class="loadMore" id="testLoadMore" *ngIf="page < totalPage">
      <a href="javascript:void(0);" (click)="loadMore($event)">Xem thêm</a>
    </div>
  </mat-select>
  <mat-error
    *ngIf="
      (invalid == true && required == true) ||
      (selectCtrl.touched && selectCtrl.invalid)
    "
    [innerHTML]="errorLabel"
  >
  </mat-error>
</mat-form-field>
