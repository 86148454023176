import {Component, OnInit, Output, Input, EventEmitter} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DbdBoardService} from '@app/api/dbdBoard.service';

@Component({
  selector: 'meu-modal-edit-widget-name',
  templateUrl: './modal-edit-widget-name.component.html',
  styleUrls: ['./modal-edit-widget-name.component.scss'],
})
export class ModalEditWidgetNameComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Input() board_widget;

  hasFormErrors = false;
  editWidgetForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  constructor(
    private toastr: ToastrService,
    private dbdBoardService: DbdBoardService
  ) {}

  ngOnInit(): void {
    this.editWidgetForm.patchValue({
      name: this.board_widget.name,
    });
  }

  save() {
    const controls = this.editWidgetForm.controls;
    if (this.editWidgetForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    }

    this.board_widget.name = controls.name.value;
    this.dbdBoardService
      .apiMyBoardsSectionsIdWidgetsPost(
        this.board_widget.board_section_id,
        this.board_widget
      )
      .subscribe((result) => {
        if (result.success) {
          this.toastr.success('Cập nhật tên thành công.');
          this.closeModalClick(result.data);
        } else {
          this.toastr.error('Cập nhật tên thất bại.');
          this.closeModalClick(null);
        }
      });
  }

  closeModalClick(data) {
    this.closeModal.emit(data);
  }
}
