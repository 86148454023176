// Angular
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
// Material
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatStepperModule} from '@angular/material/stepper';
// Translate
import {TranslateModule} from '@ngx-translate/core';
// NGRX
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
// CRUD
import {InterceptService} from '../../../core/_base/crud/';
// Module components
import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';

import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {AuthNoticeComponent} from './auth-notice/auth-notice.component';
// Auth
import {
  AuthEffects,
  AuthGuard,
  authReducer,
  AuthService,
} from '../../../core/auth';

import {CookieService} from 'ngx-cookie-service';
import {MaterialModule} from 'mat-modules';
import {SharedModule} from '@app/views/shared/shared.module';
import {SetPasswordComponent} from './set-password/set-password.component';
import {InventoryLogoutComponent} from './inventory/inventory-logout/inventory-logout.component';
import {InventoryLoginLayoutComponent} from './inventory/inventory-login-layout/inventory-login-layout.component';
import {InventoryLoginComponent} from './inventory/inventory-login/inventory-login.component';
import {InventoryConfirmAccountComponent} from './inventory/inventory-confirm-account/inventory-confirm-account.component';
import {CommonElementModule} from '@app/views/common_elements/common_element.module';
import {RegisterComponent} from './inventory/inventory-register/register.component';
import {AuthLayoutComponent} from './auth-layout/auth-layout.component';
import {ModalSelectSpecialityComponent} from './inventory/inventory-register/modal-select-speciality/modal-select-speciality.component';
import {SettingModule} from '../../areas/portal/hr/setting/setting.module';

const routes: Routes = [
  // {
  //   path: '',
  //   component: AuthComponent,
  //   children: [
  //     {
  //       path: '',
  //       redirectTo: 'login',
  //       pathMatch: 'full',
  //     },
  //     {
  //       path: 'login',
  //       component: LoginComponent,
  //       data: {returnUrl: window.location.pathname},
  //     },
  //     {
  //       path: 'register',
  //       component: RegisterComponent,
  //     },
  //     {
  //       path: 'forgot-password',
  //       component: ForgotPasswordComponent,
  //     },
  //     {
  //       path: 'set-password',
  //       component: SetPasswordComponent,
  //       data: {secretkey: window.location.pathname},
  //     },
  //   ],
  // },
  {
    path: '',
    component: InventoryLoginLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'logout',
        component: InventoryLogoutComponent,
      },
      {
        path: 'login',
        component: InventoryLoginComponent,
        data: {secretkey: window.location.pathname},
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'confirm-account',
        component: InventoryConfirmAccountComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'set-password',
        component: SetPasswordComponent,
        data: {secretkey: window.location.pathname},
      },
    ],
  },
];

@NgModule({
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    CookieService,
  ],
  exports: [AuthComponent, RegisterComponent],
  declarations: [
    AuthComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    AuthNoticeComponent,
    SetPasswordComponent,
    InventoryLoginComponent,
    AuthLayoutComponent,
    InventoryLoginLayoutComponent,
    InventoryConfirmAccountComponent,
    ModalSelectSpecialityComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    RouterModule.forChild(routes),
    MatInputModule,
    MatStepperModule,
    MatFormFieldModule,
    MatCheckboxModule,
    TranslateModule.forChild(),
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([AuthEffects]),
    MaterialModule,
    SharedModule,
    CommonElementModule,
    SettingModule,
  ],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [AuthService, AuthGuard],
    };
  }
}
