<section ngClass="{{ tableClass(check) }}">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>
        <span [style.paddingLeft.px]="40"> Mã ngành </span>
      </th>
      <td *matCellDef="let data" class="table-row">
        <mat-checkbox
          class="checklist-leaf-node"
          [checked]="checklistSelection.isSelected(data)"
          [indeterminate]="descendantsPartiallySelected(data)"
          (change)="treeItemSelectionToggle(data)"
        ></mat-checkbox>
        <button
          mat-icon-button
          [style.visibility]="!data.expandable ? 'hidden' : ''"
          [style.marginLeft.px]="data.level * 32"
          (click)="treeControl.toggle(data)"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(data) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        {{ data.code }}
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Tên Ngành</th>
      <td *matCellDef="let data" class="table-row">{{ data.name }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Thao tác</th>
      <td *matCellDef="let data" class="table-row actions-row">
        <!-- <mat-icon class="actions-icon" (click)="openAddDialog(data)"
          >add</mat-icon
        >
        <mat-icon class="actions-icon" (click)="openEditDialog(data)"
          >edit</mat-icon
        > -->
        <!-- <mat-icon class="actions-icon" (click)="openDeleteDialog(data)"
          >delete_outline</mat-icon
        > -->
        <i class="fas fa-plus" (click)="openAddDialog(data)"></i>
        <i class="fas fa-pen" (click)="openEditDialog(data)"></i>
        <i class="fas fa-trash" (click)="openDeleteDialog(data)"></i>
      </td>
    </ng-container>
    <tr
      mat-header-row
      style="position: sticky; z-index: 1"
      *matHeaderRowDef="
        isRegister ? displayedRegisterColumns : displayedColumns;
        sticky: true
      "
      class="index-header-full"
    ></tr>
    <tr
      mat-row
      *matRowDef="
        let row;
        columns: isRegister ? displayedRegisterColumns : displayedColumns
      "
    ></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        No data matching the filter.
      </td>
    </tr>
  </table>
</section>

<div
  class="selected-specialties"
  *ngIf="checklistSelection.selected.length && check === true"
>
  <p>Đã chọn:</p>
  <ng-container *ngFor="let choosed of checklistSelection.selected">
    <div
      class="selected-specialties-chips"
      *ngIf="choosed?.expandable === false"
    >
      <!-- <span [innerHTML]="selectedTreeNode(choosed, choosed.name)"></span> -->
      <p
        matTooltip="{{ selectedTreeNode(choosed, choosed.name) }}"
        [innerHTML]="selectedTreeNode(choosed, choosed.name)"
      >
        {{ choosed.name }}
      </p>
      <mat-icon class="clear-icon" (click)="clearItem(choosed)">clear</mat-icon>
    </div>
  </ng-container>
</div>
