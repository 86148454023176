<div class="kt-portlet m-0">
  <div class="kt-portlet__head kt-portlet__head__custom">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title title-color bold">Chọn widget</h3>
    </div>
    <div
      (click)="closeModalClick(null)"
      class="close_modal_btn pointer"
      [matTooltip]="'Đóng'"
    >
      <mat-icon>close</mat-icon>
    </div>
  </div>

  <div class="kt-portlet__body kt-padding-15 max-height-body">
    <div class="row kt-margin-b-5">
      <div class="col-xl-12 category-flex">
        <button
          class="btn btn-scroll"
          [matTooltip]="'Trước'"
          (click)="scrollLeft()"
        >
          <mat-icon class="icon-arrow d-flex flex-column"
            >keyboard_arrow_left</mat-icon
          >
        </button>
        <div #widgetsContent class="d-flex scroll-content">
          <button
            *ngFor="let item of listBtn; index as i"
            class="btn btn-name"
            (click)="getCurrentBtn(i)"
            [ngStyle]="{
              background: item.isChoose ? '#5d78ff' : '#e9eaf9',
              color: item.isChoose ? 'white' : '#5d78ff'
            }"
          >
            {{ item.name }}
          </button>
        </div>

        <button
          class="btn btn-scroll"
          [matTooltip]="'Sau'"
          (click)="scrollRight()"
        >
          <mat-icon class="icon-arrow d-flex flex-column"
            >keyboard_arrow_right
          </mat-icon>
        </button>
      </div>
    </div>
    <div class="row kt-margin-b-5">
      <div class="col-12 col-md-5 col-lg-5 kt-padding-b-10">
        <div class="row padding-input-search">
          <div
            class="right-inner-addon input-container pointer input-search-event full-width-field"
          >
            <div class="input-group border-search">
              <div class="input-group-prepend">
                <div class="input-group-text icon-search none-border">
                  <mat-icon class="d-flex justify-content-center flex-column">
                    search</mat-icon
                  >
                </div>
              </div>
              <input
                type="text"
                class="form-control input-search none-border"
                id="inlineFormInputGroupUsername2"
                placeholder="Nhập tìm kiếm..."
                autocomplete="off"
                (keyup)="keyUp.next($event.target.value)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-widget-grid">
      <div
        *ngFor="let item of listWidget; index as i"
        class="card pointer"
        [class]="'kt-margin-10 br-4 card-widget'"
        [ngStyle]="{
          border: item.isChoose ? '1px solid #5d78ff' : '1px solid #ebedf2'
        }"
        (click)="chooseWidget(i)"
      >
        <div class="card-header card-header-widget">
          <div class="name-widget ellipsis">
            {{ item.name }}
          </div>
        </div>
        <div class="card-body">
          <img class="img-widget" [src]="item.image" />
        </div>
        <div class="card-footer card-footer-widget ellipsis">
          <span class="name-widget">{{ item.widget_name }}</span
          ><br />
          <span class="description-text">{{ item.description }}</span>
        </div>
      </div>
    </div>
    <mat-paginator
      [length]="length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="paginatorChange($event)"
    >
    </mat-paginator>
  </div>

  <div class="kt-portlet__foot">
    <div class="row">
      <div class="col-12 footer-padding text-right">
        <button
          type="button"
          class="btn button-cancel"
          (click)="closeModalClick(null)"
        >
          Hủy
        </button>
        <button type="button" class="btn button-save" (click)="save()">
          Lưu
        </button>
      </div>
    </div>
  </div>
</div>
