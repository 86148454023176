<mat-card>
    <mat-card-content>

        <mat-table [dataSource]="allRole">
            <!-- Position Column -->

            <ng-container matColumnDef="index">
                <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
                <mat-cell *matCellDef="let element; index as i"> {{i + 1}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Tên Group </mat-header-cell>
                <mat-cell *matCellDef="let element" class="ellipsis" [title]="element.name">
                    <span class="ellipsis">
                        {{element.name}}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef> Mô tả </mat-header-cell>
                <mat-cell *matCellDef="let element" class="ellipsis" [title]="element.description">
                    {{element.description}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef style="justify-content:center;"> Thao tác </mat-header-cell>
                <mat-cell *matCellDef="let element" class="overflow" style="justify-content:center;">

                    <button mat-icon-button color="primary" matTooltip="Chỉnh sửa" (click)="editGroup(element.id)">
                        <mat-icon class="iconColor">edit</mat-icon>
                    </button>

                    <button mat-icon-button color="warn" matTooltip="Phân quyền Role" (click)="addRole(element.id)">
                        <mat-icon class="iconColor">group_add</mat-icon>
                    </button>

                    <button mat-icon-button color="warn" matTooltip="Phân quyền người dùng"
                        (click)="addUser(element.id)">
                        <mat-icon class="iconColor">person_add</mat-icon>
                    </button>

                    <button mat-icon-button color="warn" matTooltip="Xóa" (click)="onRemove(element.id)">
                        <mat-icon class="iconColor">delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div *ngIf="allRole.length == 0" class="no-records">
            Không có dữ liệu
        </div>
        <mat-paginator (page)="paginatorChange($event)" [pageSize]="pagesize" [length]="total" [hidePageSize]="false"
            [pageSizeOptions]="[5, 10, 20, 100]" [showFirstLastButtons]="true">
        </mat-paginator>

    </mat-card-content>
</mat-card>