<div class="wrapper font-roboto-medium">
  <div class="cover">
    <h1>HƯỚNG DẪN ĐĂNG KÝ TÀI KHOẢN</h1>
    <h1>PHẦN MỀM QUẢN LÝ NHIỆM VỤ</h1>
    <img class="mt-2" src="assets/media/user-guide/cover.jpg" alt="cover" />
  </div>
  <div class="step-1">
    <p>
      Để <a href="/auth/login">đăng ký</a> tài khoải mới tại phần mềm quản lý
      nhiệm vụ. Người dùng truy cập vào trang đăng nhập của hệ thống.Để đăng ký
      tài khoải mới tại phần mềm quản lý nhiệm vụ. Người dùng truy cập vào trang
      đăng nhập của hệ thống.
    </p>
    <img
      src="assets/media/user-guide/login.png"
      alt="login-page"
      width="100%"
    />
    <div class="mt-2">
      Nhấn vào <a href="/auth/login">Đăng ký</a> để tiến hành đăng ký tài khoản
      mới.
    </div>
    <div>
      Tạo một tài khoản mới bởi người dùng bao gồm 3 giai đoạn chính và một giai
      đoạn xác nhận tài khoản thông qua mail.
    </div>
    <div>Tài khoản thông qua email:</div>
    <div class="mt-4" style="font-weight: 600">
      &diams; Giai đoạn 1 - Thông tin cá nhân:
    </div>
    <img
      src="assets/media/user-guide/register-info.png"
      alt="info"
      width="100%"
    />
    <div class="mt-4">
      Sau khi điền đầy đủ thông tin tại Giai đoạn 1 thì người dùng có thể nhấn
      vào nút <button class="btn-primary">Tiếp theo</button> để đi đến Giai đoạn
      2 hoặc <button class="btn-secondary">Đóng</button> để quay lại trang
      <a href="/auth/login">Đăng nhập</a>
    </div>
    <div>
      Lưu ý: Người dùng có thể chọn Đơn vị công tác có sẵn hoặc nhấn vào nút
      <label class="button-outer">+ Thêm mới</label> để thêm mới đơn vị.
    </div>
    <img
      src="assets/media/user-guide/add-new-unit.png"
      alt="unit"
      width="100%"
    />
    <div class="mt-4" style="font-weight: 600">
      &diams; Giai đoạn 2 - Quá trình đào tạo:
    </div>
    <img src="assets/media/user-guide/education.png" alt="edu" width="100%" />
    <div class="mt-4">
      Người dùng có thể nhấn vào nút
      <label class="button-outer">+ Thêm bậc đào tạo</label> để thêm hoặc
      <img
        src="assets/icons/svg/delete.svg"
        alt="delete"
        width="24px"
        height="24px"
      />
      để xoá bậc đào tạo.
    </div>
    <div>
      Sau khi điền đầy đủ thông tin tại giai đoạn 2 thì người dùng có thể nhấn
      vào nút <button class="btn-primary">Tiếp theo</button> để đi đến Giai đoạn
      3.
    </div>
    <div class="mt-4" style="font-weight: 600">
      &diams; Giai đoạn 3 - Thiết lập mật khẩu:
    </div>
    <img src="assets/media/user-guide/password.png" alt="unit" width="100%" />
    <div class="mt-4">
      Sau khi điền đầy đủ thông tin tại Giai đoạn 3 thì người dùng có thể nhấn
      vào nút <button class="btn-primary">Hoàn thành</button> để đi đến giai
      đoạn Xác nhận tài khoản.
    </div>
    <div class="mt-4" style="font-weight: 600">
      &diams; Giai đoạn xác nhận tài khoản:
    </div>
    <img src="assets/media/user-guide/confirm.png" alt="unit" width="100%" />
    <div class="mt-4">
      Sau khi nhận được thông báo như ảnh trên, người dùng mở hộp thư trong
      email để kiểm tra, thông báo xác nhận tài khoản sẽ được gửi thông qua
      email (người dùng vui lòng kiểm tra các mục như
      <label style="font-weight: 600">Inbox</label>,
      <label style="font-weight: 600">Trash</label>,
      <label style="font-weight: 600">Spam</label>). Sau khi thấy email với tiêu
      đề “Xác nhận tài khoản” người dùng vui lòng nhấp vào email và nhấp vào
      liên kết để kích hoạt tài khoản.
    </div>
    <div>
      Lưu ý: Liên kết xác nhận tài khoản chỉ tồn tại trong vòng 15 phút, nếu quá
      thời gian cho phép người dùng vui lòng liên hệ trực tiếp quản trị viên để
      được hỗ trợ
    </div>
    <div class="mt-4"></div>
    <img
      src="assets/media/user-guide/register-success.png"
      alt="unit"
      width="100%"
    />
    <div class="mt-4">
      Tài khoản đã xác nhận thành công, người dùng vui lòng nhấn vào nút
      <button class="btn-primary">Đăng nhập ngay</button> để đăng nhập vào trang
      web của Sở KHCN.
    </div>
  </div>
</div>
