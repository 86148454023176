export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export enum SOCIAL_TYPE {
  MOBILE = 'MOBILE',
  EMAIL = 'EMAIL',
  FACEBOOK = 'FACEBOOK',
  SKYPE = 'SKYPE',
  TWITTER = 'TWITTER',
  INSTAGRAM = 'INSTAGRAM',
  WHATSAPP = 'WHATSAPP',
  VIBER = 'VIBER',
  SNAPCHAT = 'SNAPCHAT',
  TELEGRAM = 'TELEGRAM',
  LINKEDIN = 'LINKEDIN',
  APPLE = 'APPLE',
  YOUTUBE = 'YOUTUBE',
  PINTEREST = 'PINTEREST',
}

export const configSummerNote = {
  placeholder: '',
  tabsize: 2,
  height: 'auto',
  minHeight: '70vh',
  uploadImagePath: '/api/upload',
  focus: true,
  toolbar: [
    ['misc', ['codeview', 'undo', 'redo']],
    ['style', ['bold', 'italic', 'underline', 'clear']],
    ['font', ['strikethrough', 'superscript', 'subscript']],
    ['fontsize', ['fontname', 'fontsize', 'color']],
    ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
    ['insert', ['table', 'hr']],
  ],
  codeviewFilter: false,
  codeviewFilterRegex: /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml)[^>]*?>/gi,
  codeviewIframeFilter: true,
  fontNames: [
    'Helvetica',
    'Arial',
    'Arial Black',
    'Comic Sans MS',
    'Courier New',
    'Roboto',
    'Times',
  ],
};
