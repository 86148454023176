<kt-splash-screen *ngIf="htmlClassService.loader$ | async"></kt-splash-screen>
<router-outlet></router-outlet>
<!-- <ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner> -->
