// Angular
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
// Store
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../core/reducers';

// Auth
import {Logout} from '../../../../../core/auth';

@Component({
  selector: 'meu-inventory-logout',
  templateUrl: './inventory-logout.component.html',
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class InventoryLogoutComponent implements OnInit {
  // Public params

  formErrorServie: any;

  constructor(private store: Store<AppState>) {}

  /**
   * On init
   */
  ngOnInit(): void {
    this.store.dispatch(new Logout());
  }
}
