import {
  Component,
  OnInit,
  ChangeDetectorRef,
  TemplateRef,
  Input,
  ViewContainerRef,
  ViewChild,
  Compiler,
  ViewChildren,
  ElementRef,
  QueryList,
  AfterViewInit,
  AfterViewChecked,
} from '@angular/core';
import {LayoutUtilsService} from '@app/core/_base/crud';
import {DbdBoardService} from '@app/api/dbdBoard.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '@app/../environments/environment';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {Store, select} from '@ngrx/store';
import {AppState} from '@app/core/reducers/index';
import {currentUser, User} from '@app/core/auth';
import {PluginLoaderService} from '@app/services/plugin-loader/plugin-loader.service';

@Component({
  selector: 'meu-dynamic-dashboard',
  templateUrl: './dynamic-dashboard.component.html',
  styleUrls: ['./dynamic-dashboard.component.scss'],
})
export class DynamicDashboardComponent implements OnInit, AfterViewChecked {
  listMyBoard = [];
  boardContent = [];
  indexSelect = 0;
  boardSelected;
  idBoard;
  isEdit = false;
  modalRef: BsModalRef;

  imgPath = environment.url_svg_local;

  // modal add widget
  sort_order_add_widget;
  board_section_id;
  category_add_widget;

  @Input() categoryDashboard;

  //modal edit widget name
  board_widget;

  public controlSearch: FormControl = new FormControl();

  isInit = true;
  owner_id: any;
  user$: Observable<User>;

  constructor(
    private layoutUtilsService: LayoutUtilsService,
    private changeDetectorRef: ChangeDetectorRef,
    private dbdBoardService: DbdBoardService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private store: Store<AppState>,

    private pluginLoader: PluginLoaderService,
    private compiler: Compiler,
    private ref: ChangeDetectorRef
  ) {}

  @ViewChildren('pluginReference', {read: ViewContainerRef})
  pluginReferences: QueryList<ViewContainerRef>;
  ngOnInit(): void {
    this.user$ = this.store.pipe(select(currentUser));
    this.user$.subscribe((user) => {
      this.owner_id = user.id;
    });

    this.getListMyBoard();
  }

  ngAfterViewChecked() {
    //this.loadCurrentPlugin();
  }
  loadCurrentPlugin() {
    var self = this;
    setTimeout(function () {
      self.boardContent.forEach((section) => {
        section.board_widget.forEach((element) => {
          if (element.widget_name != undefined) {
            self.loadPlugin(element.widget_name, element.id);
          }
        });
      });
    }, 10);
  }
  loadPlugin(pluginName: string, id: any) {
    this.pluginReferences.forEach((pluginItem) => {
      if (pluginItem['_lContainer'][0].id == id) {
        this.pluginLoader.load(pluginName).then((moduleType: any) => {
          const ngModuleFactory = this.compiler.compileModuleSync(moduleType);
          const ngModule = ngModuleFactory.create(pluginItem.injector);
          const factory = ngModule.componentFactoryResolver.resolveComponentFactory(
            moduleType.entry
          );
          pluginItem.createComponent(factory);
          this.ref.detectChanges();
        });
      }
    });
  }
  getListMyBoard() {
    let list = [];
    this.dbdBoardService
      .apiMyBoardsGet(this.categoryDashboard.code)
      .subscribe((res) => {
        if (res.success) {
          let data_sort = res.data.sort((a, b) =>
            a.sort_order > b.sort_order ? 1 : -1
          );
          for (let i = 0; i < data_sort.length; i++) {
            list.push(data_sort[i]);
          }
          this.listMyBoard = list;

          if (this.listMyBoard.length == 0 && this.isInit) {
            this.isInit = false;
            let objCreateBoard = {
              name: 'New board',
              code: this.categoryDashboard.code,
              owner_id: this.owner_id,
              sort_order: 0,
              is_public: false,
            };
            this.dbdBoardService
              .apiMyBoardsPost(objCreateBoard)
              .subscribe((result) => {
                if (result.success) {
                  this.getListMyBoard();
                }
              });
          }
          this.boardSelected =
            this.listMyBoard.length > 0 ? this.listMyBoard[0] : null;
          if (this.listMyBoard.length > 0) {
            this.getListBoardSection();
          }
        }
      });
  }

  getListBoardSection() {
    this.dbdBoardService
      .apiMyBoardsIdGet(this.boardSelected.id)
      .subscribe((res) => {
        if (res.success) {
          let list = [];
          for (let i = 0; i < res.data.extend_sections.length; i++) {
            let array_section_layout = res.data.extend_sections[
              i
            ].section.section_layout.split(':');

            let list_board_widget = res.data.extend_sections[
              i
            ].widget.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));

            let board_widget = [],
              index_widget = 0;
            for (let j = 0; j < array_section_layout.length; j++) {
              if (
                list_board_widget[index_widget] &&
                list_board_widget[index_widget].sort_order == j
              ) {
                board_widget.push({
                  id: list_board_widget[index_widget].id,
                  board_section_id:
                    list_board_widget[index_widget].board_section_id,
                  name: list_board_widget[index_widget].name,
                  widget_code: list_board_widget[index_widget].widget_code,
                  widget_name: list_board_widget[index_widget].widget_name,
                  sort_order: list_board_widget[index_widget].sort_order,
                  configuration: list_board_widget[index_widget].configuration,
                  is_edit_config: false,
                  array_configuration: JSON.parse(
                    list_board_widget[index_widget].configuration
                  ),
                });
                index_widget++;
              } else {
                board_widget.push({});
              }
            }

            list.push({
              id: res.data.extend_sections[i].section.id,
              sort_order: res.data.extend_sections[i].section.sort_order,
              board_section_id: res.data.extend_sections[i].section.id,
              board_id: this.boardSelected.id,
              section_layout:
                res.data.extend_sections[i].section.section_layout,
              array_section_layout: array_section_layout,
              board_widget: board_widget,
            });
          }
          this.boardContent = list.sort((a, b) =>
            a.sort_order > b.sort_order ? 1 : -1
          );
          this.changeDetectorRef.detectChanges();
        }
        this.loadCurrentPlugin();
      });
  }

  checkObjEmpty(obj) {
    return Object.keys(obj).length == 0 ? true : false;
  }

  getCurrentBoard(item, index) {
    if (item.id != this.boardSelected.id) {
      this.indexSelect = index;
      this.boardSelected = item;
      this.getListBoardSection();
    }
  }

  openModalCreateBoard(template: TemplateRef<any>, isEdit) {
    this.idBoard = isEdit ? this.boardSelected.id : null;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
  }
  closeModalCreateBoard(data: any) {
    this.modalRef.hide();
    if (data && !data.isEdit) {
      this.listMyBoard.push(data.response);
      this.indexSelect = this.listMyBoard.length - 1;
      this.boardSelected = this.listMyBoard[this.indexSelect];
      this.boardContent = [];
    } else if (data && data.isEdit) {
      this.listMyBoard[this.indexSelect].name = data.response.name;
    }
  }

  openModalChooseLayout(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
    });
  }
  closeModalChooseLayout(data: any) {
    this.modalRef.hide();
    if (data) {
      let objSection: any = {
        board_id: this.boardSelected.id,
        sort_order: this.boardContent.length,
        section_layout: data.section_layout,
      };
      this.dbdBoardService
        .apiMyBoardsIdSectionsPost(this.boardSelected.id, objSection)
        .subscribe((res) => {
          if (res.success) {
            let array_section_layout = [];
            let listObjEmpty = [];
            for (let i = 0; i < data.col_layout.length; i++) {
              array_section_layout.push(data.col_layout[i]);
              listObjEmpty.push({});
            }

            this.boardContent.push({
              id: res.data.id,
              sort_order: res.data.sort_order,
              board_section_id: res.data.id,
              board_id: this.boardSelected.id,
              section_layout: res.data.section_layout,
              array_section_layout: array_section_layout,
              board_widget: listObjEmpty,
            });
            this.changeDetectorRef.detectChanges();
            this.toastr.success('Thêm mới section thành công');
          } else {
            this.toastr.error(res.message, 'Thêm mới section không thành công');
          }
        });
    }
  }

  openModalChooseWidget(template: TemplateRef<any>, board_section_id, index) {
    this.sort_order_add_widget = index;
    this.board_section_id = board_section_id;
    this.category_add_widget = this.categoryDashboard.categories;
    this.idBoard = this.boardSelected.id;

    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered modal-lg',
    });
  }
  closeModalChooseWidget(data: any) {
    this.modalRef.hide();
    if (data) {
      this.getListBoardSection();
    }
  }

  openModalEditWidgetName(template: TemplateRef<any>, board_widget) {
    this.board_widget = board_widget;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog-centered',
    });
  }
  closeModalEditWidgetName(data: any) {
    this.modalRef.hide();
  }

  deleteBoard() {
    const title = 'Xóa thẻ đang mở';
    const description = 'Bạn có chắc chắn muốn xóa ?';
    const waitDesciption = 'Đang xử lý...';
    const dialogRef = this.layoutUtilsService.deleteElement(
      title,
      description,
      waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      let id = this.boardSelected.id;
      this.dbdBoardService
        .apiMyBoardsIdDelete(this.boardSelected.id)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success('Thao tác xóa thành công');
            let index = this.listMyBoard.findIndex((x) => x.id == id);
            this.listMyBoard.splice(index, 1);

            for (let i = 0; i < this.listMyBoard.length; i++) {
              this.listMyBoard[i].sort_order = i;
              let obj: any = {
                sort_order: i,
              };
              this.dbdBoardService
                .apiMyBoardsIdPut(this.listMyBoard[i].id, obj)
                .subscribe((result) => {});
              index++;
            }

            this.indexSelect = 0;
            this.boardSelected =
              this.listMyBoard.length > 0 ? this.listMyBoard[0] : null;

            if (this.listMyBoard.length > 0) {
              this.getListBoardSection();
            }
          } else {
            this.toastr.error(res.message, 'Thao tác xóa không thành công');
          }
        });
    });
  }

  deleteSection(boardSectionId) {
    const title = 'Xóa section này';
    const description = 'Bạn có chắc chắn muốn xóa ?';
    const waitDesciption = 'Đang xử lý...';
    const dialogRef = this.layoutUtilsService.deleteElement(
      title,
      description,
      waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.dbdBoardService
        .apiMyBoardsSectionsIdDelete(boardSectionId)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success('Thao tác xóa thành công');
            let index = this.boardContent.findIndex(
              (x) => x.board_section_id == boardSectionId
            );
            this.boardContent.splice(index, 1);
            for (let i = 0; i < this.boardContent.length; i++) {
              this.boardContent[i].sort_order = i;
              let obj: any = {
                sort_order: i,
                section_layout: this.boardContent[i].section_layout,
              };
              this.dbdBoardService
                .apiMyBoardsSectionsIdPut(
                  this.boardContent[i].board_section_id,
                  obj
                )
                .subscribe((res) => {});
            }
          } else {
            this.toastr.error(res.message, 'Thao tác xóa không thành công');
          }
        });
    });
  }

  deleteBoardWidget(boardWidgetId) {
    const title = 'Xóa widget này';
    const description = 'Bạn có chắc chắn muốn xóa ?';
    const waitDesciption = 'Đang xử lý...';
    const dialogRef = this.layoutUtilsService.deleteElement(
      title,
      description,
      waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.dbdBoardService
        .apiMyBoardsWidgetsIdDelete(boardWidgetId)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success('Thao tác xóa thành công');
            this.getListBoardSection();
          } else {
            this.toastr.error(res.message, 'Thao tác xóa không thành công');
          }
        });
    });
  }

  moveUpSection(section, indexSection) {
    var b = this.boardContent;
    if (indexSection - 1 >= 0) {
      section.sort_order--;
      b[indexSection - 1].sort_order++;

      let obj: any = {
        sort_order: section.sort_order,
        section_layout: section.section_layout,
      };
      this.dbdBoardService
        .apiMyBoardsSectionsIdPut(section.board_section_id, obj)
        .subscribe((res) => {});

      obj = {
        sort_order: b[indexSection - 1].sort_order,
        section_layout: b[indexSection - 1].section_layout,
      };
      this.dbdBoardService
        .apiMyBoardsSectionsIdPut(b[indexSection - 1].board_section_id, obj)
        .subscribe((res) => {});
    } else {
      return;
    }

    b = b.sort(function (a, b) {
      return a.sort_order - b.sort_order;
    });
    this.boardContent = [...b];
  }

  moveDownSection(section, indexSection) {
    var b = this.boardContent;
    if (indexSection + 1 < b.length) {
      section.sort_order++;
      b[indexSection + 1].sort_order--;

      let obj: any = {
        sort_order: section.sort_order,
        section_layout: section.section_layout,
      };
      this.dbdBoardService
        .apiMyBoardsSectionsIdPut(section.board_section_id, obj)
        .subscribe((res) => {});

      obj = {
        sort_order: b[indexSection + 1].sort_order,
        section_layout: b[indexSection + 1].section_layout,
      };
      this.dbdBoardService
        .apiMyBoardsSectionsIdPut(b[indexSection + 1].board_section_id, obj)
        .subscribe((res) => {});
    } else {
      return;
    }

    b = b.sort(function (a, b) {
      return a.sort_order - b.sort_order;
    });
    this.boardContent = [...b];
  }

  configWidget(widgetId, indexSection, indexWidget) {
    this.boardContent[indexSection].board_widget[
      indexWidget
    ].is_edit_config = true;
  }

  cancelConfig(widgetId, indexSection, indexWidget) {
    this.boardContent[indexSection].board_widget[
      indexWidget
    ].is_edit_config = false;

    this.boardContent[indexSection].board_widget[
      indexWidget
    ].array_configuration = JSON.parse(
      this.boardContent[indexSection].board_widget[indexWidget].configuration
    );
  }

  saveConfig(widgetId, indexSection, indexWidget) {
    this.boardContent[indexSection].board_widget[
      indexWidget
    ].configuration = JSON.stringify(
      this.boardContent[indexSection].board_widget[indexWidget]
        .array_configuration
    );

    this.dbdBoardService
      .apiMyBoardsSectionsIdWidgetsPost(
        this.boardContent[indexSection].board_widget[indexWidget]
          .board_section_id,
        this.boardContent[indexSection].board_widget[indexWidget]
      )
      .subscribe((res) => {
        if (res.success) {
          this.boardContent[indexSection].board_widget[
            indexWidget
          ].is_edit_config = false;
          this.toastr.success('Thao tác cập nhật thành công');
        } else {
          this.toastr.error(res.message, 'Thao tác không thành công');
        }
      });
  }

  search(event) {}

  checkBoardEmpty() {
    for (let i = 0; i < this.boardContent.length; i++) {
      for (let j = 0; j < this.boardContent[i].board_widget.length; j++) {
        if (!this.checkObjEmpty(this.boardContent[i].board_widget[j])) {
          return false;
        }
      }
    }
    return true;
  }

  getIdDefault(item) {
    if (Array.isArray(item)) {
      return item.length > 0 ? item[0] : '';
    } else return item;
  }
}
