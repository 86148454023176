import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {DbdWidgetCollectionService} from '@app/api/dbdWidgetCollection.service';
import {DbdBoardService} from '@app/api/dbdBoard.service';
import {map, debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {environment} from '@app/../environments/environment';

@Component({
  selector: 'meu-modal-add-widget',
  templateUrl: './modal-add-widget.component.html',
  styleUrls: ['./modal-add-widget.component.scss'],
})
export class ModalAddWidgetComponent implements OnInit {
  public keyUp = new Subject<KeyboardEvent>();
  dataSearchBasic: any = '';
  filter = '';
  indexBtnSeleted = -1;
  btnSelected;
  listWidget = [];

  listBtn = [];

  // MatPaginator Inputs
  length = 0;
  pageSize = 4;
  pageSizeOptions: number[] = [2, 4, 8, 100];
  pageIndex = 0;
  // MatPaginator Output
  pageEvent: PageEvent;

  @Output() closeModal = new EventEmitter();
  @Input() boardId;
  @Input() sort_order;
  @Input() board_section_id;
  @Input() category;

  @ViewChild('widgetsContent', {read: ElementRef})
  public widgetsContent: ElementRef<any>;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(
    public ref: ChangeDetectorRef,
    public boardService: DbdBoardService,
    public widgetService: DbdWidgetCollectionService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.keyUp
      .pipe(
        map((event) => event),
        debounceTime(500)
      )
      .subscribe((value) => {
        this.dataSearchBasic = value;
        this.filter = 'widget_name|description@=*' + this.dataSearchBasic;
        this.pageIndex = 0;
        this.paginator.pageIndex = 0;
        this.getWidgetCollection();
      });
    //Load lstBtn
    for (let i = 0; i < this.category.length; i++) {
      this.category[i].isChoose = false;
    }
    if (this.category.length > 0) this.category[0].isChoose = true;
    this.listBtn = this.category;
    //avoid conflict with post spinner
    setTimeout(() => this.getWidgetCollection(), 300);
  }

  save() {
    let widget = this.listWidget.find((p) => p.isChoose == true);
    delete widget.isChoose;
    let body = {
      board_section_id: this.board_section_id,
      sort_order: this.sort_order,
      widget_name: widget.widget_name,
      widget_code: widget.code,
      configuration: widget.configuration,
      name: widget.name,
    };
    this.boardService
      .apiMyBoardsSectionsIdWidgetsPost(this.board_section_id, body)
      .subscribe((result) => {
        if (result.success) {
          this.toastr.success('Thao tác lưu thành công.');
          let data = {
            boardWidget: result.data,
            boardId: this.boardId,
            widgetCollection: widget,
          };
          this.closeModalClick(data);
        } else {
          this.toastr.error('Thao tác lưu không thành công.');
        }
      });
  }

  closeModalClick(data) {
    this.closeModal.emit(data);
  }

  getCurrentBtn(index) {
    this.listBtn[index].isChoose = this.listBtn[index].isChoose ? false : true;
    this.getWidgetCollection();
  }

  getWidgetCollection() {
    let codeArr = [];
    this.listBtn.forEach((element) => {
      if (element.isChoose) {
        codeArr.push(element.code);
      }
    });
    this.widgetService
      .apiDbdWidgetCollectionPost(
        codeArr,
        this.filter,
        '',
        this.pageIndex + 1,
        this.pageSize
      )
      .subscribe((res) => {
        if (res.success) {
          this.listWidget = res.data.collection;
          this.sortWidget();
          for (let i = 0; i < this.listWidget.length; i++) {
            this.listWidget[i].image =
              environment.backendhost + this.listWidget[i].image;
            i == 0
              ? (this.listWidget[0].isChoose = true)
              : (this.listWidget[i].isChoose = false);
          }
          this.pageIndex = res.data.pageIndex - 1;
          this.length = res.data.total;
          this.pageSize = res.data.pagesize;
        }
      });
  }
  chooseWidget(index) {
    this.listWidget.forEach((element) => {
      element.isChoose = false;
    });
    this.listWidget[index].isChoose = this.listWidget[index].isChoose
      ? false
      : true;
  }
  paginatorChange(event?: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getWidgetCollection();
  }
  scrollRight() {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft + 150,
      behavior: 'smooth',
    });
  }
  sortWidget() {
    this.listWidget.sort((a, b) => {
      return a.sort_order - b.sort_order;
    });
  }
  scrollLeft() {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft - 150,
      behavior: 'smooth',
    });
  }
}
