import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {HrPositionService} from '@apiservice/hrPosition.service';
import {Router} from '@angular/router';
import {LayoutUtilsService} from '@app/core/_base/crud';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {SubHeaderButtonService} from '@app/services/sub-header-button.service';
import {ModalCreateGroupComponent} from './modal-create-group/modal-create-group.component';
import {MatDialog} from '@angular/material/dialog';
import {SubheaderService} from '@app/core/_base/layout';

@Component({
  selector: 'meu-group-management',
  templateUrl: './group-management.component.html',
  styleUrls: ['./group-management.component.scss'],
})
export class GroupManagementComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['index', 'name', 'description', 'action'];
  allRole = [];
  total = 0;
  pageIndex = 1;
  pagesize = 10;
  private subscriptions: Subscription[] = [];

  constructor(
    private positionService: HrPositionService,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private toastr: ToastrService,
    private subHeaderButtonService: SubHeaderButtonService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private subheaderService: SubheaderService
  ) {}

  ngOnInit() {
    this.subheaderService.title$.next({
      title: 'Cài đặt',
      desc: 'Quản lý Group',
    });

    this.subHeaderButtonService.setButtonCollection([
      {
        name: 'New',
        type: 'Text',
        icon: 'flaticon-download-1',
        label: 'Thêm mới',
        Code: 'openModalCreateGroup',
      },
    ]);
    this.initServiceSubheader();
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  getData() {
    // this.subscriptions.push(
    // this.positionService.apiHrPositionGet("", "", this.pageIndex, this.pagesize).subscribe(res => {
    //   this.allRole = res.data.collection;
    //   this.total = res.data.total;
    //   this.pageIndex = res.data.pageIndex;
    //   this.changeDetectorRef.detectChanges();
    // })
    // )
    for (let i = 1; i <= 5; i++) {
      this.allRole.push({
        id: '' + i,
        name: 'GROUP ADMIN ' + i,
        description: 'Group Admin ' + i,
      });
    }
  }

  initServiceSubheader() {
    this.subscriptions.push(
      this.subHeaderButtonService.onClickButton.subscribe((code) => {
        if (code === 'openModalCreateGroup') {
          const dialogRef = this.dialog.open(ModalCreateGroupComponent, {
            data: {},
            width: '700px',
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result?.event) {
              this.getData();
            }
          });
        }
      })
    );
  }

  editGroup(id) {
    const dialogRef = this.dialog.open(ModalCreateGroupComponent, {
      data: {groupId: id},
      width: '700px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.event) {
        this.getData();
      }
    });
  }

  addRole(groupId) {
    this.router.navigate([
      '/portal/hr/setting/permission/group-management/group-role/' + groupId,
    ]);
  }

  addUser(groupId) {
    this.router.navigate([
      '/portal/hr/setting/permission/group-management/group-user/' + groupId,
    ]);
  }

  onRemove(id) {
    const title = 'Xóa Group';
    const description = 'Bạn có chắc chắn muốn xóa Group này?';
    const waitDesciption = 'Đang xử lý...';

    const dialogRef = this.layoutUtilsService.deleteElement(
      title,
      description,
      waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.positionService.apiHrPositionIdDelete(id).subscribe(
        (result) => {
          if (result.success) {
            this.toastr.success('Đã xóa thành công', '');
            this.getData();
          } else {
            this.toastr.error('Xóa thất bại', '', {
              timeOut: 3000,
            });
          }
        },
        (error) => {
          this.toastr.error('Xóa thất bại', '', {
            timeOut: 3000,
          });
        }
      );
    });
  }
  paginatorChange(event) {
    this.pagesize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getData();
  }
}
