// Angular
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as signalR from '@microsoft/signalr';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { NotificationService } from '@apiservice/notification.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { LayoutUtilsService } from '@app/core/_base/crud';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { HashService } from '@app/core/auth/_services/hash.service';

@Component({
  selector: 'kt-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['notification.component.scss'],
})
export class NotificationComponent implements OnChanges, OnInit, OnDestroy {
  // Show dot on top of the icon
  @Input() dot: string;

  // Show pulse on icon
  @Input() pulse: boolean;

  @Input() pulseLight: boolean;

  // Set icon class name
  @Input() icon = 'flaticon2-bell-alarm-symbol';
  @Input() iconType: '' | 'success';

  // Set true to icon as SVG or false as icon class
  @Input() useSVG: boolean;

  // Set bg image path
  @Input() bgImage: string;

  // Set skin color, default to light
  @Input() skin: 'light' | 'dark' = 'light';

  @Input() type: 'brand' | 'success' = 'success';
  public notificationCount$ = new Subject();
  private hubConnection: signalR.HubConnection;
  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.backendhost + '/api/notificationhub', {
        accessTokenFactory: () => this.hash.encryptDataSignalR(localStorage.getItem(environment.authTokenKey)),
      })
      .build();

    this.hubConnection.start().then().catch();
  };

  public addTransferChartDataListener = () => {
    this.hubConnection.on('NEW_NOTIFICATION_COMMING', (data) => {
      this.notificationCount$.next(data);
      this.getNotificationGroupInit(false);
    });
  };
  /**
   * Component constructor
   *
   * @param sanitizer: DomSanitizer
   */
  groupNotification: any = [];
  groupNotification$ = new Subject();
  subGroupNotification: any = [];
  notify: any = [];
  notify$ = new Subject();
  pageNumber = 1;
  pageSize = 20;
  selectedGroup = null;
  selectedGroupIndex;
  notifyTotal = 0;
  @ViewChildren(NgbDropdown)
  dropdowns: QueryList<NgbDropdown>;
  private dropdown: NgbDropdown;
  constructor(
    private sanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private toastr: ToastrService,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private hash: HashService
  ) { }

  ngOnChanges() { }
  ngOnInit() {
    this.startConnection();
    this.addTransferChartDataListener();
    this.getNotificationGroupInit(true);
  }
  ngOnDestroy() { }
  backGroundStyle(): string {
    if (!this.bgImage) {
      return 'none';
    }
    return 'url(' + this.bgImage + ')';
  }
  getNotificationGroup(drop) {
    this.dropdown = this.dropdowns.find(
      (x) => (x as any)._elementRef.nativeElement == drop
    );
    if (this.dropdown.isOpen()) {
      this.selectedGroup = null;
      this.getNotificationGroupInit(true);
    }
  }
  getNotificationGroupInit(isInit: boolean) {
    this.notificationService.apiNotificationsSummaryGet().subscribe((res) => {
      this.groupNotification = [...res.data];
      console.log(this.groupNotification);
      this.groupNotification$.next(this.groupNotification);
      this.groupNotification.forEach((item) => {
        this.matIconRegistry.addSvgIcon(
          'icon_sitemap',
          this.sanitizer.bypassSecurityTrustResourceUrl(
            '/assets/media/meu-icons/sitemap.svg'
          )
        );
      });

      if (isInit) this.getNotify('', null);
    });
  }
  getNotify(type, index) {
    this.pageNumber = 1;
    this.selectedGroup = this.groupNotification[index];
    this.selectedGroupIndex = index;
    const filter: string = 'main_category_type==' + type;
    this.notificationService
      .apiNotificationsGet(filter, '', this.pageNumber, this.pageSize)
      .subscribe((res) => {
        if (res.success) {
          this.notify = res.data.collection;
          this.notifyTotal = res.data.total;
          this.notificationCount$.next(res.data.unread);
          this.notify.forEach((item, index) => {
            let indexOfGroup = this.groupNotification.findIndex(
              (x) => x.type == item.main_category_type
            );
            item.icon = this.groupNotification[
              indexOfGroup
            ].sub_categories.find(
              (x) => x.type == item.sub_category_type
            )?.icon;
            this.convertTimeInNotify(item);
          });
          this.notify$.next(this.notify);
        }
      });
  }
  getNotifyInGroup(type, groupCode, index) {
    const filter: string =
      'main_category_type==' + type + ',group_code==' + groupCode;
    this.notificationService
      .apiNotificationsGet(filter, '', 1, 100)
      .subscribe((res) => {
        if (res.success) {
          this.notify[index].group_count = res.data.total;
        }
      });
  }
  loadMoreNotify() {
    this.pageNumber += 1;
    const filter: string = this.selectedGroup
      ? 'main_category_type==' + this.selectedGroup.type
      : '';
    this.notificationService
      .apiNotificationsGet(filter, '', this.pageNumber, this.pageSize)
      .subscribe((res) => {
        if (res.success) {
          let notifyList = res.data.collection;
          notifyList.forEach((item, index) => {
            let indexOfGroup = this.groupNotification.findIndex(
              (x) => x.type == item.main_category_type
            );
            item.icon = this.groupNotification[
              indexOfGroup
            ].sub_categories.find(
              (x) => x.type == item.sub_category_type
            )?.icon;
            this.convertTimeInNotify(item);
          });
          this.notify = [...this.notify.concat(notifyList)];
          this.notify$.next(this.notify);
        }
      });
  }
  deleteNotify(notifyId, index, event) {
    event.stopPropagation();
    this.notificationService
      .apiNotificationsIdDelete(notifyId)
      .subscribe((res) => {
        if (res.success) {
          //this.toastr.success('Đã xóa thành công', '');
          this.notify.splice(index, 1);
          this.notify$.next(this.notify);
        } else {
          this.toastr.error('Xóa thất bại', '', {
            timeOut: 3000,
          });
        }
      });
  }

  readNotify(notifyId, index, event, isCheckMarkRead) {
    event.stopPropagation();
    this.notificationService
      .apiNotificationsIdReadPut(notifyId)
      .subscribe((res) => {
        if (res.success) {
          this.notify[index].read = res.data.read;
          this.notify[index].expired = res.data.expired;
          if (this.notify[index].expired != null) {
            this.notify[index].expired = moment().diff(
              this.notify[index].expired,
              'minutes'
            );
            this.notify[index].expired = Math.abs(this.notify[index].expired);
          }
          //this.notify[index].expired = this.notify[index].expired ? moment().diff(this.notify[index].expired, 'minutes') : null;
          if (isCheckMarkRead && this.notify[index].reference_link) {
            this.redirectTo(this.notify[index].reference_link);
          }
        }
      });
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  unreadNotify(notifyId, index, event) {
    event.stopPropagation();
    this.notificationService
      .apiNotificationsIdUnReadPut(notifyId)
      .subscribe((res) => {
        if (res.success) {
          this.notify[index].read = res.data.read;
          this.notify[index].expired = res.data.expired;
        }
      });
  }
  cancelDeleteNotify(notifyId, index, event) {
    event.stopPropagation();
    this.notificationService
      .apiNotificationsIdCancelDeletePut(notifyId)
      .subscribe((res) => {
        if (res.success) {
          this.notify[index].expired = res.data.expired;
        }
      });
  }
  convertTimeInNotify(item) {
    if (item.expired != null) {
      item.expired = moment().diff(item.expired, 'minutes');
      item.expired = Math.abs(item.expired);
    }
    let timeFrom = moment().diff(item.created_at, 'minutes');
    switch (true) {
      case timeFrom < 60:
        item.created_at = timeFrom + ' phút trước';
        break;
      case timeFrom > 60 && timeFrom < 1440:
        item.created_at =
          moment().diff(item.created_at, 'hours') + ' giờ trước';
        break;
      case timeFrom > 1440 && timeFrom < 4320:
        item.created_at =
          moment().diff(item.created_at, 'days') + ' ngày trước';
        break;
      case timeFrom > 4320:
        item.created_at = moment.utc(item.created_at).format('DD-MM-YYYY');
        break;
      default:
        break;
    }
  }
}
