import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatePipe} from './pipe/date.pipe';
import {GenderPipe} from './pipe/gender.pipe';
import {FormErrorService} from './services/form-error.service';
import {FullNameService} from './services/full-name.service';
import {Title} from '@angular/platform-browser';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {MY_FORMATS} from '@app/views/shared/constants/constants';
import {InputComponent} from './common_elements/input/input.component';
import {DropdownComponent} from './common_elements/dropdown/dropdown.component';
import {DatepickerComponent} from './common_elements/datepicker/datepicker.component';
import {MaterialModule} from 'mat-modules';
import {ReactiveFormsModule} from '@angular/forms';
import {TreegridComponent} from './common_elements/treegrid/treegrid.component';
import {TreeGridModule} from '@syncfusion/ej2-angular-treegrid';
import {TreegridAddDialogComponent} from './common_elements/treegrid/treegrid-add-dialog/treegrid-add-dialog.component';

@NgModule({
  declarations: [
    DatePipe,
    GenderPipe,
    InputComponent,
    DropdownComponent,
    DatepickerComponent,
    TreegridComponent,
    TreegridAddDialogComponent,
  ],
  imports: [CommonModule, MaterialModule, ReactiveFormsModule, TreeGridModule],
  providers: [
    FormErrorService,
    FullNameService,
    Title,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  exports: [
    DatePipe,
    GenderPipe,
    InputComponent,
    DropdownComponent,
    DatepickerComponent,
    TreegridComponent,
  ],
})
export class SharedModule {}
