<div class="login-page">
  <div class="kt-login__body mr-40px mb-16px screen-fit login-form">
    <div class="kt-login__form animated animatedFadeInUp fadeInUp">
      <div class="kt-login__title">
        <h3 class="frm-title">Thiết lập mật khẩu mới</h3>
      </div>
    </div>
    <form class="kt-form" [formGroup]="resetPassForm" autocomplete="off">
      <div class="form-group">
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Mật khẩu mới</mat-label>
          <input
            class="input-login"
            [type]="showNewPass ? 'text' : 'password'"
            matInput
            name="password"
            id="password"
            autocomplete="new-password"
            placeholder="Mật khẩu mới"
            formControlName="password"
          />

          <mat-error *ngIf="isControlHasError('password', 'required')">
            <strong>Mật khẩu mới là trường bắt buộc điền</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'minlength')">
            <strong>Tối thiểu 8 ký tự</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'pattern')">
            <strong
              >Mật khẩu gồm ít nhất 1 chữ số hoặc kí tự đặc biệt @ $ ! # % * ?
              &</strong
            >
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'maxlength')">
            <strong>Tối đa 100 ký tự</strong>
          </mat-error>
          <mat-icon
            matSuffix
            (click)="showNewPass = !showNewPass"
            *ngIf="!showNewPass"
            class="icon-input"
            matTooltip="Hiển thị mật khẩu"
          >
            visibility
          </mat-icon>
          <mat-icon
            matSuffix
            (click)="showNewPass = !showNewPass"
            *ngIf="showNewPass"
            matTooltip="Ẩn mật khẩu"
            class="icon-input"
          >
            visibility_off</mat-icon
          >
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Xác nhận mật khẩu</mat-label>
          <input
            class="input-login"
            [type]="showConfirmPass ? 'text' : 'password'"
            matInput
            name="showConfirmPass"
            id="showConfirmPass"
            autocomplete="new-password"
            placeholder="Xác nhận mật khẩu"
            formControlName="confirmPassword"
          />

          <mat-error *ngIf="isControlHasError('confirmPassword', 'required')">
            <strong>Xác nhận mật khẩu là trường bắt buộc</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('confirmPassword', 'minlength')">
            <strong>Tối thiểu 8 ký tự</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('confirmPassword', 'maxlength')">
            <strong>Tối đa 100 ký tự</strong>
          </mat-error>
          <mat-error
            *ngIf="
              resetPassForm.get('confirmPassword').errors &&
              resetPassForm.get('confirmPassword').errors.ConfirmPassword
            "
          >
            <strong>Mật khẩu không trùng khớp</strong>
          </mat-error>
          <mat-icon
            matSuffix
            (click)="showConfirmPass = !showConfirmPass"
            *ngIf="!showConfirmPass"
            class="icon-input"
            matTooltip="Hiển thị mật khẩu"
          >
            visibility
          </mat-icon>
          <mat-icon
            matSuffix
            (click)="showConfirmPass = !showConfirmPass"
            *ngIf="showConfirmPass"
            matTooltip="Ẩn mật khẩu"
            class="icon-input"
          >
            visibility_off</mat-icon
          >
        </mat-form-field>
      </div>
      <div class="mb-16px">
        <button
          mat-flat-button
          color="primary"
          class="btn-login fs-16px"
          (click)="setPassword()"
          [ngClass]="{
            'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
              loading
          }"
        >
          Cập nhật
        </button>
      </div>
    </form>
  </div>
</div>

<!-- <div class="kt-login__body">

  <div class="kt-login__form">
    <div class="kt-login__title">
      <h3>Thiết lập mật khẩu mới</h3>
    </div>

    <form
      class="kt-login__form kt-form"
      [formGroup]="resetPassForm"
      autocomplete="off"
    >
      <div class="form-group">
        <mat-form-field>
          <mat-label>Mật khẩu mới</mat-label>
          <input
            matInput
            type="password"
            placeholder="Mật khẩu mới"
            formControlName="password"
            autocomplete="new-password"
          />
          <mat-error *ngIf="isControlHasError('password', 'required')">
            <strong>Mật khẩu mới là trường bắt buộc điền</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'minlength')">
            <strong>Tối thiểu 3 ký tự</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('password', 'maxlength')">
            <strong>Tối đa 100 ký tự</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Xác nhận mật khẩu</mat-label>
          <input
            matInput
            type="password"
            placeholder="Xác nhận mật khẩu"
            formControlName="confirmPassword"
            autocomplete="new-password"
          />
          <mat-error *ngIf="isControlHasError('confirmPassword', 'required')">
            <strong>Xác nhận mật khẩu là trường bắt buộc</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('confirmPassword', 'minlength')">
            <strong>Tối thiểu 3 ký tự</strong>
          </mat-error>
          <mat-error *ngIf="isControlHasError('confirmPassword', 'maxlength')">
            <strong>Tối đa 100 ký tự</strong>
          </mat-error>
          <mat-error
            *ngIf="
              resetPassForm.get('confirmPassword').errors &&
              resetPassForm.get('confirmPassword').errors.ConfirmPassword
            "
          >
            <strong>Mật khẩu không trùng khớp</strong>
          </mat-error>
        </mat-form-field>
      </div>


      <div class="kt-login__actions kt-setPass__actions">

        <button
          id="kt_login_signin_submit"
          (click)="setPassword()"
          class="btn btn-primary btn-elevate kt-login__btn-primary"
          [ngClass]="{
            'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading
          }"
        >
          Cập nhật
        </button>
      </div>
    </form>
  </div>
</div> -->
