import {Component, OnInit, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {LayoutUtilsService} from '@app/core/_base/crud';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {SubHeaderButtonService} from '@app/services/sub-header-button.service';
import {SubheaderService} from '@app/core/_base/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormControl} from '@angular/forms';
import {PRoleService} from '@app/api/api';
import {environment} from '@app/../environments/environment';
import {AuthenticateService} from '@apiservice/authenticate.service';

@Component({
  selector: 'meu-role-user',
  templateUrl: './role-user.component.html',
  styleUrls: ['./role-user.component.scss'],
})
export class RoleUserComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'index',
    'name',
    'code',
    'position',
    'department',
    'action',
  ];
  roleId;
  private subscriptions: Subscription[] = [];

  listUser = [];
  roleUserForm = new FormGroup({
    role_id: new FormControl(),
    user_id: new FormControl(),
  });
  total = 0;
  pageIndex = 1;
  pagesize = 10;
  avatar = './assets/media/users/default.jpg';
  myID;

  constructor(
    private layoutUtilsService: LayoutUtilsService,
    private toastr: ToastrService,
    private subHeaderButtonService: SubHeaderButtonService,
    private changeDetectorRef: ChangeDetectorRef,
    private subheaderService: SubheaderService,
    private activatedRoute: ActivatedRoute,
    private pRoleService: PRoleService,
    private authenticateService: AuthenticateService
  ) {}

  ngOnInit() {
    this.subheaderService.url$.next({
      url: '/portal/hr/setting/permission/role-management',
    });
    this.getMyID();
    if (
      this.activatedRoute.snapshot.params.roleId !== undefined &&
      this.activatedRoute.snapshot.params.roleId !== null
    ) {
      this.roleId = this.activatedRoute.snapshot.params.roleId;
      this.getListUser();
      this.pRoleService.apiPRolesIdGet(this.roleId).subscribe((res) => {
        this.subheaderService.title$.next({
          title: '',
          desc: 'Quản lý phân quyền người dùng - ' + res.data.name,
        });
      });
    }
  }

  getMyID() {
    this.authenticateService.apiAuthenticateGetMyInfoGet().subscribe((res) => {
      if (res.success) {
        this.myID = res.user.id;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
    this.subheaderService.url$.next({url: null});
    this.subheaderService.title$.next({title: null, desc: null});
  }

  getListUser() {
    this.pRoleService
      .apiPRolesRoleIdUsersGet(
        this.roleId,
        '',
        '',
        this.pageIndex,
        this.pagesize
      )
      .subscribe((res) => {
        if (res.success) {
          this.listUser = res.data.collection;
          this.total = res.data.total;
          this.pageIndex = res.data.pageIndex;
          for (let i = 0; i < res.data.collection.length; i++) {
            if (
              res.data.collection[i].extend_user_avartar == null ||
              res.data.collection[i].extend_user_avartar == undefined
            ) {
              this.listUser[i].avatar = this.avatar;
            } else {
              this.listUser[i].avatar =
                environment.backendhost +
                res.data.collection[i].extend_user_avartar;
            }
          }
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  addUser() {
    if (this.roleUserForm.value.user_id == null) {
      this.toastr.info('Vui lòng lựa chọn nhân viên', 'Thông báo');
      return;
    }

    let indexOfItem = this.listUser
      .map(function (x) {
        return x.user_id;
      })
      .indexOf(this.roleUserForm.value.user_id);

    if (indexOfItem != -1) {
      this.toastr.warning('Nhân viên này đã được thêm', 'Cảnh báo');
      return;
    }
    this.roleUserForm.value.role_id = this.roleId;

    this.pRoleService
      .apiPRolesRoleIdUsersPost(this.roleId, this.roleUserForm.value)
      .subscribe((res) => {
        if (res.success) {
          this.getListUser();
          this.toastr.success(
            'Thêm nhân viên thành công',
            'Thao tác thành công'
          );
        } else {
          this.toastr.error('Thao tác thêm mới thất bại', '');
        }
      });
  }

  paginatorChange(event) {
    this.pagesize = event.pageSize;
    this.pageIndex = event.pageIndex + 1;
    this.getListUser();
  }

  removeUser(id) {
    const title = 'Xóa nhân viên';
    const description = 'Bạn có chắc chắn muốn xóa?';
    const waitDesciption = 'Đang xử lý...';

    const dialogRef = this.layoutUtilsService.deleteElement(
      title,
      description,
      waitDesciption
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.pRoleService
        .apiPRolesRoleIdUsersIdDelete(this.roleId, id)
        .subscribe((result) => {
          if (result.success) {
            this.getListUser();
            this.toastr.success(
              'Thao tác xóa nhân viên thành công',
              'Thao tác thành công'
            );
          } else {
            this.toastr.error(
              'Thao tác xóa nhân viên không thành công',
              'Thao tác không thành công',
              {
                timeOut: 3000,
              }
            );
          }
        });
    });
  }
}
