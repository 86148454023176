<!--begin::Body-->
<div class="register-body">
  <!--begin::Signin-->
  <div class="kt-login__form">
    <div class="register-stepper-container">
      <div *ngIf="!is_modal" class="text-left register-stepper-login fs-14px">
        Đã có tài khoản?
        <a routerLink="/auth/login" class="login-btn">Đăng nhập</a>
      </div>
      <div class="register-stepper-title">ĐĂNG KÝ</div>
      <mat-horizontal-stepper
        labelPosition="bottom"
        #stepper
        color="blue"
        class="register-stepper"
      >
        <ng-template matStepperIcon="edit" let-index="index">
          {{ index + 1 }}
        </ng-template>
        <mat-step [stepControl]="firstFormGroup">
          <div class="stepper-tab-body">
            <form [formGroup]="firstFormGroup" class="mb-4">
              <ng-template matStepLabel>Thông tin cá nhân</ng-template>
              <div class="row">
                <div class="register-input mt-3 col col-lg-3 col-md-6 col-6">
                  <meu-input
                    [name]="'first_name'"
                    [title]="'Họ'"
                    [placeholderText]="'Nhập họ'"
                    [formControl]="firstFormGroup.get('first_name')"
                    [invalidState]="
                      firstFormGroup.get('first_name').invalid &&
                      (firstFormGroup.get('first_name').dirty ||
                        firstFormGroup.get('first_name').touched)
                    "
                    [isRequiredField]="true"
                    ngDefaultControl
                  >
                    <ng-container errorMessage>
                      <div class="flex items-center">
                        <div class="input-error-label">Họ là bắt buộc nhập</div>
                      </div>
                    </ng-container>
                  </meu-input>
                </div>
                <div class="register-input mt-3 col col-lg-3 col-md-6 col-6">
                  <meu-input
                    [name]="'middle_name'"
                    [title]="'Tên lót'"
                    [placeholderText]="'Nhập tên lót'"
                    [formControl]="firstFormGroup.get('middle_name')"
                    [invalidState]="
                      firstFormGroup.get('middle_name').invalid &&
                      (firstFormGroup.get('middle_name').dirty ||
                        firstFormGroup.get('middle_name').touched)
                    "
                    ngDefaultControl
                  >
                  </meu-input>
                </div>
                <div class="register-input mt-3 col col-lg-3 col-md-6 col-6">
                  <meu-input
                    [name]="'last_name'"
                    [title]="'Tên'"
                    [placeholderText]="'Nhập tên'"
                    [formControl]="firstFormGroup.get('last_name')"
                    [invalidState]="
                      firstFormGroup.get('last_name').invalid &&
                      (firstFormGroup.get('last_name').dirty ||
                        firstFormGroup.get('last_name').touched)
                    "
                    [isRequiredField]="true"
                    ngDefaultControl
                  >
                    <ng-container errorMessage>
                      <div class="flex items-center">
                        <div class="input-error-label">
                          Tên là bắt buộc nhập
                        </div>
                      </div>
                    </ng-container>
                  </meu-input>
                </div>
                <div
                  class="register-input custom-select-control mt-3 col col-lg-3 col-md-6 col-6"
                >
                  <label for="education_level_id" class="input-label"
                    >Giới tính
                    <span class="required-input-label">*</span></label
                  >
                  <meu-control-select-search
                    [placeholderLabel]="'Giới tính'"
                    [noEntriesFoundLabel]="'Không có loại phù hợp'"
                    [arrayInput]="lstGender"
                    [formControl]="firstFormGroup.get('gender')"
                    [floatLabel]="false"
                    [allowUnSelect]="false"
                  >
                  </meu-control-select-search>
                  <div
                    *ngIf="
                      firstFormGroup.get('gender').invalid &&
                      (firstFormGroup.get('gender').dirty ||
                        firstFormGroup.get('gender').touched) &&
                      !firstLoading
                    "
                    class="input-error-label"
                  >
                    Giới tính là bắt buộc nhập
                  </div>
                </div>
                <div class="register-input mt-3 col col-lg-3 col-md-6 col-6">
                  <meu-datepicker
                    [name]="'birthday'"
                    [title]="'Ngày sinh'"
                    [placeholderText]="'Chọn ngày'"
                    [formControl]="firstFormGroup.get('birthday')"
                    [invalidState]="
                      firstFormGroup.get('birthday').invalid &&
                      (firstFormGroup.get('birthday').dirty ||
                        firstFormGroup.get('birthday').touched)
                    "
                    ngDefaultControl
                  >
                    <ng-container errorMessage>
                      <div class="flex items-center">
                        <div class="input-error-label">
                          Ngày sinh là bắt buộc nhập
                        </div>
                      </div>
                    </ng-container>
                  </meu-datepicker>
                </div>
                <div class="register-input mt-3 col col-lg-3 col-md-6 col-6">
                  <meu-input
                    [name]="'cell_phone'"
                    [title]="'Số điện thoại'"
                    [placeholderText]="'Nhập số điện thoại'"
                    [formControl]="firstFormGroup.get('cell_phone')"
                    [invalidState]="
                      firstFormGroup.get('cell_phone').invalid &&
                      (firstFormGroup.get('cell_phone').dirty ||
                        firstFormGroup.get('cell_phone').touched)
                    "
                    [isRequiredField]="true"
                    ngDefaultControl
                  >
                    <ng-container errorMessage>
                      <div class="flex items-center">
                        <div class="input-error-label">
                          SĐT là bắt buộc nhập (dài từ 10 đến 12 số bắt đầu bằng
                          0)
                        </div>
                      </div>
                    </ng-container>
                  </meu-input>
                </div>
                <div class="register-input mt-3 col col-lg-3 col-md-6 col-12">
                  <meu-input
                    [name]="'email'"
                    [title]="'Email'"
                    placeholderText="Nhập email"
                    [formControl]="firstFormGroup.get('email')"
                    [invalidState]="
                      firstFormGroup.get('email').invalid &&
                      (firstFormGroup.get('email').dirty ||
                        firstFormGroup.get('email').touched)
                    "
                    [isRequiredField]="true"
                    ngDefaultControl
                  >
                    <ng-container errorMessage>
                      <div class="flex items-center">
                        <div class="input-error-label">
                          Email là bắt buộc nhập
                        </div>
                      </div>
                    </ng-container>
                  </meu-input>
                </div>
                <div class="register-input mt-3 col col-lg-3 col-md-6 col-12">
                  <meu-input
                    [name]="'id_card_number'"
                    [title]="'Số CMND/CCCD/Hộ chiếu'"
                    [placeholderText]="'Nhập CMND/CCCD/Hộ chiếu'"
                    [formControl]="firstFormGroup.get('id_card_number')"
                    [invalidState]="
                      firstFormGroup.get('id_card_number').invalid &&
                      (firstFormGroup.get('id_card_number').dirty ||
                        firstFormGroup.get('id_card_number').touched)
                    "
                    [isRequiredField]="is_modal ? false : true"
                    ngDefaultControl
                    [maxlength]="12"
                  >
                    <ng-container errorMessage>
                      <div class="flex items-center">
                        <div class="input-error-label">
                          Số CMND/CCCD/Hộ chiếu là bắt buộc nhập(Tối đa 12 ký
                          tự)
                        </div>
                      </div>
                    </ng-container>
                  </meu-input>
                </div>
                <div
                  class="register-input custom-select-control mt-3 col col-lg-3 col-md-6 col-12"
                >
                  <label for="company_id" class="input-label"
                    >Đơn vị công tác
                    <span *ngIf="!is_modal" class="required-input-label"
                      >*</span
                    ></label
                  >
                  <meu-control-select-search-load-more
                    [placeholderLabel]="'Chọn đơn vị công tác'"
                    [noEntriesFoundLabel]="'Không có lựa chọn phù hợp'"
                    [columnName]="'name'"
                    [nameAPI]="'/api/companies'"
                    [stringFilter]="'is_draft!=true,is_approve!=false'"
                    [multiple]="false"
                    [formControl]="firstFormGroup.get('company_id')"
                    [defaultID]="firstFormGroup.value.company_id"
                    [floatLabel]="false"
                    [allowUnSelect]="false"
                    [isAdd]="true"
                    (onOpenAddNewRowModal)="
                      onOpenAddNewRowInDropdownModal($event)
                    "
                  >
                  </meu-control-select-search-load-more>
                  <div
                    *ngIf="
                      firstFormGroup.get('company_id').invalid &&
                      (firstFormGroup.get('company_id').dirty ||
                        firstFormGroup.get('company_id').touched) &&
                      !firstLoading
                    "
                    class="input-error-label"
                  >
                    Đơn vị công tác là bắt buộc nhập
                  </div>
                </div>
                <div class="register-input mt-3 col col-lg-3 col-md-6 col-12">
                  <meu-input
                    [name]="'position'"
                    [title]="'Chức vụ'"
                    [placeholderText]="'Nhân viên, kỹ thuật viên...'"
                    [formControl]="firstFormGroup.get('position')"
                    [invalidState]="
                      firstFormGroup.get('position').invalid &&
                      (firstFormGroup.get('position').dirty ||
                        firstFormGroup.get('position').touched)
                    "
                    [isRequiredField]="is_modal ? false : true"
                    ngDefaultControl
                  >
                    <ng-container errorMessage>
                      <div class="flex items-center">
                        <div class="input-error-label">
                          Chức vụ là bắt buộc nhập
                        </div>
                      </div>
                    </ng-container>
                  </meu-input>
                </div>
                <div class="register-input mt-3 col col-lg-3 col-md-6 col-12">
                  <meu-input
                    [name]="'tax'"
                    [title]="'Mã số thuế cá nhân'"
                    [placeholderText]="'Nhập mã số thuế cá nhân'"
                    [formControl]="firstFormGroup.get('tax')"
                    [invalidState]="
                      firstFormGroup.get('tax').invalid &&
                      (firstFormGroup.get('tax').dirty ||
                        firstFormGroup.get('tax').touched)
                    "
                    [isRequiredField]="false"
                    ngDefaultControl
                  >
                    <ng-container errorMessage>
                      <div class="flex items-center">
                        <div class="input-error-label">
                          Mã số thuế không hợp lệ
                        </div>
                      </div>
                    </ng-container>
                  </meu-input>
                </div>
              </div>
            </form>
            <div class="d-flex">
              <div class="col-6">
                <button
                  mat-stroked-button
                  class="meu-primary-button mb-2"
                  color="primary"
                  (click)="goBack()"
                >
                  Đóng
                </button>
              </div>
              <div class="stepper-footer mb-2 col-6">
                <button
                  mat-flat-button
                  (click)="registerStep1()"
                  class="meu-primary-button"
                  color="primary"
                >
                  Tiếp theo
                </button>
              </div>
            </div>
          </div>
        </mat-step>
        <mat-step>
          <div class="stepper-tab-body">
            <ng-template matStepLabel>Quá trình đào tạo</ng-template>
            <div class="d-flex">
              <span class="label step-2-label">Quá trình đào tạo</span>
              <button mat-button class="step-2-button" (click)="createDegree()">
                <mat-icon class="button-icon">add_circle</mat-icon>
                <span class="button-text label">Thêm bậc đào tạo</span>
              </button>
            </div>
            <div class="row padding-top-8 table-mobile example-container">
              <mat-table class="full-width" #table [dataSource]="lstDegree">
                <ng-container matColumnDef="extend_user_education_level_name">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell
                    *matCellDef="let element; let i = index"
                    class="mobile-label cell-element mobile-label no-padding"
                  >
                    <div
                      class="register-input custom-select-control mt-3 w-100"
                    >
                      <label for="education_level_id" class="input-label"
                        >Bậc đào tạo
                        <span class="required-input-label">*</span></label
                      >
                      <meu-control-select-search
                        [arrayInput]="filteredEducationLevelList"
                        [placeholderLabel]="'Chọn bậc'"
                        [noEntriesFoundLabel]="'Không có lựa chọn phù hợp'"
                        [floatLabel]="false"
                        [allowUnSelect]="false"
                        [(ngModel)]="arrListEducationLevelId[i]"
                        [ngModelOptions]="{standalone: true}"
                        (change)="onChangeEducationLevel($event, i)"
                      >
                      </meu-control-select-search>
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="user_academic_rank_name">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell
                    *matCellDef="let element; let i = index"
                    class="mobile-label cell-element mobile-label no-padding"
                  >
                    <div
                      class="register-input custom-select-control mt-3 w-100"
                    >
                      <label for="education_level_id" class="input-label"
                        >Học hàm
                      </label>
                      <meu-control-select-search
                        [arrayInput]="filteredAcademicRank[i]"
                        [placeholderLabel]="'Chọn học hàm'"
                        [noEntriesFoundLabel]="'Không có lựa chọn phù hợp'"
                        [floatLabel]="false"
                        [allowUnSelect]="true"
                        [(ngModel)]="arrListUserAcademicRankId[i]"
                        [ngModelOptions]="{standalone: true}"
                        (change)="onChangeAcademicRank($event, i)"
                      >
                      </meu-control-select-search>
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="extend_user_degree_name">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell
                    *matCellDef="let element; let i = index"
                    class="mobile-label cell-element mobile-label no-padding"
                  >
                    <div
                      class="register-input custom-select-control mt-3 w-100"
                    >
                      <label for="education_level_id" class="input-label"
                        >Học vị
                        <span class="required-input-label">*</span></label
                      >
                      <meu-control-select-search
                        [arrayInput]="filteredDegreeList[i]"
                        [placeholderLabel]="'Chọn học vị'"
                        [noEntriesFoundLabel]="'Không có lựa chọn phù hợp'"
                        [floatLabel]="false"
                        [allowUnSelect]="false"
                        [(ngModel)]="arrListUserDegreeId[i]"
                        [ngModelOptions]="{standalone: true}"
                        (change)="onChangeDegree($event, i)"
                      >
                      </meu-control-select-search>
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="training_place">
                  <mat-header-cell *matHeaderCellDef
                    >Nơi đào tạo</mat-header-cell
                  >
                  <mat-cell
                    *matCellDef="let element; let i = index"
                    class="mobile-label cell-element mobile-label no-padding"
                  >
                    <div class="register-input mt-3 w-100">
                      <meu-input
                        [title]="'Nơi đào tạo'"
                        [placeholderText]="'Nhập nơi đào tạo'"
                        [(ngModel)]="arrListTrainingPlace[i]"
                        [ngModelOptions]="{standalone: true}"
                        ngDefaultControl
                      >
                      </meu-input>
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell
                    *matCellDef="let element; let i = index"
                    class="mobile-label cell-element mobile-label no-padding"
                  >
                    <div
                      class="register-input mt-3 w-100"
                      (click)="onSelectSpeciality(i)"
                    >
                      <label class="font-roboto-medium"
                        >Chuyên môn <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        placeholder="Chọn chuyên môn"
                        [(ngModel)]="arrListName[i]"
                        class="input-speciality"
                        [matTooltip]="arrListName[i]"
                        readonly
                      />
                    </div>
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="finish_date">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell
                    *matCellDef="let element; let i = index"
                    class="mobile-label cell-element mobile-label no-padding"
                  >
                    <div class="register-input mt-3 w-100 row">
                      <meu-input
                        class="col-10"
                        type="number"
                        [title]="'Năm tốt nghiệp'"
                        [placeholderText]="'Nhập năm tốt nghiệp'"
                        [(ngModel)]="arrListFinishDate[i]"
                        [ngModelOptions]="{standalone: true}"
                        ngDefaultControl
                      >
                      </meu-input>
                      <button
                        class="col-2 delete-btn"
                        mat-icon-button
                        (click)="deleteDegree(i)"
                      >
                        <mat-icon class="icon-btn">delete</mat-icon>
                      </button>
                    </div>
                  </mat-cell>
                </ng-container>
                <mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></mat-row>
              </mat-table>
            </div>
            <div class="d-flex">
              <div class="col-6 mt-4 mb-2">
                <button
                  mat-stroked-button
                  matStepperPrevious
                  class="meu-primary-button"
                  color="primary"
                >
                  Quay lại
                </button>
              </div>
              <div class="stepper-footer mt-4 mb-2 col-6">
                <button
                  mat-flat-button
                  (click)="registerStep3()"
                  class="meu-primary-button"
                  color="primary"
                >
                  {{ is_modal ? 'Hoàn thành' : 'Tiếp theo' }}
                </button>
              </div>
            </div>
          </div>
        </mat-step>
        <mat-step *ngIf="!is_modal">
          <div class="stepper-tab-body">
            <ng-template matStepLabel>Thiết lập mật khẩu</ng-template>
            <div class="flex row">
              <div
                class="col-12 flex flex-column step-4-box col col-lg-4 col-md-4"
              >
                <div class="register-input mt-3">
                  <meu-input
                    [name]="'password'"
                    [title]="'Mật khẩu'"
                    placeholder="Nhập mật khẩu"
                    [formControl]="fouthFormGroup.get('password')"
                    [type]="showPass ? 'text' : 'password'"
                    (change)="
                      fouthFormGroup.get('repeat_password').setValue('')
                    "
                    [invalidState]="
                      fouthFormGroup.get('password').invalid &&
                      (fouthFormGroup.get('password').dirty ||
                        fouthFormGroup.get('password').touched)
                    "
                    ngDefaultControl
                  >
                    <ng-container errorMessage>
                      <div class="flex items-center">
                        <div class="input-error-label">
                          Mật khẩu là bắt buộc nhập, 8 kí tự trở lên, có chữ số,
                          chữ in hoa, chữ thường và ký tự đặc biệt
                        </div>
                      </div>
                    </ng-container>
                  </meu-input>
                  <mat-icon
                    matSuffix
                    (click)="showPass = !showPass"
                    *ngIf="!showPass"
                    class="icon-input"
                    matTooltip="Hiển thị mật khẩu"
                  >
                    visibility
                  </mat-icon>
                  <mat-icon
                    matSuffix
                    (click)="showPass = !showPass"
                    *ngIf="showPass"
                    class="icon-input"
                    matTooltip="Ẩn mật khẩu"
                  >
                    visibility_off</mat-icon
                  >
                </div>
                <div class="w-100 mt-3">
                  <label class="input-label"
                    >Xác nhận mật khẩu
                    <span class="required-input-label"> *</span></label
                  >
                  <div
                    class="input-box"
                    [ngClass]="
                      fouthFormGroup.get('repeat_password').invalid &&
                      (fouthFormGroup.get('repeat_password').dirty ||
                        fouthFormGroup.get('repeat_password').touched)
                        ? 'error-input'
                        : ''
                    "
                  >
                    <input
                      [type]="showPassConfirm ? 'text' : 'password'"
                      [formControl]="fouthFormGroup.get('repeat_password')"
                      matInput
                      name="showConfirmPass"
                      id="showConfirmPass"
                      autocomplete="new-password"
                      placeholder="Xác nhận mật khẩu"
                    />
                    <mat-icon
                      matSuffix
                      (click)="showPassConfirm = !showPassConfirm"
                      *ngIf="!showPassConfirm"
                      class="icon-confirm-input"
                      matTooltip="Hiển thị mật khẩu"
                    >
                      visibility
                    </mat-icon>
                    <mat-icon
                      matSuffix
                      (click)="showPassConfirm = !showPassConfirm"
                      *ngIf="showPassConfirm"
                      matTooltip="Ẩn mật khẩu"
                      class="icon-confirm-input"
                    >
                      visibility_off</mat-icon
                    >
                  </div>
                </div>
                <div
                  class="flex items-center"
                  *ngIf="
                    fouthFormGroup.get('repeat_password').invalid &&
                    (fouthFormGroup.get('repeat_password').dirty ||
                      fouthFormGroup.get('repeat_password').touched)
                  "
                >
                  <div class="input-error-label">
                    Xác nhận mật khẩu không hợp lệ
                  </div>
                </div>
              </div>
              <img class="img-password" src="assets\media\Image\blue-cog.png" />
            </div>
            <div class="d-flex">
              <div class="col-6 mt-4 mb-2">
                <button
                  mat-stroked-button
                  matStepperPrevious
                  class="meu-primary-button"
                  color="primary"
                >
                  Quay lại
                </button>
              </div>
              <div class="col-6 stepper-footer mt-4 mb-2">
                <button
                  mat-flat-button
                  (click)="registerStep4()"
                  class="meu-primary-button"
                  color="primary"
                >
                  Hoàn thành
                </button>
              </div>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>
