<div class="kt-portlet">
  <div class="kt-portlet__head kt-portlet__head__custom">
    <div class="kt-portlet__head-label">
      <h3 class="kt-portlet__head-title">{{ Title_Modal }}</h3>
    </div>
  </div>
  <form class="kt-form">
    <div class="kt-portlet__body content-body">
      <div [className]="classes.actions">
        <span [className]="classes.flex"></span>
        <!-- Hidden input -->
        <input
          #_fileInput
          type="file"
          (change)="cropping.selectInputEvent($event)"
          accept="image/*"
          hidden
        />
      </div>

      <div class="btn-image">
        <button
          (click)="_fileInput.click()"
          ly-button
          appearance="icon"
          matTooltip="Ảnh mới"
        >
          <ly-icon>portrait</ly-icon>
        </button>
        <button
          (click)="cropping.zoomIn()"
          ly-button
          appearance="icon"
          matTooltip="Phóng to"
        >
          <ly-icon>zoom_in</ly-icon>
        </button>
        <button
          (click)="cropping.zoomOut()"
          ly-button
          appearance="icon"
          matTooltip="Thu nhỏ"
        >
          <ly-icon>zoom_out</ly-icon>
        </button>
        <button
          (click)="cropping.center()"
          ly-button
          appearance="icon"
          matTooltip="Trung tâm"
        >
          <ly-icon>filter_center_focus</ly-icon>
        </button>
        <button
          (click)="cropping.rotate(-90)"
          ly-button
          appearance="icon"
          matTooltip="Xoay"
        >
          <ly-icon>rotate_90_degrees_ccw</ly-icon>
        </button>
        <button
          (click)="cropping.fit()"
          ly-button
          appearance="icon"
          matTooltip="Fit"
        >
          <ly-icon>crop_free</ly-icon>
        </button>
        <button
          (click)="cropping.fitToScreen()"
          ly-button
          appearance="icon"
          matTooltip="Vừa màn hình"
        >
          <ly-icon>crop_original</ly-icon>
        </button>
        <button
          (click)="cropping.setScale(1)"
          ly-button
          appearance="icon"
          matTooltip="1:1"
        >
          <ly-icon>crop_square</ly-icon>
        </button>
        <button
          (click)="cropping.clean()"
          ly-button
          appearance="icon"
          matTooltip="Xóa"
        >
          <ly-icon>clear</ly-icon>
        </button>
      </div>

      <ly-img-cropper
        Class="image-area"
        [withClass]="classes.cropping"
        #cropping
        [config]="myConfig"
        [(scale)]="scale"
        (cropped)="onCropped($event)"
        (loaded)="onloaded($event)"
        (error)="onerror($event)"
      >
        <span>
          <img
            class="icon-download"
            [src]="urlImage + 'eva_download-fill.svg'"
          />
        </span>
        <span> <strong>Chọn tệp</strong> hoặc kéo thả tệp vào đây</span>
      </ly-img-cropper>

      <!-- <div [className]="classes.range">
        <mat-slider
          [min]="cropping.minScale"
          max="10"
          step="0.0001"
          [value]="scale"
          (input)="onInputSliderChange($event)"
        ></mat-slider>
      </div> -->
    </div>
    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit">
      <div class="kt-form__actions kt-form__actions--solid footer_dialog">
        <div class="row text-right">
          <div class="col-lg-12">
            <button
              mat-flat-button
              class="btn button btn-submit"
              (click)="onButtonClick()"
            >
              Lưu
            </button>
            <button
              mat-flat-button
              [mat-dialog-close]=""
              cdkFocusInitial
              class="btn button btn-close ml-2"
            >
              Hủy
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
