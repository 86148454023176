<div class="login-page">
  <div class="kt-login__body mr-40px mb-16px screen-fit login-form">
    <div class="kt-login__form animated animatedFadeInUp fadeInUp">
      <div class="kt-login__title">
        <h3 class="frm-title">Khôi phục tài khoản</h3>
      </div>

      <kt-auth-notice></kt-auth-notice>

      <form class="kt-form" [formGroup]="forgotPasswordForm" autocomplete="off">
        <div class="form-group">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>
              {{ 'AUTH.INPUT.EMAIL' | translate }}
            </mat-label>
            <input
              class="input-login"
              type="email"
              placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
              formControlName="email"
              matInput
              name="email"
              id="email"
              autocomplete="off"
            />
            <mat-error *ngIf="isControlHasError('email', 'required')">
              <strong>
                <!-- {{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }} -->
                Email là bắt buộc
              </strong>
            </mat-error>
            <mat-error *ngIf="isControlHasError('email', 'pattern')">
              <strong>
                <!-- {{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }} -->
                Email không hợp lệ
              </strong>
            </mat-error>

            <mat-error *ngIf="isControlHasError('email', 'minlength')">
              <strong>
                {{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3
              </strong>
            </mat-error>
            <mat-error *ngIf="isControlHasError('email', 'maxlength')">
              <strong>
                {{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320
              </strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="mb-16px">
          <button
            mat-flat-button
            color="primary"
            class="btn-request fs-16px"
            (click)="submit()"
          >
            Gửi yêu cầu
          </button>
        </div>
        <div class="mb-16px cnt-center">
          <a href="javascript:;" routerLink="/auth/login" class="fs-14px"
            >Quay lại</a
          >
        </div>
      </form>
    </div>
  </div>
</div>
