import { Component, OnInit, Input, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { AttachmentService } from '@app/api/attachment.service';
import { HttpHeaders, HttpEventType, HttpEvent } from '@angular/common/http';
import { Configuration } from '@app/configuration';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { CommonElementService } from '@app/views/common_elements/service/common-element.service'
import { Attachment } from '@app/model/attachment';
@Component({
  selector: 'meu-control-upload-file-dropzone',
  templateUrl: './control-upload-file-dropzone.component.html',
  styleUrls: ['./control-upload-file-dropzone.component.scss']
})
export class ControlUploadFileDropzoneComponent implements OnInit {
  @ViewChild('commonDropzone') commonDropzone: ElementRef<HTMLElement>;
  @Input() disabled: boolean;
  @Input() code: string;
  constructor(private attachmentService: AttachmentService, private toastr: ToastrService,
    private commonElementService: CommonElementService) { }
  files: any[] = [];
  filesOld: any;
  filesRemove: any = [];

  //download: boolean = false;
  disableClick: boolean = false;
  ngOnChanges(changes: SimpleChanges) {

    if (this.code != undefined && this.code != null || changes.code.previousValue != changes.code.currentValue) {
      this.getAttachmentByCode(this.code);
    }
    if (this.disabled == true) {
      this.disableClick = true;
    }
  }
  ngOnInit(): void {

  }
  onMouseOver() {
    this.disableClick = true;
  }
  onMouseOut() {
    if (this.disabled != true)
      this.disableClick = false;
  }
  getAttachmentByCode(code) {
    this.attachmentService.apiAttachmentsCodeGet(code).subscribe(res => {
      this.files = res.data.collection;

      this.filesOld = this.files.map((x) => x.id);

      // var arrRes = res.data.collection;

      // this.changeDetectorRef.detectChanges();

    })
  }
  downloadFile(id, url, file_name) {
    if (id != undefined) {
      url = url.replace('/api/attachments/download?url=', '');
      this.attachmentService.apiAttachmentsDownloadGet(
        url
      ).subscribe(res => {
        var downloadURL = URL.createObjectURL(res);
        if (file_name.includes('png') || file_name.includes('jpg') || file_name.includes('jpeg') || file_name.includes('pdf')) {

          window.open(downloadURL);
        }
        else {
          var blob = new Blob([res]);
          saveAs(blob, file_name);
        }


        // var blob = new Blob([res]);
        // saveAs(blob, file_name);
      })

    }

  }
  uploadFile(code) {
    if (this.filesRemove.length > 0) {
      for (var j = 0; j < this.filesRemove.length; j++) {
        this.attachmentService.apiAttachmentsIdDelete(this.filesRemove[j]).subscribe(res => {
        })
      }
    }
    for (var i = 0; i < this.files.length; i++) {
      if (this.files[i].id == undefined || this.filesOld.indexOf(this.files[i].id) < 0) {
        const attachment:Attachment = {
          'name': this.files[i].name,
          'code': code,
          'sub_code': i.toString(),
          'file_name': this.files[i].name,
          'file_size': this.files[i].size,
        }
        let file: File = this.files[i];
        var _this = this;
        this.attachmentService.apiAttachmentsPost(attachment).subscribe(res => {
          if (res.success == true) {
            this.attachmentService.apiAttachmentsIdUploadPut(res.data.id, file).subscribe(res => {
            })
          }
        })
      }
      else {
        continue;
      }

    }

  }
  onSelect(event) {
    this.files.push(...event.addedFiles);
  }
  onRemove(event) {
    if (this.disabled != true) {
      this.files.splice(this.files.indexOf(event), 1);
      if (event.id != undefined) {
        this.filesRemove.push(event.id)
      }
      this.onMouseOut();
    }

  }
  getIconFile(name) {
    return this.commonElementService.getIconClass(name);
  }
}
