import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  forwardRef,
  ChangeDetectorRef,
} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {environment} from '@app/../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Configuration} from '@app/configuration';
import * as _ from 'lodash';
import {CustomHttpUrlEncodingCodec} from '@app/encoder';

@Component({
  selector: 'meu-select-load-more-dynamic-dashboard',
  templateUrl: './select-load-more-dynamic-dashboard.component.html',
  styleUrls: ['./select-load-more-dynamic-dashboard.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectLoadMoreDynamicDashboardComponent),
      multi: true,
    },
  ],
})
export class SelectLoadMoreDynamicDashboardComponent implements OnInit {
  @Input() placeholderLabel: string;
  @Input() noEntriesFoundLabel: string;
  @Input() defaultID: any;
  @Input() nameAPI: string;
  @Input() required: boolean;
  @Input() multiple: boolean;
  @Input() stringFilter: string;
  @Input() columnName: string;
  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;
  @Output() change = new EventEmitter();
  @Output() changeItem = new EventEmitter();
  cacheSelectedItem = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.nameAPI != undefined) {
      this.cacheSelectedItem = [];
      this.fiteredInput = [];
      if (changes.stringFilter != undefined) {
        this.filters = this.stringFilter;
        this.runAPI(this.nameAPI);
      } else {
        this.runAPI(this.nameAPI);
      }
    }
    if (changes.page != undefined) {
      this.runAPI(this.nameAPI);
    }
  }
  ngOnInit() {
    this.selectCtrl.valueChanges.subscribe((res) => {
      this.emitChangeValue(this.selectCtrl.value);
      if (res != null && res != undefined) {
        let user = this.fiteredInput.find((x) => x.id == res);
        if (user != undefined) {
          this.cacheSelectedItem.push(user);
        } else {
          if (Array.isArray(res)) {
            for (let i = 0; i < res.length; i++) {
              this.getDataByID(res[i]);
            }
          } else {
            this.getDataByID(res);
          }
        }
      }
    });
  }

  fiteredInput: any[];
  selectCtrl: FormControl = new FormControl();
  searchControl: FormControl = new FormControl();
  page = 1;
  filters = null;
  totalPage = 0;
  private _value: any;

  ispagination = true;
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
    this.propagateChange(this._value);
  }
  search(event) {
    this.page = 1;
    this.fiteredInput = [];
    this.filters = null;
    if (event != '' || event != null) {
      if (this.stringFilter) {
        this.filters =
          this.stringFilter + ',' + this.columnName + '@=*' + event;
      } else {
        this.filters = this.columnName + '@=*' + event;
      }
    } else {
      if (this.stringFilter != null || this.stringFilter != '') {
        this.filters = this.stringFilter;
      }
    }
    this.runAPI(this.nameAPI);
  }
  loadMore(e) {
    this.page = this.page + 1;
    this.runAPI(this.nameAPI);
  }
  unselect(): void {
    this.defaultID = '';
  }
  runAPI(nameAPI) {
    if (nameAPI == null || nameAPI.trim() == '') {
      return;
    }
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (this.filters !== undefined && this.filters !== null) {
      queryParameters = queryParameters.set('Filters', <any>this.filters);
    }
    if (this.page !== undefined && this.page !== null) {
      queryParameters = queryParameters.set('Page', <any>this.page);
    }
    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    this.httpClient
      .request<any>('get', `${environment.backendhost + nameAPI}`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
      })
      .subscribe((res) => {
        let array = [];
        if (res.data.collection) {
          this.ispagination = true;
          array = [...res.data.collection];
        } else {
          this.ispagination = false;
          array = [...res.data];
        }
        this.totalPage = Math.ceil(res.data.total / 10);
        if (this.fiteredInput != []) {
          this.fiteredInput = [...array];
        } else {
          for (let i = 0; i < array.length; i++) {
            if (this.cacheSelectedItem != undefined) {
              let index = this.cacheSelectedItem.findIndex(
                (x) => x.id == array[i].id
              );
              if (index < 0) {
                // this.fiteredInput = [...this.fiteredInput, array[i]];
                this.fiteredInput.push(array[i]);
              }
            }
          }
        }
        this.ChangeDetectorRef.detectChanges();
        let index = null;
        try {
          if (this.fiteredInput.length % 10 == 0) {
            index = this.fiteredInput.length - 10;
          } else {
            index = Math.floor(this.fiteredInput.length / 10) * 10;
          }
        } catch (error) {}
        if (index != null && index != 0) {
          if (this.fiteredInput[index] !== undefined) {
            let elementID = this.fiteredInput[index].id;
            if (document.getElementById(elementID) != undefined) {
              setTimeout(function () {
                document.getElementById(elementID).scrollIntoView(false);
              }, 0.5);
            }
          }
        }
      });

    if (
      this.cacheSelectedItem != null &&
      this.cacheSelectedItem != undefined &&
      this.cacheSelectedItem.length > 0
    ) {
      for (let i = 0; i < this.cacheSelectedItem.length; i++) {
        let index = this.fiteredInput.findIndex(
          (x) => x.id == this.cacheSelectedItem[i].id
        );
        if (index < 0) {
          // this.fiteredInput = [...this.fiteredInput, this.cacheSelectedItem[i]];
          this.fiteredInput.push(this.cacheSelectedItem[i]);
        }
      }
    }
  }
  getDataByID(id) {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    let filter = ' id==' + id;
    queryParameters = queryParameters.set('Filters', <any>filter);
    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    this.httpClient
      .request<any>('get', `${environment.backendhost + this.nameAPI}`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
      })
      .subscribe((res) => {
        let array = [];
        if (res.data.collection) {
          array = res.data.collection;
        } else {
          array = res.data;
        }
        for (let i = 0; i < array.length; i++) {
          this.cacheSelectedItem.push(array[i]);
          let index = this.fiteredInput.findIndex((x) => x.id == array[i].id);
          if (index < 0) {
            // this.fiteredInput = [...this.fiteredInput, array[i]];
            this.fiteredInput.push(array[i]);
          }
        }
        this.ChangeDetectorRef.detectChanges();
      });
  }
  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  private propagateChange = (_: any) => {};
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  constructor(
    protected httpClient: HttpClient,
    private ChangeDetectorRef: ChangeDetectorRef
  ) {}

  emitChangeValue(event) {
    this.value = event;
    this.change.emit(this.value);
    let index = this.fiteredInput.findIndex((x) => x.id === this.value);
    this.changeItem.emit(this.fiteredInput[index]);
  }
}
