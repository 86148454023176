<mat-select
  [disabled]="disabled"
  [formControl]="selectCtrl"
  [(ngModel)]="defaultID"
  [multiple]="multiple"
  [required]="required"
  (change)="emitChangeValue($event)"
  [placeholder]="placeholderLabel"
  [ngClass]="invalid ? 'ng-invalid mat-form-field-invalid' : ''"
>
  <mat-option *ngIf="ispagination">
    <ngx-mat-select-search
      class="select"
      [placeholderLabel]="placeholderLabel"
      [noEntriesFoundLabel]="noEntriesFoundLabel"
      [formControl]="searchControl"
      (ngModelChange)="search($event)"
    >
    </ngx-mat-select-search>
  </mat-option>
  <mat-option (click)="unselect()" *ngIf="!multiple" [value]="null">-- Bỏ chọn --</mat-option>
  <mat-option
    [id]="item.id"
    *ngFor="let item of fiteredInput"
    [value]="item.id"
    >{{ item[columnName] }}</mat-option
  >
  <div id="testLoadMore" class="loadmore-select" *ngIf="page < totalPage && ispagination">
    <a href="javascript:void(0);" (click)="loadMore($event)">Xem thêm</a>
  </div>
</mat-select>
