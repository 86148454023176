<div class="wrapper font-roboto-medium">
  <div class="cover">
    <h1>HƯỚNG DẪN NỘP HỒ SƠ</h1>
    <h1>PHẦN MỀM QUẢN LÝ NHIỆM VỤ</h1>
    <img class="mt-2" src="assets/media/user-guide/cover.jpg" alt="cover" />
  </div>
  <div class="step-1">
    <div>
      Sau khi đăng nhập thành công vào hệ thống, người dùng sẽ tiến hành đăng ký
      để tài.
    </div>
    <div>Để gửi đề tài, người dùng cần thực hiện qua 3 giai đoạn sau:</div>
    <div class="mt-4" style="font-weight: 600">
      &diams; Giai đoạn 1 - Chọn Thủ tục cho đề tài
    </div>
    <div class="mt-4">- Chọn thủ tục như hình ảnh dưới đây:</div>
    <img src="assets/media/user-guide/nop-ho-so.png" alt="info" width="100%" />
    <div class="mt-4">
      Ngoài ra, người dùng còn có thể tìm kiếm thủ tục bằng cách nhập từ khóa
      vào
      <input class="search-input" type="text" placeholder="Tìm kiếm..." /> bên
      trên góc phải màn hình.
    </div>
    <div class="mt-4" style="font-weight: 600">
      &diams; Giai đoạn 2 - Điền thông tin cho đề tài
    </div>
    <div>
      Sau khi hoàn tất việc chọn thủ tục, người dùng cần phải điền Thông tin
      nhiệm vụ.
    </div>
    <img
      src="assets/media/user-guide/thong-tin-qui-trinh.png"
      alt="info"
      width="100%"
    />
    <div>
      Ngoài ra người dùng có thêm Chi tiết nguồn khác khi bấm vào
      <label class="button-outer">+ Thêm nguồn khác</label>
    </div>
    <img src="assets/media/user-guide/nguon-khac.png" alt="info" width="100%" />
    <div>
      - Tại danh sách thành viên thực hiện nhiệm vụ, người dùng có thể thêm
      nhiều thành viên thực hiện đề tài bằng cách bấm nút
      <label class="button-outer">+ Thêm</label>, sau khi điền thông tin thành
      viên thì bấm nút
      <img
        src="assets/icons/svg/save.svg"
        alt="save"
        width="24px"
        height="24px"
      />
      , người dùng có thể chỉnh sửa khi bấm
      <img
        src="assets/icons/svg/mode_edit.svg"
        alt="save"
        width="24px"
        height="24px"
      />
      hoặc xóa khi bấm
      <img
        src="assets/icons/svg/delete.svg"
        alt="save"
        width="24px"
        height="24px"
      />
      <div>
        - Bên cạnh đó, để ủy quyền cho thành viên khác quản lý đề tài, ta bấm
        <button class="btn-warning">Chỉnh sửa</button> , sau đó chọn thành viên
        để ủy quyền, thêm tệp đính kèm giấy ủy quyền và bấm
        <button class="btn-blue">Lưu</button>.
      </div>
      <img
        src="assets/media/user-guide/thanh-vien-uy-quyen.png"
        alt="info"
        width="100%"
      />
      <div>
        - Tại nội dung biểu mẫu, bấm vào
        <img
          src="assets/media/user-guide/phan-phoi-kinh-phi-thuc-hien.png"
          alt="info"
          width="50%"
        />
        và
        <img
          src="assets/media/user-guide/noi-dung-nhiem-vu.png"
          alt="info"
          width="50%"
        />
        để nhập thông tin biểu mẫu có nội dung như ảnh dưới đây:
      </div>
      <img
        src="assets/media/user-guide/nhap-noi-dung.png"
        alt="info"
        width="100%"
      />
      <div>
        <label class="txt-red">Lưu ý: </label>Tổng tiền nhập tại phân bổ kinh
        phí phải bằng ngân sách trên nhiệm vụ.
      </div>
      <img
        src="assets/media/user-guide/hoan-thanh-noi-dung-nhiem-vu.png"
        alt="info"
        width="100%"
      />
      <div>
        - Sau khi điền đủ thông tin cho biểu mẫu, bấm nút để lưu biểu mẫu và bấm
        <button class="btn-primary">Tiếp theo</button> để thực hiện bước tiếp
        theo của hồ sơ..
      </div>
      <div class="mt-4" style="font-weight: 600">
        &diams; Giai đoạn 3 - Đính kèm giấy tờ cho hồ sơ:
      </div>
      <div class="mt-2">
        - Bấm vào
        <img
          src="assets/icons/svg/info.svg"
          alt="save"
          width="24px"
          height="24px"
        />
        và bấm
        <label class="button-outer">Tải file mẫu</label>
        để tải tệp đính kèm mẫu
      </div>
      <div class="mt-2">
        - Điền thông tin vào tệp đính kèm mẫu, sau đó gửi tệp đính kèm lên khi
        bấm
        <img
          src="assets/icons/svg/cloud_upload.svg"
          alt="save"
          width="24px"
          height="24px"
        />
        và có thể xóa tệp khi bấm nút
        <img
          src="assets/icons/svg/delete_icon.svg"
          alt="save"
          width="24px"
          height="24px"
        />.
      </div>
      <div class="mt-2">
        - Có thể thêm hàng tệp đính kèm tại “Công văn đặt hàng, cam kết ứng
        dụng, cam kết cùng đầu tư kinh phí thực hiện nhiệm vụ” khi bấm
        <label class="button-outer">+ Thêm file</label>.
      </div>
      <div class="mt-2">
        - Sau khi thêm tệp đính kèm cho hồ sơ, bấm nút
        <button class="btn-blue">Lưu</button> để lưu tệp đính kèm và bấm
        <button class="btn-primary">Tiếp theo</button> để thực hiện bước tiếp
        theo của hồ sơ.
      </div>
      <img
        src="assets/media/user-guide/dinh-kem-giay-to.png"
        alt="info"
        width="100%"
      />
      <div class="mt-2">
        - Cuối cùng kiểm tra và xác nhận nộp hồ sơ bằng cách bấm nút
        <button class="btn-primary">Hoàn thành</button>
      </div>
      <img
        src="assets/media/user-guide/nop-ho-so-thanh-cong.png"
        alt="info"
        width="100%"
      />
      <div class="mt-2">
        - Hồ sơ được nộp thành công và chuyển vào quy trình Xử lý hồ sơ. Để tiếp
        tục nộp hồ sơ thì bấm
        <button class="btn-blue">Tiếp tục nộp hồ sơ</button> hoặc muốn xem hồ sơ
        vừa nộp thì bấm <button class="btn-primary">Hồ sơ của tôi</button> để
        chuyển qua Hồ sơ của tôi để xem các thông tin của hồ sơ.
      </div>
    </div>
  </div>
</div>
