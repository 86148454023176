import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {CompanyInfoService} from '@app/api/companyInfo.service';
import {RootSpinnerService} from '@app/services/root-spinner.service';
import {ToastrService} from 'ngx-toastr';
import {finalize} from 'rxjs/operators';
import * as XLSX from 'xlsx';

@Component({
  selector: 'meu-company-import-modal',
  templateUrl: './company-import-modal.component.html',
  styleUrls: ['./company-import-modal.component.scss'],
})
export class CompanyImportModalComponent implements OnInit {
  templateFile: File = null;
  companyLst: any = [];
  isSubmitted = false;
  allResult = [];
  displayedColumns: string[] = ['title', 'message'];
  emailPattern = '^[A-z0-9._%+-]+@[a-z0-9.-]+[.]+[a-z]{2,4}$';
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CompanyImportModalComponent>,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private companyInfoService: CompanyInfoService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      file: [],
    });
  }

  uploadFile(event) {
    this.companyLst = [];
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      this.templateFile = file;
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const data = fileReader.result;
        const workBook = XLSX.read(data, {type: 'binary'});
        const firstSheetName = workBook.SheetNames[0];
        const sheet = workBook.Sheets[firstSheetName];
        const output: any = XLSX.utils.sheet_to_json(sheet);
        console.log(output);
        for (let i = 0; i < output.length; i++) {
          let companyExcel: any = {};
          if (!output[i]['Mã đơn vị']) {
            this.toastr.error(
              'Dữ liệu mã đơn vị dòng số ' + (i + 2) + ' không được trống'
            );
            this.resetFile();
            return;
          }
          if (!output[i]['Tên đơn vị']) {
            this.toastr.error(
              'Dữ liệu tên đơn vị dòng số ' + (i + 2) + ' không được trống'
            );
            this.resetFile();
            return;
          }
          if (output[i]['Website'] && output[i]['Website'].length > 100) {
            this.toastr.error(
              'Dữ liệu Website dòng số ' + (i + 2) + ' không được quá 100 ký tự'
            );
            this.resetFile();
            return;
          }
          companyExcel = {
            employee_code_prefix: output[i]['Mã đơn vị'] ?? null,
            cellphone: output[i]['Số điện thoại'] ?? null,
            name: output[i]['Tên đơn vị'] ?? null,
            email: output[i].Email ?? null,
            website: output[i].Website ?? null,
            address: output[i]['Địa chỉ liên lạc'] ?? null,
            fax: output[i].Fax ?? null,
            tax_code: output[i]['Mã số thuế'] ?? null,
          };
          this.companyLst.push(companyExcel);
        }
      };
    }
  }

  onSubmit() {
    const fileInput = <HTMLInputElement>(
      document.querySelector(
        'ngx-mat-file-input[formcontrolname="file"] input[type="file"]'
      )
    );
    if (fileInput.value == '') {
      this.toastr.warning('Hãy tải file hợp lệ');
      return;
    }
    RootSpinnerService.show();
    this.companyInfoService
      .apiCompanyImportPost(this.companyLst)
      .pipe(
        finalize(() => {
          RootSpinnerService.hide();
        })
      )
      .subscribe((res) => {
        if (res.success) {
          this.isSubmitted = true;
          this.allResult = res.data;
        } else {
          this.toastr.error('Có lỗi xảy ra. Vui lòng thử lại!', '');
        }
      });
  }

  resetFile() {
    this.form.controls['file'].setValue(null, {emitEvent: false});
    const fileInput = <HTMLInputElement>(
      document.querySelector(
        'ngx-mat-file-input[formcontrolname="file"] input[type="file"]'
      )
    );
    fileInput.value = null;
  }

  closeModal() {
    this.dialogRef.close({event: false});
  }
}
