import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommonElementModule} from '@app/views/common_elements/common_element.module';
import {PortletModule} from '@app/views/partials/content/general/portlet/portlet.module';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {MaterialModule} from 'mat-modules';
import {NgxSummernoteModule} from 'ngx-summernote';
//declarations
import {ResizableModule} from 'angular-resizable-element';

import {
  MatMomentDatetimeModule,
  MomentDatetimeAdapter,
} from '@mat-datetimepicker/moment';
import {
  MatDatetimepickerModule,
  MAT_DATETIME_FORMATS,
  DatetimeAdapter,
} from '@mat-datetimepicker/core';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {CanActivateRouteGuard} from '@app/core/auth/_guards/permissions-route.guard';
import {SharedModule} from '@app/views/shared/shared.module';

import {RoleManagementComponent} from './setting-permission/role-management/role-management.component';
import {GroupManagementComponent} from './setting-permission/group-management/group-management.component';
import {ModalCreateRoleComponent} from './setting-permission/role-management/modal-create-role/modal-create-role.component';
import {RolePermissionComponent} from './setting-permission/role-management/role-permission/role-permission.component';
import {ModalCreateGroupComponent} from './setting-permission/group-management/modal-create-group/modal-create-group.component';
import {GroupRoleComponent} from './setting-permission/group-management/group-role/group-role.component';
import {GroupUserComponent} from './setting-permission/group-management/group-user/group-user.component';
import {RoleUserComponent} from './setting-permission/role-management/role-user/role-user.component';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {LyImageCropperModule} from '@alyle/ui/image-cropper';
import {LyButtonModule} from '@alyle/ui/button';
import {LyIconModule} from '@alyle/ui/icon';
import {LySliderModule} from '@alyle/ui/slider';
import {CoreModule} from '@app/core/core.module';
import {HashRouteGuard} from '@app/core/auth/_guards/hashUrl.guard';
import {CheckHashRouteGuard} from '@app/core/auth/_guards/checkHash.guard';
import {CompanyManagementComponent} from './setting-company/company-management/company-management.component';
import {AppCreateCompanyModalComponent} from './setting-company/app-create-company-modal/app-create-company-modal.component';
import {CompanyDetailComponent} from './setting-company/company-detail/company-detail.component';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {CompanyImportModalComponent} from './setting-company/company-import-modal/company-import-modal.component';
import {ListOfSpecialtiesComponent} from './setting-permission/list-of-specialties/list-of-specialties.component';
import { CompanyBudgetMissionModalComponent } from './setting-company/company-budget-mission-modal/company-budget-mission-modal/company-budget-mission-modal.component';

export const DATETIME_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
    monthInput: 'MMMM',
    timeInput: 'LT',
    datetimeInput: 'DD-MM-YYYY LT',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthInput: 'MMMM',
    datetimeInput: 'DD-MM-YYYY LT',
    timeInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY',
    popupHeaderDateLabel: 'ddd, DD MMM',
  },
};

const routes: Routes = [
  {
    path: 'setting/permission/role-management',
    component: RoleManagementComponent,
    canActivate: [CanActivateRouteGuard],
    data: {permissions: 'PERMISSION_MANGEMENT'},
  },
  {
    path: 'setting/permission/list-of-specialties',
    component: ListOfSpecialtiesComponent,
    canActivate: [CanActivateRouteGuard],
    data: {permissions: 'PERMISSION_MANGEMENT'},
  },
  {
    path: 'setting/permission/company-management',
    component: CompanyManagementComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: 'setting/permission/company-management/:id',
    component: CompanyDetailComponent,
    canActivate: [CanActivateRouteGuard],
  },
  {
    path: 'setting/permission/role-management/role-permission/:roleId',
    component: RolePermissionComponent,
    canActivate: [CanActivateRouteGuard],
    data: {permissions: 'PERMISSION_MANGEMENT'},
  },
  {
    path: 'setting/permission/role-management/role-user/:roleId',
    component: RoleUserComponent,
    canActivate: [CanActivateRouteGuard],
    data: {permissions: 'PERMISSION_MANGEMENT'},
  },
];

@NgModule({
  declarations: [
    RoleManagementComponent,
    GroupManagementComponent,
    ModalCreateRoleComponent,
    RolePermissionComponent,
    ModalCreateGroupComponent,
    GroupRoleComponent,
    GroupUserComponent,
    RoleUserComponent,
    CompanyManagementComponent,
    AppCreateCompanyModalComponent,
    CompanyDetailComponent,
    CompanyImportModalComponent,
    ListOfSpecialtiesComponent,
    CompanyBudgetMissionModalComponent,
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    MaterialModule,
    CommonElementModule,
    PortletModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(routes),
    ResizableModule,
    MatDatetimepickerModule,
    MatMomentDatetimeModule,
    NgxMaterialTimepickerModule,
    NgxSummernoteModule,
    NgxDropzoneModule,
    LyImageCropperModule,
    LyIconModule,
    LyButtonModule,
    LySliderModule,
    CoreModule,
    SharedModule,
    MaterialFileInputModule,
  ],
  exports: [RouterModule],
  providers: [
    {provide: DatetimeAdapter, useClass: MomentDatetimeAdapter},
    {provide: MAT_DATETIME_FORMATS, useValue: DATETIME_FORMATS},
  ],
})
export class SettingModule {}
