import {Component, OnInit, Output, Input, EventEmitter} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DbdBoardService} from '@app/api/dbdBoard.service';
import {currentUser, User} from '@app/core/auth';
import {Observable} from 'rxjs';
import {Store, select} from '@ngrx/store';
import {AppState} from '@app/core/reducers/index';

@Component({
  selector: 'meu-modal-create-board',
  templateUrl: './modal-create-board.component.html',
  styleUrls: ['./modal-create-board.component.scss'],
})
export class ModalCreateBoardComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Input() boardId = null;
  @Input() code;
  @Input() sort_order;
  @Input() is_public;

  owner_id;

  isEdit = true;
  hasFormErrors = false;
  createBoardForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
  });

  user$: Observable<User>;
  myInfo: any;

  constructor(
    private toastr: ToastrService,
    private dbdBoardService: DbdBoardService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.user$ = this.store.pipe(select(currentUser));
    this.user$.subscribe((user) => {
      this.myInfo = user;
      this.owner_id = this.myInfo.id;
    });
    if (this.boardId) {
      this.isEdit = true;
      this.getBoardByID();
    } else {
      this.isEdit = false;
    }
  }

  getBoardByID() {
    this.dbdBoardService.apiMyBoardsIdGet(this.boardId).subscribe((res) => {
      if (res.success) {
        this.createBoardForm.patchValue({
          name: res.data.name,
        });
      }
    });
  }
  save() {
    const controls = this.createBoardForm.controls;
    if (this.createBoardForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      this.hasFormErrors = true;
      return;
    }

    if (!this.isEdit) {
      let objCreateBoard = {
        name: this.createBoardForm.value.name,
        code: this.code,
        owner_id: this.owner_id,
        sort_order: this.sort_order,
        is_public: this.is_public,
      };
      this.dbdBoardService.apiMyBoardsPost(objCreateBoard).subscribe((res) => {
        if (res.success) {
          this.toastr.success('Thêm mới thành công');
          let data = {
            isEdit: false,
            response: res.data,
          };
          this.closeModalClick(data);
        } else {
          this.toastr.error(res.message, 'Thêm mới không thành công');
        }
      });
    } else {
      let objEditBoard = {
        name: this.createBoardForm.value.name,
      };
      this.dbdBoardService
        .apiMyBoardsIdPut(this.boardId, objEditBoard)
        .subscribe((res) => {
          if (res.success) {
            this.toastr.success('Chỉnh sửa thành công');
            let data = {
              isEdit: true,
              response: res.data,
            };
            this.closeModalClick(data);
          } else {
            this.toastr.error(res.message, 'Chỉnh sửa không thành công');
          }
        });
    }
  }

  closeModalClick(data) {
    this.closeModal.emit(data);
  }
}
