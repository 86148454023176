/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { SaBook } from '../model/saBook';
import { SaSheet } from '../model/saSheet';
import { SaSheetCell } from '../model/saSheetCell';
import { SaSheetColumn } from '../model/saSheetColumn';
import { SaSheetStaff } from '../model/saSheetStaff';
import { SaSheetStaffGroup } from '../model/saSheetStaffGroup';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SaBookService {

    protected basePath = 'https://gateway.dev.meu-solutions.com/sciencetechnologysso';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param currentSabookId 
     * @param nextTrueBackFalse 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPayslipMySaBooksGet(currentSabookId?: string, nextTrueBackFalse?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPayslipMySaBooksGet(currentSabookId?: string, nextTrueBackFalse?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPayslipMySaBooksGet(currentSabookId?: string, nextTrueBackFalse?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPayslipMySaBooksGet(currentSabookId?: string, nextTrueBackFalse?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (currentSabookId !== undefined && currentSabookId !== null) {
            queryParameters = queryParameters.set('currentSabookId', <any>currentSabookId);
        }
        if (nextTrueBackFalse !== undefined && nextTrueBackFalse !== null) {
            queryParameters = queryParameters.set('nextTrueBackFalse', <any>nextTrueBackFalse);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/payslip/mySaBooks`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param currentSaSheetId 
     * @param nextTrueBackFalse 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPayslipMySaSheetsGet(currentSaSheetId?: string, nextTrueBackFalse?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPayslipMySaSheetsGet(currentSaSheetId?: string, nextTrueBackFalse?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPayslipMySaSheetsGet(currentSaSheetId?: string, nextTrueBackFalse?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPayslipMySaSheetsGet(currentSaSheetId?: string, nextTrueBackFalse?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (currentSaSheetId !== undefined && currentSaSheetId !== null) {
            queryParameters = queryParameters.set('currentSaSheetId', <any>currentSaSheetId);
        }
        if (nextTrueBackFalse !== undefined && nextTrueBackFalse !== null) {
            queryParameters = queryParameters.set('nextTrueBackFalse', <any>nextTrueBackFalse);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/payslip/mySaSheets`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaBooksGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaBooksGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaBooksGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaBooksGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/saBooks`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaBooksIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaBooksIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaBooksIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaBooksIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiSaBooksIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/saBooks/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaBooksIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaBooksIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaBooksIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaBooksIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiSaBooksIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/saBooks/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaBooksIdPut(id: string, body?: SaBook, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaBooksIdPut(id: string, body?: SaBook, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaBooksIdPut(id: string, body?: SaBook, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaBooksIdPut(id: string, body?: SaBook, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiSaBooksIdPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/saBooks/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSaBooksPost(body?: SaBook, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSaBooksPost(body?: SaBook, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSaBooksPost(body?: SaBook, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSaBooksPost(body?: SaBook, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/saBooks`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param bookId 
     * @param sheetId 
     * @param body 
     * @param includeEnterData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaBooksBookIdCloneSASheetSheetIdPost(bookId: string, sheetId: string, body?: SaSheet, includeEnterData?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaBooksBookIdCloneSASheetSheetIdPost(bookId: string, sheetId: string, body?: SaSheet, includeEnterData?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaBooksBookIdCloneSASheetSheetIdPost(bookId: string, sheetId: string, body?: SaSheet, includeEnterData?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaBooksBookIdCloneSASheetSheetIdPost(bookId: string, sheetId: string, body?: SaSheet, includeEnterData?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookId === null || bookId === undefined) {
            throw new Error('Required parameter bookId was null or undefined when calling apiSalariesSaBooksBookIdCloneSASheetSheetIdPost.');
        }

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaBooksBookIdCloneSASheetSheetIdPost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeEnterData !== undefined && includeEnterData !== null) {
            queryParameters = queryParameters.set('includeEnterData', <any>includeEnterData);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/salaries/saBooks/${encodeURIComponent(String(bookId))}/cloneSASheet/${encodeURIComponent(String(sheetId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param bookId 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaBooksBookIdSheetsGet(bookId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaBooksBookIdSheetsGet(bookId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaBooksBookIdSheetsGet(bookId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaBooksBookIdSheetsGet(bookId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookId === null || bookId === undefined) {
            throw new Error('Required parameter bookId was null or undefined when calling apiSalariesSaBooksBookIdSheetsGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/salaries/saBooks/${encodeURIComponent(String(bookId))}/sheets`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param bookId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaBooksBookIdSheetsPost(bookId: string, body?: SaSheet, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaBooksBookIdSheetsPost(bookId: string, body?: SaSheet, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaBooksBookIdSheetsPost(bookId: string, body?: SaSheet, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaBooksBookIdSheetsPost(bookId: string, body?: SaSheet, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bookId === null || bookId === undefined) {
            throw new Error('Required parameter bookId was null or undefined when calling apiSalariesSaBooksBookIdSheetsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/salaries/saBooks/${encodeURIComponent(String(bookId))}/sheets`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param cellID 
     * @param cellData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaCellsCellIDDataPost(cellID: string, cellData?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaCellsCellIDDataPost(cellID: string, cellData?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaCellsCellIDDataPost(cellID: string, cellData?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaCellsCellIDDataPost(cellID: string, cellData?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (cellID === null || cellID === undefined) {
            throw new Error('Required parameter cellID was null or undefined when calling apiSalariesSaCellsCellIDDataPost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (cellData !== undefined && cellData !== null) {
            queryParameters = queryParameters.set('cellData', <any>cellData);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/salaries/saCells/${encodeURIComponent(String(cellID))}/data`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaCellsDataPost(body?: Array<SaSheetCell>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaCellsDataPost(body?: Array<SaSheetCell>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaCellsDataPost(body?: Array<SaSheetCell>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaCellsDataPost(body?: Array<SaSheetCell>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/salaries/saCells/data`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param sheetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetStaffGroupsIdGet(id: string, sheetId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetStaffGroupsIdGet(id: string, sheetId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetStaffGroupsIdGet(id: string, sheetId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetStaffGroupsIdGet(id: string, sheetId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiSalariesSaSheetStaffGroupsIdGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sheetId !== undefined && sheetId !== null) {
            queryParameters = queryParameters.set('sheetId', <any>sheetId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/salaries/saSheetStaffGroups/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetStaffGroupsIdPut(id: string, body?: SaSheetStaffGroup, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetStaffGroupsIdPut(id: string, body?: SaSheetStaffGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetStaffGroupsIdPut(id: string, body?: SaSheetStaffGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetStaffGroupsIdPut(id: string, body?: SaSheetStaffGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiSalariesSaSheetStaffGroupsIdPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/salaries/saSheetStaffGroups/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetStaffId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetStaffsSheetStaffIdDelete(sheetStaffId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetStaffsSheetStaffIdDelete(sheetStaffId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetStaffsSheetStaffIdDelete(sheetStaffId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetStaffsSheetStaffIdDelete(sheetStaffId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetStaffId === null || sheetStaffId === undefined) {
            throw new Error('Required parameter sheetStaffId was null or undefined when calling apiSalariesSaSheetStaffsSheetStaffIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/salaries/saSheetStaffs/${encodeURIComponent(String(sheetStaffId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetStaffId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetStaffsSheetStaffIdPut(sheetStaffId: string, body?: any, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetStaffsSheetStaffIdPut(sheetStaffId: string, body?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetStaffsSheetStaffIdPut(sheetStaffId: string, body?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetStaffsSheetStaffIdPut(sheetStaffId: string, body?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetStaffId === null || sheetStaffId === undefined) {
            throw new Error('Required parameter sheetStaffId was null or undefined when calling apiSalariesSaSheetStaffsSheetStaffIdPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/salaries/saSheetStaffs/${encodeURIComponent(String(sheetStaffId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsAutoSyncTypesGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsAutoSyncTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsAutoSyncTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsAutoSyncTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/salaries/saSheets/auto_sync_types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsColumnTypesGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsColumnTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsColumnTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsColumnTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/salaries/saSheets/column_types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsDataTypesGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsDataTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsDataTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsDataTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/salaries/saSheets/data_types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsOuputSalaryTypesGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsOuputSalaryTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsOuputSalaryTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsOuputSalaryTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/salaries/saSheets/ouput_salary_types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdCaculatePost(sheetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdCaculatePost(sheetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdCaculatePost(sheetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdCaculatePost(sheetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdCaculatePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/caculate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdCellsDataGet(sheetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdCellsDataGet(sheetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdCellsDataGet(sheetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdCellsDataGet(sheetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdCellsDataGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/cells/data`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param loopCount 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdCellsTestBigDataGet(sheetId: string, loopCount?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdCellsTestBigDataGet(sheetId: string, loopCount?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdCellsTestBigDataGet(sheetId: string, loopCount?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdCellsTestBigDataGet(sheetId: string, loopCount?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdCellsTestBigDataGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (loopCount !== undefined && loopCount !== null) {
            queryParameters = queryParameters.set('loopCount', <any>loopCount);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/cells/testBigData`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdDelete(sheetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdDelete(sheetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdDelete(sheetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdDelete(sheetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdExportExcelBankPost(sheetId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiSalariesSaSheetsSheetIdExportExcelBankPost(sheetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiSalariesSaSheetsSheetIdExportExcelBankPost(sheetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiSalariesSaSheetsSheetIdExportExcelBankPost(sheetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdExportExcelBankPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/exportExcelBank`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdExportPayslipPost(sheetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdExportPayslipPost(sheetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdExportPayslipPost(sheetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdExportPayslipPost(sheetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdExportPayslipPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/exportPayslip`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdGet(sheetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdGet(sheetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdGet(sheetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdGet(sheetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdPut(sheetId: string, body?: SaSheet, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdPut(sheetId: string, body?: SaSheet, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdPut(sheetId: string, body?: SaSheet, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdPut(sheetId: string, body?: SaSheet, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdSaSheetColumnsGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdSaSheetColumnsGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetColumnsGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetColumnsGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdSaSheetColumnsGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/saSheetColumns`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdSaSheetColumnsIdDelete(sheetId: string, id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdSaSheetColumnsIdDelete(sheetId: string, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetColumnsIdDelete(sheetId: string, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetColumnsIdDelete(sheetId: string, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdSaSheetColumnsIdDelete.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiSalariesSaSheetsSheetIdSaSheetColumnsIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/saSheetColumns/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdSaSheetColumnsIdGet(sheetId: string, id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdSaSheetColumnsIdGet(sheetId: string, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetColumnsIdGet(sheetId: string, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetColumnsIdGet(sheetId: string, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdSaSheetColumnsIdGet.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiSalariesSaSheetsSheetIdSaSheetColumnsIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/saSheetColumns/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdSaSheetColumnsIdPut(sheetId: string, id: string, body?: SaSheetColumn, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdSaSheetColumnsIdPut(sheetId: string, id: string, body?: SaSheetColumn, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetColumnsIdPut(sheetId: string, id: string, body?: SaSheetColumn, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetColumnsIdPut(sheetId: string, id: string, body?: SaSheetColumn, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdSaSheetColumnsIdPut.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiSalariesSaSheetsSheetIdSaSheetColumnsIdPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/saSheetColumns/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdSaSheetColumnsPost(sheetId: string, body?: SaSheetColumn, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdSaSheetColumnsPost(sheetId: string, body?: SaSheetColumn, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetColumnsPost(sheetId: string, body?: SaSheetColumn, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetColumnsPost(sheetId: string, body?: SaSheetColumn, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdSaSheetColumnsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/saSheetColumns`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdSaSheetStaffGroupsGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdSaSheetStaffGroupsGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetStaffGroupsGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetStaffGroupsGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdSaSheetStaffGroupsGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/saSheetStaffGroups`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param sheetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdSaSheetStaffGroupsIdDelete(id: string, sheetId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdSaSheetStaffGroupsIdDelete(id: string, sheetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetStaffGroupsIdDelete(id: string, sheetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetStaffGroupsIdDelete(id: string, sheetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiSalariesSaSheetsSheetIdSaSheetStaffGroupsIdDelete.');
        }

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdSaSheetStaffGroupsIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/saSheetStaffGroups/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdSaSheetStaffGroupsPost(sheetId: string, body?: SaSheetStaffGroup, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdSaSheetStaffGroupsPost(sheetId: string, body?: SaSheetStaffGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetStaffGroupsPost(sheetId: string, body?: SaSheetStaffGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetStaffGroupsPost(sheetId: string, body?: SaSheetStaffGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdSaSheetStaffGroupsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/saSheetStaffGroups`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdSaSheetStaffsGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdSaSheetStaffsGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetStaffsGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetStaffsGet(sheetId: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdSaSheetStaffsGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/saSheetStaffs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdSaSheetStaffsPost(sheetId: string, body?: SaSheetStaff, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdSaSheetStaffsPost(sheetId: string, body?: SaSheetStaff, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetStaffsPost(sheetId: string, body?: SaSheetStaff, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdSaSheetStaffsPost(sheetId: string, body?: SaSheetStaff, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdSaSheetStaffsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/saSheetStaffs`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdSalaryTablePost(sheetId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiSalariesSaSheetsSheetIdSalaryTablePost(sheetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiSalariesSaSheetsSheetIdSalaryTablePost(sheetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiSalariesSaSheetsSheetIdSalaryTablePost(sheetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdSalaryTablePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/salaryTable`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdSyncDataForSalaryTablePost(sheetId: string, body?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdSyncDataForSalaryTablePost(sheetId: string, body?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdSyncDataForSalaryTablePost(sheetId: string, body?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdSyncDataForSalaryTablePost(sheetId: string, body?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdSyncDataForSalaryTablePost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/syncDataForSalaryTable`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdTemplateDownloadGet(sheetId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiSalariesSaSheetsSheetIdTemplateDownloadGet(sheetId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiSalariesSaSheetsSheetIdTemplateDownloadGet(sheetId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiSalariesSaSheetsSheetIdTemplateDownloadGet(sheetId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdTemplateDownloadGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/template/download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param sheetId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalariesSaSheetsSheetIdTemplateUploadPut(sheetId: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalariesSaSheetsSheetIdTemplateUploadPut(sheetId: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalariesSaSheetsSheetIdTemplateUploadPut(sheetId: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalariesSaSheetsSheetIdTemplateUploadPut(sheetId: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (sheetId === null || sheetId === undefined) {
            throw new Error('Required parameter sheetId was null or undefined when calling apiSalariesSaSheetsSheetIdTemplateUploadPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/salaries/saSheets/${encodeURIComponent(String(sheetId))}/template/upload`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param currentSabookId 
     * @param nextTrueBackFalse 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalaryConfirmMySaBooksGet(currentSabookId?: string, nextTrueBackFalse?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalaryConfirmMySaBooksGet(currentSabookId?: string, nextTrueBackFalse?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalaryConfirmMySaBooksGet(currentSabookId?: string, nextTrueBackFalse?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalaryConfirmMySaBooksGet(currentSabookId?: string, nextTrueBackFalse?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (currentSabookId !== undefined && currentSabookId !== null) {
            queryParameters = queryParameters.set('currentSabookId', <any>currentSabookId);
        }
        if (nextTrueBackFalse !== undefined && nextTrueBackFalse !== null) {
            queryParameters = queryParameters.set('nextTrueBackFalse', <any>nextTrueBackFalse);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/salaryConfirm/mySaBooks`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param currentSaSheetId 
     * @param nextTrueBackFalse 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiSalaryConfirmMySaSheetsGet(currentSaSheetId?: string, nextTrueBackFalse?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiSalaryConfirmMySaSheetsGet(currentSaSheetId?: string, nextTrueBackFalse?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiSalaryConfirmMySaSheetsGet(currentSaSheetId?: string, nextTrueBackFalse?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiSalaryConfirmMySaSheetsGet(currentSaSheetId?: string, nextTrueBackFalse?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (currentSaSheetId !== undefined && currentSaSheetId !== null) {
            queryParameters = queryParameters.set('currentSaSheetId', <any>currentSaSheetId);
        }
        if (nextTrueBackFalse !== undefined && nextTrueBackFalse !== null) {
            queryParameters = queryParameters.set('nextTrueBackFalse', <any>nextTrueBackFalse);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/salaryConfirm/mySaSheets`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
