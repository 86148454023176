<mat-card>
    <mat-card-content>
        <h3 class="title">DANH SÁCH NHÂN VIÊN</h3>

        <form [formGroup]="groupUserForm">
            <div class="row">
                <div class="col-lg-4 col-xs-8 col-sm-8 kt-margin-bottom-20-mobile">
                    <meu-control-select-search-load-more [placeholderLabel]="'Chọn nhân viên'"
                        [noEntriesFoundLabel]="'Không có lựa chọn phù hợp'" [defaultID]="groupUserForm.value.staff_id"
                        formControlName="staff_id" [columnName]="'extend_user_full_name'" [nameAPI]="'/api/users'">
                    </meu-control-select-search-load-more>
                </div>
                <div class="col-lg-4 col-xs-4 col-sm-4 kt-margin-bottom-20-mobile">
                    <a class="btn kt-subheader__btn-primary btn-label-primary btn-bold btn-icon-h" (click)="addUser()">
                        Thêm
                    </a>
                </div>
            </div>
        </form>

        <div class="example-container mat-elevation-z8 mat-table-user">
            <mat-table #table [dataSource]="listUser">
                <!-- index Column -->
                <ng-container matColumnDef="index">
                    <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index;"> {{i + 1}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Tên CBNV </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis kt-padding-l-15"
                        [title]="element.extend_staff_full_name">
                        <span class="ellipsis">
                            <img class="image-profile-user-management" [src]="element.extend_staff_avatar" />
                            {{ element.extend_staff_full_name }}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="code">
                    <mat-header-cell *matHeaderCellDef> Mã NV </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis" [title]="element.code">
                        <span class="ellipsis">
                            {{element.code}}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="position">
                    <mat-header-cell *matHeaderCellDef>Chức vụ</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis" [title]="element.position">
                        <span class="ellipsis">
                            {{element.position}}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="department">
                    <mat-header-cell *matHeaderCellDef>Phòng ban</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="ellipsis" [title]="element.department">
                        <span class="ellipsis">
                            {{element.department}}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef>
                        Thao tác
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button class="mat-icon-button-sm" mat-icon-button matTooltip="Xóa"
                            (click)="removeUser(element.id)" type="button">
                            <i class="fa fa-trash iconColor" aria-hidden="true"></i>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
            <div *ngIf="listUser.length == 0" class="no-records">
                Không có dữ liệu
            </div>

        </div>
    </mat-card-content>
</mat-card>