<div class="modal-header">
  <h4 class="modal-title text-16px pull-left">{{ modalTitle }}</h4>
  <button
    type="button"
    class="close pull-right header-button"
    aria-label="Close"
    (click)="goBack()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="container-fluid">
    <form [formGroup]="companyFrm">
      <div class="row">
        <div class="col-lg-6 col-6">
          <div class="form-field">
            <span class="title">Tên đơn vị </span>
            <span class="color-red"> *</span>
            <input
              [ngClass]="
                isControlHasError('name', 'required') ? 'modal-input-error' : ''
              "
              class="modal-input"
              placeholder="Nhập tên đơn vị"
              formControlName="name"
              autocomplete="off"
            />
            <span class="error" *ngIf="isControlHasError('name', 'required')"
              >Tên đơn vị là
              <strong>bắt buộc</strong>
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-6">
          <div class="form-field">
            <span class="title">Mã đơn vị </span>
            <span class="color-red"> *</span>
            <input
              [ngClass]="
                isControlHasError('employee_code_prefix', 'required')
                  ? 'modal-input-error'
                  : ''
              "
              class="modal-input"
              placeholder="Nhập mã đơn vị"
              formControlName="employee_code_prefix"
              autocomplete="off"
              maxlength="12"
            />
            <span
              class="error"
              *ngIf="isControlHasError('employee_code_prefix', 'required')"
              >Mã đơn vị là
              <strong>bắt buộc</strong>
            </span>
            <span
              class="error"
              *ngIf="isControlHasError('employee_code_prefix', 'pattern')"
              >Mã đơn vị không được chứa số
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-6">
          <div class="form-field">
            <span class="title">Mã số thuế </span>
            <span class="color-red"> *</span>
            <input
              [ngClass]="
                isControlHasError('tax_code', 'required') ||
                isControlHasError('tax_code', 'pattern')
                  ? 'modal-input-error'
                  : ''
              "
              class="modal-input"
              placeholder="Mã số thuế"
              formControlName="tax_code"
              autocomplete="off"
            />
            <span
              class="error"
              *ngIf="isControlHasError('tax_code', 'required')"
              >Mã số thuế là
              <strong>bắt buộc</strong>
            </span>
            <span
              class="error"
              *ngIf="isControlHasError('tax_code', 'pattern')"
            >
              Mã số thuế
              <strong> không hợp lệ </strong>
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-6">
          <div class="form-field">
            <span class="title">Số điện thoại </span>
            <input
              [ngClass]="
                isControlHasError('cellphone', 'pattern')
                  ? 'modal-input-error'
                  : ''
              "
              class="modal-input"
              placeholder="Nhập số điện thoại"
              formControlName="cellphone"
              autocomplete="off"
            />
            <span
              class="error"
              *ngIf="isControlHasError('cellphone', 'pattern')"
            >
              Số điện thoại
              <strong> không hợp lệ </strong>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-12">
          <div class="form-field">
            <span class="title">Địa chỉ liên lạc </span>
            <span class="color-red"> *</span>
            <input
              [ngClass]="
                isControlHasError('address', 'required')
                  ? 'modal-input-error'
                  : ''
              "
              class="modal-input"
              placeholder="Nhập địa chỉ liên lạc"
              formControlName="address"
              autocomplete="off"
            />
            <span class="error" *ngIf="isControlHasError('address', 'required')"
              >Địa chỉ là
              <strong>bắt buộc</strong>
            </span>
          </div>
        </div>
      </div>

      <div class="row logo-input" *ngIf="!isRegister">
        <div class="col-6 d-flex">
          <div class="flex-column d-inline-block w-90 pt-2">
            <span class="d-flex bold">Logo đơn vị</span>
            <span class="d-flex">Định dạng JPEG hoặc PNG</span>
          </div>
          <div class="d-inline-block icon-align">
            <mat-icon>info</mat-icon>
          </div>
        </div>
        <div class="col-6">
          <div
            class="pointer w-90 d-inline-block pr-2"
            (click)="openModalImageCovert()"
          >
            <div class="right-inner-addon input-container pointer">
              <div class="icon-cloud">
                <mat-icon style="color: #5d78ff">cloud_upload</mat-icon>
              </div>
              <label
                class="form-control pointer no-border"
                for="file_allowance"
              >
                <span *ngIf="!fileNameShow" class="lbl-empty-file"
                  >Chọn file logo</span
                >
                <div class="lbl-name-file" *ngIf="fileNameShow">
                  <span class="ellipsis">
                    {{ fileNameShow }}
                  </span>
                </div>
              </label>
            </div>
          </div>
          <div
            class="d-inline-block delete-container pointer"
            (click)="deleteFile()"
          >
            <label class="delete-button pointer">
              <mat-icon>close</mat-icon>
            </label>
          </div>
        </div>
      </div>
    </form>
    <div class="row mt-2" *ngIf="!isRegister">
      <div class="col-12">
        <span class="title">Thủ trưởng:</span>
      </div>
    </div>
    <div class="row" *ngIf="!isRegister">
      <div class="col-lg-4 col-xs-8 col-sm-8 kt-margin-bottom-20-mobile">
        <meu-control-select-search-load-more
          [placeholderLabel]="'Chọn nhân viên'"
          [noEntriesFoundLabel]="'Không có lựa chọn phù hợp'"
          [columnName]="'extend_user_full_name'"
          [nameAPI]="'/api/users'"
          (change)="onChangeLeader($event)"
        >
        </meu-control-select-search-load-more>
      </div>
      <div class="col-lg-4 col-xs-4 col-sm-4 kt-margin-bottom-20-mobile">
        <a
          class="btn kt-subheader__btn-primary btn-label-primary btn-bold btn-icon-h"
          (click)="addUser()"
        >
          Thêm
        </a>
      </div>
    </div>
    <div
      class="example-container mat-elevation-z8 mat-table-user"
      *ngIf="!isRegister"
    >
      <mat-table #table [dataSource]="listUserShow">
        <!-- index Column -->
        <ng-container matColumnDef="index">
          <mat-header-cell *matHeaderCellDef> # </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            {{ i + 1 }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Tên CBNV </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="ellipsis"
            [title]="element.extend_user_full_name"
          >
            <span class="ellipsis">
              {{ element.extend_user_full_name }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="code">
          <mat-header-cell *matHeaderCellDef> Mã NV </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            class="ellipsis"
            [title]="element.employee_code"
          >
            <span class="ellipsis">
              {{ element.employee_code }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef style="justify-content: center">
            Thao tác
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element; let i = index"
            style="justify-content: center"
          >
            <button
              class="mat-icon-button-sm"
              mat-icon-button
              matTooltip="Xóa"
              (click)="removeUser(i)"
              type="button"
            >
              <mat-icon class="iconColor">delete</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <div *ngIf="listUserShow.length == 0" class="no-records">
        Không có dữ liệu
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    mat-flat-button
    *ngIf="!isRegister"
    class="btn btn-submit"
    (click)="onSubmit()"
  >
    {{ modalButton }}
  </button>
  <button
    mat-flat-button
    *ngIf="isRegister"
    class="btn btn-submit"
    (click)="onSubmitRegister()"
  >
    Thêm
  </button>
  <button mat-flat-button class="btn btn-close ml-2" (click)="goBack()">
    Hủy
  </button>
</div>
