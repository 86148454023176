// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  backendhost: 'http://qlnvdn.doimoisangtao.vn',
  url_svg_local: '/assets/icons/svg/',
  url_image_local: '/assets/media/Image/',
  encryptPassword: '@123RAPTOR!@#&^',
  feReferenceUrl: 'http://qlnv.doimoisangtao.vn',
  zabbixUrl: '',
  ossecUrl: '',
  kibanaUrl: '',
  titlePage: 'Sở khoa học công nghệ',
  aes_iv: '8080808080808080',
  aes_key: '4343243151380319',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
