<!-- begin:: Content Head -->
<div
  class="kt-subheader kt-grid__item"
  [ngClass]="{'kt-container--clear': clear}"
  [hidden]="subheaderService.disabled$ | async"
  id="kt_subheader"
>
  <div class="kt-container" [ngClass]="{'kt-container--fluid': fluid}">
    <div class="kt-subheader__main py-3">
      <h3 *ngIf="title" class="kt-subheader__title">{{ title }}</h3>
      <h3 *ngIf="!title" class="kt-subheader__title-back d-none">
        <i
          (click)="goBack()"
          class="la la-arrow-left btn btn-secondary none-border"
          mat-button
          color="primary"
        ></i>
      </h3>

      <!-- <i
        (click)="goBack()"
        class="la la-arrow-left btn btn-secondary none-border"
        mat-button
        color="primary"
      ></i> -->
      <!-- <img
        src="../../../../../../assets/icons/svg/arrow_back.svg"
        alt=""
        (click)="goBack()"
        class="pr-2"
      /> -->
      <span class="kt-subheader__separator kt-subheader__separator--v"></span>
      <span *ngIf="desc" class="kt-subheader__desc">{{ desc }}</span>
      <!-- <a href="javascript:;" class="btn btn-label-primary btn-bold btn-icon-h">
				Thêm mới
			</a> -->
    </div>
    <div class="kt-subheader__toolbar">
      <div
        class="kt-subheader__wrapper row"
        style="margin-left: 0; margin-right: 0"
      >
        <ng-container *ngFor="let buttonItem of buttonCollection">
          <a
            class="btn kt-subheader__btn-primary"
            *ngIf="buttonItem.type == 'Icon'"
            (click)="eventClickBtn(buttonItem)"
            [matTooltip]="buttonItem.Title"
            [routerLink]="buttonItem.routerlink"
          >
            <i class="icon-subheader" [class]="buttonItem.icon"></i>
          </a>
          <a
            class="btn kt-subheader__btn-primary"
            *ngIf="buttonItem.type == 'Text'"
            (click)="eventClickBtn(buttonItem)"
            [routerLink]="buttonItem.routerlink"
            class="btn btn-label-primary btn-bold btn-icon-h"
          >
            {{ buttonItem.label }}
          </a>

          <div
            ngbDropdown
            [placement]="'bottom-right'"
            class="dropdown"
            *ngIf="buttonItem.type == 'Dropdown'"
          >
            <a
              ngbDropdownToggle
              href="javascript:;"
              class="btn btn-label-primary btn-label-brand btn-bold btn-sm dropdown-toggle"
            >
              {{ buttonItem.label }}
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit">
              <ul class="kt-nav">
                <!-- <li class="kt-nav__section">
                                    <span class="kt-nav__section-text">Lựa chọn:</span>
                                </li> -->
                <li
                  class="kt-nav__item"
                  *ngFor="let dropdown of buttonItem.dropdownCollection"
                >
                  <a
                    [routerLink]="dropdown.routerlink"
                    class="kt-nav__link"
                    (click)="eventClickBtn(buttonItem)"
                  >
                    <i class="kt-nav__link-icon" [class]="dropdown.icon"></i>
                    <span class="kt-nav__link-text">{{ dropdown.title }}</span>
                  </a>
                </li>
                <!-- <li class="kt-nav__item">
                                    <a href="javascript:;" class="kt-nav__link">
                                        <i class="kt-nav__link-icon flaticon2-calendar-4"></i>
                                        <span class="kt-nav__link-text">Save</span>
                                    </a>
                                </li>
                                <li class="kt-nav__item">
                                    <a href="javascript:;" class="kt-nav__link">
                                        <i class="kt-nav__link-icon flaticon2-layers-1"></i>
                                        <span class="kt-nav__link-text">Import</span>
                                    </a>
                                </li>
                                <li class="kt-nav__item">
                                    <a href="javascript:;" class="kt-nav__link">
                                        <i class="kt-nav__link-icon flaticon2-calendar-4"></i>
                                        <span class="kt-nav__link-text">Update</span>
                                    </a>
                                </li>
                                <li class="kt-nav__item">
                                    <a href="javascript:;" class="kt-nav__link">
                                        <i class="kt-nav__link-icon flaticon2-file-1"></i>
                                        <span class="kt-nav__link-text">Customize</span>
                                    </a>
                                </li> -->
              </ul>
            </div>
          </div>
        </ng-container>
        <!-- <a href="javascript:;" class="btn btn-label-primary btn-bold btn-icon-h">
					Thêm mới
				</a> -->

        <!-- <a href="javascript:;" class="btn kt-subheader__btn-primary">
					Actions &nbsp;
					<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon kt-svg-icon--sm">
						<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							<rect id="bound" x="0" y="0" width="24" height="24"></rect>
							<rect id="Rectangle-8" fill="#000000" x="4" y="5" width="16" height="3" rx="1.5"></rect>
							<path d="M7.5,11 L16.5,11 C17.3284271,11 18,11.6715729 18,12.5 C18,13.3284271 17.3284271,14 16.5,14 L7.5,14 C6.67157288,14 6,13.3284271 6,12.5 C6,11.6715729 6.67157288,11 7.5,11 Z M10.5,17 L13.5,17 C14.3284271,17 15,17.6715729 15,18.5 C15,19.3284271 14.3284271,20 13.5,20 L10.5,20 C9.67157288,20 9,19.3284271 9,18.5 C9,17.6715729 9.67157288,17 10.5,17 Z" id="Combined-Shape" fill="#000000" opacity="0.3"></path>
						</g>
					</svg>
				</a>

				<a href="javascript:;" class="btn kt-subheader__btn-primary btn-icon">
					<i class="flaticon2-file"></i>
				</a>

				<a href="javascript:;" class="btn kt-subheader__btn-primary btn-icon">
					<i class="flaticon-download-1"></i>
				</a>

				<a href="javascript:;" class="btn kt-subheader__btn-primary btn-icon">
					<i class="flaticon2-fax"></i>
				</a>

				<a href="javascript:;" class="btn kt-subheader__btn-primary btn-icon">
					<i class="flaticon2-settings"></i>
				</a>

				<div ngbDropdown [placement]="'bottom-right'" class="dropdown dropdown-inline" ngbTooltip="Quick actions">
					<a ngbDropdownToggle href="javascript:;" class="btn btn-icon">
						<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon kt-svg-icon--success kt-svg-icon--md">
							<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
								<polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
								<path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" id="Combined-Shape" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
								<path d="M11,14 L9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 L11,12 L11,10 C11,9.44771525 11.4477153,9 12,9 C12.5522847,9 13,9.44771525 13,10 L13,12 L15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 L13,14 L13,16 C13,16.5522847 12.5522847,17 12,17 C11.4477153,17 11,16.5522847 11,16 L11,14 Z" id="Combined-Shape" fill="#000000"></path>
							</g>
						</svg>
					</a>
					<div ngbDropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-md">
						<ul class="kt-nav">
							<li class="kt-nav__head">
								Export Options:
								<i class="flaticon2-correct kt-font-warning" data-placement="left" ngbTooltip="Click to learn more..."></i>
							</li>
							<li class="kt-nav__separator"></li>
							<li class="kt-nav__item">
								<a href="javascript:;" class="kt-nav__link">
									<i class="kt-nav__link-icon flaticon2-drop"></i>
									<span class="kt-nav__link-text">Orders</span>
								</a>
							</li>
							<li class="kt-nav__item">
								<a href="javascript:;" class="kt-nav__link">
									<i class="kt-nav__link-icon flaticon2-new-email"></i>
									<span class="kt-nav__link-text">Members</span>
									<span class="kt-nav__link-badge">
                                        <span class="kt-badge kt-badge--brand kt-badge--rounded">15</span>
                                    </span>
								</a>
							</li>
							<li class="kt-nav__item">
								<a href="javascript:;" class="kt-nav__link">
									<i class="kt-nav__link-icon flaticon2-calendar-8"></i>
									<span class="kt-nav__link-text">Reports</span>
								</a>
							</li>
							<li class="kt-nav__item">
								<a href="javascript:;" class="kt-nav__link">
									<i class="kt-nav__link-icon flaticon2-telegram-logo"></i>
									<span class="kt-nav__link-text">Finance</span>
								</a>
							</li>
							<li class="kt-nav__separator"></li>
							<li class="kt-nav__foot">
								<a class="btn btn-label-brand btn-bold btn-sm" href="javascript:;">More options</a>
								<a class="btn btn-clean btn-bold btn-sm kt-hidden" href="javascript:;" data-placement="left" ngbTooltip="Click to learn more...">Learn more</a>
							</li>
						</ul>
					</div>
				</div> -->
      </div>
    </div>
  </div>
</div>
<!-- end:: Content Head -->
