<div class="kt-portlet m-0">
    <div class="kt-portlet__head kt-portlet__head__custom">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title title-color bold">
                Chọn bố cục hiển thị
            </h3>
        </div>
        <div (click)="closeModalClick(null)" class="close_modal_btn pointer" [matTooltip]="'Đóng'">
            <mat-icon>close</mat-icon>
        </div>
    </div>

    <div class="kt-portlet__body kt-padding-l-10 kt-padding-r-10 max-height-body">
        <div class="row">
            <div *ngFor="let item of templateLayout; index as i" class="col-sm-12 col-lg-4 col-md-6">
                <div class="d-flex br-4 kt-margin-10 pointer" (click)="getCurrentLayout(item, i)"
                    [ngStyle]="{
                    'border' : layoutSelected != null && indexSelect === i ? ' 1px solid #5d78ff':'1px dotted #c4c4c4'}">
                    <div *ngFor=" let subItem of item.col_layout" class="p-0 " [ngClass]="'col-' + subItem">
                        <div class="br-4 kt-margin-5 item-layout"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="kt-portlet__foot">
        <div class="row">
            <div class="col-12 footer-padding text-right">
                <button type="button" class="btn button-cancel" (click)="closeModalClick(null)">
                    Hủy
                </button>
                <button type="button" class="btn button-save" (click)="save()">
                    Lưu
                </button>
            </div>
        </div>
    </div>
</div>