<div class="login-page">
  <div class="kt-login__body mr-40px mb-16px screen-fit login-form">
    <div class="kt-login__form animated animatedFadeInUp fadeInUp">
      <div class="kt-login__title">
        <h3 class="frm-title">Đăng nhập</h3>
      </div>

      <kt-auth-notice class="error-mess"></kt-auth-notice>

      <form class="kt-form" [formGroup]="loginForm">
        <div class="form-group">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label class="color-white">
              {{ 'AUTH.INPUT.EMAIL' | translate }}
            </mat-label>
            <input
              class="input-login"
              type="email"
              placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
              formControlName="email"
              matInput
              name="email"
              id="email"
              autocomplete="off"
            />
            <mat-error
              *ngIf="
                formErrorServie.isControlHasError(
                  loginForm,
                  'email',
                  'required'
                )
              "
            >
              <strong>
                <!-- {{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }} -->
                Email là bắt buộc
              </strong>
            </mat-error>
            <mat-error
              *ngIf="
                formErrorServie.isControlHasError(loginForm, 'email', 'pattern')
              "
            >
              <strong>
                <!-- {{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }} -->
                Email không hợp lệ
              </strong>
            </mat-error>
            <mat-error
              *ngIf="
                formErrorServie.isControlHasError(
                  loginForm,
                  'email',
                  'minlength'
                )
              "
            >
              <strong>
                <!-- {{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3 -->
                Email phải có độ dài hơn 3 kí tự
              </strong>
            </mat-error>
            <mat-error
              *ngIf="
                formErrorServie.isControlHasError(
                  loginForm,
                  'email',
                  'maxlength'
                )
              "
            >
              <strong>
                <!-- {{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320 -->
                Email có thể dài tối đa 320 kí tự
              </strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field class="full-width-field" appearance="outline">
            <!-- <mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label> -->
            <mat-label class="color-white">Mật khẩu</mat-label>
            <!-- <input
              class="input-login"
              [type]="showPass ? 'text' : 'password'"
              placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
              formControlName="password"
              matInput
              name="password"
              id="password"
              autocomplete="new-password"
            /> -->
            <input
              class="input-login"
              [type]="showPass ? 'text' : 'password'"
              placeholder="Mật khẩu"
              formControlName="password"
              matInput
              name="password"
              id="password"
              autocomplete="new-password"
            />
            <mat-error
              *ngIf="
                formErrorServie.isControlHasError(
                  loginForm,
                  'password',
                  'required'
                )
              "
            >
              <strong>
                <!-- {{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }} -->
                Mật khẩu là bắt buộc
              </strong>
            </mat-error>
            <mat-error
              *ngIf="
                formErrorServie.isControlHasError(
                  loginForm,
                  'password',
                  'minlength'
                )
              "
            >
              <strong>
                <!-- {{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3 -->
                Mật khẩu phải có độ dài hơn 3 kí tự
              </strong>
            </mat-error>
            <mat-error
              *ngIf="
                formErrorServie.isControlHasError(
                  loginForm,
                  'password',
                  'maxlength'
                )
              "
            >
              <strong>
                <!-- {{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100 -->
                Mật khẩu có thể dài tối đa 100 kí tự
              </strong>
            </mat-error>
            <mat-icon
              matSuffix
              (click)="showPass = !showPass"
              *ngIf="!showPass"
              class="icon-input"
              matTooltip="Hiển thị mật khẩu"
            >
              visibility
            </mat-icon>
            <mat-icon
              matSuffix
              (click)="showPass = !showPass"
              *ngIf="showPass"
              class="icon-input"
              matTooltip="Ẩn mật khẩu"
            >
              visibility_off</mat-icon
            >
          </mat-form-field>
        </div>

        <div class="mb-16px">
          <button
            mat-flat-button
            color="primary"
            class="btn-login fs-16px"
            (click)="submit()"
          >
            Đăng nhập
          </button>
        </div>
        <div class="mb-16px cnt-center">
          <a
            href="javascript:;"
            (click)="resetForm()"
            class="color-white fs-14px"
            >Quên mật khẩu?</a
          >
        </div>
        <div class="text-center fs-14px register-label-desktop">
          <a routerLink="/user-guide-register" target="_blank" class="forgot-pw"
            >Hướng dẫn đăng ký tài khoản</a
          >
        </div>
        <div class="text-center fs-14px register-label-desktop">
          <a routerLink="/user-guide-workflow" target="_blank" class="forgot-pw"
            >Hướng dẫn nộp hồ sơ</a
          >
        </div>
      </form>
    </div>
  </div>

  <div class="mr-40px screen-fit animated animatedFadeInUp fadeInUp">
    <!-- <div
      [innerHtml]="
        (htmlClassService.companyInfo$ | async)?.login_contact_description
      "
    ></div> -->
    <div class="color-white">
      <span>
        Nếu chưa nhận được thông tin đăng nhập của bạn, hãy liên lạc với Phòng
        CNTT của chúng tôi.
      </span>
      <ul class="kt-padding-l-15">
        <li>Phone: (028) 3511 3399 - Ext 1308</li>
        <li>Email: phongcntt@thanglongreal.vn</li>
      </ul>
    </div>
  </div>
</div>
