import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  forwardRef,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
} from '@angular/forms';
import {environment} from '@app/../environments/environment';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import {Configuration} from '@app/configuration';
import * as _ from 'lodash';
import {CustomHttpUrlEncodingCodec} from '@app/encoder';
import {isThisHour} from 'date-fns';

@Component({
  selector: 'meu-control-select-search-load-more',
  templateUrl: './control-select-search-load-more.component.html',
  styleUrls: ['./control-select-search-load-more.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ControlSelectSearchLoadMoreComponent),
      multi: true,
    },
  ],
})
export class ControlSelectSearchLoadMoreComponent implements OnInit, OnChanges {
  @Input() placeholderLabel: string;
  @Input() noEntriesFoundLabel: string;
  @Input() defaultID: string;
  @Input() nameAPI: string;
  @Input() required: boolean;
  @Input() multiple: boolean;
  @Input() stringFilter: string;
  @Input() columnName: string;
  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() errorLabel: string = 'Vui lòng chọn dữ liệu thích hợp';
  @Input() allowUnSelect = true;
  @Input() isAdd: boolean = false;
  @Output() change = new EventEmitter();
  @Output() changeItem = new EventEmitter();
  @Output() onOpenAddNewRowModal = new EventEmitter();
  @ViewChild('myInputRef', {static: false}) myInputRef: ElementRef;
  imgPath = environment.url_svg_local;

  /* @floatLabel:
   * true => show label in normally
   * false => hide label when input has value
   */
  @Input() floatLabel = true;

  cacheSelectedItem = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes.nameAPI != undefined) {
      this.page = 1;
      this.pageSize = 10;
      this.cacheSelectedItem = [];
      this.filteredInput = [];
    }
    if (changes.stringFilter != undefined) {
      this.page = 1;
      this.pageSize = 10;
      this.cacheSelectedItem = [];
      this.filteredInput = [];
      this.filters = this.stringFilter;
      this.runAPI(this.nameAPI);
    } else {
      this.runAPI(this.nameAPI);
    }
    if (changes.page != undefined) {
      this.runAPI(this.nameAPI);
    }
    if (!!changes.invalid && changes.invalid.currentValue) {
      this.triggerCheckError();
    }
  }
  ngOnInit() {
    this.selectCtrl.valueChanges.subscribe((res) => {
      this.emitChangeValue(this.selectCtrl.value);
      if (res != null && res != undefined) {
        let item = this.filteredInput.find((x) => x.id == res);
        if (item != undefined) {
          if (!this.multiple) {
            this.cacheSelectedItem = [];
          }
          this.cacheSelectedItem.push(item);
        } else {
          if (Array.isArray(res)) {
            for (let i = 0; i < res.length; i++) {
              this.getDataByID(res[i]);
            }
          } else {
            this.getDataByID(res);
          }
        }
      }
    });
  }

  public filteredInput: any[];
  public selectCtrl: FormControl = new FormControl();
  public searchControl: FormControl = new FormControl();
  public page = 1;
  public pageSize = 10;
  public filters = null;
  public totalPage = 0;
  private _value: any;
  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
    this.propagateChange(this._value);
  }
  search(event) {
    this.page = 1;
    this.pageSize = 10;
    this.filteredInput = [];

    this.filters = null;
    if (event != '' || event != null) {
      if (this.stringFilter) {
        this.filters =
          this.stringFilter + ',' + this.columnName + '@=*' + event;
      } else {
        this.filters = this.columnName + '@=*' + event;
      }
    } else {
      if (this.stringFilter != null || this.stringFilter != '') {
        this.filters = this.stringFilter;
      }
    }
    this.runAPI(this.nameAPI);

    //this.filterData(this.searchControl.value);
  }
  loadMore(e) {
    this.page = this.page + 1;
    this.pageSize = this.pageSize + 10;
    this.runAPI(this.nameAPI);
  }

  triggerCheckError() {
    this.selectCtrl.markAsTouched();
  }
  unselect(): void {
    this.defaultID = null;
  }
  runAPI(nameAPI) {
    if (nameAPI == null || nameAPI.trim() == '') {
      return;
    }
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (this.filters !== undefined && this.filters !== null) {
      queryParameters = queryParameters.set('Filters', <any>this.filters);
    }
    if (this.page !== undefined && this.page !== null) {
      queryParameters = queryParameters.set('Page', <any>this.page);
    }
    if (this.pageSize !== undefined && this.pageSize !== null) {
      queryParameters = queryParameters.set('PageSize', <any>this.pageSize);
    }
    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    this.httpClient
      .request<any>('get', `${environment.backendhost + nameAPI}`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
      })
      .subscribe((res) => {
        let array = [...res.data.collection];
        this.totalPage = Math.ceil(res.data.total / 10);
        if (this.filteredInput.length == 0) {
          array.map((item) => {
            this.filteredInput.push(item);
          });
        } else {
          for (let i = 0; i < array.length; i++) {
            if (this.cacheSelectedItem != undefined) {
              let index = this.cacheSelectedItem.findIndex(
                (x) => x.id == array[i].id
              );
              let indexfilteredInput = this.filteredInput.findIndex(
                (x) => x.id == array[i].id
              );
              if (index == -1 && indexfilteredInput == -1) {
                this.filteredInput.push(array[i]);
              }
            }
          }
        }
        this.ChangeDetectorRef.detectChanges();
        //scroll to element
        let index = null;
        try {
          if (this.filteredInput.length % 10 == 0) {
            index = this.filteredInput.length - 10;
          } else {
            index = Math.floor(this.filteredInput.length / 10) * 10;
          }
        } catch (error) {}
        if (index != null && index != 0) {
          if (this.filteredInput[index] !== undefined) {
            let elementID = this.filteredInput[index].id;
            if (document.getElementById(elementID) != undefined) {
              setTimeout(function () {
                document.getElementById(elementID).scrollIntoView(false);
              }, 0.5);
            }
          }
        }
      });

    if (
      this.cacheSelectedItem != null &&
      this.cacheSelectedItem != undefined &&
      this.cacheSelectedItem.length > 0
    ) {
      for (let i = 0; i < this.cacheSelectedItem.length; i++) {
        let index = this.filteredInput.findIndex(
          (x) => x.id == this.cacheSelectedItem[i].id
        );
        if (index < 0) {
          this.filteredInput.push(this.cacheSelectedItem[i]);
        }
      }
    }
  }
  getDataByID(id) {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    let filter = ' id==' + id;
    queryParameters = queryParameters.set('Filters', <any>filter);
    let headers = this.defaultHeaders;
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    this.httpClient
      .request<any>('get', `${environment.backendhost + this.nameAPI}`, {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
      })
      .subscribe((res) => {
        let array = res.data.collection;
        for (let i = 0; i < array.length; i++) {
          this.cacheSelectedItem.push(array[i]);
          let index = this.filteredInput.findIndex((x) => x.id == array[i].id);
          if (index < 0) {
            this.filteredInput.push(array[i]);
          }
        }

        this.ChangeDetectorRef.detectChanges();
      });
  }
  writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    //throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    //throw new Error("Method not implemented.");
  }

  private propagateChange = (_: any) => {};
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  constructor(
    protected httpClient: HttpClient,
    private ChangeDetectorRef: ChangeDetectorRef
  ) {}

  emitChangeValue(event) {
    this.value = event;
    this.change.emit(this.value);
    let index = this.filteredInput.findIndex((x) => x.id === this.value);
    this.changeItem.emit(this.filteredInput[index]);
  }

  emitOpenAddNewModal() {
    this.onOpenAddNewRowModal.emit(true);
  }
}
