/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { User } from '../model/user';
import { WorkflowItem } from '../model/workflowItem';
import { WorkflowItemSignature } from '../model/workflowItemSignature';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WorkflowItemService {

    protected basePath = 'https://gateway.dev.meu-solutions.com/sciencetechnologysso';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiMyWorkflowItemsGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiMyWorkflowItemsGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiMyWorkflowItemsGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiMyWorkflowItemsGet(Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/myWorkflowItems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param dataType 
     * @param Filters 
     * @param Sorts 
     * @param Page 
     * @param PageSize 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsAssignedToMeGet(dataType?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsAssignedToMeGet(dataType?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsAssignedToMeGet(dataType?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsAssignedToMeGet(dataType?: string, Filters?: string, Sorts?: string, Page?: number, PageSize?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (dataType !== undefined && dataType !== null) {
            queryParameters = queryParameters.set('dataType', <any>dataType);
        }
        if (Filters !== undefined && Filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>Filters);
        }
        if (Sorts !== undefined && Sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>Sorts);
        }
        if (Page !== undefined && Page !== null) {
            queryParameters = queryParameters.set('Page', <any>Page);
        }
        if (PageSize !== undefined && PageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>PageSize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/assignedToMe`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdAvailableHashtagsGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdAvailableHashtagsGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdAvailableHashtagsGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdAvailableHashtagsGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdAvailableHashtagsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/availableHashtags`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdCompletePut(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdCompletePut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdCompletePut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdCompletePut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdCompletePut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/complete`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdCurrentActivityAvailableOperatorsGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdCurrentActivityAvailableOperatorsGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdCurrentActivityAvailableOperatorsGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdCurrentActivityAvailableOperatorsGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdCurrentActivityAvailableOperatorsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/currentActivity/availableOperators`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdCurrentActivityIsValidToMoveGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdCurrentActivityIsValidToMoveGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdCurrentActivityIsValidToMoveGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdCurrentActivityIsValidToMoveGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdCurrentActivityIsValidToMoveGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/currentActivity/isValidToMove`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdCurrentActivityOperatorsGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdCurrentActivityOperatorsGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdCurrentActivityOperatorsGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdCurrentActivityOperatorsGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdCurrentActivityOperatorsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/currentActivity/operators`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param comment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdCurrentActivityOperatorsPut(id: string, body?: Array<User>, comment?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdCurrentActivityOperatorsPut(id: string, body?: Array<User>, comment?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdCurrentActivityOperatorsPut(id: string, body?: Array<User>, comment?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdCurrentActivityOperatorsPut(id: string, body?: Array<User>, comment?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdCurrentActivityOperatorsPut.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (comment !== undefined && comment !== null) {
            queryParameters = queryParameters.set('comment', <any>comment);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}/currentActivity/operators`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsIdPut(id: string, body?: WorkflowItem, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsIdPut(id: string, body?: WorkflowItem, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsIdPut(id: string, body?: WorkflowItem, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsIdPut(id: string, body?: WorkflowItem, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsIdPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsPost(body?: WorkflowItem, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsPost(body?: WorkflowItem, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsPost(body?: WorkflowItem, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsPost(body?: WorkflowItem, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param nextDirection 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdAvailableTrasitionsGet(workflowItemId: string, nextDirection?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdAvailableTrasitionsGet(workflowItemId: string, nextDirection?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdAvailableTrasitionsGet(workflowItemId: string, nextDirection?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdAvailableTrasitionsGet(workflowItemId: string, nextDirection?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdAvailableTrasitionsGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (nextDirection !== undefined && nextDirection !== null) {
            queryParameters = queryParameters.set('nextDirection', <any>nextDirection);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/availableTrasitions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param comment 
     * @param password 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdCancelPost(workflowItemId: string, comment?: string, password?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdCancelPost(workflowItemId: string, comment?: string, password?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdCancelPost(workflowItemId: string, comment?: string, password?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdCancelPost(workflowItemId: string, comment?: string, password?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdCancelPost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (comment !== undefined && comment !== null) {
            queryParameters = queryParameters.set('comment', <any>comment);
        }
        if (password !== undefined && password !== null) {
            queryParameters = queryParameters.set('password', <any>password);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/cancel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param comment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdDonePost(workflowItemId: string, comment?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdDonePost(workflowItemId: string, comment?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdDonePost(workflowItemId: string, comment?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdDonePost(workflowItemId: string, comment?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdDonePost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (comment !== undefined && comment !== null) {
            queryParameters = queryParameters.set('comment', <any>comment);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/done`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param comment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdInvalidPost(workflowItemId: string, comment?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdInvalidPost(workflowItemId: string, comment?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdInvalidPost(workflowItemId: string, comment?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdInvalidPost(workflowItemId: string, comment?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdInvalidPost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (comment !== undefined && comment !== null) {
            queryParameters = queryParameters.set('comment', <any>comment);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/invalid`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdModifiedLogsGet(workflowItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdModifiedLogsGet(workflowItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdModifiedLogsGet(workflowItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdModifiedLogsGet(workflowItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdModifiedLogsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/modifiedLogs`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param body 
     * @param transitionID 
     * @param comment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdMoveToTransitionPost(workflowItemId: string, body?: Array<User>, transitionID?: string, comment?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdMoveToTransitionPost(workflowItemId: string, body?: Array<User>, transitionID?: string, comment?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdMoveToTransitionPost(workflowItemId: string, body?: Array<User>, transitionID?: string, comment?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdMoveToTransitionPost(workflowItemId: string, body?: Array<User>, transitionID?: string, comment?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdMoveToTransitionPost.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (transitionID !== undefined && transitionID !== null) {
            queryParameters = queryParameters.set('transitionID', <any>transitionID);
        }
        if (comment !== undefined && comment !== null) {
            queryParameters = queryParameters.set('comment', <any>comment);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/moveToTransition`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdReceiptsGet(workflowItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdReceiptsGet(workflowItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdReceiptsGet(workflowItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdReceiptsGet(workflowItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdReceiptsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/receipts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param receiptID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDGet(workflowItemId: string, receiptID: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDGet(workflowItemId: string, receiptID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDGet(workflowItemId: string, receiptID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDGet(workflowItemId: string, receiptID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDGet.');
        }

        if (receiptID === null || receiptID === undefined) {
            throw new Error('Required parameter receiptID was null or undefined when calling apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/receipts/${encodeURIComponent(String(receiptID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param receiptID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDPdfGet(workflowItemId: string, receiptID: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDPdfGet(workflowItemId: string, receiptID: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDPdfGet(workflowItemId: string, receiptID: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDPdfGet(workflowItemId: string, receiptID: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDPdfGet.');
        }

        if (receiptID === null || receiptID === undefined) {
            throw new Error('Required parameter receiptID was null or undefined when calling apiWorkflowItemsWorkflowItemIdReceiptsReceiptIDPdfGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/receipts/${encodeURIComponent(String(receiptID))}/pdf`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
		responseType: 'blob' as 'json',
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdResultsAttachmentsGet(workflowItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdResultsAttachmentsGet(workflowItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdResultsAttachmentsGet(workflowItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdResultsAttachmentsGet(workflowItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdResultsAttachmentsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/results/attachments`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdResultsFormsGet(workflowItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdResultsFormsGet(workflowItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdResultsFormsGet(workflowItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdResultsFormsGet(workflowItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdResultsFormsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/results/forms`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdSignaturesGet(workflowItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdSignaturesGet(workflowItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdSignaturesGet(workflowItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdSignaturesGet(workflowItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdSignaturesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/signatures`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdSignaturesIdGet(workflowItemId: string, id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdSignaturesIdGet(workflowItemId: string, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdSignaturesIdGet(workflowItemId: string, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdSignaturesIdGet(workflowItemId: string, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdSignaturesIdGet.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiWorkflowItemsWorkflowItemIdSignaturesIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/signatures/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdSignedPost(workflowItemId: string, body?: WorkflowItemSignature, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdSignedPost(workflowItemId: string, body?: WorkflowItemSignature, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdSignedPost(workflowItemId: string, body?: WorkflowItemSignature, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdSignedPost(workflowItemId: string, body?: WorkflowItemSignature, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdSignedPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/signed`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param workflowItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiWorkflowItemsWorkflowItemIdTransitionHistoriesGet(workflowItemId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiWorkflowItemsWorkflowItemIdTransitionHistoriesGet(workflowItemId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiWorkflowItemsWorkflowItemIdTransitionHistoriesGet(workflowItemId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiWorkflowItemsWorkflowItemIdTransitionHistoriesGet(workflowItemId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (workflowItemId === null || workflowItemId === undefined) {
            throw new Error('Required parameter workflowItemId was null or undefined when calling apiWorkflowItemsWorkflowItemIdTransitionHistoriesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/workflowItems/${encodeURIComponent(String(workflowItemId))}/transitionHistories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
