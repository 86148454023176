<label for="{{ name }}" attr.data-name="{{ name }}" class="input-label"
  >{{ title }}
  <span class="required-input-label" *ngIf="isRequiredField">*</span></label
>
<ng-container *ngIf="!iconClass">
  <div>
    <input
      [readonly]="readonly"
      *ngIf="currency; else inputNotCurrency"
      id="{{ name }}"
      [type]="type"
      attr.minlength="{{ minimumLength }}"
      attr.maxlength="{{ maximumLength }}"
      class="input-box {{ classInput }}"
      placeholder="{{ placeholderText }}"
      [formControl]="formControl"
      attr.data-name="{{ name }}"
      pattern="{{ pattern }}"
      attr.inputmode="{{ inputType }}"
      attr.disabled="{{ disabled }}"
      [value]="
        formControl?.value?.toString().split(',').join('') | currency: 'VND':''
      "
      autocomplete="off"
    />
  </div>
  <ng-template #inputNotCurrency>
    <input
      [readonly]="readonly"
      id="{{ name }}"
      [type]="type"
      attr.minlength="{{ minimumLength }}"
      attr.maxlength="{{ maximumLength }}"
      class="input-box {{ classInput }}"
      placeholder="{{ placeholderText }}"
      [formControl]="formControl"
      attr.data-name="{{ name }}"
      pattern="{{ pattern }}"
      attr.inputmode="{{ inputType }}"
      attr.disabled="{{ disabled }}"
      autocomplete="off"
    />
  </ng-template>
</ng-container>
<ng-container *ngIf="iconClass">
  <div class="input-container">
    <div>
      <input
        [readonly]="readonly"
        *ngIf="currency; else inputNotCurrencyIcon"
        id="{{ name }}"
        [type]="type"
        attr.minlength="{{ minimumLength }}"
        attr.maxlength="{{ maximumLength }}"
        class="input-box {{ classInput }}"
        placeholder="{{ placeholderText }}"
        [formControl]="formControl"
        attr.data-name="{{ name }}"
        pattern="{{ pattern }}"
        attr.inputmode="{{ inputType }}"
        attr.disabled="{{ disabled }}"
        [ngClass]="{'input-with-icon-padding': iconClass.length}"
        [value]="
          formControl?.value?.toString().split(',').join('')
            | currency: 'VND':''
        "
        autocomplete="off"
      />
    </div>
    <ng-template #inputNotCurrencyIcon>
      <input
        [readonly]="readonly"
        id="{{ name }}"
        [type]="type"
        attr.minlength="{{ minimumLength }}"
        attr.maxlength="{{ maximumLength }}"
        class="input-box {{ classInput }}"
        placeholder="{{ placeholderText }}"
        [formControl]="formControl"
        attr.data-name="{{ name }}"
        pattern="{{ pattern }}"
        attr.inputmode="{{ inputType }}"
        attr.disabled="{{ disabled }}"
        [ngClass]="{'input-with-icon-padding': iconClass.length}"
        autocomplete="off"
      />
    </ng-template>
    <em class="{{ iconClass }}" (click)="iconClick.emit()"></em>
  </div>
</ng-container>
<div *ngIf="invalidState" class="input-error-label mt-[4px]">
  <ng-content select="[errorMessage]"></ng-content>
</div>
