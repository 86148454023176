import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
export interface DropdownOption {
  id?: string;
  icon?: string;
  name?: string;
  selected?: boolean;
  tooltip?: string;
}
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  AbstractControl,
} from '@angular/forms';
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DropdownComponent),
  multi: true,
};
@Component({
  selector: 'meu-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class DropdownComponent
  implements OnInit, ControlValueAccessor, DoCheck
{
  @Input() classFont = 'text-14px';
  constructor() {}

  get value() {
    return this._value;
  }
  set value(val) {
    this._value = val;
    this.propagateChange(this._value);
  }
  @Input() options: DropdownOption[] = [];
  @Input() name;
  @Input() title;
  @Input() placeholder = '--';
  @Input() align = 'left';
  @Input() isActionMenu = false;
  @Input() canInput = false;
  @Input() inputType = 'text';
  @Input() formControl: FormControl;
  @Input() invalidState: boolean;
  @Input() disabled: boolean;
  @Input() classInput: string;
  @Output() change = new EventEmitter<any>();
  @ViewChild('drdbtn') drdbtn: ElementRef<any>;
  openDropdown = false;
  selectedItem: DropdownOption = {};
  isDisabled = false;
  private _value: any;
  touched = false;
  isRequiredField: boolean = false;

  private propagateChange = (_: any) => {};
  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngDoCheck(): void {
    if (this.value) {
      this.setItemSelected(this.value);
    }
  }

  setItemSelected(selectedId) {
    if (!selectedId) {
      return;
    }
    const item = this.options.find((x) => x.id === selectedId);
    this.selectedItem = item ?? {};
  }

  writeValue(value: any): void {
    if (value && value !== '') {
      this.value = value;
    }
  }

  onSelectItem(item) {
    if (item) {
      if (typeof item !== 'object') {
        this.value = item;
      } else {
        this.value = item.id;
        this.selectedItem = item;
      }
      this.change.emit(this.value);
      this.openDropdown = false;
    } else {
      this.selectedItem = {};
    }
  }

  ngOnInit(): void {
    this.isDisabled = this.disabled;
    this.selectedItem = this.options.find((x) => x.selected) ?? {};
    if (this.selectedItem) {
      this.onSelectItem(this.selectedItem);
    }
  }
  ngAfterViewInit(): void {
    const validator = this.formControl?.validator({} as AbstractControl);
    this.isRequiredField = validator && validator?.required;
  }
}
